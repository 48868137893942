import { sleep } from "../core/AEngine.js";
export class AWindowRelay {
    get win() { return this._window; }
    get doc() { return this.win.document; }
    get $() { return this.win['$']; }
    constructor(opt) {
        this.closeEventId = -1;
        this.onCloseCallbacks = [];
        this.onLoadCallbacks = [];
        const { title, html, features } = Object.assign({
            features: [
                'scrollbars=yes',
                'resizable=yes',
                'status=no',
                'location=no',
                'toolbar=yes',
                'menubar=yes',
                'width=1280',
                'height=720'
            ].join(',')
        }, opt);
        this.title = title;
        this.html = html;
        this.features = features;
        this.init();
        // On BackOffice close
        window.addEventListener('beforeunload', (event) => this.close());
    }
    init() {
        const UNIQUE_ID = 'Central Verification System';
        this._window = open('about:blank', UNIQUE_ID, this.features);
        if (this._window === null) {
            throw new Error(`Browser couldn't open another window!`);
        }
        console.log('hasEle ', this.hasEle('#AjaxContent'));
        if (!this.hasEle('#AjaxContent')) {
            this.writeHtml();
        }
        Promise.all([
            this.waitForEle('#AjaxContent'),
            this.waitForLib('$')
        ]).then(_ => {
            this.onLoadCallbacks.map(cb => cb());
            this.onLoadCallbacks = [];
        });
        this.relayEventsToPrimaryWindow();
    }
    relayEventsToPrimaryWindow() {
        $(this.doc).on('keydown', (e) => {
            // const catchAll = ['F1', 'F2', 'F3', 'F4']
            // if (catchAll.includes(e.key)) {
            //   e.preventDefault()
            //   e.stopPropagation()
            // }
            $(document).trigger(e);
        });
    }
    writeHtml() {
        this.doc.write(/*html*/ `
      <html>
      <head>
        <title>${this.title}</title>
        <meta http-equiv="X-UA-Compatible" content="IE=EDGE" />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <link rel="stylesheet" href="/index.min.css">
        <!-- SPECTRE -->
        <link rel="stylesheet" href="/third-party/spectre-0.5.2/reset.css">
        <link rel="stylesheet" href="/third-party/spectre-0.5.2/spectre.min.css">
        <link rel="stylesheet" href="/third-party/spectre-0.5.2/spectre-exp.min.css">
        <link rel="stylesheet" href="/third-party/spectre-0.5.2/spectre-icons.min.css">
        <!-- BRYNTUM GRID-->
        <link href="/third-party/grid-4.1.3/build/grid.classic-light.css" rel="stylesheet" id="bryntum-theme">
        <!-- FONT AWESOME -->
        <link href="/third-party/fontawesome-pro-6.3.0/css/all.min.css" rel="stylesheet">
        <!-- JQUERY -->
        <script type="text/javascript" src="/third-party/jquery-3.6.3/jquery-3.6.3.min.js"></script>
        <script type="text/javascript" src="/third-party/jquery-1.11.3/jquery.resize.js"></script>
        <script type="text/javascript" src="/third-party/jquery-1.11.3/jquery.floatThead.min.js"></script>
        <script>
          function onload() {
            alert('onload called!')
          }
        </script>
      </head>
      <body class="gradient-default" onload="onload();">
        <div class="container" id="Main">
          <div class="header-strip">
            <div class="top-logo" atooltip="...">
              <img id="Logo" src="/img/logo-orange.svg">
            </div>
            <ul class="strip-list">
            </ul>
          </div>

          <div class="main col-12">
            <nav id="LanguageFrame" class="mid sidebar-overlay"></nav>
            <div class="sidebar-menu">
              <span class="subtitle">scanacar</span>
              <ul class="menu-links"></ul>
            </div>
            <article id="AjaxContent">${this.html}</article>
          </div>

          <div id="loading" class="noselect">
            <div class="loading-circular"></div>
            <span>Loading</span>
          </div>
        </div>
        <div class="background-overlay"></div>
      </body>
      </html>
    `);
    }
    hasEle(select) {
        let $obj = $(this.doc.querySelector(select));
        return $obj.length > 0;
    }
    async waitForEle(select, maxAttempts = 20) {
        let attempts = 0;
        let obj = null;
        do {
            obj = $(this.doc.querySelector(select));
            await sleep(200);
        } while (obj.length === 0 && ++attempts < maxAttempts);
        if (obj === null) {
            throw new Error(`Element $('${select}') couldn't be found!`);
        }
        return obj;
    }
    async waitForLib(getter, maxAttempts = 20) {
        let attempts = 0;
        let obj = null;
        do {
            obj = this.win[getter];
            await sleep(200);
        } while (obj !== undefined && ++attempts < maxAttempts);
        if (obj === null) {
            throw new Error(`Lib ${getter} couldn't be found!`);
        }
        return obj;
    }
    getElementById(id) {
        return this.doc.getElementById(id);
    }
    querySelector(query) {
        return this.doc.querySelector(query);
    }
    querySelectorAll(query) {
        return this.doc.querySelectorAll(query);
    }
    onLoad(callback) {
        this.onLoadCallbacks.push(callback);
    }
    onClose(callback) {
        this.onCloseCallbacks.push(callback);
        if (this.closeEventId === -1) {
            this.closeEventId = window.setInterval(() => this.checkIfClosed(), 1000);
        }
    }
    close() {
        try {
            this.win.close();
            this.checkIfClosed();
        }
        catch (err) {
            console.error(err);
        }
    }
    checkIfClosed() {
        if (this.win.closed) {
            this.closeEventId = -1;
            clearInterval(this.closeEventId);
            this.onCloseCallbacks.map(cb => cb());
            this.onCloseCallbacks = [];
        }
    }
}
