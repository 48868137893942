import { AConfig } from "../classes/AConfig.js";
import { ATableFormatter } from "../classes/ATableFormatter.js";
export function default_order() {
    return AConfig.get('infoWindows.default.order', []);
}
export function default_tableformatter() {
    return new ATableFormatter(AConfig.get('infoWindows.default.definitions'));
}
export function team_sorting() {
    return [
        "User",
        "UserDisplayName",
        "Status",
        "StatusString",
        "ComState",
        "ValidLogin",
        "CheckPRDB",
        "NodeId",
        "DeviceName",
        "NodeName",
        "NodeType",
        "Unit",
        "UserSessionId",
        "LastUpdated",
        "Gps",
        "UserGroups",
        "id_mrs",
    ];
}
export function team_tableformatter() {
    return new ATableFormatter({
        "ValidLogin": { type: "BOOLEAN" },
        "User": { type: "TEXT" },
        "UserDisplayName": { type: "TEXT" },
        "Status": { type: "TEXT" },
        "StatusString": { type: "TEXT" },
        "ComState": { type: "TEXT" },
        "NodeId": { type: "TEXT" },
        "DeviceName": { type: "TEXT" },
        "NodeName": { type: "TEXT" },
        "NodeType": { type: "TEXT" },
        "Unit": { type: "TEXT" },
        "CheckPRDB": { type: "BOOLEAN" },
        "LastUpdated": { type: "TEXT" },
        "UserSessionId": { type: "HIDDEN" },
        "Gps": { type: "HIDDEN" },
        "UserGroups": { type: "HIDDEN" },
        "id_mrs": { type: "HIDDEN" },
    });
}
export function gpsstat_tableformatter() {
    return new ATableFormatter({
        'gpsTime': { type: "DATETIME" },
        'deviceName': { type: "TEXT" },
        'speed': { type: "NUMBER" },
        'precision': { type: "NUMBER" },
    });
}
export function locationimagerequest_tableformatter() {
    return new ATableFormatter({
        'DetectionId': { type: "TEXT" },
        'DetectionDeviceId': { type: "NUMBER" },
        'LocationType': { type: "TEXT" },
        'Latitude': { type: "TEXT" },
        'Longitude': { type: "TEXT" },
        'BeginTimeFrame': { type: "TEXT" },
        'EndTimeFrame': { type: "TEXT" },
        'Days': { type: "TEXT" },
        'ExpiryDate': { type: "DATETIME" },
        'CreationTime': { type: "DATETIME" },
        'CreationUser': { type: "TEXT" },
        'Description': { type: "TEXT" },
        'Valid': { type: "BOOLEAN" },
    });
}
export function locationimage_tableformatter() {
    return new ATableFormatter({
        'LocationId': { type: 'TEXT' },
        'CreationDeviceId': { type: 'TEXT' },
        'LocationType': { type: 'TEXT' },
        'Name': { type: 'TEXT' },
        'Description': { type: 'TEXT' },
        'BeginTimeFrame': { type: 'TEXT' },
        'EndTimeFrame': { type: 'TEXT' },
        'Days': { type: 'TEXT' },
        'ExpiryDate': { type: 'DATETIME' },
        'CreationTime': { type: 'DATETIME' },
        'CreationUser': { type: 'TEXT' },
        'GpsPoint': { type: 'HIDDEN' },
        'parentIndex': { type: 'HIDDEN' },
        'id': { type: 'HIDDEN' },
        'ImageTime': { type: 'DATETIME' },
        'Images': { type: 'NUMBER' },
    });
}
export function polygons_tableformatter() {
    return new ATableFormatter({
        'Name': { type: "TEXT" },
        'Bounds': { type: "HIDDEN" },
        'Attributes': { type: "OBJECT" },
        'Center': { type: "OBJECT" },
        'SRID': { type: "TEXT" }
    });
}
export function trafficsigns_tableformatter() {
    return new ATableFormatter({
        'data': { type: "HIDDEN" },
        'CameraId': { type: "TEXT" },
        'Confidence': { type: "NUMBER" },
        'DetectionDevice': { type: "TEXT" },
        'DetectionDeviceId': { type: "TEXT" },
        'DetectionId': { type: "TEXT" },
        'DetectionTime': { type: "DATETIME" },
        'DetectionValid': { type: "BOOLEAN" },
        'GpsPrecision': { type: "NUMBER" },
        'ScanDeviceCenter': { type: "HIDDEN" },
        'ScanDeviceDirection': { type: "TEXT" },
        'ScanDeviceSpeed': { type: "NUMBER" },
        'SessionId': { type: "TEXT" },
        'SessionMode': { type: "TEXT" },
        'SessionName': { type: "TEXT" },
        'Side': { type: "TEXT" },
        'TrafficSign': { type: "TEXT" },
        'TrafficSignCenter': { type: "OBJECT" },
        'TrafficSignText': { type: "TEXT" },
    });
}
