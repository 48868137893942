import { ALoopTimerAsync } from "../classes/ALoopTimerAsync.js";
import { sleep } from "../core/AEngine.js";
import { EVENTS } from "./AEventService.js";
export class AQueueService {
    get cachedChannelStats() { return this.channelStats; }
    constructor() { }
    async autoInit() {
        this.queueTimer = new ALoopTimerAsync(async () => {
            await Promise.all([
                (Events.hasRoute(EVENTS.CHANNEL_QUEUES_UPDATED)) ? await this.refreshChannelStats({ triggerEvent: true }) : Promise.resolve(),
                sleep(1500),
            ]);
        }, { loopLifeCycle: 'CONTINUOUS' }).start();
    }
    // private async refetchChannelStats() {
    //   this.channelStats = await requestService.send<AChannelStats[]>('ChannelStatsRequest', {}, { waitForEvent: 'ChannelStatsResponse' })
    //   return this.channelStats
    // }
    async refreshChannelStats(opt) {
        this.channelStats = await requestService.send('ChannelStatsRequest', {}, { waitForEvent: 'ChannelStatsResponse', priority: 3 });
        if (opt.triggerEvent === true) {
            Events.tryInvoke(EVENTS.CHANNEL_QUEUES_UPDATED, this.channelStats);
        }
        return this.channelStats;
        // return this.channelStats || this.refetchChannelStats()
    }
}
