function convertTooltipText(tooltipText) {
    return tooltipText.replace(/(\r\n)+/g, '<br>').replace(/\n+/g, '<br>');
}
export const initToolTip = async ($ele, { includeNewlines, align }) => {
    const $tooltip = $('.atooltip-overlay');
    $ele.attr('atooltip-init', "done");
    if ($ele.is('[atooltip-translate="true"]')) {
        const translated = await Translate.get($ele.attr('atooltip'));
        $ele.attr('atooltip', translated);
    }
    $ele.on({
        mouseenter: function (e) {
            const tooltipText = ($ele.attr('atooltip') || '').trim();
            $tooltip.css({ 'text-align': align });
            $tooltip.html(includeNewlines ? convertTooltipText(tooltipText) : tooltipText);
            const $t = $(e.target).is('[atooltip]') ? $(e.target) : $(e.target).closest('[atooltip]');
            if ($t.length > 0) {
                let { left, top, width, height } = $t.get(0).getBoundingClientRect();
                $tooltip.css('left', (left + Math.round(width / 2.0)) + 'px');
                $tooltip.css('top', (top + height) + 'px');
                $tooltip.addClass('active');
            }
        },
        mouseleave: function (e) {
            $tooltip.removeClass('active');
        }
    });
};
export function initToolTips($tooltips, options) {
    const opt = Object.assign({
        includeNewlines: false,
        align: 'center'
    }, options);
    let $tooltip = $('.atooltip-overlay');
    if ($tooltip.length === 0) {
        $tooltip = $(`<div class="atooltip-overlay"></div>`);
        $('body').append($tooltip);
    }
    if ($tooltips.length > 0) {
        $tooltips.toArray().map(tt => $(tt))
            .filter($tt => $tt.attr('atooltip-init') === undefined)
            .map($tt => initToolTip($tt, opt));
    }
}
