import { AConfig } from "../classes/AConfig.js";
import { AError } from "../classes/AError.js";
import { AResponse } from "../classes/AResponse.js";
import { AGetCssRule, AUrlEncodedImageFromBase64 } from "./tools.js";
export var DISPLAY_TYPES;
(function (DISPLAY_TYPES) {
    DISPLAY_TYPES[DISPLAY_TYPES["Polygon"] = 0] = "Polygon";
    DISPLAY_TYPES[DISPLAY_TYPES["Position"] = 1] = "Position";
    DISPLAY_TYPES[DISPLAY_TYPES["None"] = 2] = "None";
})(DISPLAY_TYPES || (DISPLAY_TYPES = {}));
export function RequestTrafficSigns(Filters, InnerQuery) {
    // Potential MySQL Injection?
    return requestService.query({
        Name: 'RequestTrafficSigns',
        Query: `${InnerQuery}`,
        Params: Filters,
        Language: Language,
        Translate: ['TrafficSignText']
    }, { cacheQuery: true });
}
export function RequestAndShowTrafficSign(marker) {
    const [DetectionDeviceId, DetectionId, TrafficSign] = marker.data.id.split('_');
    return requestService.query({
        Name: `RequestTrafficSign`,
        Query: (`
      SELECT
        ST_AsGeoJSON(TrafficSignCenter) as TrafficSignCenter,
        ST_AsGeoJSON(ScanDeviceCenter) as ScanDeviceCenter,
        DetectionId,
        DetectionDeviceId,
        TrafficSign,
        DetectionDevice,
        SessionId,
        SessionMode,
        SessionName,
        TrafficSignText,
        DetectionTime,
        DetectionValid,
        Confidence,
        Side,
        CameraId,
        ScanDeviceSpeed,
        ScanDeviceDirection,
        GpsPrecision
      FROM traffic_signs
      WHERE
        DetectionDeviceId=:DetectionDeviceId AND
        DetectionId=:DetectionId AND
        TrafficSign=:TrafficSign
      LIMIT 1
    `),
        Params: {
            DetectionDeviceId,
            DetectionId,
            TrafficSign
        },
        Language: Language,
        Translate: ['TrafficSignText']
    }).then((response) => {
        response.Rows = response.Rows.map((row) => {
            if (row[0] && row[0].coordinates && row[0].coordinates.length > 1) {
                row[0] = {
                    Longitude: row[0].coordinates[0],
                    Latitude: row[0].coordinates[1]
                };
            }
            if (row[1] && row[1].coordinates && row[1].coordinates.length > 1) {
                row[1] = {
                    Longitude: row[1].coordinates[0],
                    Latitude: row[1].coordinates[1]
                };
            }
            return row;
        });
        // return ATableBuilder.DisplayInfoWindowRealtime(marker, [priorityText, fullText])
        return response;
    }).catch(AError.handle);
}
/**
 * @deprecated
 * @param {*} lat
 * @returns
 */
export function groupMeterLat(lat) {
    return lat * 111111.111;
}
/**
 * @deprecated
 * @param {*} lng
 * @returns
 */
export function groupMeterLng(lng) {
    return lng * 111111.111;
}
export function ShowTrafficSigns(response, bounds) {
    const iDetectionId = response.Columns.indexOf('DetectionId'), iDetectionDeviceId = response.Columns.indexOf('DetectionDeviceId'), iTrafficSign = response.Columns.indexOf('TrafficSign'), iTrafficSignText = response.Columns.indexOf('TrafficSignText'), iTrafficSignCenter = response.Columns.indexOf('TrafficSignCenter'), iScanDeviceCenter = response.Columns.indexOf('ScanDeviceCenter'), iDetectionTime = response.Columns.indexOf('DetectionTime');
    const output = [];
    for (const r of response.Rows) {
        const DetectionId = r[iDetectionId], DetectionDeviceId = r[iDetectionDeviceId], TrafficSign = r[iTrafficSign], TrafficSignText = r[iTrafficSignText], TrafficSignLongitude = r[iTrafficSignCenter].coordinates[0], // Math.round(row[iTrafficSignLongitude] * 1111.111) / 1111.111
        TrafficSignLatitude = r[iTrafficSignCenter].coordinates[1], // Math.round(row[iTrafficSignLatitude] * 1111.111) / 1111.111
        ScanDeviceLongitude = r[iScanDeviceCenter] ? r[iScanDeviceCenter].coordinates[0] : TrafficSignLongitude, ScanDeviceLatitude = r[iScanDeviceCenter] ? r[iScanDeviceCenter].coordinates[1] : TrafficSignLatitude, DetectionTime = r[iDetectionTime];
        const point = new google.maps.LatLng(TrafficSignLatitude, TrafficSignLongitude);
        const image = {
            url: `/img/trafficsigns/nl/${TrafficSign}.png`,
            scaledSize: new google.maps.Size(32, 32),
            origin: new google.maps.Point(0, 0)
        };
        // let m: AMarker = new AMarker();
        let marker = new google.maps.Marker({
            // strokeColor: '#FF0000',
            // strokeOpacity: 0.8,
            // strokeWeight: 2,
            // fillColor: '#FF0000',
            // fillOpacity: 0.35,
            icon: image,
            animation: google.maps.Animation.DROP,
            position: point,
            // flat: true,
        });
        marker.data = {
            id: `${DetectionDeviceId}_${DetectionId}_${TrafficSign}`,
            DetectionId,
            DetectionDeviceId,
            TrafficSign,
            TrafficSignText,
            TrafficSignLongitude,
            TrafficSignLatitude,
            ScanDeviceLongitude,
            ScanDeviceLatitude,
            DetectionTime
        };
        if (bounds != null) {
            bounds.extend(point);
        }
        output.push(marker);
        // marker.setMap(map)
    }
    return output;
}
/**
 * Generates where clause for the following filters [ParkingRight, Verification, IllegallyParked, DeviceName, Area, Zone]
 * @param {*} filters
 * @returns {string} string with all the where clauses conjucted by ' AND '
 */
export function generateWhereClauseDefault({ filters }) {
    const conditions = [];
    if (filters['ParkingRight'])
        conditions.push(`ParkingRight IN (${filters['ParkingRight'].join(', ')})`);
    if (filters['Verification'])
        conditions.push(`Verification IN (${filters['Verification'].join(', ')})`);
    if (filters['IllegallyParked'])
        conditions.push(`IllegallyParked IN (${filters['IllegallyParked'].join(', ')})`);
    if (filters['DeviceName'])
        conditions.push(`DetectionDevice=:DeviceName`);
    if (filters['Area'])
        conditions.push('Area=:Area');
    if (filters['Zone'])
        conditions.push('Zone=:Zone');
    const whereClause = conditions.join(' AND ');
    return (conditions.length > 0) ? whereClause : '1=1';
}
export function RequestMapScans(filters, InnerQuery, options = {}) {
    const { extraColumns } = Object.assign({
        extraColumns: []
    }, options);
    const whereClause = generateWhereClauseDefault({ filters });
    const extraColumnsText = extraColumns.length ? extraColumns.join(',') + ',' : '';
    // Potential MySQL Injection?
    return requestService.query({
        Name: 'MapScans',
        Query: `
      SELECT
          ${extraColumnsText}
          ScanDeviceLatitude,
          ScanDeviceLongitude,
          VehicleBounds,
          VehicleCenterLatitude,
          VehicleCenterLongitude,
          DetectionId,
          DetectionDeviceId,
          DetectionTime,
          LicensePlate,
          Area,
          Confidence,
          Side,
          CameraIds,
          GpsPrecision,
          VehicleType,
          VehicleMoving,
          HasParkingRight,
          IsIllegallyParked,
          ParkingAreaType,
          TaxRequired,
          ParkingRightType,
          ParkingRightResults,
          NearMatches,
          AreaId,
          AreaConfidence,
          ParkingSpaceId,
          ParkingSpaceConfidence,
          ZoneId,
          ZoneConfidence,
          SegmentId,
          SegmentConfidence,
          SegmentTimestamp,
          ScanDeviceDirection,
          CardinalDirection,

          Digital,
          IllegallyParked,
          ParkingRight,
          Verification,
          TimeLimitedParking,
          DetectionState,

          keyDigital,
          keyIllegallyParked,
          keyParkingRight,
          keyVerification,
          keyTimeLimitedParking,
          keyDetectionState,

          Label
      FROM (${InnerQuery}) full
      WHERE
          ${whereClause}
      ORDER BY DetectionId
      LIMIT :Limit`,
        Params: filters,
        Language: Language,
        Translate: ["VehicleType", "ParkingAreaType", "ParkingRightType", "IsIllegallyParked", "Digital", "IllegallyParked", "ParkingRight", "Verification", "DetectionState"]
    }, { cacheQuery: true });
}
export function RequestParkingSpaceMapScans(Filters, InnerQuery) {
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "MapScans",
        Query: "SELECT VehicleBounds,VehicleCenterLatitude,VehicleCenterLongitude,DetectionTime, LicensePlate, Area, Confidence,Side,CameraIds,GpsPrecision,VehicleMoving,HasParkingRight,ParkingRightType,NearMatches,AreaId,AreaConfidence, ParkingSpaceId,ParkingSpaceConfidence,SegmentId,SegmentTimestamp,ScanDeviceDirection,CardinalDirection from (" + InnerQuery + ") full inner join(" + ParkingPlaceDetections + ") u using(DetectionId,DetectionDeviceId) where ABS(VehicleSpeed) between :MinSpeed and :MaxSpeed and GpsPrecision between :MinGpsPrecision and :MaxGpsPrecision and IF(:ParkingRightType='%',true, ParkingRightType=:ParkingRightType) and IF(:DeviceName='%',true, DetectionDevice=:DeviceName) and IF(:Area='%',true, Area=:Area) and IF(:VerifyResult='%',true, VerificationResult=:VerifyResult) ORDER BY DetectionId LIMIT :Limit",
        Params: Filters,
        Language: Language,
        Context: document.location.hash.substr(2),
        Translate: ["VehicleType", "ParkingAreaType", "ParkingRightType"]
    });
}
export function RequestMapRouteFull(Filters) {
    return requestService.query({
        Name: "MapRouteFull",
        Query: (`
    SELECT GpsTime, DeviceName, Latitude, Longitude, Points, \`Precision\`, Speed
      FROM gps
      WHERE (GpsTime BETWEEN :FromDate AND :ToDate)
        AND IF(:DeviceName='%',true,DeviceName=:DeviceName)
        AND Speed IS NOT NULL
        AND ValidGps=1
      ORDER BY DeviceName, GpsTime DESC
      LIMIT :RouteLimit
    `),
        Params: Object.assign({
            DeviceName: '%',
            RouteLimit: 5000,
        }, Filters),
        Language
    });
}
/**
 * @deprecated
 */
export function RequestMapRoute(Filters) {
    return [
        requestService.query({
            Name: "MapRouteGps",
            Query: (`
          SELECT SessionId, GpsTime, Latitude, Longitude 
          FROM gps 
          WHERE (GpsTime Between :FromDate AND :ToDate)
          AND DeviceName like :DeviceName 
          AND Latitude!=0 AND Longitude!=0 
          AND if(:Area='%' or :Area is null, true, ST_Contains((select Bounds from geo_areas where Name=:Area) , ST_GeomFromText(concat ('POINT(',Longitude, ' ', Latitude, ')' ), 4326 ) ) )
      `),
            Params: {
                FromDate: Filters.FromDate,
                ToDate: Filters.ToDate,
                DeviceName: Filters.DeviceName,
                Area: Filters.Area
            },
            Language: Language,
            Translate: []
        }),
        requestService.query({
            Name: "MapRouteSessionStatus",
            Query: "select SessionId,Time,Status from sessionstatus where SessionId in (SELECT SessionId FROM gps where GpsTime>=:FromDate and GpsTime<=:ToDate and DeviceName like :DeviceName group by SessionId);",
            Params: {
                FromDate: Filters.FromDate,
                ToDate: Filters.ToDate,
                DeviceName: Filters.DeviceName
            }
        }),
        requestService.query({
            Name: "MapRouteCamStatus",
            Query: "select SessionId,Time,Left_DetectingCount,Right_DetectingCount from camstatus where SessionId in (SELECT SessionId FROM gps where GpsTime>=:FromDate and GpsTime<=:ToDate and DeviceName like :DeviceName group by SessionId);",
            Params: {
                FromDate: Filters.FromDate,
                ToDate: Filters.ToDate,
                DeviceName: Filters.DeviceName
            }
        })
    ];
}
export function RequestMapRouteLatestSession() {
    return [
        requestService.query({
            Name: "MapRouteGps",
            Query: `
            SELECT SessionId, GpsTime, Latitude, Longitude 
            FROM gps 
            WHERE SessionId = (select max(SessionId) from gps where DeviceName like 'Scan%' AND Latitude!=0 AND Longitude!=0);`,
            Params: {},
            Language: Language,
            Translate: []
        }),
        requestService.query({
            Name: "MapRouteSessionStatus",
            Query: "select SessionId,Time,Status from sessionstatus where SessionId = (select max(SessionId) from gps where DeviceName like 'Scan%' AND Latitude!=0 AND Longitude!=0);",
            Params: {}
        }),
        requestService.query({
            Name: "MapRouteCamStatus",
            Query: "select SessionId,Time,Left_DetectingCount,Right_DetectingCount from camstatus where SessionId = (select max(SessionId) from gps where DeviceName like 'Scan%' AND Latitude!=0 AND Longitude!=0);",
            Params: {}
        })
    ];
}
/**
 * @param {{ DetectionId, DetectionDeviceId, VehicleBounds, VehicleCenterLatitude, VehicleCenterLongitude }} args
 * @returns {{ displayType: Number, center?: {lat: Number, lng: Number}, coordinates?: any }}
 */
export function getDetectionDisplay(args) {
    const { DetectionId, DetectionDeviceId, VehicleBounds, VehicleCenterLatitude, VehicleCenterLongitude } = args;
    if (VehicleBounds) {
        const { coordinates, center } = mapHelperService.geoJsonToPolygonCoords(VehicleBounds);
        if (AIsLatLngValid([center.lat, center.lng])) {
            return {
                displayType: DISPLAY_TYPES.Polygon,
                coordinates,
                center
            };
        }
    }
    if (AIsLatLngValid([VehicleCenterLatitude, VehicleCenterLongitude])) {
        return {
            displayType: DISPLAY_TYPES.Position,
            center: {
                lat: Number(VehicleCenterLatitude),
                lng: Number(VehicleCenterLongitude)
            }
        };
    }
    console.warn({ DetectionId, DetectionDeviceId, Err: `Couldn't draw detection because of no gps data` });
    return {
        displayType: DISPLAY_TYPES.None
    };
}
export function ShowMapScans({ response, map, bounds, infoParent }) {
    const output = [];
    const toRemove = [];
    const detectionAttributes = {
        strokeOpacity: 0.733,
        strokeWeight: 3,
        fillOpacity: 0.35,
        zIndex: 10.0
    };
    const USE_DESKCONTROL = PageScript.hasOwnProperty('DeskControl');
    const applyCustomCaching = (USE_DESKCONTROL) ?
        (marker, detection) => {
            const { Label, LicensePlate, IsIllegallyParked, DetectionTime, Image } = detection;
            marker.Label = Label;
            marker.LicensePlate = LicensePlate;
            marker.IsIllegallyParked = IsIllegallyParked;
            marker.Date = new Date(Date.parse(DetectionTime));
            marker.LPImage = Image;
            marker._onclick = MarkerClickProxy;
        } : () => { };
    const addEventListeners = (USE_DESKCONTROL) ?
        (marker) => {
            google.maps.event.addListener(marker, "click", () => { PageScript.DeskControl.markerClick(marker, false); });
            google.maps.event.addListener(marker, "rightclick", OnRightClick);
        } :
        (marker) => {
            google.maps.event.addListener(marker, "click", function (args) { purgatoryService.onMarkerClickRealtime(this, args); });
            google.maps.event.addListener(marker, "rightclick", OnRightClick);
        };
    const ares = new AResponse(response);
    ares.loop((detection, i) => {
        let marker = null;
        const { HasParkingRight, IsIllegallyParked, DetectionId, DetectionDeviceId, keyDigital, keyIllegallyParked, keyVerification, keyParkingRight, keyDetectionState } = detection;
        let { displayType, center, coordinates } = getDetectionDisplay(detection);
        switch (displayType) {
            case DISPLAY_TYPES.None:
                toRemove.push(i);
                return;
            case DISPLAY_TYPES.Position:
                if (bounds !== undefined && center !== undefined)
                    bounds.extend(center);
                marker = new google.maps.Marker({ position: center });
                break;
            case DISPLAY_TYPES.Polygon:
                if (bounds !== undefined && center !== undefined)
                    bounds.extend(center);
                marker = new google.maps.Polygon(Object.assign({}, detectionAttributes, {
                    paths: coordinates,
                    position: center
                }));
                break;
        }
        detectionService.setMarkerIdentifier(marker, DetectionDeviceId, DetectionId);
        detectionService.setMarkerFinal(marker, { HasParkingRight, IsIllegallyParked, keyParkingRight, keyVerification, keyDetectionState, keyDigital, keyIllegallyParked });
        marker.DetectionId = DetectionId;
        marker.DetectionDeviceId = DetectionDeviceId;
        marker.Index = output.length;
        marker.infoParent = infoParent;
        applyCustomCaching(marker, detection);
        addEventListeners(marker);
        if (displayType === DISPLAY_TYPES.Polygon) {
            const clr = mapHelperService.calcLegendColor(marker._final, { opacity: true });
            const { fill, stroke } = (typeof clr === 'string') ? { fill: clr, stroke: clr } : clr;
            marker.setOptions({
                strokeColor: stroke,
                fillColor: fill
            });
        }
        marker.setMap(map);
        output.push(marker);
    });
    // TODO: Check whether toRemove needs to exist
    toRemove.reverse().map(index => {
        response.Rows.splice(index, 1);
    });
    if (toRemove.length > 0) {
        console.warn(`skipped ${toRemove.length} detections`);
    }
    mapHelperService.updateCountLabel(output.length);
    return output;
}
/**
 * @deprecated
 */
export async function CVS_ShowMapScans({ verifyRequests, map, bounds }) {
    let output = [];
    if (!(verifyRequests instanceof Array)) {
        verifyRequests = [verifyRequests];
    }
    const detectionAttributes = {
        strokeOpacity: 0.733,
        strokeWeight: 3,
        fillOpacity: 0.35,
        zIndex: 10.0
    };
    const calcVehicleBounds = ({ vehicleBoundsJson }) => {
        let vehicleBounds = [];
        for (let p = 0; p < vehicleBoundsJson.Points.length; p++) {
            vehicleBounds.push({
                lat: vehicleBoundsJson.Points[p].X,
                lng: vehicleBoundsJson.Points[p].Y
            });
        }
        return vehicleBounds;
    };
    const calcVehicleCenter = ({ verify, vehicleBoundsJson }) => {
        return (AIsLatLngValid([verify.Latitude, verify.Longitude])) ?
            { lat: verify.Latitude, lng: verify.Longitude } :
            extractCenterFromVehicleBoundsJson(vehicleBoundsJson);
    };
    for (let verify of verifyRequests) {
        const vehicleBoundsJson = extractBoundsFromStreamCVS(verify);
        const vehicleBounds = calcVehicleBounds({ vehicleBoundsJson });
        const center = calcVehicleCenter({ verify, vehicleBoundsJson });
        const { FillColor, StrokeColor } = mapHelperService.getColors({
            HasParkingRight: verify.HasParkingRight,
            IsIllegallyParked: verify.IsIllegallyParked
        });
        const marker = new google.maps.Polygon(Object.assign({}, detectionAttributes, {
            paths: vehicleBounds,
            strokeColor: StrokeColor,
            fillColor: FillColor,
            position: center
        }));
        detectionService.setMarkerIdentifier(marker, verify.DetectionDeviceId, verify.DetectionId);
        Object.assign(marker, {
            Index: output.length,
            DetectionId: verify.DetectionId,
            DetectionDeviceId: verify.DetectionDeviceId,
            LicensePlate: verify.LicensePlate,
            IsIllegallyParked: verify.IsIllegallyParked,
            Date: new Date(Date.parse(verify.DetectionTime)),
            LPImage: verify.LPImage,
            cachedVerify: Object.assign({}, verify)
        });
        if (verify.OverviewImages) {
            if (typeof verify.OverviewImages == 'string') {
                verify.OverviewImages = JSON.parse(verify.OverviewImages);
            }
            marker.images = verify.OverviewImages.map(img => AUrlEncodedImageFromBase64(img));
            if (marker.images && marker.images.length) {
                marker.images.unshift(AUrlEncodedImageFromBase64(verify.CarImage));
            }
        }
        marker._onclick = MarkerClickProxy;
        google.maps.event.addListener(marker, "click", _ => PageScript.DeskControl.markerClick(marker, false));
        google.maps.event.addListener(marker, "rightclick", OnRightClick);
        bounds.extend(center);
        marker.setMap(map);
        output.push(marker);
    }
    mapHelperService.updateCountLabel(output.length);
    return output;
}
/**
 * @deprecated
 */
export function ShowMapScansOriginalBounds(response, map, bounds, offset) {
    let output = [];
    let VehicleBoundsIndex = response.Columns.indexOf("VehicleBounds");
    let OrgVehicleBoundsIndex = response.Columns.indexOf("OriginalVehicleBounds");
    let DetectionIdIndex = response.Columns.indexOf("DetectionId");
    let DetectionDeviceIdIndex = response.Columns.indexOf("DetectionDeviceId");
    for (let i = 0; i < response.Rows.length; i++) {
        let CurrentRow = response.Rows[i];
        let VehicleBounds = CurrentRow[VehicleBoundsIndex];
        let OrgVehicleBounds = CurrentRow[OrgVehicleBoundsIndex];
        let DetectionId = CurrentRow[DetectionIdIndex];
        let DetectionDeviceId = CurrentRow[DetectionDeviceIdIndex];
        let Marker = null;
        let OriginalMarker = null;
        if (VehicleBounds && OrgVehicleBounds) {
            const correctedGps = mapHelperService.geoJsonToPolygonCoords(VehicleBounds);
            if (AIsLatLngValid([correctedGps.center.lat, correctedGps.center.lng])) {
                bounds.extend({
                    lat: correctedGps.center.lat,
                    lng: correctedGps.center.lng
                });
                Marker = new google.maps.Polygon({
                    paths: correctedGps.coordinates,
                    strokeColor: '#00FF00',
                    strokeOpacity: 0.733,
                    strokeWeight: 3,
                    fillColor: '#00FF00',
                    fillOpacity: 0.35,
                    zIndex: 10.0,
                    // position: {
                    //     lat: correctedGps.center.lat,
                    //     lng: correctedGps.center.lng
                    // }
                });
            }
            const originalGps = mapHelperService.geoJsonToPolygonCoords(OrgVehicleBounds);
            if (AIsLatLngValid([originalGps.center.lat, originalGps.center.lng])) {
                OriginalMarker = new google.maps.Polygon({
                    paths: originalGps.coordinates.map((latLng) => {
                        latLng.lng += offset;
                        return latLng;
                    }),
                    strokeColor: '#0000FF',
                    strokeOpacity: 0.733,
                    strokeWeight: 3,
                    fillColor: '#0000FF',
                    fillOpacity: 0.35,
                    zIndex: 10.0,
                    // position: {
                    //     lat: originalGps.center.lat,
                    //     lng: originalGps.center.lng + offset
                    // }
                });
            }
            const lineCoordinates = [
                Marker.position,
                OriginalMarker.position
            ];
            const line = new google.maps.Polyline({
                path: lineCoordinates,
                geodesic: true,
                strokeColor: "#0000FF",
                strokeOpacity: 1.0,
                strokeWeight: 10,
            });
            line.setMap(map);
            Marker.DetectionId = DetectionId;
            Marker.DetectionDeviceId = DetectionDeviceId;
            Marker.Index = output.length;
            OriginalMarker.setMap(map);
            output.push(OriginalMarker);
            Marker.setMap(map);
            output.push(Marker);
            google.maps.event.addListener(Marker, "click", () => PageScript.map?.focusOnMarker(OriginalMarker));
            google.maps.event.addListener(OriginalMarker, "click", () => PageScript.map?.focusOnMarker(Marker));
            google.maps.event.addListener(Marker, "rightclick", OnRightClick);
            google.maps.event.addListener(OriginalMarker, "rightclick", OnRightClick);
        }
    }
    mapHelperService.updateCountLabel(output.length);
    return output;
}
export function getCoordsbyAddress(address) {
    return new Promise((resolve, reject) => {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                return resolve(results !== null ? results[0] : null);
            }
            else {
                return reject(new Error('Geocode statuscode was not OK.'));
            }
        });
    });
}
export function OnRightClick() {
    // @ts-ignore
    const { zIndex } = this;
    // @ts-ignore
    this.setOptions({
        zIndex: (zIndex === null || zIndex === undefined) ? 0 : zIndex - 1
    });
}
export function extractBoundsFromStreamCVS(verify) {
    if (verify.VehicleBounds) {
        if (verify.VehicleBounds.Points && verify.VehicleBounds.Points.length) {
            return verify.VehicleBounds;
        }
        else if (verify.VehicleBounds.coordinates.length === 1) {
            return {
                Points: verify.VehicleBounds.coordinates[0].map(([lng, lat]) => {
                    return { X: lat, Y: lng };
                })
            };
        }
        else {
            console.error(`Unexpected coordinates array size!`);
        }
    }
    console.warn(`Didn't receive VehicleBounds from VerifyRequest, drawing straight square instead.`);
    return {
        Points: [
            { X: verify.Latitude + 0.000015, Y: verify.Longitude - 0.000015 },
            { X: verify.Latitude + 0.000015, Y: verify.Longitude + 0.000015 },
            { X: verify.Latitude - 0.000015, Y: verify.Longitude + 0.000015 },
            { X: verify.Latitude - 0.000015, Y: verify.Longitude - 0.000015 }
        ]
    };
}
export function extractCenterFromVehicleBoundsJson(vehicleBoundsJson) {
    let latCenter = 0.0, lngCenter = 0.0;
    for (let p = 0; p < vehicleBoundsJson.Points.length; p++) {
        let LatLon = {
            lat: vehicleBoundsJson.Points[p].X,
            lng: vehicleBoundsJson.Points[p].Y
        };
        latCenter += LatLon.lat;
        lngCenter += LatLon.lng;
    }
    return {
        lat: latCenter / vehicleBoundsJson.Points.length,
        lng: lngCenter / vehicleBoundsJson.Points.length
    };
}
/**
 * @deprecated
 */
export function ShowMapScansAdvancedLegacy(response, map, Bounds) {
    let mapScanResults = [];
    let VehicleBoundsIndex = response.Columns.indexOf("VehicleBounds");
    let VehicleCenterLatitudeIndex = response.Columns.indexOf("VehicleCenterLatitude");
    let VehicleCenterLongitudeIndex = response.Columns.indexOf("VehicleCenterLongitude");
    let HasParkingRightIndex = response.Columns.indexOf("HasParkingRight");
    let IsIllegallyParkedIndex = response.Columns.indexOf("IsIllegallyParked");
    let DetectionIdIndex = response.Columns.indexOf("DetectionId");
    let DetectionDeviceIdIndex = response.Columns.indexOf("DetectionDeviceId");
    let LabelIndex = response.Columns.indexOf("Label");
    let LicensePlateIndex = response.Columns.indexOf("LicensePlate");
    let ScanDeviceLatitudeIndex = response.Columns.indexOf("ScanDeviceLatitude");
    let ScanDeviceLongitudeIndex = response.Columns.indexOf("ScanDeviceLongitude");
    let DetectionTimeIndex = response.Columns.indexOf("DetectionTime");
    let LPImageIndex = response.Columns.indexOf("Image");
    let DigitalIndex = response.Columns.indexOf("Digital");
    let IllegallyParkedIndex = response.Columns.indexOf("IllegallyParked");
    let ParkingRightIndex = response.Columns.indexOf("ParkingRight");
    let VerificationIndex = response.Columns.indexOf("Verification");
    let DetectionStateIndex = response.Columns.indexOf("DetectionState");
    let toRemove = [];
    for (let i = 0; i < response.Rows.length; i++) {
        let CurrentRow = response.Rows[i];
        let VehicleCenterLatitude = CurrentRow[VehicleCenterLatitudeIndex];
        let VehicleCenterLongitude = CurrentRow[VehicleCenterLongitudeIndex];
        let VehicleBounds = CurrentRow[VehicleBoundsIndex];
        let HasParkingRight = CurrentRow[HasParkingRightIndex];
        let IsIllegallyParked = CurrentRow[IsIllegallyParkedIndex];
        let DetectionId = CurrentRow[DetectionIdIndex];
        let DetectionDeviceId = CurrentRow[DetectionDeviceIdIndex];
        let Label = CurrentRow[LabelIndex];
        let LicensePlate = CurrentRow[LicensePlateIndex];
        let ScanDeviceLatitude = CurrentRow[ScanDeviceLatitudeIndex];
        let ScanDeviceLongitude = CurrentRow[ScanDeviceLongitudeIndex];
        let DetectionTime = CurrentRow[DetectionTimeIndex];
        let LPImage = CurrentRow[LPImageIndex];
        let keyDigital = CurrentRow[DigitalIndex];
        let keyIllegallyParked = CurrentRow[IllegallyParkedIndex];
        let keyParkingRight = CurrentRow[ParkingRightIndex];
        let keyVerification = CurrentRow[VerificationIndex];
        let keyDetectionState = CurrentRow[DetectionStateIndex];
        let marker = null;
        if (VehicleBounds) {
            const { coordinates, center } = mapHelperService.geoJsonToPolygonCoords(VehicleBounds);
            if (AIsLatLngValid([center.lat, center.lng])) {
                Bounds.extend(center);
                const { FillColor, StrokeColor } = mapHelperService.getColors({
                    HasParkingRight,
                    IsIllegallyParked
                });
                marker = new google.maps.Polygon({
                    paths: coordinates,
                    strokeColor: StrokeColor,
                    strokeOpacity: 0.733,
                    fillColor: FillColor,
                    fillOpacity: 0.35,
                    strokeWeight: 3,
                    zIndex: 10.0,
                    // position: {
                    //     lat: center.lat,
                    //     lng: center.lng
                    // }
                });
            }
        }
        else if (AIsLatLngValid([VehicleCenterLatitude, VehicleCenterLongitude])) {
            let Pointer = {
                lat: VehicleCenterLatitude,
                lng: VehicleCenterLongitude,
                zIndex: 10
            };
            Bounds.extend(Pointer);
            marker = new google.maps.Marker({
                position: Pointer,
                map: map
            });
        }
        if (marker) {
            marker.DetectionId = DetectionId;
            marker.DetectionDeviceId = DetectionDeviceId;
            marker.Label = Label;
            marker.Index = mapScanResults.length;
            detectionService.setMarkerIdentifier(marker, DetectionDeviceId, DetectionId);
            detectionService.setMarkerFinal(marker, { HasParkingRight, IsIllegallyParked, keyDigital, keyIllegallyParked, keyParkingRight, keyVerification, keyDetectionState });
            marker.LicensePlate = LicensePlate;
            marker.IsIllegallyParked = IsIllegallyParked;
            marker.Date = new Date(Date.parse(DetectionTime));
            marker.LPImage = LPImage;
            if (ScanDeviceLatitude != null || ScanDeviceLongitude != null) {
                marker.scanDevicePos = {
                    lat: ScanDeviceLatitude,
                    lng: ScanDeviceLongitude
                };
            }
            marker._onclick = MarkerClickProxy;
            google.maps.event.addListener(marker, "click", () => {
                PageScript.DeskControl.markerClick(marker, false);
            });
            google.maps.event.addListener(marker, "rightclick", OnRightClick);
            marker.setMap(map);
            mapScanResults.push(marker);
        }
        else {
            toRemove.push(i);
        }
    }
    // TODO: Check whether toRemove needs to exist
    toRemove.reverse().map(index => {
        response.Rows.splice(index, 1);
    });
    mapHelperService.updateCountLabel(mapScanResults.length);
    return mapScanResults;
}
export function MarkerClickProxy(e) {
    return purgatoryService.onMarkerClickRealtime(e.target);
}
export function ShowMapRoute(QueryReponseCollection, map, Bounds) {
    function FindNextStateIndex(GpsTime, CurrentStateIndex, SessionStatusRows) {
        let i;
        let Result = CurrentStateIndex;
        for (i = CurrentStateIndex + 1; i < SessionStatusRows.length; i++) {
            let RowTime = new Date(SessionStatusRows[i][1]);
            if (RowTime < GpsTime) {
                Result = i;
            }
            else {
                return Result;
            }
        }
        return Result;
    }
    let Lines = [];
    let CurrentStateLine = [];
    let CurrentState = "";
    let CurrentStateColor = "rgb(255,0,0)";
    let CurrentSessionStatusPos = -1;
    let SessionId = null;
    let MapRouteGps = QueryReponseCollection.MapRouteGps;
    let MapRouteSessionStatus = QueryReponseCollection.MapRouteSessionStatus;
    for (let i = 0; i < MapRouteGps.Rows.length; i++) {
        let CurrentRow = MapRouteGps.Rows[i];
        let GpsTime = new Date(CurrentRow[1]);
        let NewStatePos = FindNextStateIndex(GpsTime, CurrentSessionStatusPos, MapRouteSessionStatus.Rows);
        let NewState = CurrentState;
        if (NewStatePos != CurrentSessionStatusPos) {
            CurrentSessionStatusPos = NewStatePos;
            if (CurrentSessionStatusPos >= 0) {
                NewState = MapRouteSessionStatus.Rows[CurrentSessionStatusPos][2];
            }
        }
        if (CurrentStateLine.length) {
            if (SessionId != CurrentRow[0]) {
                SessionId = CurrentRow[0];
                Lines.push({ Color: CurrentStateColor, Path: CurrentStateLine });
                CurrentStateLine = [];
            }
            else if (NewState != CurrentState) {
                Lines.push({ Color: CurrentStateColor, Path: CurrentStateLine });
                CurrentStateLine = [CurrentStateLine[CurrentStateLine.length - 1]];
            }
        }
        if (NewState != CurrentState) {
            CurrentState = NewState;
            CurrentStateColor = AGetCssRule("." + CurrentState, "color");
        }
        let LatLon = { lat: CurrentRow[2], lng: CurrentRow[3] };
        if (Math.round(LatLon.lat) || Math.round(LatLon.lng)) {
            Bounds.extend(LatLon);
            CurrentStateLine.push(LatLon);
        }
    }
    if (CurrentStateLine.length) {
        Lines.push({ Color: CurrentStateColor, Path: CurrentStateLine });
    }
    let Result = [];
    for (var i = 0; i < Lines.length; i++) {
        let RouteLine = new google.maps.Polyline({
            path: Lines[i].Path,
            geodesic: true,
            strokeColor: Lines[i].Color,
            strokeOpacity: 1,
            strokeWeight: AConfig.get(`drawing & colors.strokeWidth`, 3.5)
        });
        RouteLine.setMap(map);
        Result.push(RouteLine);
    }
    return Result;
}
export function ShowMapRoute2(QueryReponseCollection, map, Bounds) {
    function FindNextStateIndex(GpsTime, CurrentStateIndex, SessionStatusRows) {
        let i;
        let Result = CurrentStateIndex;
        for (i = CurrentStateIndex + 1; i < SessionStatusRows.length; i++) {
            let RowTime = new Date(SessionStatusRows[i][1]);
            if (RowTime < GpsTime) {
                Result = i;
            }
            else {
                return Result;
            }
        }
        return Result;
    }
    let Lines = [];
    let CurrentStateLine = [];
    let CurrentState = "";
    let CurrentStateColor = "rgb(255,0,0)";
    let CurrentSessionStatusPos = -1;
    let SessionId = null;
    let MapRouteGps = QueryReponseCollection.MapRouteGps;
    let MapRouteSessionStatus = QueryReponseCollection.MapRouteSessionStatus;
    for (let i = 0; i < MapRouteGps.Rows.length; i++) {
        let CurrentRow = MapRouteGps.Rows[i];
        let GpsTime = new Date(CurrentRow[1]);
        let NewStatePos = FindNextStateIndex(GpsTime, CurrentSessionStatusPos, MapRouteSessionStatus.Rows);
        let NewState = CurrentState;
        if (NewStatePos != CurrentSessionStatusPos) {
            CurrentSessionStatusPos = NewStatePos;
            if (CurrentSessionStatusPos >= 0) {
                NewState = MapRouteSessionStatus.Rows[CurrentSessionStatusPos][2];
                // console.log("NewState", NewState, " : CurrentState", CurrentState);
            }
        }
        if (CurrentStateLine.length) {
            if (SessionId != CurrentRow[0]) {
                // console.log(SessionId);
                SessionId = CurrentRow[0];
                Lines.push({ Color: CurrentStateColor, Path: CurrentStateLine });
                CurrentStateLine = [];
            }
            else if (NewState != CurrentState) {
                Lines.push({ Color: CurrentStateColor, Path: CurrentStateLine });
                CurrentStateLine = [CurrentStateLine[CurrentStateLine.length - 1]];
            }
        }
        if (NewState != CurrentState) {
            CurrentState = NewState;
            CurrentStateColor = AGetCssRule("." + CurrentState, "color");
            // console.log("CurrentStateColor:", CurrentStateColor, " : ", i);
        }
        let LatLon = { lat: CurrentRow[2], lng: CurrentRow[3] };
        if (Math.round(LatLon.lat) || Math.round(LatLon.lng)) {
            Bounds.extend(LatLon);
            CurrentStateLine.push(LatLon);
        }
    }
    if (CurrentStateLine.length) {
        Lines.push({ Color: CurrentStateColor, Path: CurrentStateLine });
    }
    let Result = [];
    for (let i = 0; i < Lines.length; i++) {
        let RouteLine = new google.maps.Polyline({
            path: Lines[i].Path,
            geodesic: true,
            strokeColor: '#00ff00',
            strokeOpacity: 1,
            strokeWeight: 10
        });
        RouteLine.setMap(map);
        Result.push(RouteLine);
    }
    return Result;
}
export function DefaultBounds() {
    let bounds = new google.maps.LatLngBounds();
    if (bounds.isEmpty()) {
        bounds.extend({ lng: Config.GpsBounds[0][0], lat: Config.GpsBounds[0][1] });
        bounds.extend({ lng: Config.GpsBounds[1][0], lat: Config.GpsBounds[1][1] });
    }
    return bounds;
}
export function GetFilterArea() {
    return (FilterSettings.Area != "%" && FilterSettings.Area != "Unknown") ? FilterSettings.Area : null;
}
export function _getEle(str) {
    let ele = null;
    if (str instanceof jQuery) {
        // @ts-ignore
        ele = str.get(0);
    }
    else if (str instanceof Element) {
        ele = str;
    }
    else if (typeof str === 'string') {
        let found = document.querySelector(str) || document.querySelector('#' + str);
        ele = found;
    }
    else {
        throw new Error(`_getEle(#${str}) is an unexpected value`);
    }
    return ele;
}
export function _getEle$(str) {
    let ele = _getEle(str);
    return $(ele);
}
export function AIsLatLngValid(array) {
    let output = false;
    for (const item of array) {
        if (item === null) {
            return false;
        }
        if (item !== 0) {
            output = true;
        }
    }
    return output;
}
export function createMap(id, options = {}) {
    const opts = $.extend(true, { position: DefaultBounds() }, options);
    const mapEle = _getEle(id);
    if (!mapEle) {
        throw new Error(`Couldn't find HTMLElement: #${id}`);
    }
    const map = new google.maps.Map(mapEle, $.extend(true, {
        scaleControl: false,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP
        },
        mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
        },
    }, opts));
    return map;
}
export function createPano(id, options) {
    if (options === undefined) {
        const { lat, lng } = AConfig.get(`general.map.pos`, { lat: 0, lng: 0 });
        const pov = AConfig.get(`general.map.pov`, { heading: 90, pitch: 0, zoom: 1 });
        options = {
            position: new google.maps.LatLng(lat, lng),
            pov: pov,
            visible: true,
            fullscreenControl: false
        };
    }
    if (typeof options.pov.zoom === 'string') {
        options.pov.zoom = Number(options.pov.zoom);
    }
    if (typeof options.pov.heading === 'string') {
        options.pov.heading = Number(options.pov.heading);
    }
    if (typeof options.pov.pitch === 'string') {
        options.pov.pitch = Number(options.pov.pitch);
    }
    if (typeof options.position.lat === 'string') {
        options.position.lat = Number(options.position.lat);
    }
    if (typeof options.position.lng === 'string') {
        options.position.lng = Number(options.position.lng);
    }
    const pano = new google.maps.StreetViewPanorama(_getEle(id), options);
    return pano;
}
export function getCenterAny(markerOrPolygon) {
    if (markerOrPolygon instanceof google.maps.Polyline) {
        const path = markerOrPolygon.getPath();
        markerOrPolygon = path.getAt(Math.floor(path.getLength() / 2));
    }
    if (markerOrPolygon instanceof google.maps.Polygon) {
        return getPolygonCenter(markerOrPolygon);
    }
    if (markerOrPolygon instanceof google.maps.Marker) {
        return markerOrPolygon.getPosition();
    }
    if (markerOrPolygon instanceof google.maps.LatLng) {
        return markerOrPolygon;
    }
    if (markerOrPolygon.pos) {
        return markerOrPolygon.pos;
    }
    if (markerOrPolygon.position) {
        return markerOrPolygon.position;
    }
    console.warn('Marker/Polygin: ', markerOrPolygon);
    throw new Error('Unexpected marker/polygon');
}
export function getPolygonCenter(polygon) {
    const path = polygon.getPath();
    const length = path.getLength();
    let bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < length; i++) {
        let val = path.getAt(i);
        bounds.extend(val);
    }
    return bounds.getCenter();
}
export function increaseMapZoom(map = PageScript.map, amount = 1) {
    console.warn('increasing zoom');
    map.setZoom(map.getZoom() + amount);
}
export function moveMap(map = PageScript.map) {
    const { lat, lng } = map.center.toJSON();
    const latLng = { lat, lng: lng + 0.001 };
    map.setCenter(latLng);
}
export function hideMapLabels(map, styleJson) {
    if (styleJson === undefined) {
        styleJson = [
            {
                featureType: "all",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            }
        ];
    }
    map.set('styles', styleJson);
}
export function showMapLabels(map, styleJson) {
    if (styleJson === undefined) {
        styleJson = [
            {
                featureType: "all",
                elementType: "labels",
                stylers: [
                    { visibility: "on" }
                ]
            }
        ];
    }
    map.set('styles', styleJson);
}
globalThis.DefaultBounds = DefaultBounds;
globalThis.getCenterAny = getCenterAny;
