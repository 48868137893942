import { AFormatDate, AConvertMillisecondsToHM, ARound, AIsDate } from "../utils/tools.js";
import { AConfig } from "./AConfig.js";
import { ATableKey } from "./ATableBuilder2.js";
export class ATableFormatter {
    get UNKNOWN() { return { type: 'UNKNOWN' }; }
    constructor(fieldDefinitions) {
        this.fieldDefinitions = fieldDefinitions;
    }
    formatTableColumn(tableKey, value, index, ref, depth) {
        const nestedValues = [];
        let column = tableKey.getColumn();
        if (value === undefined || value === '') {
            value = null;
        }
        const { type, data, mapTo } = this.getColumnFormatOptions(tableKey);
        switch (type) {
            case 'TIME':
                if (value !== null) {
                    value = value.replace(/[ ]+/g, ':');
                }
                break;
            case 'DATETIME':
                if (value !== null) {
                    value = AFormatDate(new Date(value));
                }
                break;
            case 'DURATION':
                if (value !== null) {
                    value = AConvertMillisecondsToHM(value * 1000);
                }
                break;
            case 'NUMBER':
                if (value !== null) {
                    value = ARound(value, 2);
                }
            case 'UNKNOWN':
            case 'TEXT':
                if (value === null) {
                    value = '';
                }
                if (AIsDate(value)) {
                    console.error(column, 'date', value);
                    value = AFormatDate(new Date(value));
                }
                if (this.isJsonText(value)) {
                    console.error(column, 'json', value);
                    value = value
                        .replace(/&/g, "&amp;")
                        .replace(/</g, "&lt;")
                        .replace(/>/g, "&gt;")
                        .replace(/"/g, "&quot;")
                        .replace(/'/g, "&#039;")
                        .replace(/\//g, '&#x2F;');
                }
                break;
            case 'BOOLEAN':
                if (value !== null) {
                    let stringifiedValue = value.toString();
                    switch (stringifiedValue.toLocaleLowerCase()) {
                        case 'true':
                            value = true;
                            break;
                        case 'yes':
                            value = true;
                            break;
                        case '1':
                            value = true;
                            break;
                        case 'false':
                            value = false;
                            break;
                        case 'no':
                            value = false;
                            break;
                        case '0':
                            value = false;
                            break;
                        default:
                            throw new Error(`Boolean value not valid for column: ${column} value: ${value}`);
                    }
                    value = value === true ? (`<i class="fa fa-check fa-lg"></i>`) : (`<i class="fa fa-times fa-lg"></i>`);
                }
                break;
            case 'ARRAY':
                if (value instanceof Array) {
                    const subRef = Object.assign({}, ref, { checkForUnknownColumns: false });
                    for (let i in value) {
                        nestedValues.push(...this.formatTableColumn(new ATableKey(i, i, tableKey), value[i], Number(i), subRef, depth + 1));
                        // nestedValues.push([i, JSON.stringify(value[i]), { type }])
                    }
                    ref.extraData.childCount = nestedValues.length;
                    value = '';
                }
                else {
                    value = JSON.stringify(value);
                }
                break;
            case 'OBJECT':
                if (value != null && Object.keys(value).length !== 0) {
                    const keys = Object.keys(value);
                    const tfDef = AConfig.get(`tableformatter.${column}.definitions`, {});
                    const tf = new ATableFormatter(tfDef);
                    const subRef = Object.assign({}, ref, { checkForUnknownColumns: false });
                    keys.map(key => {
                        nestedValues.push(...tf.formatTableColumn(new ATableKey(key, key, tableKey), value[key], index, subRef, depth + 1));
                    });
                    ref.extraData.childCount = nestedValues.length;
                    value = '';
                }
                else {
                    value = null;
                }
                break;
            case 'CUSTOM':
                const id = (`${Date.now()}-${index}`);
                ref.data.push({ column, id, type, value, mapTo: data });
                value = (`<div rid="${id}">${(value != null) ? value : ''}</div>`);
                break;
            case 'HIDDEN':
                value = null;
                Object.assign(ref.extraData, {
                    hidden: true
                });
                break;
            default:
                value = JSON.stringify(value);
                Object.assign(ref.extraData, {
                    err: true,
                    errText: `Unexpected type '${type}' at column '${column}' with value '${value}'`
                });
                if (ref.checkForUnknownColumns) {
                    ref.missing.push(column);
                }
                break;
        }
        if (value === null) {
            value = '';
        }
        // ref.output.push()
        // nestedValues.map(nestedValue => {
        //   nestedValue[0] = '<span class="padding"></span>' + nestedValue[0]
        //   ref.output.push(nestedValue)
        // })
        Object.assign({}, ref, { checkForUnknownColumns: false });
        return (nestedValues.length > 0) ? [
            [tableKey.getColumnTranslated(), value, Object.assign({}, ref.extraData, { type, depth, tableKey })],
            ...nestedValues
        ] : [[tableKey.getColumnTranslated(), value, Object.assign({}, ref.extraData, { type, depth, tableKey })]];
    }
    isJsonText(text) {
        if (typeof text === 'string') {
            let trimmed = text.trim();
            if (!trimmed.startsWith('{') || !trimmed.endsWith('}'))
                return false;
            return (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
                replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                replace(/(?:^|:|,)(?:\s*\[)+/g, '')));
        }
        return false;
    }
    // private isNumber(text: any) {
    //   return /^-?\d+\.?\d*$/.test(text)
    // }
    getColumnFormatOptions(tableKey) {
        if (!this.fieldDefinitions) {
            return this.getColumnFormatOptionsFallback(tableKey);
        }
        const { fieldDefinitions } = this;
        const columnName = tableKey.getColumn();
        return fieldDefinitions.hasOwnProperty(columnName) ? fieldDefinitions[columnName] : this.getColumnFormatOptionsFallback(tableKey);
    }
    getColumnFormatOptionsFallback(tableKey) {
        const columnName = tableKey.getColumn();
        const { fallbackDefinitions: fallbackColumns } = ATableFormatter;
        return fallbackColumns.hasOwnProperty(columnName) ? fallbackColumns[columnName] : this.UNKNOWN;
    }
}
ATableFormatter.fallbackOrder = [
    "DetectionTime",
    "LicensePlate",
    "DetectionState",
    "ParkingRight",
    "IllegallyParked",
    "Verification",
    "TimeLimitedParking",
    "Digital",
    "DetectionUser",
    "Address",
    "IsSuspect",
    "Side",
    "Area",
    "IsFine",
    "VerificationChannel",
    "SessionVerificationChannels",
    "VerificationUserRemarks",
    "Label",
];
/**
 * Remove:
 * HasParkingRight
 * IsIllegallyParked
 * ParkingRightType
 * VerificationResult
 * VerificationResultText
 *
 * DetectionState
 * ParkingRight
 * IllegallyParked
 * TimeLimitedParking
 * Verification
 * IsSuspect
 * VerificationChannel (Translated)
 */
ATableFormatter.fallbackDefinitions = {
    "DetectionTime": { type: 'DATETIME', isPriority: true },
    "LicensePlate": { type: 'TEXT', isPriority: true },
    "IsSuspect": { type: 'BOOLEAN', isPriority: true },
    "Digital": { type: "TEXT", isPriority: true },
    "TimeLimitedParking": { type: "TEXT", isPriority: true },
    "IllegallyParked": { type: "TEXT", isPriority: true },
    "ParkingRight": { type: "TEXT", isPriority: true },
    "Verification": { type: "TEXT", isPriority: true },
    "DetectionState": { type: "TEXT", isPriority: true },
    "DetectionUser": { type: "TEXT", isPriority: true },
    "Address": { type: 'TEXT', isPriority: true },
    "Area": { type: 'TEXT', isPriority: true },
    "Side": { type: 'TEXT', isPriority: true },
    "IsFine": { type: "BOOLEAN", isPriority: true },
    "VerificationChannel": { type: 'TEXT', isPriority: true },
    "SessionVerificationChannels": { type: "ARRAY", isPriority: true },
    "VerificationUserRemarks": { type: "TEXT", isPriority: true },
    "Label": { type: 'CUSTOM', isPriority: true, mapTo: 'generateLabelField' },
    "DetectionValid": { type: 'BOOLEAN' },
    "ChannelCode": { type: "HIDDEN" },
    "CardinalDirection": { type: 'TEXT' },
    "VerificationResult": { type: 'TEXT' },
    "VerificationResultText": { type: 'TEXT' },
    "HasParkingRight": { type: 'BOOLEAN' },
    "IsIllegallyParked": { type: 'BOOLEAN' },
    "ParkingRightType": { type: 'TEXT' },
    "ParkingRightTypeText": { type: 'TEXT' },
    "ParkingRightAttributes": { type: 'OBJECT' },
    "ParkingRightError": { type: 'TEXT' },
    "Id": { type: 'TEXT' },
    // "36503218372149461",
    "DetectionId": { type: 'TEXT' },
    // "281629595598849",
    "DetectionDeviceId": { type: 'TEXT' },
    // "Unknown",
    "VehicleType": { type: "TEXT" },
    // "ScanAuto1",
    "DetectionDevice": { type: "TEXT" },
    // "18 48 29",
    "Time": { type: "TIME" },
    // 0.901,
    "Confidence": { type: "NUMBER" },
    // null,
    "DuplicateCodeLp": { type: "TEXT" },
    // null,
    "DuplicateCodeLpConf": { type: "TEXT" },
    // Array(1),
    "CameraIds": { type: "ARRAY" },
    // 213,
    "BestCameraId": { type: "TEXT" },
    // Array(0),
    "NearMatches": { type: "ARRAY" },
    // 8.11161442463566,
    "VehicleSpeed": { type: "NUMBER" },
    // {…},
    "VehicleBoundsJson": { type: "OBJECT" },
    // "No",
    "VehicleMoving": { type: "BOOLEAN" },
    // 59.9259468705078,
    "ScanDeviceLatitude": { type: "TEXT" },
    // 10.7575656701899,
    "ScanDeviceLongitude": { type: "TEXT" },
    // 1.0432681497923,
    "GpsPrecision": { type: "NUMBER" },
    // "1.0/1.1",
    "GpsPrecisionGroup": { type: "TEXT" },
    // 1,
    "GpsValid": { type: "BOOLEAN" },
    // 281.22096559014,
    "ScanDeviceDirection": { type: "NUMBER" },
    // "18",
    "Hour": { type: "TEXT" },
    // "2017-08-25",
    "Day": { type: "TEXT" },
    // "Week 34 2017",
    "Week": { type: "TEXT" },
    // "August 2017",
    "Month": { type: "TEXT" },
    // "Friday",
    "Weekday": { type: "TEXT" },
    // 0,
    "TaxRequired": { type: "BOOLEAN" },
    // null,
    "AreaConfidence": { type: "NUMBER" },
    // {…},
    "AreaAttributes": { type: "OBJECT" },
    // null,
    "ParkingSpaceId": { type: "HIDDEN" },
    // "Unknown",
    "ParkingSpace": { type: "TEXT" },
    // null,
    "ParkingSpaceConfidence": { type: "NUMBER" },
    // {…},
    "ParkingSpaceAttributes": { type: "OBJECT" },
    // 41183,
    "SegmentId": { type: "HIDDEN" },
    // "2017-08-25T16:48:25.700Z",
    "SegmentTimestamp": { type: "DATETIME" },
    // null,
    "SegmentConfidence": { type: "NUMBER" },
    // null,
    "SegmentAttributes": { type: "OBJECT" },
    // null,
    "ZoneId": { type: "HIDDEN" },
    // "Unknown",
    "Zone": { type: "TEXT" },
    // null,
    "ZoneConfidence": { type: "NUMBER" },
    // null,
    "ZoneTimestamp": { type: "DATETIME" },
    // {…},
    "ZoneAttributes": { type: "OBJECT" },
    // "Markveien 12B",
    "address": { type: "TEXT" },
    // "DK",
    "CountryCode": { type: "TEXT" },
    // null,
    "ParkingRightResults": { type: "OBJECT" },
    // "No Right",
    "Visitor": { type: "TEXT" },
    // null,
    "VerificationUser": { type: "TEXT" },
    // null,
    "VerificationStartTime": { type: "DATETIME" },
    // "2017-08-25T17:18:36.000Z",
    "VerificationEndTime": { type: "DATETIME" },
    // null,
    "VerificationLatitude": { type: "TEXT" },
    // null,
    "VerificationLongitude": { type: "TEXT" },
    "FinalVerificationUser": { type: "TEXT" },
    "FinalVerificationDistanceToVehicle": { type: "TEXT" },
    "ParkingAreaType": { type: "TEXT" },
    "AreaId": { type: "HIDDEN" },
    "VehicleCenterLatitude": { type: "TEXT" },
    "VehicleCenterLongitude": { type: "TEXT" },
    "VehicleBounds": { type: "HIDDEN" },
    "SessionId": { type: "HIDDEN" },
    "PreviousDetectionState": { type: "TEXT" },
    "PreviousDetectionStateDuration": { type: "DURATION" },
    // "\"Unit2\""
    "EnforceUnit": { type: "TEXT" },
    // "\"2021-12-01T13:12:44.600Z\""
    "DetectionReceiveTime": { type: "DATETIME" },
    // "\"2021-12-01T13:12:44.402Z\""
    "DetectionSendTime": { type: "DATETIME" },
    // "\"5hAAAAEBAAAAekB5APN0JUBlgbJ7ivhNQA==\""
    "VehicleCenter": { type: "HIDDEN" },
    // "\"5hAAAAEBAAAATizL9PB0JUBfDzYnifhNQA==\""
    "ScanDeviceCenter": { type: "HIDDEN" },
    // "14.384799544996"
    "ScanDeviceSpeed": { type: "TEXT" },
    // "null"
    "DebugInfo": { type: "TEXT" },
    // "1"
    "Anonymized": { type: "BOOLEAN" },
    // "\"Enforce\""
    "SessionMode": { type: "TEXT" },
    // "null"
    "SessionName": { type: "TEXT" },
    // "\"2021-12-01T13:12:45.046Z\""
    "GeoCheckTime": { type: "DATETIME" },
    // "\"Vestgrensa\""
    "Street": { type: "TEXT" },
    // "\"8\""
    "HouseNumber": { type: "TEXT" },
    // "null"
    "City": { type: "TEXT" },
    // "[{\"Id\":138,\"Name\":\"G\",\"TimeStamp\":null,\"Attributes\":{\"dbid\":18,\"sone\":\"G\",\"zone\":\"Nordre Aker sone G\",\"bydel\":\"Nordre Aker\",\"objectid\":419,\"bydel_old\":null,\"bydel_old2\":\"Nordre Aker\",\"st_area(shape)\":2256537.12101401,\"st_perimeter(shape)\":9687.13651163797},\"Confidence\":0.999999436491758}]"
    "Areas": { type: "OBJECT" },
    // "\"G\""
    "AreaName": { type: "TEXT" },
    // "\"2021-12-01T12:44:18.504Z\""
    "AreaTimeStamp": { type: "DATETIME" },
    // "[{\"Id\":18106,\"Name\":\"Tax parking\",\"TimeStamp\":null,\"Attributes\":{\"P_type\":1,\"Fritekst\":null,\"GlobalID\":\"{8CF030FB-703D-4159-BAA5-B9853AC29243}\",\"OBJECTID\":243390,\"P_bredde\":\"2\",\"P_lengde\":null,\"Tellemetode\":1,\"StrekningsID\":105148,\"Takstgruppe1\":2300,\"Takstgruppe2\":2300,\"Befart_antall\":null,\"Dato_oppdatert\":\"1539674566000\",\"Beregnet_antall\":19,\"Dato_registrert\":\"1521645902000\",\"P_spesifisering\":37,\"Bruker_oppdatert\":\"GISEDITOR\",\"Shape.STLength()\":101.180598731446,\"Bruker_registrert\":\"GISEDITOR\",\"Beboerparkeringssone\":\"G\"},\"Confidence\":0.926730829265264}]"
    "ParkingSpaces": { type: "OBJECT" },
    // "\"Tax parking\""
    "ParkingSpaceName": { type: "TEXT" },
    // "\"2021-12-01T13:12:17.939Z\""
    "ParkingSpaceTimeStamp": { type: "DATETIME" },
    // "[{\"Id\":21922,\"Name\":\"Tax parking\",\"TimeStamp\":null,\"Attributes\":{\"Capacity\":5.36244623302013,\"Geo-Area\":96.5240321943624,\"SegmentId\":17173,\"Geo-Length\":100.813820486734},\"Confidence\":0.926730890247006}]"
    "SplitParkingSpaces": { type: "OBJECT" },
    // "21922"
    "SplitParkingSpaceId": { type: "HIDDEN" },
    // "null"
    "SplitParkingSpaceName": { type: "TEXT" },
    // "0.926730890247006"
    "SplitParkingSpaceConfidence": { type: "NUMBER" },
    // "{\"Capacity\":5.36244623302013,\"Geo-Area\":96.5240321943624,\"SegmentId\":17173,\"Geo-Length\":100.813820486734}"
    "SplitParkingSpaceAttributes": { type: "OBJECT" },
    // "\"2021-12-01T13:12:44.939Z\""
    "SplitParkingSpaceTimeStamp": { type: "DATETIME" },
    // "[{\"Id\":1857,\"Name\":\"Yellow\",\"TimeStamp\":null,\"Attributes\":{\"dbid\":1603,\"navn\":\"C1047\",\"globalid\":\"{EA69D06C-2BF5-4243-B84C-9BFDAE8A9D26}\",\"objectid\":152817,\"takstsone\":\"GUL\",\"takstgruppe\":2300,\"created_date\":\"1539674229000\",\"created_user\":\"yem\",\"takstgruppe2\":null,\"st_area(shape)\":1.97538531202675e-8,\"last_edited_date\":\"1620285435000\",\"last_edited_user\":\"GISOWNER\",\"st_perimeter(shape)\":0.00112416697257701},\"Confidence\":0.894749989556078}]"
    "Zones": { type: "OBJECT" },
    // "\"Yellow\""
    "ZoneName": { type: "TEXT" },
    // "\"2021-12-01T13:12:17.939Z\""
    "ZoneTimeStamp": { type: "DATETIME" },
    // "\"2021-12-01T13:12:20.995Z\""
    "SegmentTimeStamp": { type: "DATETIME" },
    // "0"
    "IsDuplicate": { type: "BOOLEAN" },
    // "null"
    "LinkedDetectionId": { type: "TEXT" },
    // "null"
    "LinkedDetectionDeviceId": { type: "TEXT" },
    // "[{\"Id\":17173,\"Name\":\"Vestgrensa\",\"TimeStamp\":null,\"Attributes\":{\"name\":\"Vestgrensa\",\"highway\":\"residential\",\"Geo-Area\":3073.58869477433,\"Geo-Length\":252.709563431688,\"WaySegmentId\":394,\"WaySegmentSide\":1},\"Confidence\":1}]"
    "Segments": { type: "OBJECT" },
    // "394"
    "WaySegmentId": { type: "HIDDEN" },
    // "0.67001755076803"
    "WaySegmentConfidence": { type: "NUMBER" },
    // "{\"name\":\"Vestgrensa\",\"highway\":\"residential\",\"Geo-Area\":0,\"Geo-Length\":109.76404253097}"
    "WaySegmentAttributes": { type: "OBJECT" },
    // "\"2021-12-01T13:12:20.995Z\""
    "WaySegmentTimeStamp": { type: "DATETIME" },
    // "[]"
    "WaySegments": { type: "ARRAY" },
    // "null"
    "AddressId": { type: "HIDDEN" },
    // "null"
    "AddressConfidence": { type: "NUMBER" },
    // "{}"
    "AddressAttributes": { type: "OBJECT" },
    // "null"
    "AddressTimeStamp": { type: "HIDDEN" },
    // "[]"
    "Addresses": { type: "ARRAY" },
    // "[\"Nordre Aker sone G\",\"Alle soner\",\"2300\"]"
    "ParkingRightGeoRestrictions": { type: "OBJECT" },
    // "1"
    "GeoMappingProcessed": { type: "BOOLEAN" },
    // "\"2021-12-16T18:42:05.096Z\""
    "GeoMappingTime": { type: "DATETIME" },
    // "1"
    "DetectionVersion": { type: "NUMBER" },
    // "1"
    "ParkingRightVersion": { type: "NUMBER" },
    // "null"
    "AreaCode": { type: "TEXT" },
    // "null"
    "ParkingSpaceCode": { type: "TEXT" },
    // "\"******\""
    "Match": { type: "TEXT" },
    // "\"2021-12-01T13:12:45.046Z\""
    "ParkingRightCheckTime": { type: "DATETIME" },
    // "\"Cecilie Gjersøe\""
    "ParkingRightRequestUser": { type: "TEXT" },
    // "\"ScanAuto2\""
    "ParkingRightRequestDevice": { type: "TEXT" },
    // "\"DetectionStream\""
    "ParkingRightRequestMessageType": { type: "TEXT" },
    // "1"
    "VerificationVersion": { type: "NUMBER" },
    // "\"281629606871043\""
    "VerificationDeviceId": { type: "TEXT" },
    // "\"Pda173\""
    "VerificationDevice": { type: "TEXT" },
    // "null"
    "FineNumber": { type: "TEXT" },
    // "{}"
    "FineData": { type: "OBJECT" },
    // "0"
    "FineIsPaid": { type: "BOOLEAN" },
    // "null"
    "FinePaidReference": { type: "HIDDEN" },
    // "null"
    "FinePaidTime": { type: "DATETIME" },
    // "null"
    "FineImage": { type: "HIDDEN" },
    // "\"Unit2\""
    "VerificationEnforceUnit": { type: "TEXT" },
    // "null"
    "FineAmount": { type: "TEXT" },
    // "0"
    "FineValid": { type: "BOOLEAN" },
    "keyDigital": { type: "HIDDEN" },
    "keyTimeLimitedParking": { type: "HIDDEN" },
    "keyIllegallyParked": { type: "HIDDEN" },
    "keyParkingRight": { type: "HIDDEN" },
    "keyVerification": { type: "HIDDEN" },
    "keyDetectionState": { type: "HIDDEN" },
    "OffenceCode": { type: 'TEXT' },
    "OffenceText": { type: 'TEXT' },
    "OffenceDescription": { type: 'TEXT' },
    "OffencePrice": { type: 'TEXT' },
    "SessionVerificationChannel": { type: 'TEXT' },
    "SessionEnforceUnit": { type: 'TEXT' },
    "HasCapacity": { type: 'BOOLEAN' },
    "MustFollowUp": { type: 'BOOLEAN' },
    "ForceFollowUp": { type: 'BOOLEAN' },
    "SegmentName": { type: 'TEXT' },
    "WaySegmentName": { type: 'TEXT' },
    "AddressName": { type: 'TEXT' },
    "Regions": { type: 'ARRAY' },
    "Region": { type: 'TEXT' },
    "RegionId": { type: 'HIDDEN' },
    "RegionName": { type: 'TEXT' },
    "RegionConfidence": { type: 'NUMBER' },
    "RegionAttributes": { type: 'OBJECT' },
    "RegionTimeStamp": { type: 'DATETIME' },
    "RouteAreas": { type: 'ARRAY' },
    "RouteArea": { type: 'TEXT' },
    "RouteAreaId": { type: 'HIDDEN' },
    "RouteAreaName": { type: 'TEXT' },
    "RouteAreaConfidence": { type: 'NUMBER' },
    "RouteAreaAttributes": { type: 'OBJECT' },
    "RouteAreaTimeStamp": { type: 'DATETIME' },
    "CorrectionDeviceId": { type: 'TEXT' },
    "CorrectionDevice": { type: 'TEXT' },
    "CorrectionUser": { type: 'TEXT' },
    "CorrectionTime": { type: 'DATETIME' },
    "FinalVerificationDevice": { type: 'TEXT' },
    "DetectionExpireTime": { type: 'DATETIME' },
    "CallingCode": { type: 'TEXT' },
    "CountryCodeISO2": { type: 'TEXT' },
    "CountryCodeISO3": { type: 'TEXT' },
    "GeoType": { type: 'TEXT' },
    "GeoId": { type: 'NUMBER' },
    "GeoVersion": { type: 'DATETIME' },
    "GeoCreated": { type: 'DATETIME' },
};
