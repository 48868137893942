import { AError } from '../classes/AError.js';
export class AUserActionService {
    constructor() { }
    autoInit() { }
    logAction(Category, Action, Inputs) {
        if (typeof Inputs === 'object' && Inputs !== null) {
            Inputs = Object.keys(Inputs).map(key => `${key}=${Inputs[key]}`).join(', ');
        }
        if (Action && Action.length > 64) {
            Action = Action.substring(0, 64);
            console.warn('CUT OFF USER ACTION WITH LENGTH > 64');
        }
        if (Inputs && Inputs.length > 1024) {
            Inputs = Inputs.substring(0, 1024);
            console.warn('CUT OFF DESCRIPTION WITH LENGTH > 1024');
        }
        return Loading.waitForPromises(requestService.query({
            Query: (`INSERT INTO user_actions (User, Category, Action, Target, Description) VALUES (:User, :Category, :Action, :Target, :Inputs)`),
            Params: {
                User: _.getUser().User,
                Category,
                Action,
                Target: document.location.hash.substr(2),
                Inputs
            }
        })).catch(AError.handle);
    }
}
