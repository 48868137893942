import { AEngine } from "../AEngine.js";
import { AIdAllocatorService } from "../allocator/AIdAllocatorService.js";
export class AEventableValue {
    constructor(opt) {
        this.listeners = [];
        this.listenerKeys = {};
        this.history = [];
        this.opt = opt;
        if (this.opt.watch) {
            this.opt.watch.listen(() => this.add(this.value));
        }
        if (this.opt.filter) {
            this.opt.filter.attach(this);
            this.opt.getter = this.opt.filter.filter;
        }
        this.init();
    }
    init() {
        this.history = [];
        if (this.opt.initialValue !== undefined) {
            this.add(this.opt.initialValue);
        }
    }
    get value() {
        return this.opt.getter ? this.opt.getter(this.history[0]) : this.history[0];
    }
    add(value) {
        this.history.unshift(value);
        if (this.history.length > this.maxHistory) {
            this.history.pop();
        }
        const newValue = this.value;
        this.listeners.map(listener => listener(newValue));
    }
    reload() {
        this.add(this.history[0]);
    }
    listen(callback) {
        const key = AEngine.get(AIdAllocatorService).getNextId({ prefix: 'E' });
        this.listenerKeys[key] = callback;
        this.listeners.push(callback);
        callback(this.value);
        return key;
    }
    listenOnce(callback) {
        let key = '';
        key = this.listen((value) => {
            this.stopListen({ key });
            return callback(value);
        });
        return key;
    }
    stopListen({ key }) {
        if (!this.listenerKeys.hasOwnProperty(key)) {
            console.warn(`No AEventableValue listener exists with key`, key);
        }
        const callback = this.listenerKeys[key];
        this.listeners.splice(this.listeners.indexOf(callback), 1);
        delete this.listeners[key];
    }
    get maxHistory() {
        return this.opt.maxHistory || 1;
    }
    toString() {
        return this.opt.toString ? this.opt.toString(this) : 'AEventableValue<T>';
    }
}
