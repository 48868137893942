import { ATableFormatter } from "./ATableFormatter.js";
import { createArray, mergeDeep } from "../utils/tools.js";
import { AError } from "./AError.js";
export class ATableKey {
    constructor(column, columnTranslated, parent) {
        this.column = column;
        this.columnTranslated = columnTranslated;
        this.parentKey = parent;
    }
    hasParent() {
        return this.parentKey !== undefined;
    }
    getColumn() {
        return this.column;
    }
    getColumnTranslated() {
        return this.columnTranslated;
    }
}
export class ATableBuilder2 {
    constructor(options) {
        const opt = Object.assign({}, {
            tableFormatter: new ATableFormatter,
            greyOutFields: true
        }, options || {});
        this.tableId = opt.tableId;
        this.column_count = 2;
        this.insertHistory = [];
        this.data = [];
        this.tableFormatter = opt.tableFormatter;
        this.greyOutFields = opt.greyOutFields;
        this.checkForUnknownColumns = true;
    }
    get TYPE_PRIORITY() { return 'PRIORITY'; }
    get TYPE_INSERT() { return 'INSERT'; }
    get TYPE_LAST() { return 'LAST'; }
    /**
     * Use custom formatter
     */
    setFormatter(tableFormatter) {
        this.tableFormatter = tableFormatter;
        return this;
    }
    /**
     * Inserts [column, column, column...] to the table
     * Syntax is insert([a, b, c], boolean)
     * @param array
     * @param nestedLevel
     */
    insert(array) {
        this.addToHistory(array, this.TYPE_INSERT);
    }
    addToHistory(array, type) {
        this.insertHistory.push(mergeDeep({}, {
            array,
            type
        }));
    }
    sortHistory(arrayToSort, sortingTemplate) {
        const result = [];
        sortingTemplate.forEach(function (key) {
            var found = false;
            arrayToSort = arrayToSort.filter(function (item) {
                if (!found && item.array[0].getColumn() == key) {
                    result.push(item);
                    found = true;
                    return false;
                }
                else {
                    return true;
                }
            });
        });
        return result.concat(arrayToSort);
    }
    buildFromHistory({ sorting }) {
        this.data = [];
        const sorted = this.sortHistory(this.insertHistory, sorting);
        const output = [];
        const missing = [];
        sorted.map(({ array }, index) => {
            let [tableKey, value] = array;
            const extraData = {};
            // TODO: Find out why mapTo isn't used here!
            const ref = {
                output,
                data: this.data,
                extraData,
                checkForUnknownColumns: this.checkForUnknownColumns,
                missing,
            };
            const tableRows = this.tableFormatter.formatTableColumn(tableKey, value, index, ref, 0);
            output.push(...tableRows);
        });
        if (missing.length) {
            console.log(`TableFormatter Columns Missing: ${missing.join(', ')}`);
            AError.handleSilent(`TableFormatter Columns Missing: ${missing.join(', ')}`);
        }
        return output;
    }
    /**
     * Creates and returns a table in the correct HTML format.
     * @param {object} [options]
     * @param {boolean} [options.visible]
     * @param {any[]} [options.sorting]
     * @param {boolean} [options.padding]
     */
    build({ visible = true, sorting = [], padding = true } = {}) {
        const rows = this.buildFromHistory({ sorting });
        let output = '';
        for (let row of rows) {
            const extraData = row[2];
            const hasChildren = ['OBJECT', 'ARRAY'].includes(extraData.type) && (extraData.childCount ?? 0) > 0;
            if (!extraData.hidden) {
                // const cls = (this.greyOutFields && extraData.err) ? 'tr-error' : ''
                const attributeMap = {
                    ...(this.greyOutFields && extraData.err ? { class: 'tr-error' } : {}),
                    ...(hasChildren ? { rkey: extraData.tableKey.getColumn() } : {}),
                    ...(extraData.tableKey.hasParent() ? { pkey: extraData.tableKey.parentKey.getColumn(), style: `display: none;` } : {})
                };
                const attributes = Object.entries(attributeMap).map(([attrName, attrVal]) => {
                    return `${attrName}="${attrVal}"`;
                }).join(' ');
                // const attributes = hasChildren ? `rkey=${extraData.tableKey.getColumn()}` : ''
                // output += this.greyOutFields && extraData.err ? '<tr class="tr-error">' : '<tr>'
                // for (let i = 0; i < this.column_count; i++) {
                //   output += `<td>${row[i]}</td>`
                // }
                output += `<tr ${attributes}>`;
                if (hasChildren) {
                    output += `<td style="white-space: pre;">${createArray((extraData.depth || 0) * 2, ' ').join('')}${row[0]}<i class="icon icon-arrow-down ml-1"></i></td>`;
                }
                else {
                    output += `<td style="white-space: pre;">${createArray((extraData.depth || 0) * 2, ' ').join('')}${row[0]}</td>`;
                }
                output += `<td>${row[1]}</td>`;
                output += '</tr>';
            }
        }
        const paddingClass = padding ? 'table-center-padding' : '';
        const style = visible ? `` : `style="display: none"`;
        const html = (`<table id="${this.tableId}" class="${paddingClass}" ${style}>${output}</table>`);
        return html;
    }
}
//   _________________________________________
//  /                                         \  
// /===============\ Unit Test /===============\ 
// (_ => {
//     var unit = new ATableBuilder(2)
//     unit.insert(['name',    'wisse'])
//     unit.insert(['sibling', 'jaap'])
//     unit.insert(['Address', {"Street":"Louises gate","HouseNumber":"4","City":"Oslo"}])
//     console.log('table:\n', unit.build())
// })()
// \---------------/ Unit Test \---------------/ 
//  \_________________________________________/  
