import { EVENTS } from "./AEventService.js";
export class AStateService {
    constructor() {
        this.state = {
            Status: "Disconnected",
            StatusString: "ControlCenter Disconnected"
        };
    }
    autoInit() { }
    isDisconnected() {
        return stateService.state.Status === 'Disconnected';
    }
    isLoggedIn(loginResponse) {
        const Status = loginResponse?.Status || this.state.Status;
        const SuccessStates = [
            'LoggedIn',
            'ReadyToFollowUp',
            'ReadyToEnforce',
            'ReadyToVerify',
            'Linked'
        ];
        return SuccessStates.includes(Status);
    }
    handleStateStream(newState) {
        if (this.state.Status != newState.Status) {
            this.prevState = this.state;
            this.state = newState;
            const { state, prevState } = this;
            Events.tryInvoke(EVENTS.STATE_CHANGED, { state, prevState });
        }
    }
}
