var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AError } from "../classes/AError.js";
import { AResponse } from "../classes/AResponse.js";
import { ACCCClient } from "../core/ACCCClient.js";
import { executeOrder } from "../types/attributes.js";
import { ALERT_TITLES } from "./AAlertService.js";
import { EVENTS } from "./AEventService.js";
export class APacketIdAllocator {
    constructor() {
        this.packetNum = 1000;
    }
    get _nextPacketId() {
        return this._addPadding(this.packetNum++);
    }
    _addPadding(s, len = 4) {
        s = s.toString();
        if (s.length < len) {
            s = ('0000000000' + s).slice(-len);
        }
        return s;
    }
    getNextPacketId(suffix = 'PACKET') {
        return `[${this._nextPacketId}]${suffix}`;
    }
}
export class ATimeoutError extends Error {
}
export let ARequestService = class ARequestService {
    constructor() {
        this.client = null;
        this.allocator = new APacketIdAllocator();
        Object.defineProperty(window, 'Request', {
            get: () => this
        });
    }
    autoInit() {
        Events.hardwire(EVENTS.JSONS_LOADED, _ => {
            let CCCClient = new ACCCClient(Settings);
            this.client = CCCClient;
            globalThis.CCCClient = CCCClient;
            Events.tryInvoke(EVENTS.API_READY);
        });
    }
    login({ state }) {
        const username = $('#Username').val();
        const password = $('#Password').val();
        const unitId = $("#EnforeUnitId").val();
        const code = $("#AuthenticatorCode").val();
        return this.loginCreds(username, password, code, unitId, state);
    }
    loginCreds(username, password, authenticatorCode, enforceUnitId, desiredState) {
        try {
            const loginPromise = this.waitForLogin();
            this.client.Login(username, password, authenticatorCode, enforceUnitId, desiredState);
            return loginPromise;
        }
        catch (err) {
            AError.handle(err);
            return Promise.resolve(false);
        }
    }
    waitForLogin() {
        return Loading.waitForEvent(EVENTS.STATE_STREAM).then((data) => {
            const { Status, StatusString } = data;
            switch (Status) {
                case 'WaitingForVerificationChannel':
                case 'ReadyToFollowUp':
                case 'LoggedIn':
                    _.setUser(data);
                    return true;
                case 'InvalidLogin':
                    return false;
                default:
                    AError.handleSilent(`Didn't expect Status="${Status}"`);
                    Alerts.show({
                        title: ALERT_TITLES.Warning,
                        content: StatusString
                    });
                    return false;
            }
        });
    }
    async fetch(queryWrapper, options = {}) {
        const res = await this.query(queryWrapper, options);
        const wrapped = new AResponse(res);
        return wrapped;
    }
    // Send query to the server using ajax/websocket
    query(queryWrapper, options = {}) {
        const { skipIdAllocator, cacheQuery } = Object.assign({
            skipIdAllocator: false,
            cacheQuery: false
        }, options);
        if (typeof queryWrapper === 'string') {
            return this.query({
                Query: queryWrapper.replace(/\s\s+/g, ' ').trim()
            });
        }
        const pid = skipIdAllocator === false ? this.allocator.getNextPacketId(queryWrapper.Name) : queryWrapper.Name;
        // Add packet id
        queryWrapper.Name = pid;
        // Remove all unnecessary whitespaces in the query
        queryWrapper.Query = queryWrapper.Query.replace(/\s\s+/g, ' ').trim();
        // Add context for translation
        queryWrapper.Context = document.location.hash.substr(2) || '/';
        // Add Params if the key doesn't exist
        if (!queryWrapper.Params)
            queryWrapper.Params = {};
        // Optional query cache
        if (cacheQuery === true) {
            this.prevQuery = queryWrapper.Query;
            this.prevParams = queryWrapper.Params;
        }
        return new Promise((resolve, reject) => {
            Events.once(`query->${pid}`, (data) => {
                if (data.Success === false) {
                    return reject(new Error(data.Error));
                }
                if (data instanceof Error) {
                    return reject(data);
                }
                return resolve(data);
            });
            this.client.SendMessage("QueryRequest", 2, queryWrapper);
        });
    }
    queries(queryWrappers) {
        return Promise.all(queryWrappers.map((wrapper) => this.query(wrapper)));
    }
    indexes(response, keyArray) {
        const indexes = {};
        for (const key of keyArray) {
            indexes[key] = -1;
        }
        Object.keys(indexes).map(key => indexes[key] = response.Columns.indexOf(key));
        return indexes;
    }
    translate(array, lang) {
        let returnArray = true;
        if (!(array instanceof Array)) {
            array = [array];
            returnArray = false;
        }
        if (array[0] === null)
            throw new Error(`Unexpected error!`);
        const pid = this.allocator.getNextPacketId('Translate');
        this.client.SendMessage("TranslationRequest", 1, {
            Name: pid,
            Translate: array.map(item => {
                return item.replace(/(\d+)/g, ' $1');
            }),
            Context: document.location.hash.substr(2),
            Language: lang ? lang : Language
        });
        return new Promise((resolve) => {
            Events.h_once(`TranslationResponse->${pid}`, async (Data) => {
                // await sleep({ min: 0, max: 10000 })
                returnArray === true ? resolve(Data) : resolve(Object.values(Data)[0]);
            });
        });
    }
    translateDom(html) {
        const pid = this.allocator.getNextPacketId('TranslateDom');
        this.client.SendMessage("TranslationRequest", 1, {
            Name: pid,
            Translate: [html],
            Type: 'Html',
            Context: document.location.hash.substr(2),
            Language: Language
        });
        return new Promise((resolve, reject) => {
            Events.once(`TranslationResponse->${pid}`, async (Data) => {
                if (Object.keys(Data).length !== 1) {
                    return reject(new Error(`Unexpected Translation Response Length`));
                }
                resolve(Object.values(Data)[0]);
            });
        });
    }
    locationRequest(params) {
        this.client.SendMessage("LocationRequest", 1, params);
        return new Promise((resolve) => {
            Events.once(`LocationResponse`, (data) => {
                return resolve(data);
            });
        });
    }
    stream(name, data, opt = { version: 1, priority: 0 }) {
        const { version, priority } = $.extend(true, { version: 1, priority: 0 }, opt);
        this.client.SendMessage(name, version, data, priority);
    }
    sendLog(data) {
        CCCClient.SendMessage("LogStream", 1, data);
    }
    send(name, data, opt) {
        let promise;
        if (opt?.waitForEvent !== undefined) {
            const { waitForEvent, timeout } = opt;
            promise = (timeout == undefined) ? Loading.waitForEvent(waitForEvent) : Loading.awaitTimeout(Loading.waitForEvent(waitForEvent), timeout);
        }
        // const promise = (opt?.waitForEvent !== undefined) ? Loading.waitForEvent(opt!.waitForEvent) : undefined
        this.client.SendMessage(name, opt?.version ?? 1, data, opt?.priority || 0);
        return promise;
    }
};
ARequestService = __decorate([
    executeOrder(100)
], ARequestService);
