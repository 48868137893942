import { ACVSImage } from "./ACVSImage.js";
import { AStripUrl, AUrlEncodedImageFromBase64 } from "../utils/tools.js";
import { EVENTS } from "../services/AEventService.js";
import { APhotoHandler } from "./APhotoHandler.js";
export class AImageHelper {
    get hasProxyWindow() { return this.proxyWindow !== undefined; }
    constructor(opt, stackedHorizontal = false) {
        this.eventIds = [];
        this.opt = Object.assign({
            $rh: $('.right-half'),
            $photos: $('.photos'),
            $lpImage: $('.license-plate'),
            $: $
        }, opt);
        this.images = [];
        this.stackedV = !stackedHorizontal;
        // $(window).on('resize', _ => this.updateImageSize())
        this.$rh.on('scroll', _ => this.images.map(($img) => ACVSImage.refresh($img)));
        this.eventIds.push(Events.on(EVENTS.CONTENT_RESIZE, _ => {
            this.$('.imageContainer .image').each((_, ele) => this.$(ele).data('zoom')?.updateOffset());
        }));
    }
    get win() {
        return this.proxyWindow ? this.proxyWindow.win : window;
    }
    get $() {
        return this.opt.$;
    }
    get stackedHorizontal() {
        return !this.stackedV;
    }
    get stackedVertical() {
        return this.stackedV;
    }
    get $rh() {
        return this.opt.$rh;
    }
    get $photos() {
        return this.opt.$photos;
    }
    get $lpImage() {
        return this.opt.$lpImage;
    }
    setWindowRelay(relay) {
        this.proxyWindow = relay;
    }
    removeAllEvents() {
        this.eventIds.map(eventId => {
            Events.off(EVENTS.CONTENT_RESIZE, eventId);
        });
    }
    setLPImage(image) {
        if (this.$lpImage.is('img')) {
            this.$lpImage.attr('src', AUrlEncodedImageFromBase64(image));
        }
        else {
            this.$lpImage.html('');
            if (image) {
                this.$lpImage.append(`<img src="${AUrlEncodedImageFromBase64(image)}" />`);
            }
        }
    }
    async addImageSet(images, options) {
        const $view = this.$photos.find('[show-if-no-results]');
        $view.toggleClass('hidden', images.length !== 0);
        await Promise.all(images.map(base64 => this.addImage(base64, options)));
    }
    async addImage(base64, options) {
        const { allowFilter, allowFullscreen, allowZoom, isFullScreen, defaultSize } = Object.assign({
            allowFilter: false,
            allowFullscreen: false,
            allowZoom: true,
            isFullScreen: false,
            defaultSize: {
                width: null,
                height: null,
            }
        }, options);
        const imgData = {
            $scrollParent: this.$photos,
            allowFilter,
            allowFullscreen,
            allowZoom,
            defaultSize
        };
        const scrollText = await Loading.waitForPromises(Translate.get('Use Scroll Wheel To Zoom'));
        const $img = await ACVSImage.create(this.$photos, base64, imgData);
        if (isFullScreen) {
            const scrollToZoomText = scrollText;
            const $imageContainer = $img.closest('.imageContainer');
            $imageContainer.append(`
        <div class="absolute-label-container">
          <label class="image-label">${scrollToZoomText}</label>
        </div>
      `);
            $img.trigger('click');
        }
        this.addImageButtonEvents($img.parent());
        this.images.push($img);
    }
    addNavigation({ current, selector, btnSelector, close }) {
        this.$photos.append(`
      <div class="nav-arrows">
        <div class="nav-arrow"><i class="fa-solid fa-left fa-6x"></i></div>
        <div class="nav-arrow"><i class="fa-solid fa-right fa-6x"></i></div>
      </div>
    `);
        let $current = current;
        const [eleLeft, eleRight] = this.$photos.find('.nav-arrow').toArray();
        const $ = this.$;
        $(eleLeft).toggleClass('active', $current.prev().is(selector));
        $(eleRight).toggleClass('active', $current.next().is(selector));
        $(this.win).on('keydown', (e) => {
            switch (e.which) {
                case 37:
                    if ($(eleLeft).length === 0) {
                        $(this.win).off('keydown');
                    }
                    $(eleLeft).trigger('click');
                    break;
                case 39:
                    if ($(eleRight).length === 0) {
                        $(this.win).off('keydown');
                    }
                    $(eleRight).trigger('click');
                    break;
                default:
                    return;
            }
        });
        Events.once('MINIMAL_MODAL_CLOSE', (e) => {
            $(this.win).off('keydown');
        });
        $(eleLeft).on('click', _ => {
            const $prev = $current.prev();
            if ($prev.is(selector)) {
                $current = $prev;
                const $btn = $current.find(btnSelector);
                if (!$btn.length) {
                    return console.error(`Couldn't navigate to next image!`);
                }
                close();
                $btn.trigger('click');
            }
        });
        $(eleRight).on('click', _ => {
            const $next = $current.next();
            if ($next.is(selector)) {
                $current = $next;
                const $btn = $current.find(btnSelector);
                if (!$btn.length) {
                    return console.error(`Couldn't navigate to next image!`);
                }
                close();
                $btn.trigger('click');
            }
        });
    }
    addImageButtonEvents($imageContainers) {
        $imageContainers.each((i, ele) => {
            const $imageContainer = this.$(ele);
            const $img = $imageContainer.find('.image');
            $imageContainer.find('.imageFilterBtn').on('click', _ => {
                APhotoHandler.staticNextFilterSpecific($img);
            });
            $imageContainer.find('.imageZoomBtn').on('click', _ => {
                const $modal = APhotoHandler.staticFullScreen($img);
                if (this.proxyWindow)
                    $modal.detach().appendTo(this.proxyWindow.$('body'));
                const imghelper = new AImageHelper({
                    $photos: $modal.find('.modal-padding')
                });
                imghelper.addNavigation({
                    current: $imageContainer,
                    selector: '.imageContainer',
                    btnSelector: '.imageZoomBtn',
                    close: () => $modal.find('a[href="#close"]').eq(0).trigger('click')
                });
                const base64 = AStripUrl($img.css('background-image'));
                imghelper.addImage(base64, {
                    allowFilter: true,
                    allowFullscreen: false,
                    isFullScreen: true,
                    defaultSize: {
                        width: imghelper.$photos.width(),
                        height: null
                    }
                });
            });
        });
    }
    clearImageSet() {
        for (const $ele of this.images) {
            $ele.remove();
        }
        this.$photos.find('.imageContainer').remove();
        this.images = [];
    }
    updateImageSize() {
        if (this.stackedVertical) {
            return this.images.map($img => {
                const { width, height } = this.getPreferredSize($img);
                ACVSImage.scale($img, width, height);
            });
        }
        else {
            return this.images.map($img => {
                const { width, height } = this.getPreferredSize($img);
                ACVSImage.scale($img, width, height);
            });
        }
    }
    getPreferredSize($img) {
        return $img.data('cachedDomSize');
    }
    getPreferredWidth($img) {
        const { width } = $img.data('cachedDomSize');
        return width;
    }
    getPreferredHeight($img) {
        const { height } = $img.data('cachedDomSize');
        return height;
    }
}
