import { AParkingRight } from "../classes/AUnificationTypes.js";
let UniqueDetections = (`
	SELECT detectionId,DetectionDeviceId 
	FROM detections_geo 
	INNER JOIN (
		select SegmentId,SegmentTimeStamp 
		FROM (SELECT SegmentId,SegmentTimeStamp, count(*) AS DetectionCount 
				FROM detections_geo u1 
				where u1.detectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16) *1000) and (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16) *1000) 
					and IsDuplicate=0 
				group by SegmentTimeStamp order by SegmentId asc, DetectionCount desc
				) t1 
		group by SegmentId
	) t2 
	using(SegmentTimeStamp) 
	where IsDuplicate=0
`).trim();
let ParkingPlaceDetections = (`
	SELECT detectionId,DetectionDeviceId 
	FROM detections_geo g 
	INNER JOIN parkingspaces p ON(ST_Contains(p.Bounds,g.Pos)) 
	WHERE detectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16) *1000) 
		and (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16) *1000)`).trim();
function AMakeQuery(Fields, Ids, Joins) {
    return "SELECT u.DetectionId, u.DetectionDeviceId " + Fields + " FROM (" + Ids + ") u " + Joins;
}
function ADetectionsLatestIds(Filter) {
    return `SELECT d1.DetectionId, d1.DetectionDeviceId,
			IFNULL(MAX(dc.DetectionVersion), 1) AS DetectionVersion,
			IFNULL(MAX(p.ParkingRightVersion), 0) AS ParkingRightVersion, 
			IFNULL(MAX(iv.VerificationVersion), 0) AS VerificationVersion 
		FROM detections d1 
      LEFT JOIN corrections dc USING (DetectionId,DetectionDeviceId) 
		LEFT JOIN parkingrights p USING (DetectionId,DetectionDeviceId) 
		LEFT JOIN verifications iv USING (DetectionId,DetectionDeviceId) `
        + Filter
        + ` group by d1.DetectionId, d1.DetectionDeviceId`;
}
/**
 * Returns where clause for table detections with alias d1 & Filters :FromDate AND :ToDate
 */
function ADetectionsBaseQuery() {
    return (`
		WHERE d1.detectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000)
	`);
}
function AMakeDetectionsFull(Ids) {
    return AMakeQuery(DetectionFields + DetectionsFinalFields + DetectionsUserTagsFields + DetectionGeoFields + ParkingrightFields + VerificationFields, Ids, DetectionsJoin + DetectionsUserTagsJoin + DetectionsGeoJoin + DetectionsGeoBoundsJoin + DetectionsGeoBoundsOriginalJoin + CorrectionsJoin + ParkingrightsJoin + VerificationsJoin + DetectionsFinalJoin);
}
function AMakeDetectionsFullImages(Ids) {
    return AMakeQuery(DetectionFields + DetectionsFinalFields + DetectionsUserTagsFields + DetectionGeoFields + ParkingrightFields + VerificationFields + PlateImageFields, Ids, DetectionsJoin + DetectionsUserTagsJoin + DetectionsGeoJoin + DetectionsGeoBoundsJoin + DetectionsGeoBoundsOriginalJoin + CorrectionsJoin + ParkingrightsJoin + VerificationsJoin + PlateImagesJoin + DetectionsFinalJoin);
}
function AMakeDetectionsPreGeoCorrection(Ids) {
    return AMakeQuery(DetectionFields + DetectionsFinalFields + DetectionsUserTagsFields + DetectionGeoFields + DetectionGeoOriginalFields + ParkingrightFields + VerificationFields, Ids, DetectionsJoin + DetectionsUserTagsJoin + DetectionsGeoJoin + DetectionsGeoBoundsJoin + DetectionsGeoBoundsOriginalJoin + CorrectionsJoin + ParkingrightsJoin + VerificationsJoin + DetectionsFinalJoin);
}
function AMakeFinesFull(Ids) {
    return AMakeQuery(DetectionFields + DetectionsFinalFields + DetectionsUserTagsFields + DetectionGeoFields + ParkingrightFields + VerificationFields + FineFields, Ids, DetectionsJoin + DetectionsUserTagsJoin + DetectionsGeoJoin + DetectionsGeoBoundsJoin + DetectionsGeoBoundsOriginalJoin + CorrectionsJoin + ParkingrightsJoin + VerificationsJoin + DetectionsFinalJoin);
}
function AGetSelectFromQuery(PreparedQuery) {
    const prefix = "SELECT ";
    return PreparedQuery.substring(0, PreparedQuery.indexOf('FROM (SELECT d1.DetectionId')).substr(prefix.length).split(', ');
}
export function AGetSelectNamesFromQuery(PreparedQuery) {
    const selectArray = AGetSelectFromQuery(PreparedQuery);
    return selectArray.map(select => {
        const parts = select.split(' AS ');
        let output = (parts.length > 1) ? parts.pop() : select.split('.').pop();
        return output.replace(/`+/g, '').trim();
    });
}
let UnificationTypes = ['Digital', 'TimeLimitedParking', 'IllegallyParked', 'ParkingRight', 'Verification', 'DetectionState'];
let DetectionsJoin = " LEFT JOIN detections                        d   USING (DetectionId,DetectionDeviceId) ";
let DetectionsUserTagsJoin = " LEFT JOIN detections_usertags               du  USING (DetectionId,DetectionDeviceId) ";
let DetectionsGeoJoin = " LEFT JOIN detections_geo                    dg  USING (DetectionId,DetectionDeviceId) ";
let DetectionsGeoBoundsJoin = " LEFT JOIN detections_geo_bounds             gb  USING (DetectionId,DetectionDeviceId) ";
let DetectionsGeoBoundsOriginalJoin = " LEFT JOIN detections_geo_bounds_original    gbo USING (DetectionId,DetectionDeviceId) ";
let CorrectionsJoin = " LEFT JOIN corrections                       dc  USING (DetectionId,DetectionDeviceId,DetectionVersion) ";
let ParkingrightsJoin = " LEFT JOIN parkingrights                     p   USING (DetectionId,DetectionDeviceId,DetectionVersion,ParkingRightVersion) ";
let VerificationsJoin = " LEFT JOIN verifications                     v   USING (DetectionId,DetectionDeviceId,DetectionVersion,ParkingRightVersion,VerificationVersion) ";
let PlateImagesJoin = " LEFT JOIN plateimages                       pi  USING (DetectionId,DetectionDeviceId) ";
let DetectionsFinalJoin = " LEFT JOIN detections_final                  df  USING (DetectionId,DetectionDeviceId) ";
let CardinalDirection = "FLOOR(IF(d.Side='L',(d.ScanDeviceDirection+270+45)%360,(d.ScanDeviceDirection+90+45)%360)/90)";
let ParkingRightDef = new AParkingRight;
// TODO: Check which should be used for DetectionFields
let DetectionFields = ", d.VehicleType, d.DetectionValid, d.DetectionDevice, d.DetectionUser, d.SessionId, CAST(d.DetectionTime as DateTime) AS DetectionTime, TIME_FORMAT(CAST(d.DetectionTime as DateTime),\"%H %i %s\") AS Time, ROUND(d.Confidence,3) AS Confidence, d.DuplicateCodeLp, ROUND(d.DuplicateCodeLpConf,3) AS DuplicateCodeLpConf, IF(d.Side='R','→',IF(d.Side='L','←','-')) AS Side, d.CameraIds, d.BestCameraId, d.NearMatches, d.VehicleSpeed, ST_AsGeoJSON(IFNULL(gb.VehicleBounds,gbo.VehicleBounds)) AS VehicleBounds, d.VehicleBoundsJson, IF(d.VehicleMoving,'Yes','No') AS VehicleMoving, d.ScanDeviceLatitude, d.ScanDeviceLongitude, d.GpsPrecision, CONCAT(ROUND(FLOOR(d.GpsPrecision*10)/10.0,1),'/',ROUND(CEIL(d.GpsPrecision*10)/10.0,1)) AS GpsPrecisionGroup, d.GpsValid, d.ScanDeviceDirection, CASE " + CardinalDirection + " WHEN 0 THEN 'North' WHEN 1 THEN 'East' WHEN 2 THEN 'South' ELSE 'West' END AS CardinalDirection, DATE_FORMAT(d.DetectionTime,'%H') AS `Hour`, DATE_FORMAT(d.DetectionTime,'%Y-%m-%d') AS `Day`, DATE_FORMAT(d.DetectionTime,'Week %v %Y') AS `Week`, DATE_FORMAT(d.DetectionTime,'%M %Y') AS `Month`, DATE_FORMAT(d.DetectionTime,'%W') AS `Weekday` ";
// let DetectionFields            = ", d.VehicleType, d.DetectionDevice, d.DetectionUser, d.SessionId, CAST(d.DetectionTime as DateTime) AS DetectionTime, TIME_FORMAT(CAST(d.DetectionTime as DateTime),\"%H %i %s\") AS Time, ROUND(d.Confidence,3) AS Confidence, d.DuplicateCodeLp, ROUND(d.DuplicateCodeLpConf,3) AS DuplicateCodeLpConf, IF(d.Side='R','→',IF(d.Side='L','←','-')) AS Side, d.CameraIds, d.BestCameraId, d.NearMatches, d.VehicleSpeed, ST_AsGeoJSON(IFNULL(gb.VehicleBounds,gbo.VehicleBounds)) AS VehicleBounds, ST_AsGeoJSON(IFNULL(gb.VehicleBounds,gbo.VehicleBounds)) AS VehicleBoundsJson, IF(d.VehicleMoving,'Yes','No') AS VehicleMoving, d.ScanDeviceLatitude, d.ScanDeviceLongitude, d.GpsPrecision, CONCAT(ROUND(FLOOR(d.GpsPrecision*10)/10.0,1),'/',ROUND(CEIL(d.GpsPrecision*10)/10.0,1)) AS GpsPrecisionGroup, d.GpsValid, d.ScanDeviceDirection, CASE " + CardinalDirection + " WHEN 0 THEN 'North' WHEN 1 THEN 'East' WHEN 2 THEN 'South' ELSE 'West' END AS CardinalDirection, DATE_FORMAT(d.DetectionTime,'%H') AS `Hour`, DATE_FORMAT(d.DetectionTime,'%Y-%m-%d') AS `Day`, DATE_FORMAT(d.DetectionTime,'Week %v %Y') AS `Week`, DATE_FORMAT(d.DetectionTime,'%M %Y') AS `Month`, DATE_FORMAT(d.DetectionTime,'%W') AS `Weekday` ";
let DetectionsUserTagsFields = ", du.Label ";
let DetectionGeoFields = ", dg.IsIllegallyParked, dg.ParkingAreaType, dg.TaxRequired, dg.AreaId, IFNULL(dg.AreaName,'Unknown') AS Area, dg.AreaConfidence, dg.AreaAttributes, dg.ParkingSpaceId, IFNULL(dg.ParkingSpaceName,'Unknown') AS ParkingSpace, dg.ParkingSpaceConfidence, dg.ParkingSpaceAttributes, dg.SegmentId, dg.SegmentTimestamp, dg.SegmentConfidence, dg.SegmentAttributes, dg.ZoneId, IFNULL(dg.ZoneName,'Unknown') AS Zone, dg.ZoneConfidence, dg.ZoneTimestamp, dg.ZoneAttributes, CONCAT(dg.Street,' ',dg.Housenumber) AS Address ";
let DetectionGeoOriginalFields = ", ST_AsGeoJSON(gbo.VehicleBounds) AS OriginalVehicleBounds ";
let ParkingrightFields = ", IFNULL(p.VehicleCenterLatitude,d.VehicleCenterLatitude) AS VehicleCenterLatitude, IFNULL(p.VehicleCenterLongitude,d.VehicleCenterLongitude) AS VehicleCenterLongitude, p.HasParkingRight, IFNULL(p.LicensePlate,IFNULL(dc.LicensePlate,d.LicensePlate)) AS LicensePlate, IFNULL(p.CountryCode,IFNULL(dc.CountryCode,d.CountryCode)) AS CountryCode, p.ParkingRightType, p.ParkingRightResults";
let VerificationFields = ", v.VerificationResult, v.VerificationResultText, v.VerificationUser, v.VerificationStartTime, v.VerificationEndTime, v.VerificationLatitude, v.VerificationLongitude ";
let FineFields = ", v.FineNumber, v.FineData ";
let PlateImageFields = ", pi.Image ";
let DetectionsFinalFields = ", df.Digital, df.TimeLimitedParking, df.IllegallyParked, df.ParkingRight, df.Verification, df.DetectionState, df.Digital as keyDigital, df.TimeLimitedParking as keyTimeLimitedParking, df.IllegallyParked as keyIllegallyParked, df.ParkingRight as keyParkingRight, df.Verification as keyVerification, df.DetectionState as keyDetectionState, df.FinalVerificationUser, df.FinalVerificationDistanceToVehicle"
    //  + `, IF(df.ParkingRight='NoParkingRight' || df.ParkingRight between ${ParkingRightDef.Options.Indecisive.FirstIndex} and  ${ParkingRightDef.Options.Indecisive.LastIndex},'Non Paying Visitor', IF(df.ParkingRight between ${ParkingRightDef.Options.ParkingRight.Options.Visitor.FirstIndex} and  ${ParkingRightDef.Options.ParkingRight.Options.Visitor.LastIndex}, 'Paying Visitor', null)) AS Visitor`
    + `, IF(df.ParkingRight='NoParkingRight' || df.ParkingRight between ${ParkingRightDef.Options.Indecisive.FirstIndex} and  ${ParkingRightDef.Options.Indecisive.LastIndex},'Non Paying Visitor',IF(df.ParkingRight between ${ParkingRightDef.Options.ParkingRight.Options.Visitor.FirstIndex} and  ${ParkingRightDef.Options.ParkingRight.Options.Visitor.LastIndex},'Paying Visitor',null)) AS Visitor`;
let DetectionsFinalFieldsClean = `, ${UnificationTypes.join(', ')}, FinalVerificationUser, FinalVerificationDistanceToVehicle,`;
let filterTimeRange = "WHERE d1.detectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000)";
let filterViewport = "WHERE d1.detectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000) AND (ScanDeviceLatitude between :South AND :North) AND (ScanDeviceLongitude BETWEEN :West AND :East)";
let filterTimeRangeFine = "WHERE d1.detectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000) AND IF(IFNULL(:FineNumber, '')='', true, FineNumber LIKE :FineNumber) AND IF(Length(:LicensePlate)=0, true, d1.LicensePlate LIKE :LicensePlate)";
let filterLicensePlate = "WHERE d1.detectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000) AND IF(IFNULL(:LicensePlate, '')='', true, d1.LicensePlate LIKE CONCAT(:LicensePlate, '%'))"; // d1.LicensePlate LIKE CONCAT('%', :LicensePlate, '%')";
let filterSearch = "WHERE d1.detectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000) AND IF(IFNULL(:DetectionId, '')='', true, :DetectionId = d1.DetectionId) AND IF(IFNULL(:DetectionDeviceId, '')='', true, :DetectionDeviceId = d1.DetectionDeviceId) and IF(IFNULL(:LicensePlate, '')='', true, d1.LicensePlate LIKE CONCAT(:LicensePlate, '%'))";
let filterSingle = "WHERE d1.detectionid=:DetectionId AND d1.detectiondeviceid=:DetectionDeviceId";
let ReportsSelectFields = `DetectionDevice AS 'Device', Day, DetectionTime AS Time, LicensePlate AS 'LicensePlate', HasParkingRight, IsIllegallyParked, CountryCode AS 'Country', Confidence, Side, Zone, Area, DetectionUser AS 'D-User', VehicleMoving AS 'Moving', ParkingRightType AS 'Right', ParkingAreaType, VerificationResultText AS 'Result', Address, GpsPrecision, DuplicateCodeLp AS 'Dup', VerificationUser AS 'V-User', ScanDeviceLatitude, ScanDeviceLongitude, VehicleCenterLatitude, VehicleCenterLongitude ${DetectionsFinalFieldsClean} DetectionTime, DetectionValid, DetectionDeviceId, DetectionId`;
let ReportsImageSelectFields = `DetectionDevice AS 'Device', Day, DetectionTime AS Time, Image, LicensePlate AS 'LicensePlate', HasParkingRight, IsIllegallyParked, CountryCode AS 'Country', Confidence, Side, Zone, Area, DetectionUser AS 'D-User', VehicleMoving AS 'Moving', ParkingRightType AS 'Right', ParkingAreaType, VerificationResultText AS 'Result', Address, GpsPrecision, DuplicateCodeLp AS 'Dup', VerificationUser AS 'V-User', ScanDeviceLatitude, ScanDeviceLongitude, VehicleCenterLatitude, VehicleCenterLongitude ${DetectionsFinalFieldsClean} DetectionTime, DetectionValid, DetectionDeviceId, DetectionId`;
// Time Range
const idsTimeRange = ADetectionsLatestIds(filterTimeRange);
let DetectionsFullRanged = AMakeDetectionsFull(idsTimeRange);
let DetectionsFullImagesRanged = AMakeDetectionsFullImages(idsTimeRange);
// Detections full ranged including pre geo correction
let DetectionsPreGeoCorrection = AMakeDetectionsPreGeoCorrection(idsTimeRange);
// Viewport 
const idsViewport = ADetectionsLatestIds(filterViewport);
let DetectionsViewport = AMakeDetectionsFull(idsViewport);
// License Plate search 
const idsLicensePlate = ADetectionsLatestIds(filterLicensePlate);
let DetectionsSearchRanged = AMakeDetectionsFull(idsLicensePlate);
let DetectionsSearchImagesRanged = AMakeDetectionsFullImages(idsLicensePlate);
// License Plate or DetectionId &  DetectionDeviceId search
const idsSearch = ADetectionsLatestIds(filterSearch);
let DetectionsSearchIdRanged = AMakeDetectionsFull(idsSearch);
// DetectionId & DetectionDeviceId (without time)
const idsSingle = ADetectionsLatestIds(filterSingle);
let DetectionsFullSingle = AMakeDetectionsFull(idsSingle);
let DetectionsFullSingleImage = AMakeDetectionsFullImages(idsSingle);
// Fines
let idsFines = ADetectionsLatestIds(filterTimeRangeFine);
let FinesFullRanged = AMakeFinesFull(idsFines);
export { DetectionsFullRanged, DetectionsFullImagesRanged, DetectionsPreGeoCorrection, DetectionsViewport, DetectionsSearchRanged, DetectionsSearchImagesRanged, DetectionsSearchIdRanged, DetectionsFullSingle, DetectionsFullSingleImage, FinesFullRanged, UniqueDetections, ParkingPlaceDetections, ReportsSelectFields, ReportsImageSelectFields, UnificationTypes };
