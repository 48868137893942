import { AConfig } from '../classes/AConfig.js';
import { ADetectionStatistics } from '../classes/ADetectionStatistics.js';
import { AError } from '../classes/AError.js';
import { AResponse } from '../classes/AResponse.js';
import { AStatisticResponse } from '../classes/AStatisticResponse.js';
import { AVerification } from '../classes/AUnificationTypes.js';
import { ARound } from '../utils/tools.js';
function applyCustomCalculation(filters) {
    const useCustomCalculation = AConfig.get('general.useCustomUnificationCalc', false);
    let ParkingRightSelect = "ParkingRight";
    if (useCustomCalculation) {
        let verificationRef = new AVerification();
        ParkingRightSelect = "IF(ParkingRight='NoParkingRight', IF(Verification BETWEEN :FinedStart AND :FinedEnd OR Verification BETWEEN :NotFinedStart AND :NotFinedEnd, 'NoParkingRight', IF(Verification='InProgress','InProgress','NotProcessed_Unknown' ) ), ParkingRight) AS ParkingRight";
        filters["FinedStart"] = verificationRef.Options.Fined.FirstIndex;
        filters["FinedEnd"] = verificationRef.Options.Fined.LastIndex;
        filters["NotFinedStart"] = verificationRef.Options.NotFined.FirstIndex;
        filters["NotFinedEnd"] = verificationRef.Options.NotFined.LastIndex;
    }
    return { ParkingRightSelect };
}
export class AStatisticsService {
    async fetchDynamicSingle(filters) {
        const dynamicStats = await this.fetchDynamic(filters);
        return dynamicStats.toTuple().map(([data, stats]) => stats).pop() || new ADetectionStatistics();
    }
    async fetchDynamic(filters, options) {
        const { joinTables, where, columns } = Object.assign({
            joinTables: [],
            where: [],
            columns: [],
        }, options);
        const selects = columns.map(obj => `${obj.select} as \`${obj.name}\``);
        const groups = columns.filter(col => col.groupBy === true).map(obj => `\`${obj.name}\``);
        const additionalColumns = selects.length ? `,${selects.join(', ')}` : '';
        const additionalGroupBy = groups.length ? `${groups.join(', ')},` : '';
        const additionalOrderBy = groups.length ? `ORDER BY ${groups.join(', ')}` : '';
        const additionalJoins = joinTables.join(' ');
        const whereClause = [
            'DetectionTime BETWEEN :FromDate AND :ToDate',
            'FinalizedSuccess = 1'
        ].concat(where).join(' AND ');
        const { ParkingRightSelect } = applyCustomCalculation(filters);
        // Potential MySQL Injection?
        let detections = await requestService.query({
            Query: (`
        SELECT Digital,
          IllegallyParked,
          TimeLimitedParking,
          ${ParkingRightSelect},
          Verification,
          DetectionState,
          count(*) as Total
          ${additionalColumns}
        FROM detections_final df
        ${additionalJoins}
        WHERE
          ${whereClause}
        GROUP BY
          ${additionalGroupBy}
          Digital,
          TimeLimitedParking,
          IllegallyParked,
          ParkingRight,
          Verification
        ${additionalOrderBy}
      `),
            Params: filters,
            Translate: columns.filter(v => v.translate === true).map(v => v.name),
            Language: Language
        });
        const output = [];
        for (let row of detections.Rows) {
            const uid = {};
            let key = '_';
            for (let i = 0; i < columns.length; i++) {
                const { name, groupBy } = columns[i];
                uid[name] = row[7 + i];
                if (groupBy === true) {
                    key += row[7 + i];
                }
            }
            if (!output.hasOwnProperty(key)) {
                output[key] = [uid, new ADetectionStatistics()];
            }
            output[key][1].addDetectionFinalCount(row[0], row[1], row[2], row[3], row[4], row[5], Number(row[6]));
        }
        return new AStatisticResponse(Object.values(output), detections);
    }
    /**
     * Creates DetectionsStatistics with given filters
     * returns one or more hashtables with key being SegmentId by default
     * and value of ADetectionStatistics
     *
     * Returns one hashtable by default
     * Returns one hashtable if one or zero mapTo methods are used
     * Returns an array of hashtabled if more than one mapTo methods are used
     *
     * @param {*} filters filters from & to date
     * @param {{mapTo?: any, ignoreDetectionsOutsideSegment?: boolean, extraWhereClause?: string}} [options] mapTo is one or more functions to map the stats another value instead of SegmentId
     * @returns {Promise<{ statisticsTotal: ADetectionStatistics, statistics: (ADetectionStatistics[] | ADetectionStatistics) }>}
     */
    fetch(filters, options) {
        if (options === undefined) {
            options = {};
        }
        if (options.mapTo === undefined) {
            options.mapTo = [(segmentId) => segmentId];
        }
        if (!Array.isArray(options.mapTo)) {
            options.mapTo = [options.mapTo];
        }
        return this.fetchInternal(filters, options);
    }
    /**
     *
     * @param {*} filters
     * @param {{ mapTo?: any[], ignoreDetectionsOutsideSegment?: boolean }} [options]
     * @returns {Promise<{ statisticsTotal: ADetectionStatistics, statistics: (ADetectionStatistics[] | ADetectionStatistics) }>}
     */
    async fetchInternal(filters, options) {
        let { detections, segmentEntries } = await this.fetchQueries(filters, options);
        let { mapTo } = Object.assign({ mapTo: [(segmentId) => segmentId] }, options);
        let statisticsTotal = new ADetectionStatistics();
        let statistics = [];
        // Allocate array size
        mapTo.map(_ => statistics.push({}));
        let processedDetections = 0, skippedDetections = 0;
        let success = 0;
        let warnings = [];
        for (let row of detections.Rows) {
            mapTo.map((mapToId, i) => {
                const mappedKey = mapToId(row[7]);
                // TODO: Optimize code
                const key = (mappedKey != null && typeof mappedKey === 'object') ? mappedKey.key : mappedKey;
                const overlap = (mappedKey != null && typeof mappedKey === 'object') ? mappedKey.overlap : 1;
                if (mappedKey !== null) {
                    if (!statistics[i].hasOwnProperty(key)) {
                        statistics[i][key] = new ADetectionStatistics();
                    }
                    statistics[i][key]
                        .addDetectionFinalCount(row[0], row[1], row[2], row[3], row[4], row[5], Number(row[6]) * overlap);
                    success++;
                    processedDetections += Number(row[6]);
                }
                else {
                    warnings.push(`'${row[7]}' to mappedKey #${i}!`);
                    skippedDetections += Number(row[6]);
                }
            });
            statisticsTotal
                .addDetectionFinalCount(row[0], row[1], row[2], row[3], row[4], row[5], Number(row[6]));
        }
        const segmentEntriesObj = new AResponse(segmentEntries);
        segmentEntriesObj.loop(({ OccupancySum, CapacitySum, EntryCount, SegmentId }) => {
            mapTo.map((mapToId, i) => {
                let mappedKey = mapToId(SegmentId);
                // TODO: Optimize code
                const key = (mappedKey != null && typeof mappedKey === 'object') ? mappedKey.key : mappedKey;
                const overlap = (mappedKey != null && typeof mappedKey === 'object') ? mappedKey.overlap : 1;
                if (mappedKey !== null) {
                    if (!statistics[i].hasOwnProperty(key)) {
                        statistics[i][key] = new ADetectionStatistics();
                    }
                    statistics[i][key]
                        .addOccupancy(ARound(OccupancySum), ARound(Number(CapacitySum)), Number(EntryCount) * overlap);
                    success++;
                }
                else {
                    warnings.push(`'${SegmentId}' to mappedKey #${i}!`);
                }
            });
            statisticsTotal
                .addOccupancy(ARound(OccupancySum), ARound(Number(CapacitySum)), Number(EntryCount));
        });
        if (warnings.length) {
            console.log(`Processed: ${success}`);
            console.warn(`Couldn't link ${warnings.length} statistics\n`, warnings);
        }
        return {
            statisticsTotal: statisticsTotal,
            statistics: statistics.length > 1 ? statistics : statistics.pop(),
            processedDetections,
            skippedDetections,
            rowCounts: detections.Rows
        };
    }
    /**
     *
     * @param {*} filters
     * @param {{ ignoreDetectionsOutsideSegment?: boolean, extraWhereClause?: string }} [options]
     */
    async fetchQueries(filters, options) {
        const { onlyAllowFinalized, ignoreDetectionsOutsideSegment, extraWhereClause } = Object.assign({ onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: false }, options);
        const { DetectionDeviceId } = filters;
        let additionalWhere = (DetectionDeviceId && DetectionDeviceId.length && DetectionDeviceId !== '%') ?
            ' AND DetectionDeviceId=:DetectionDeviceId' : ' ';
        if (onlyAllowFinalized === true) {
            additionalWhere += ' AND FinalizedSuccess = 1';
        }
        if (ignoreDetectionsOutsideSegment === true) {
            additionalWhere += ' AND SegmentTimeStamp IS NOT NULL';
        }
        if (extraWhereClause) {
            additionalWhere += ' AND ' + extraWhereClause;
        }
        const { ParkingRightSelect } = applyCustomCalculation(filters);
        // Potential MySQL Injection?
        let detections = await requestService.query({
            Query: (`
        SELECT Digital,
          IllegallyParked,
          TimeLimitedParking,
          ${ParkingRightSelect},
          Verification,
          DetectionState,
          count(*) as Total,
          Segmentid
        FROM detections_final
        WHERE
          DetectionTime BETWEEN :FromDate AND :ToDate
          ${additionalWhere}
        GROUP BY
          Segmentid,
          Digital,
          TimeLimitedParking,
          IllegallyParked,
          ParkingRight,
          Verification
      `),
            Params: filters
        });
        let segmentEntries = await requestService.query({
            Query: (`
        SELECT SUM( OccupancyCount ) AS OccupancySum, SUM(Capacity) AS CapacitySum, COUNT(*) AS EntryCount, SegmentId
        FROM segment_entries
        WHERE SegmentTimeStamp BETWEEN :FromDate AND :ToDate
        GROUP BY SegmentId
      `),
            Params: filters
        });
        return { detections, segmentEntries };
    }
    fetchSegmentToStreetName() {
        const map = {};
        const imap = {};
        return requestService.query(`
      SELECT SegmentId, Name
      FROM geo_waysegments
      INNER JOIN geo_segments2waysegments USING (WaySegmentId)
      WHERE active=1 AND LENGTH(Name) > 0
      GROUP BY SegmentId
    `).then(({ Rows }) => {
            for (const [segmentId, id] of Rows) {
                map[segmentId] = id;
                imap[id] = segmentId;
            }
            return { map, imap };
        }).catch(err => this.catchError(err));
    }
    fetchSegmentToWaySegmentId() {
        const map = {};
        const imap = {};
        return requestService.query(`
      SELECT SegmentId, WaySegmentId
      FROM geo_waysegments
      INNER JOIN geo_segments2waysegments USING (WaySegmentId)
      WHERE active=1
      GROUP BY SegmentId
    `).then(({ Rows }) => {
            for (const [segmentId, id] of Rows) {
                map[segmentId] = id;
                if (!imap.hasOwnProperty(id)) {
                    imap[id] = [];
                }
                imap[id].push(segmentId);
            }
            return { map, imap };
        }).catch(err => this.catchError(err));
    }
    catchError(err) {
        const TABLE_ERROR_IDENTIFIER = 'Prepare failed:Table ';
        try {
            if (!err.message.startsWith(TABLE_ERROR_IDENTIFIER)) {
                return AError.handle(err);
            }
            const tablename = err.message.split(`'`)[1];
            const specificError = new Error(`Table '${tablename}' doesn't exist in the database!`);
            return AError.handle({
                useAdminAlerts: true,
                err: [err, specificError]
            });
        }
        catch (err) {
            return AError.handle(err);
        }
    }
    /**
     * @param {*} filters
     * @param {{ ignoreDetectionsOutsideSegment?: boolean, usernameMap?: any }} options
     */
    async fetchGroupedByDetectionUser(filters, options) {
        const { ignoreDetectionsOutsideSegment, usernameMap } = Object.assign({ ignoreDetectionsOutsideSegment: false, usernameMap: undefined }, options || {});
        let additionalWhere = '';
        if (ignoreDetectionsOutsideSegment === true) {
            additionalWhere += ' AND SegmentTimeStamp IS NOT NULL';
        }
        const { ParkingRightSelect } = applyCustomCalculation(filters);
        // Potential MySQL Injection?
        const response = await requestService.query({
            Query: (`
        SELECT
          DetectionUser,
          Digital,
          IllegallyParked,
          TimeLimitedParking,
          ${ParkingRightSelect},
          Verification,
          DetectionState,
          COUNT(*) as Total
        FROM detections_final
        WHERE
          DetectionTime BETWEEN :FromDate AND :ToDate AND
          FinalizedSuccess = 1 ${additionalWhere}
        GROUP BY
          DetectionUser,
          Digital,
          TimeLimitedParking,
          IllegallyParked,
          ParkingRight,
          Verification
      `),
            Params: filters
        });
        const output = {};
        response.Rows.map(([DetectionUser, Digital, IllegallyParked, TimeLimitedParking, ParkingRight, Verification, DetectionState, Total]) => {
            let user = DetectionUser;
            if (usernameMap && usernameMap.hasOwnProperty(user)) {
                user = usernameMap[user];
            }
            if (!output.hasOwnProperty(user)) {
                output[user] = new ADetectionStatistics();
            }
            output[user].addDetectionFinalCount(Digital, IllegallyParked, TimeLimitedParking, ParkingRight, Verification, DetectionState, Number(Total));
        });
        return output;
    }
    async fetchTotals(filters, options) {
        const { ignoreDetectionsOutsideSegment } = Object.assign({ ignoreDetectionsOutsideSegment: false }, options || {});
        let additionalWhere = '';
        if (ignoreDetectionsOutsideSegment === true) {
            additionalWhere += ' AND SegmentTimeStamp IS NOT NULL';
        }
        const { ParkingRightSelect } = applyCustomCalculation(filters);
        // Potential MySQL Injection?
        const response = await requestService.query({
            Query: (`
        SELECT
          Digital,
          IllegallyParked,
          TimeLimitedParking,
          ${ParkingRightSelect},
          Verification,
          DetectionState,
          COUNT(*) as Total
        FROM detections_final
        WHERE
          DetectionTime BETWEEN :FromDate AND :ToDate AND
          FinalizedSuccess = 1 ${additionalWhere}
        GROUP BY
          Digital,
          TimeLimitedParking,
          IllegallyParked,
          ParkingRight,
          Verification
      `),
            Params: filters
        });
        const output = new ADetectionStatistics();
        response.Rows.map(([Digital, IllegallyParked, TimeLimitedParking, ParkingRight, Verification, DetectionState, Total]) => {
            output.addDetectionFinalCount(Digital, IllegallyParked, TimeLimitedParking, ParkingRight, Verification, DetectionState, Number(Total));
        });
        return output;
    }
}
