// This script will include all unification types which do not directly relate to the database columns //
export class AOccupancy {
    constructor() {
        this.Key = "Occupancy";
        this.KeyShort = "Occupancy";
        this.OccupancySum = 0.;
        this.CapacitySum = 0.;
        this.EntryCount = 0;
    }
    get Perc() {
        return this.OccupancySum / this.CapacitySum;
    }
    get Options() {
        return {
            Occupied: {
                Key: "Occupied_Spaces",
                KeyShort: "Occupied_Spaces",
                Count: this.OccupancySum,
                Description: "",
                Options: {}
            },
            NotOccupied: {
                Key: "Free_Spaces",
                KeyShort: "Free_Spaces",
                Count: this.CapacitySum - this.OccupancySum,
                Description: "",
                Options: {}
            }
        };
    }
}
