import { AEngine, sleep } from "../core/AEngine.js";
import { APreferenceService, SAVE_IDS } from "../services/APreferenceService.js";
export class APage {
    constructor() {
        this.preferenceService = AEngine.get(APreferenceService);
        this.bgs = [
            'gradient-default',
            'gradient-1',
            'gradient-2',
            'gradient-3',
            'gradient-4',
            'gradient-5',
            'gradient-6',
            'gradient-7',
            'gradient-8',
            'gradient-9',
            'gradient-10',
            'gradient-11',
            'gradient-12',
            'gradient-13',
            'gradient-14',
            'gradient-15',
            'gradient-16',
            'gradient-17',
            'gradient-18',
            'gradient-19',
            'gradient-20',
            'gradient-21',
            'gradient-22',
            'gradient-23',
            'gradient-24',
            'gradient-25',
            'gradient-26',
            'gradient-27',
            'gradient-28',
            'gradient-29',
            'gradient-30',
            'gradient-31',
            'gradient-32',
            'gradient-33',
            'gradient-34',
            'gradient-35',
            'gradient-36'
        ];
        this.changeBackground(this.preferenceService.load(SAVE_IDS.BG_COLOR, this.bgs[0]));
    }
    async init() {
        $('#bg-prev').on('click', _ => this.changeColor(-1));
        $('#bg-next').on('click', _ => this.changeColor(1));
        $('#save').on('click', _ => this.save());
        this.showInfo();
    }
    getNextIndex(index, step) {
        if (index === -1) {
            return 0;
        }
        return (index === 0 && step === -1) ? this.bgs.length - 1 : index + step;
    }
    changeColor(step) {
        const index = this.bgs.indexOf(this.selectedBackground);
        const nextIndex = this.getNextIndex(index, step);
        this.changeBackground(this.bgs[nextIndex % this.bgs.length]);
    }
    changeBackground(newBackground) {
        const $body = $('body');
        if ($body.hasClass(this.selectedBackground)) {
            $body.removeClass(this.selectedBackground);
        }
        $body.addClass(newBackground);
        $('#background').val(newBackground);
        this.selectedBackground = newBackground;
    }
    showInfo() {
        const { StatusString, UserDisplayName, user, UserGroups } = _.getUser();
        $('#displayname').val(UserDisplayName);
        $('#username').val(user);
        $('#status').val(StatusString);
        $('#usergroups').val(UserGroups.join(', '));
    }
    async save() {
        this.preferenceService.save(SAVE_IDS.BG_COLOR, this.selectedBackground);
        await Loading.waitForPromises([
            sleep(100)
        ]);
    }
}
export function render() {
    return ( /*html*/`
        <style>
            .profile-settings {
                margin: 15px;
            }
        </style>
        <div class="profile-settings">
            <div class="columns">
                <div class="column col-8 col-mx-auto">
                    <div id="userdata" class="columns">
                        <div class="column col-6">
                            <div class="form-group">
                                <label class="form-label">User</label>
                                <input id="username" class="form-input" type="text" value="" disabled="disabled">
                            </div>
                        </div>
                        <div class="column col-6">
                            <div class="form-group">
                                <label class="form-label">Display Name</label>
                                <input id="displayname" class="form-input" type="text" value="" disabled="disabled">
                            </div>
                        </div>
                        <div class="column col-6">
                            <div class="form-group">
                                <label class="form-label">Status</label>
                                <input id="status" class="form-input" type="text" value="" disabled="disabled">
                            </div>
                        </div>
                        <div class="column col-6">
                            <div class="form-group">
                                <label class="form-label">Usergroups</label>
                                <input id="usergroups" class="form-input" type="text" value="" disabled="disabled">
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="background" class="form-label">Background</label>
                        <div class="input-group">
                            <input id="background" class="form-input" type="text" placeholder="Default" value="Default" disabled="disabled">
                            <button id="bg-prev" class="btn btn-secondary input-group-btn">Previous Background</button>
                            <button id="bg-next" class="btn btn-primary input-group-btn">Next Background</button>
                        </div>
                    </div>

                    <br>
                    <div class="columns">
                        <div class="column col-8 col-mx-auto">
                            <button id="save" class="btn btn-primary col-12">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `);
}
