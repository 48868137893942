export var MAP_POSITION;
(function (MAP_POSITION) {
    MAP_POSITION[MAP_POSITION["TOP_LEFT"] = 0] = "TOP_LEFT";
    MAP_POSITION[MAP_POSITION["TOP_RIGHT"] = 1] = "TOP_RIGHT";
    MAP_POSITION[MAP_POSITION["BOTTOM_LEFT"] = 2] = "BOTTOM_LEFT";
    MAP_POSITION[MAP_POSITION["BOTTOM_RIGHT"] = 3] = "BOTTOM_RIGHT";
})(MAP_POSITION || (MAP_POSITION = {}));
export var MAP_OPTIONS;
(function (MAP_OPTIONS) {
    MAP_OPTIONS[MAP_OPTIONS["Region"] = 2] = "Region";
    MAP_OPTIONS[MAP_OPTIONS["Area"] = 4] = "Area";
    MAP_OPTIONS[MAP_OPTIONS["RouteArea"] = 8] = "RouteArea";
    MAP_OPTIONS[MAP_OPTIONS["Segment"] = 16] = "Segment";
    MAP_OPTIONS[MAP_OPTIONS["Zone"] = 32] = "Zone";
    MAP_OPTIONS[MAP_OPTIONS["ParkingSpace"] = 64] = "ParkingSpace";
    MAP_OPTIONS[MAP_OPTIONS["GeneratedParkingSpace"] = 128] = "GeneratedParkingSpace";
    MAP_OPTIONS[MAP_OPTIONS["SplitParkingSpace"] = 256] = "SplitParkingSpace";
    MAP_OPTIONS[MAP_OPTIONS["DirectedWaySegment"] = 512] = "DirectedWaySegment";
    MAP_OPTIONS[MAP_OPTIONS["WaySegment"] = 1024] = "WaySegment";
    MAP_OPTIONS[MAP_OPTIONS["RouteOption"] = 2048] = "RouteOption";
    MAP_OPTIONS[MAP_OPTIONS["RouteIntersection"] = 4096] = "RouteIntersection";
    MAP_OPTIONS[MAP_OPTIONS["Address"] = 8192] = "Address";
    MAP_OPTIONS[MAP_OPTIONS["TemporaryZone"] = 16384] = "TemporaryZone";
    MAP_OPTIONS[MAP_OPTIONS["ParkingMachine"] = 32768] = "ParkingMachine";
    MAP_OPTIONS[MAP_OPTIONS["ImageLocation"] = 65536] = "ImageLocation";
    MAP_OPTIONS[MAP_OPTIONS["All"] = -1] = "All";
    MAP_OPTIONS[MAP_OPTIONS["None"] = 0] = "None";
    MAP_OPTIONS[MAP_OPTIONS["Default"] = 122990] = "Default";
})(MAP_OPTIONS || (MAP_OPTIONS = {}));
export const ALL_MAP_OPTIONS = [
    MAP_OPTIONS.Region,
    MAP_OPTIONS.Area,
    MAP_OPTIONS.RouteArea,
    MAP_OPTIONS.Segment,
    MAP_OPTIONS.Zone,
    MAP_OPTIONS.TemporaryZone,
    MAP_OPTIONS.ParkingSpace,
    MAP_OPTIONS.ParkingMachine,
    MAP_OPTIONS.ImageLocation,
    MAP_OPTIONS.GeneratedParkingSpace,
    MAP_OPTIONS.SplitParkingSpace,
    MAP_OPTIONS.DirectedWaySegment,
    MAP_OPTIONS.WaySegment,
    MAP_OPTIONS.RouteOption,
    MAP_OPTIONS.RouteIntersection,
    MAP_OPTIONS.Address,
];
// export const MAP_OPTIONS = {
//   All: -1,
//   None: 0,
//   Default: 7,
//   ParkingSpaces: 1,
//   Zones: 2,
//   Areas: 4,
//   Segments: 8
// }
export const UNLOAD_OPTIONS = {
    Default: 0,
    AllListeners: 1,
    HardDelete: 2,
    None: 3
};
