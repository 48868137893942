import { AFormatDate, ARound, AUrlEncodedImageFromBase64, formatPrice } from "../utils/tools.js";
const IMAGE_RENDERER = function ({ record, value, size }) {
    if (!size && this.field === 'Image') {
        return '';
    }
    size.height = 120;
    return value;
};
export const COLUMN_TEXT = {};
export const COLUMN_HIDDEN = { hidden: true };
export const COLUMN_DATETIME = { type: 'date', renderer: ({ value }) => AFormatDate(value) };
export const COLUMN_TIME = { type: 'time' };
export const COLUMN_NUMBER = { type: 'number' };
export const COLUMN_IMAGE = { htmlEncode: false, renderer: IMAGE_RENDERER };
// export const COLUMN_BOOLEAN = { htmlEncode: false, renderer: ({ value }) => { return value == '1' ? `<i class="fa fa-check fa-lg"></i><p class="hidden">true</p>` : `<i class="fa fa-times fa-lg"></i><p class="hidden">false</p>` } }
export const COLUMN_BOOLEAN = {
    htmlEncode: false,
    renderer: ({ value }) => [true, '1', 1].includes(value) ? `<i class="fa fa-check fa-lg"></i><p class="hidden">true</p>` : `<i class="fa fa-times fa-lg"></i><p class="hidden">false</p>`
};
export const COLUMN_BOOLEAN_NULLABLE = { htmlEncode: false, renderer: ({ value }) => { return value === null ? '' : COLUMN_BOOLEAN.renderer({ value }); } };
export const COLUMN_PRICE = { htmlEncode: false, renderer: ({ value }) => { return value ? formatPrice(value) : ''; } };
export const COLUMN_PRICE_NO_CURR = { htmlEncode: false, renderer: ({ value }) => { return value ? formatPrice(value, '', '') : ''; } };
export const DATA_DATETIME = (value) => new Date(value);
export const DATA_TIME = (value) => new Date(value);
export const DATA_IMAGE = (base64) => (`<img class="table-img" src="${AUrlEncodedImageFromBase64(base64)}" />`);
export const DATA_NUMBER = (value) => Number(value);
export const DATA_ROUND = (value) => ARound(value);
export const DATA_ROUND1 = (value) => ARound(value, 1);
export const DATA_ROUND2 = (value) => ARound(value, 2);
