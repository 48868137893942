const cachedServiceInstances = {};
export class AEngine {
    /**
     * Whether the framework is running in development mode
     */
    static get isDevelopmentMode() {
        const devConfig = JSON.parse($('#dev-config').text() || '{}');
        return (Object.keys(devConfig).length > 0);
    }
    /**
     * Developer config (applicable when running node webserver)
     */
    static get devConfig() {
        const devConfig = JSON.parse($('#dev-config').text() || '{}');
        return devConfig;
    }
    /**
     * Converts a module name into a service name (First A gets removed & next character will be lowercased)
     * @param {*} moduleRef
     */
    static toServiceName(moduleRef) {
        let { name } = moduleRef;
        if (name.startsWith('A')) {
            name = name.substr(1);
        }
        return (name.length > 0) ? name.charAt(0).toLowerCase() + name.slice(1) : name;
    }
    /**
     * Instantiates a singleton instance of a service.
     * @param moduleRef Reference class to the service
     * @param instantiator (optional) override default constructor
     */
    static injectModule(moduleRef, instantiator) {
        if (!moduleRef || !moduleRef.name) {
            throw new Error(`Module name couldn't be found!`);
        }
        if (!moduleRef.name.replaceAll(/\$.*/g, '').endsWith('Service')) {
            throw new Error(`Module "${moduleRef.name}" doesn't end with proper suffix: "Service"`);
        }
        const name = AEngine.toServiceName(moduleRef);
        const instance = instantiator ? instantiator() : new moduleRef();
        window[name] = instance;
        cachedServiceInstances[name] = instance;
        return instance;
    }
    /**
     * Shorthand annotation for AEngine.getOrCreateInstance
     */
    static get(moduleRef, options) {
        return AEngine.getOrCreateInstance(moduleRef, options);
    }
    /**
     * Checks webbrowser cache, whether the singleton service is already instantiated & returns it.
     * If it is not instantiated, it will inject the module before returning a new singleton reference.
     * @template T
     * @param {new() => T} moduleRef static reference the service class
     * @param {Function} [instantiator] (optional) override default constructor
     * @param {boolean} [isGlobal] (optional) doesn't allow you to call this method because the variable is global!
     * @returns {T}
     */
    static getOrCreateInstance(moduleRef, { factory, isGlobal = false } = { isGlobal: false }) {
        const name = AEngine.toServiceName(moduleRef);
        if (!(window[name] instanceof moduleRef)) {
            const instance = AEngine.injectModule(moduleRef, factory || undefined);
            Object.assign(instance, { __isGlobal: isGlobal });
            AEngine.bindLog(instance);
            this.logCreation(moduleRef.name);
        }
        else {
            let serviceInstance = window[name];
            if (serviceInstance.__isGlobal === true) {
                // console.warn(`[WARNING] Service "${name}" already exists, please use the global variable instead!`)
            }
        }
        return window[name];
    }
    /**
     * Gets Object containing key-value pair with keys being the class ref & value the service instance
     * @returns {*} object with all singleton instances
     */
    static getServiceInstances() {
        return cachedServiceInstances;
    }
    static getServiceInstance(moduleRef) {
        return cachedServiceInstances[moduleRef];
    }
    /**
     * Creates singleton versions of every service
     * @param {Array} services
     */
    static async executeServices(services) {
        const sorted = services.sort((a, b) => (a.prototype.executeOrder || 0) - (b.prototype.executeOrder || 0));
        for (let service of sorted) {
            AEngine.getOrCreateInstance(service, { isGlobal: true });
        }
    }
    /**
     * Executes autoInit method for every service singleton instance.
     */
    static async autoInitServices() {
        const serviceKeys = Object.keys(AEngine.getServiceInstances());
        for (let key of serviceKeys) {
            const serviceRef = AEngine.getServiceInstance(key);
            if (serviceRef.autoInit && ['Function', 'AsyncFunction'].includes(serviceRef.autoInit.constructor.name)) {
                await Loading.waitForPromises(Promise.resolve().then(serviceRef.autoInit()));
            }
        }
    }
    /**
     * Invokes run method to all items in array
     * @param {Array} runnables Array of runnable classes
     */
    static executeRunnables(runnables) {
        let promises = [];
        for (let runnable of runnables) {
            promises.push(Promise.resolve(runnable.run()));
        }
        return Promise.all(promises);
    }
    static bindLog(instance) {
        const mainColor = getComputedStyle(document.documentElement).getPropertyValue('--main-color') || '#00b3e3';
        const highlight = `color: ${mainColor}`, normal = 'color: black';
        Object.assign(instance, {
            log: function (str, ...params) {
                const args = [`%c[%c${this.constructor.name}%c] ` + str, normal, highlight, normal];
                const length = str.split('%c').length - 1;
                const arr = length > 0 ? args.concat(params) : args;
                return console.log.apply(instance, arr);
            }.bind(instance)
        });
    }
    /**
     * Looks for occurance of "%c" in string and will toggle blue color upon every encounter
     * %c = blue
     * %p = purple
     * %n = black
     * %s = save color
     * %r = reset to saved color
     */
    static log(msg, context) {
        const colorMap = {
            '%c': `color: ${getComputedStyle(document.documentElement).getPropertyValue('--main-color') || '#00b3e3'}`,
            '%p': `color: #ff00ff`,
            '%n': `color: #000`
        };
        const colorKeys = Object.keys(colorMap);
        const clrHistory = [];
        const prefixedMessage = `%n[%cAEngine%n] ${msg}`;
        const args = [...prefixedMessage.matchAll(/(%c|%p|%n|%r|%s)/g)].map((m, i) => {
            switch (m[0]) {
                case '%s':
                    clrHistory.unshift({ colorKey: '%s', style: clrHistory.find((a) => a.colorKey !== '%s')?.style ?? colorMap['%n'] });
                    return null;
                case '%r':
                    return clrHistory.find((a) => a.colorKey === '%s')?.style || colorMap['%n'];
                default:
                    if (!colorKeys.includes(m[0])) {
                        throw new Error(`Expected colorKey: [${colorKeys.join(',')}], but received: ${m[0]}`);
                    }
                    clrHistory.unshift({ colorKey: m[0], style: colorMap[m[0]] });
                    return clrHistory[0].style;
            }
        }).filter(item => item !== null);
        const prefixedMessageClean = prefixedMessage.replace(/%s/g, '').replace(/(%c|%p|%n|%r)/g, '%c');
        const params = [prefixedMessageClean].concat(args).concat(context !== undefined ? [context] : []);
        console.log.apply(window.console, params);
    }
    static logCreation(name) {
        return AEngine.log(`Creating %c${name}%n`);
    }
    static fetch(url) {
        return new Promise((resolve, reject) => {
            let xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState != 4) {
                    return;
                }
                if (this.status !== 200) {
                    return reject(new Error(`Status = ${this.status}`));
                }
                return resolve(this.responseText);
            };
            xhttp.open("GET", url, true);
            xhttp.timeout = 8000;
            xhttp.send();
        });
    }
    static isMounted(pageScript) {
        return (globalThis.PageScript === pageScript);
    }
}
/**
 * Resolves promise after input milliseconds
 */
export function sleep(ms = 1000) {
    return new Promise((resolve) => {
        setTimeout(_ => resolve(), ms);
    });
}
globalThis.AEngine = AEngine;
