import { AEngine } from "../core/AEngine.js";
import { ARequestService } from "../services/ARequestService.js";
import { createArray } from "../utils/tools.js";
export function MAP_RESPONSE(response) {
    return new AResponse(response);
}
export class AResponse {
    constructor(response) {
        this.originalValue = response;
        this.indexes = AEngine.get(ARequestService).indexes(response, this.Columns);
        this.init();
    }
    init() {
        this.formattedRows = createArray(this.Rows.length);
        for (let i = 0; i < this.Rows.length; i++) {
            this.formattedRows[i] = new ARow(this, this.Rows[i]).toData();
        }
    }
    get First() {
        return (this.Size > 0) ? this.formattedRows[0] : undefined;
    }
    get Last() {
        return (this.Size > 0) ? this.formattedRows[this.formattedRows.length - 1] : undefined;
    }
    get Original() {
        return this.originalValue;
    }
    get Columns() {
        return this.originalValue.Columns;
    }
    get ColumnsTranslated() {
        return this.originalValue.ColumnsTranslated;
    }
    get Rows() {
        return this.originalValue.Rows;
    }
    get Size() {
        return this.formattedRows.length;
    }
    loop(cb) {
        this.formattedRows.forEach((formattedRow, index) => {
            cb(formattedRow, index);
        });
    }
    filter(predicate) {
        return this.formattedRows.filter((formattedRow, index) => {
            return predicate(formattedRow, index);
        });
    }
    map(callbackfn) {
        return this.formattedRows.map((formattedRow, index) => callbackfn(formattedRow, index));
    }
    getFormattedRows() {
        return this.Rows.map(r => {
            return new ARow(this, r);
        });
    }
}
export class ARow {
    constructor(parent, row) {
        const { indexes } = parent;
        this.data = {};
        const output = {};
        Object.keys(indexes).map(key => {
            const i = indexes[key];
            output[key] = row[i];
        });
        Object.assign(this.data, output);
        Object.assign(this, output);
    }
    toData() {
        return this.data;
    }
}
