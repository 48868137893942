import { AMapHelperService } from "../../services/AMapHelperService.js";
import { AEngine } from "../AEngine.js";
export var MAP_POSITION;
(function (MAP_POSITION) {
    MAP_POSITION[MAP_POSITION["TOP_LEFT"] = 0] = "TOP_LEFT";
    MAP_POSITION[MAP_POSITION["TOP_RIGHT"] = 1] = "TOP_RIGHT";
    MAP_POSITION[MAP_POSITION["BOTTOM_LEFT"] = 2] = "BOTTOM_LEFT";
    MAP_POSITION[MAP_POSITION["BOTTOM_RIGHT"] = 3] = "BOTTOM_RIGHT";
})(MAP_POSITION || (MAP_POSITION = {}));
export class AMapOverlayService {
    constructor() {
        this.offsets = {};
        this.map = null;
        this.overlays = {};
        this.uids = {};
    }
    autoInit() {
        this.mapHelperService = AEngine.get(AMapHelperService);
    }
    // TODO: Move logic elsewhere
    get tabDefinitions() {
        const tabConfigurations = {
            'Digital': this.mapHelperService.legend_digital,
            'IllegallyParked': this.mapHelperService.legend_illegallyparked,
            'ParkingRight': this.mapHelperService.legend_parkingright,
            'Verification': this.mapHelperService.legend_verification,
            'DetectionState': this.mapHelperService.legend_detection_state,
            'Default': this.mapHelperService.legend_legacy
        };
        return {
            tabOptions: [
                'ParkingRight',
                'IllegallyParked',
                'Verification',
                'DetectionState',
                'Digital',
                'Default'
            ],
            tabConfigurations
        };
    }
    positionToCls(position) {
        switch (position) {
            case MAP_POSITION.TOP_LEFT:
                return 'top-left';
            case MAP_POSITION.TOP_RIGHT:
                return 'top-right';
            case MAP_POSITION.BOTTOM_LEFT:
                return 'bottom-left';
            case MAP_POSITION.BOTTOM_RIGHT:
                return 'bottom-right';
            default:
                throw new Error(`Unexpected map position "${position}"`);
        }
    }
    getOrCreateOverlay(position) {
        const className = this.positionToCls(position);
        const $map = $('#map');
        let $overlay = $(`.map-overlay-controls.${className}`);
        if ($overlay.length === 0) {
            $overlay = $(`<div class="map-overlay-controls ${className}"></div>`);
            $map.css({ 'position': 'relative' });
            $map.prepend($overlay);
        }
        const offset = this.offsets[position];
        if (offset) {
            $overlay.css('margin', offset);
        }
        return $overlay;
    }
    clear() {
        this.map = null;
        this.overlays = {};
        this.uids = {};
    }
    setOffset(position, offset) {
        this.offsets[position] = offset;
        this.getOrCreateOverlay(position);
    }
    /**
     * @param $ele the element to show on top of the map
     * @param position position of the element on top of the map
     * @param options if options.uid is defined, it will delete the previous element with the same uid, if order is defined it'll sort the ui elements
     */
    add($ele, position, { uid, order }) {
        this.insert($ele, { $parent: this.getOrCreateOverlay(position), order, uid });
    }
    /**
     * @param $container
     * @param options if uid is defined, it will delete the previous element with the same uid
     */
    insert($container, { uid, $parent, order }) {
        // Delete previous element if uid is set
        this.delete({ uid });
        this.uids[uid] = $container;
        $container.attr('order', order);
        const $mapOverlays = $parent.find('[order]').toArray().map((e) => $(e));
        for (let $ele of $mapOverlays) {
            if (order < Number($ele.attr('order'))) {
                $container.insertAfter($ele);
                this.sortElements($parent);
                return;
            }
        }
        this.sortElements($parent);
        $parent.append($container);
    }
    delete({ uid }) {
        if (this.uids.hasOwnProperty(uid)) {
            this.uids[uid].remove();
        }
    }
    sortElements($parent) {
        $parent.find('[order]')
            .sort((a, b) => Number($(a).attr('order')) - Number($(b).attr('order')))
            .appendTo($parent);
    }
}
