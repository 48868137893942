import { AStripUrl } from "../utils/tools.js";
import { ACVSImage } from "./ACVSImage.js";
import { AError } from "./AError.js";
export class APhotoHandler {
    constructor(opt) {
        this.fIndex = 1;
        this.filters = [
            'none',
            'contrast(150%)',
            'contrast(200%)',
            'brightness(1.5)',
            'brightness(2.0)',
            'brightness(2.5)',
            'invert(100%)',
            'saturate(150%)',
            'saturate(200%)',
            'sepia(150%)'
        ];
        this.addControlListeners();
        const $rh = $('.right-half');
        $rh.scroll(_ => {
            $('.photos .image').each(function () {
                ACVSImage.refresh($(this));
            });
        });
        if (opt && opt.imageHelper) {
            this.imageHelper = opt.imageHelper;
        }
    }
    addControlListeners() {
        $('button#filterReset').click(_ => {
            this.fIndex = 0;
            this.nextFilter();
        });
        $('button#filterNext').click(_ => {
            this.nextFilter();
        });
    }
    nextFilter() {
        let filter = this.filters[this.fIndex++];
        $('.zoomWindowContainer > div').css({ filter });
        $('.photos .image').css({ filter });
        if (this.fIndex >= this.filters.length)
            this.fIndex = 0;
    }
    nextFilterSpecific($btn) {
        const $parent = $btn.parents('.imageContainer');
        let fIndex = $parent.data('filterIndex') || 1;
        let filter = this.filters[fIndex % this.filters.length];
        $parent.find('.image').css({ filter });
        $parent.find('img').css({ filter });
        $parent.data('filterIndex', fIndex + 1);
    }
    static staticNextFilterSpecific($img) {
        const filters = [
            'none',
            'contrast(150%)',
            'contrast(200%)',
            'brightness(1.5)',
            'brightness(2.0)',
            'brightness(2.5)',
            'invert(100%)',
            'saturate(150%)',
            'saturate(200%)',
            'sepia(150%)'
        ];
        const $parent = $img.parents('.imageContainer');
        let fIndex = $parent.data('filterIndex') || 1;
        let filter = filters[fIndex % filters.length];
        $parent.find('.image').css({ filter });
        $parent.find('img').css({ filter });
        $parent.data('filterIndex', fIndex + 1);
    }
    static staticFullScreen($img) {
        const base64 = AStripUrl($img.css('background-image'));
        if (!base64) {
            AError.handle('Image not found!');
            return $();
        }
        const suffix = (PageScript.DeskControl?.currentSelectedDetection || { Identifier: 'none' }).Identifier;
        const $background = Alerts.createMinimal({ id: `cvs-image-viewer-${suffix}`, child: '' });
        const $mp = $(`
      <div class="modal-padding" style="
        width: 50%;
        height: calc(100% - 40px);
        margin: 20px auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "></div>
    `);
        $background.append($mp);
        const $close = $(`<div class="no-select" style="position: absolute; top: 5px; right: 6px;"><i class="no-select fa-solid fa-xmark fa-xl"></i></div>`);
        $background.append($close);
        $('body').append($background);
        return $background;
    }
}
