import { mergeDeep } from "../utils/tools.js";
import { AColor } from "./AColor.js";
import { AError } from "./AError.js";
export class AConfig {
    constructor() {
        throw new Error(`AConfig is a static class!`);
    }
    static get BackOfficeConfig() {
        try {
            return Config.BackOfficeConfig;
        }
        catch (err) {
            AError.handle(err);
        }
    }
    static get DEFAULT_CONFIG() {
        return {
            "tableformatter": {
                "ParkingRightResults": {
                    "order": [],
                    "definitions": {
                        "Provider": { type: 'TEXT' },
                        "ProviderResult": { type: 'TEXT' },
                        "GeoIds": { type: 'ARRAY' },
                        "Type": { type: 'TEXT' },
                        "ParkingRightStart": { type: 'DATETIME' },
                        "ParkingRightEnd": { type: 'DATETIME' },
                        "GeoValid": { type: 'BOOLEAN' },
                        "TimeValid": { type: 'BOOLEAN' },
                        "RightValid": { type: 'BOOLEAN' },
                    },
                }
            },
            "streetView": {
                "position": {
                    "lat": 58.9865685,
                    "lng": 6.1889224
                },
                "pov": {
                    "heading": 180,
                    "pitch": 5
                },
                "zoom": 0
            },
            "language": {
                "grid": 'En'
            },
            "infoWindow": {
                "default": {
                    "priorityFields": [
                        "DetectionTime",
                        "LicensePlate",
                        "Site",
                        "Area",
                        "ParkingAreaType",
                        "ParkingRightType",
                        "VerificationResultText",
                        "CardinalDirection",
                        "IsIllegallyParked",
                        "HasParkingRight",
                        "Label"
                    ],
                    "booleanFields": [
                        "TaxRequired",
                        "IsIllegallyParked",
                        "HasParkingRight"
                    ],
                    "excludedFields": [
                        "AreaId",
                        "Image",
                        "LPImage",
                        "ParkingAreaType",
                        "OverviewImages",
                        "CarImage",
                        "Success",
                        "VehicleBounds",
                        "Bounds",
                        "VehicleCenterLatitude",
                        "VehicleCenterLongitude",
                        "SessionId",
                        "id_mrs"
                    ],
                    "customFields": {
                        "Label": "generateLabelField"
                    },
                    "format": {
                        "numbers": {
                            "amountOfDecimals": {}
                        }
                    }
                },
                "heatmap": {
                    "priorityFields": [
                        "DetectionTime",
                        "LicensePlate",
                        "Area",
                        "ParkingAreaType",
                        "ParkingRightType",
                        "VerificationResultText",
                        "IsIllegallyParked",
                        "HasParkingRight",
                        "Label",
                        "GpsPrecision",
                        "GpsPrecisionText",
                    ],
                    "booleanFields": [
                        "TaxRequired",
                        "IsIllegallyParked",
                        "HasParkingRight"
                    ],
                    "excludedFields": [],
                    "customFields": {
                        "Label": "generateLabelField"
                    },
                    "format": {
                        "numbers": {
                            "amountOfDecimals": {
                                "GpsPrecision": "3"
                            }
                        }
                    }
                },
                "detectionManager": {
                    "priorityFields": [],
                    "booleanFields": [],
                    "excludedFields": [],
                    "format": {
                        "numbers": {
                            "amountOfDecimals": {}
                        }
                    }
                }
            },
            "statistics": {
                "fineIdentifiers": ["FINED%", "GIVEFINE", "NOK%"]
            },
            "exportKml": {
                "defaultColor": "#ff00ff"
            },
            "thematicMaps": {
                "paymentRate": {
                    "minBound": 80,
                    "maxBound": 100
                },
                "digitizationRate": {
                    "minBound": 0,
                    "maxBound": 100
                },
                "percentageVisitors": {
                    "minBound": 0,
                    "maxBound": 100
                },
                "percentagePhone": {
                    "minBound": 0,
                    "maxBound": 10
                }
            },
            "prdb": {
                "parkingRightTypes": {
                    "Day Permit": "",
                    "Free Parking": ""
                }
            },
            "locations": {
                "locationTypes": {
                    "Garbage": "",
                    "Obstruction": "",
                    "Stolen Sign": "",
                    "Other": ""
                }
            },
            "vehicleTypes": {
                "Car": "",
                "Truck": ""
            },
            "colors": {
                "charts": [[205, 225, 248], [54, 84, 144]],
                "tables": {
                    "default": "#00afe3",
                    "green": "#07CF00",
                    "red": "#E30039"
                }
            }
        };
    }
    static get BackupConfig() {
        return {
            "databaseDefinitions": {
                "vehicleTypes": [
                    "Car",
                    "Truck"
                ],
                "locationTypes": [
                    "Garbage",
                    "Obstruction",
                    "Stolen Sign",
                    "Other"
                ],
                "parkingRightTypes": [
                    "Day Permit",
                    "Free Parking"
                ]
            },
            "drawing & colors": {
                "speed": {
                    "bounds": [0, 30],
                    "colors": [
                        "#ff0000",
                        "#00ff00"
                    ]
                },
                "charts": {
                    "gradient": ["#cde1f8", "#365490"]
                },
                "thematic": {
                    "occupancy": {
                        "colors": ['#32CD32', '#ff0000'],
                        "bounds": [0, 100]
                    },
                    "visitorRate": {
                        "colors": ['#ff0000', '#32CD32'],
                        "bounds": [0, 100]
                    },
                    "compliancy": {
                        "colors": ['#ff0000', '#32CD32'],
                        "bounds": [0, 100]
                    },
                    "compliancyVisitor": {
                        "colors": ['#ff0000', '#32CD32'],
                        "bounds": [0, 100]
                    },
                    "enforcementIntensity": {
                        "colors": ['#ff0000', '#32CD32'],
                        "bounds": [0, 100]
                    }
                },
                "tables": {
                    "error": "#E30039",
                    "success": "#07CF00",
                    "highlight": "#00afe3"
                },
                "precision": {
                    "bounds": [0, 2],
                    "colors": ["#ff0000", "#00ff00"]
                },
                "arduinoSensor": {
                    "RotationY": ["#ff0000", "#00ff00"],
                    "AccelerationX": ["#ff0000", "#00ff00"],
                    "AccelerationY": ["#ff0000", "#00ff00"]
                },
                "detections": {
                    "default": {
                        "fill": "#00ff00",
                        "outline": "#05A300"
                    },
                    "unknown": {
                        "fill": "#00ADFF",
                        "outline": "#0072E8"
                    },
                    "selected": {
                        "fill": "#FFFFFF",
                        "outline": "#C7C7C7"
                    },
                    "noParkingRight": {
                        "fill": "#F70000",
                        "outline": "#B90000"
                    },
                    "illegallyParked": {
                        "fill": "#FF8000",
                        "outline": "#C86400"
                    }
                },
                "strokeWidth": 3.5,
                "legendScale": 1.2
            },
            "filters": {
                "maps": {
                    "maxResults": 2000
                },
                "tables": {
                    "maxResults": 250
                },
                "default": {
                    "maxResults": 250
                },
                "maxResultsCeiling": 100000,
                "showZonesInsteadOfAreas": false,
                "minWeeksWarning": 8,
                "overrideFilters": false,
                "override": {
                    "fromDate": null,
                    "fromTime": null,
                    "toDate": null,
                    "toTime": null,
                }
            },
            "general": {
                "map": {
                    "pos": {
                        "lat": 59.91447170000001,
                        "lng": 10.734125
                    },
                    "pov": {
                        "pitch": 2.776332195664267,
                        "heading": 2.797097632739451,
                        "zoom": 1
                    },
                    "mapZoom": 10,
                    "centerOnClick": false
                },
                "overrideName": false,
                "name": "",
                "language": "En",
                "useCustomUnificationCalc": false
            },
            "infoWindows": {
                "default": {
                    "order": [
                        "DetectionTime", "LicensePlate", "Side",
                        "Area", "HasParkingRight", "IsIllegallyParked",
                        "ParkingRightType", "VerificationResult", "VerificationResultText",
                        "ParkingAreaType", "CardinalDirection", "Label", "CardinalDirection",
                        "VerificationResult", "VerificationResultText", "DetectionId",
                        "DetectionDeviceId", "VehicleType", "DetectionDevice",
                        "DetectionUser", "Time", "Confidence",
                        "DuplicateCodeLp", "DuplicateCodeLpConf", "CameraIds",
                        "BestCameraId", "NearMatches", "VehicleSpeed",
                        "VehicleBoundsJson", "VehicleMoving", "ScanDeviceLatitude",
                        "ScanDeviceLongitude", "GpsPrecision", "GpsPrecisionGroup",
                        "GpsValid", "ScanDeviceDirection", "Hour",
                        "Day", "Week", "Month",
                        "Weekday", "TaxRequired", "AreaConfidence",
                        "AreaAttributes", "ParkingSpaceId", "ParkingSpace",
                        "ParkingSpaceConfidence", "ParkingSpaceAttributes", "SegmentId",
                        "SegmentTimestamp", "SegmentConfidence", "SegmentAttributes",
                        "ZoneId", "Zone", "ZoneConfidence",
                        "ZoneTimestamp", "ZoneAttributes", "Address",
                        "CountryCode", "ParkingRightResults", "Visitor",
                        "VerificationUser", "VerificationStartTime", "VerificationEndTime",
                        "VerificationLatitude", "VerificationLongitude",
                        "AreaId", "VehicleCenterLatitude", "VehicleCenterLongitude",
                        "VehicleBounds", "SessionId"
                    ],
                    "definitions": {
                        "DetectionTime": { data: "YYYY-MM-DD HH:MM:SS", type: "DATETIME", isPriority: true },
                        "LicensePlate": { data: null, type: "TEXT", isPriority: true },
                        "Side": { data: null, type: "TEXT", isPriority: true },
                        "Area": { data: null, type: "TEXT", isPriority: true },
                        "HasParkingRight": { data: null, type: "BOOLEAN", isPriority: true },
                        "IsIllegallyParked": { data: null, type: "BOOLEAN", isPriority: true },
                        "ParkingRightType": { data: null, type: "TEXT", isPriority: true },
                        "VerificationResult": { data: null, type: "TEXT", isPriority: true },
                        "VerificationResultText": { data: null, type: "TEXT", isPriority: true },
                        "CardinalDirection": { data: null, type: "TEXT", isPriority: true },
                        "Label": { data: "generateLabelField", type: "CUSTOM", isPriority: true },
                        "Address": { data: null, type: "TEXT", isPriority: false },
                        "AreaAttributes": { data: null, type: "OBJECT", isPriority: false },
                        "AreaConfidence": { data: "ROUND 2 DECIMALS", type: "NUMBER", isPriority: false },
                        "BestCameraId": { data: null, type: "TEXT", isPriority: false },
                        "CameraIds": { data: null, type: "ARRAY", isPriority: false },
                        "Confidence": { data: "ROUND 2 DECIMALS", type: "NUMBER", isPriority: false },
                        "CountryCode": { data: null, type: "TEXT", isPriority: false },
                        "Day": { data: null, type: "TEXT", isPriority: false },
                        "DetectionDevice": { data: null, type: "TEXT", isPriority: false },
                        "DetectionDeviceId": { data: null, type: "TEXT", isPriority: false },
                        "DetectionId": { data: null, type: "TEXT", isPriority: false },
                        "DetectionUser": { data: null, type: "TEXT", isPriority: false },
                        "DuplicateCodeLp": { data: null, type: "TEXT", isPriority: false },
                        "DuplicateCodeLpConf": { data: null, type: "TEXT", isPriority: false },
                        "GpsPrecision": { data: "ROUND 2 DECIMALS", type: "NUMBER", isPriority: false },
                        "GpsPrecisionGroup": { data: null, type: "TEXT", isPriority: false },
                        "GpsValid": { data: null, type: "BOOLEAN", isPriority: false },
                        "Hour": { data: null, type: "TEXT", isPriority: false },
                        "Month": { data: null, type: "TEXT", isPriority: false },
                        "NearMatches": { data: null, type: "ARRAY", isPriority: false },
                        "ParkingRightResults": { data: null, type: "TEXT", isPriority: false },
                        "ParkingSpace": { data: null, type: "TEXT", isPriority: false },
                        "ParkingSpaceAttributes": { data: null, type: "OBJECT", isPriority: false },
                        "ParkingSpaceConfidence": { data: "ROUND 2 DECIMALS", type: "NUMBER", isPriority: false },
                        "ParkingSpaceId": { data: null, type: "TEXT", isPriority: false },
                        "ScanDeviceDirection": { data: "ROUND 2 DECIMALS", type: "NUMBER", isPriority: false },
                        "ScanDeviceLatitude": { data: null, type: "TEXT", isPriority: false },
                        "ScanDeviceLongitude": { data: null, type: "TEXT", isPriority: false },
                        "SegmentAttributes": { data: null, type: "OBJECT", isPriority: false },
                        "SegmentConfidence": { data: "ROUND 2 DECIMALS", type: "NUMBER", isPriority: false },
                        "SegmentId": { data: null, type: "TEXT", isPriority: false },
                        "SegmentTimestamp": { data: "YYYY-MM-DD HH:MM:SS", type: "DATETIME", isPriority: false },
                        "TaxRequired": { data: null, type: "BOOLEAN", isPriority: false },
                        "Time": { data: null, type: "TIME", isPriority: false },
                        "VehicleBoundsJson": { data: null, type: "OBJECT", isPriority: false },
                        "VehicleMoving": { data: null, type: "BOOLEAN", isPriority: false },
                        "VehicleSpeed": { data: "ROUND 2 DECIMALS", type: "NUMBER", isPriority: false },
                        "VehicleType": { data: null, type: "TEXT", isPriority: false },
                        "VerificationEndTime": { data: "YYYY-MM-DD HH:MM:SS", type: "DATETIME", isPriority: false },
                        "VerificationLatitude": { data: null, type: "TEXT", isPriority: false },
                        "VerificationLongitude": { data: null, type: "TEXT", isPriority: false },
                        "VerificationStartTime": { data: "YYYY-MM-DD HH:MM:SS", type: "DATETIME", isPriority: false },
                        "VerificationUser": { data: null, type: "TEXT", isPriority: false },
                        "Visitor": { data: null, type: "TEXT", isPriority: false },
                        "Week": { data: null, type: "TEXT", isPriority: false },
                        "Weekday": { data: null, type: "TEXT", isPriority: false },
                        "Zone": { data: null, type: "TEXT", isPriority: false },
                        "ZoneAttributes": { data: null, type: "OBJECT", isPriority: false },
                        "ZoneConfidence": { data: "ROUND 2 DECIMALS", type: "NUMBER", isPriority: false },
                        "ZoneId": { data: null, type: "TEXT", isPriority: false },
                        "ZoneTimestamp": { data: "YYYY-MM-DD HH:MM:SS", type: "DATETIME", isPriority: false },
                        "ParkingAreaType": { data: null, type: "TEXT", isPriority: false },
                        "AreaId": { data: null, type: "TEXT", isPriority: false },
                        "VehicleCenterLatitude": { data: null, type: "HIDDEN", isPriority: false },
                        "VehicleCenterLongitude": { data: null, type: "HIDDEN", isPriority: false },
                        "VehicleBounds": { data: null, type: "HIDDEN", isPriority: false },
                        "SessionId": { data: null, type: "HIDDEN", isPriority: false },
                    },
                    "customFields": {
                        "Label": "generateLabelField"
                    },
                    "booleanFields": [
                        "TaxRequired",
                        "IsIllegallyParked",
                        "HasParkingRight"
                    ],
                    "excludedFields": [
                        "AreaId",
                        "Image",
                        "LPImage",
                        "ParkingAreaType",
                        "OverviewImages",
                        "CarImage",
                        "Success",
                        "VehicleBounds",
                        "Bounds",
                        "VehicleCenterLatitude",
                        "VehicleCenterLongitude",
                        "SessionId",
                        "id_mrs",
                        "KmlDescription"
                    ],
                    "priorityFields": [
                        "DetectionTime",
                        "LicensePlate",
                        "Site",
                        "Area",
                        "ParkingAreaType",
                        "ParkingRightType",
                        "VerificationResultText",
                        "CardinalDirection",
                        "IsIllegallyParked",
                        "HasParkingRight",
                        "Label"
                    ],
                    "amountOfDecimals": {
                        "*": "3",
                        "Shape_Area": "0",
                        "GpsPrecision": "3",
                        "Shape_Length": "0",
                        "ScanDeviceLatitude": "12",
                        "ScanDeviceDirection": "0",
                        "ScanDeviceLongitude": "12"
                    }
                }
            }
        };
    }
    /**
     * Fetches and returns config value, defaultValue is returned when not found
     * @param {*} key Path to traverse in the config
     * @param {*} [defaultValue] return value when config entry is not found
     */
    static get(key, defaultValue) {
        if (Array.isArray(key)) {
            return key.map(args => {
                const [str, dflt] = args;
                return AConfig.get(str, dflt);
            });
        }
        else {
            let output = this.getRoot(AConfig.BackOfficeConfig, key);
            if (output === undefined) {
                output = this.getRoot(AConfig.BackupConfig, key);
            }
            if (output === undefined) {
                console.warn(`Couldn't find path "${key}" in Config`);
                // AError.handle(new Error(`Client Error couldn't load config dependencies! (${ key })`))
                return defaultValue;
            }
            // AEngine.log(`AConfig.get(${ key }, ${ defaultValue }) ` + output)
            return output;
        }
    }
    /**
     * Fetches and returns config value, defaultValue is returned when not found
     * @param {*} cfgToUse config to traverse
     * @param {*} key Path to traverse in the config
     */
    static getRoot(cfgToUse, key) {
        try {
            const parts = key.split('.');
            let output = cfgToUse;
            for (const part of parts) {
                if (!output.hasOwnProperty(part)) {
                    output = undefined;
                    break;
                }
                output = output[part];
            }
            return output;
        }
        catch (err) {
            AError.handle(err);
        }
    }
    static isDefined(val) {
        return val !== undefined;
    }
    static isDefinedArr(val) {
        if (val && Array.isArray(val)) {
            return val.reduce((p, c) => p !== undefined && c !== undefined);
        }
        return false;
    }
    static isPrimitive(test) {
        return (test !== Object(test));
    }
    static convert(cfg) {
        const output = {
            "general": {},
            "drawing & colors": {},
            "filters": {},
            "infoWindows": {},
            "databaseDefinitions": {},
            "thematicMaps": {}
        };
        {
            const config = mergeDeep({}, cfg);
            delete config._;
            Object.assign(output["general"], {
                "name": (config && config.client && config.client.name) ? config.client.name : "?",
                "language": config.language.grid,
                "map": {
                    pos: config.streetView.position,
                    pov: config.streetView.pov,
                    mapZoom: 14,
                    streetZoom: 3
                }
            });
            Object.assign(output["drawing & colors"], {
                "charts": {
                    "gradient": config.colors.charts.map(rgb => new AColor(rgb).hexi),
                },
                "tables": {
                    "highlight": config.colors.tables.default,
                    "success": config.colors.tables.green,
                    "error": config.colors.tables.red
                },
                "speed": {
                    "colors": ["#ff0000", "#00ff00"],
                    "bounds": [0.0, 30]
                },
                "precision": {
                    "colors": ["#ff0000", "#00ff00"],
                    "bounds": [0.0, 2.0]
                },
                "detections": {
                    "default": {
                        "fill": "#00ff00",
                        "outline": "#05A300"
                    },
                    "illegallyParked": {
                        "fill": "#FF8000",
                        "outline": "#C86400"
                    },
                    "noParkingRight": {
                        "fill": "#F70000",
                        "outline": "#B90000"
                    },
                    "selected": {
                        "fill": "#FFFFFF",
                        "outline": "#C7C7C7"
                    },
                    "unknown": {
                        "fill": "#00ADFF",
                        "outline": "#0072E8"
                    }
                },
                "strokeWidth": 3.5
            });
            Object.assign(output["filters"], {
                "maps": { "maxResults": 2000 },
                "tables": { "maxResults": 250 },
                "showZonesInsteadOfAreas": false
            });
            try {
                delete config.infoWindow.detectionManager;
                delete config.infoWindow.heatmap;
            }
            catch (err) {
                console.error(err);
            }
            Object.assign(output["infoWindows"], config.infoWindow);
            Object.keys(output["infoWindows"]).map(key => {
                output["infoWindows"][key].amountOfDecimals = mergeDeep({}, config.infoWindow[key].format.numbers.amountOfDecimals);
                delete output["infoWindows"][key].format;
            });
            Object.assign(output["databaseDefinitions"], {
                "parkingRightTypes": Object.keys(config.prdb.parkingRightTypes),
                "locationTypes": Object.keys(config.locations.locationTypes),
                "vehicleTypes": Object.keys(config.vehicleTypes)
            });
            Object.assign(output["thematicMaps"], {
                "compliancy": {
                    "bounds": [0, 100],
                    "colors": ["#32CD32", "#FF0000"]
                },
                "compliancyVisitors": {
                    "bounds": [0, 100],
                    "colors": ["#32CD32", "#FF0000"]
                },
                "percentageVisitors": {
                    "bounds": [0, 100],
                    "colors": ["#32CD32", "#FF0000"]
                },
                "parkingPressure": {
                    "bounds": [0, 100],
                    "colors": ["#32CD32", "#FF0000"]
                }
            });
        }
        return output;
    }
    static update(strKey, json) {
        Config.BackOfficeConfig[strKey] = json;
    }
    static transformConfig(cfg) {
        const config = JSON.parse(JSON.stringify(cfg));
        delete config._;
        config.vehicleTypes = Object.values(config.vehicleTypes);
        const locationTypes = config.locations.LocationTypes || config.locations.locationTypes;
        delete config.locations.LocationTypes;
        config.colors.charts = ['#cde1f8', '#365490'];
        config.prdb.parkingRightTypes = Object.values(config.prdb.parkingRightTypes);
        config.locations.locationTypes = Object.values(locationTypes);
        const { isDefined, isDefinedArr } = AConfig;
        const { streetView, language, infoWindow, statistics, exportKml, thematicMaps, client } = config;
        if (isDefined(streetView)) {
            if (isDefined(streetView.position)) {
                const { lat, lng } = streetView.position;
                if (isDefinedArr([lat, lng])) {
                    streetView.position = {
                        "editType": "latlng",
                        "value": { lat, lng }
                    };
                }
            }
            if (isDefined(streetView.pov)) {
                const { heading, pitch } = streetView.pov;
                if (isDefined(heading) && isDefined(pitch)) {
                    streetView.pov = {
                        "heading": {
                            "editType": "integer",
                            "value": heading || 90
                        },
                        "pitch": {
                            "editType": "integer",
                            "value": pitch || 0
                        }
                    };
                }
            }
            const { zoom } = streetView.pov;
            if (isDefined(zoom)) {
                streetView.zoom = {
                    "editType": "integer",
                    "value": zoom || 0
                };
            }
        }
        if (isDefined(language)) {
            if (isDefined(language.grid)) {
                language.grid = {
                    "editType": "text",
                    "value": language.grid
                };
            }
        }
        if (isDefined(infoWindow)) {
            for (const key of Object.keys(infoWindow)) {
                const { priorityFields, booleanFields, excludedFields, customFields, format } = infoWindow[key];
                const hashmap = {
                    'priorityFields': priorityFields,
                    'booleanFields': booleanFields,
                    'excludedFields': excludedFields
                };
                for (const hashid of Object.keys(hashmap)) {
                    if (isDefined(hashmap[hashid])) {
                        infoWindow[key][hashid] = {
                            "editType": "array",
                            "value": infoWindow[key][hashid]
                        };
                    }
                }
                if (isDefined(customFields)) {
                    // Nothing
                }
                if (isDefined(format)) {
                    // Change infoWindow->?->format->numbers->amountOfDecimals to
                    // infoWindow->?->format->amountOfDecimals
                    if (isDefined(format.numbers)) {
                        if (isDefined(format.numbers.amountOfDecimals)) {
                            format.amountOfDecimals = format.numbers.amountOfDecimals;
                            delete format.numbers.amountOfDecimals;
                            delete format.numbers;
                            Object.keys(format.amountOfDecimals).map(key => {
                                format.amountOfDecimals[key] = {
                                    "editType": "integer",
                                    "value": format.amountOfDecimals[key],
                                    "attributes": {
                                        "width": "6"
                                    }
                                };
                            });
                        }
                    }
                }
            }
            // Move format to amountOfDecimals
            for (const key of Object.keys(infoWindow)) {
                infoWindow[key].amountOfDecimals = infoWindow[key].format.amountOfDecimals;
                delete infoWindow[key].format;
            }
        }
        if (isDefined(statistics)) {
            if (isDefined(statistics.fineIdentifiers)) {
                statistics.fineIdentifiers = {
                    "editType": "array",
                    "value": statistics.fineIdentifiers
                };
            }
        }
        if (isDefined(thematicMaps)) {
            Object.keys(thematicMaps).map(key => {
                Object.keys(thematicMaps[key]).map(key2 => {
                    thematicMaps[key][key2] = {
                        "editType": "integer",
                        "value": thematicMaps[key][key2],
                        "attributes": {
                            "width": 6
                        }
                    };
                });
            });
        }
        if (!isDefined(config.definitions)) {
            config.definitions = {};
        }
        if (isDefined(config.prdb)) {
            if (isDefined(config.prdb.parkingRightTypes)) {
                config.definitions.parkingRightTypes = {
                    "editType": "array",
                    "value": config.prdb.parkingRightTypes
                };
                delete config.prdb;
            }
        }
        if (isDefined(config.locations)) {
            if (isDefined(config.locations.locationTypes)) {
                config.definitions.locationTypes = {
                    "editType": "array",
                    "value": config.locations.locationTypes
                };
                delete config.locations;
            }
        }
        if (isDefined(config.vehicleTypes)) {
            config.definitions.vehicleTypes = {
                "editType": "array",
                "value": config.vehicleTypes
            };
            delete config.vehicleTypes;
        }
        if (isDefined(exportKml)) {
            if (isDefined(exportKml.defaultColor)) {
                if (!isDefined(config.maps)) {
                    config.maps = {};
                }
                if (!this.isDefined(config.maps.exportKml)) {
                    config.maps.exportKml = {};
                }
                config.maps.exportKml.defaultColor = {
                    "editType": "color",
                    "value": exportKml.defaultColor
                };
            }
            delete config.exportKml;
        }
        if (isDefined(config.colors)) {
            if (isDefined(config.colors.charts)) {
                if (!isDefined(config.charts)) {
                    config.charts = {};
                }
                config.charts.colors = {
                    "editType": "colorTransition",
                    "value": config.colors.charts
                };
            }
            if (isDefined(config.colors.tables)) {
                if (!isDefined(config.tables)) {
                    config.tables = {
                        "colors": {}
                    };
                }
                Object.keys(config.colors.tables).map(key => {
                    config.tables.colors[key] = {
                        "editType": "color",
                        "value": config.colors.tables[key],
                        "attributes": {
                            "width": "4"
                        }
                    };
                });
            }
            delete config.colors;
        }
        if (isDefined(client)) {
            client.name = {
                "editType": "text",
                "value": isDefined(client.name) ? client.name : "Unknown"
            };
        }
        return config;
    }
    static prepareEditTypes(cfg, path = [], arr = []) {
        const needle = 'editType';
        let ref = cfg;
        path.map(p => ref = ref[p]);
        if (this.isPrimitive(ref)) {
            return false;
        }
        if (!ref.hasOwnProperty(needle)) {
            Object.keys(ref).map(key => {
                if (ref.hasOwnProperty(key)) {
                    this.prepareEditTypes(cfg, path.concat([key]), arr);
                }
            });
        }
        else {
            const { editType, value, attributes } = ref;
            arr.push({
                editType,
                value,
                path,
                attributes
            });
        }
        return arr;
    }
    static async prepareDatabaseConfig() {
        const hasdbconfig = await Loading.waitForPromises(requestService.query("SELECT count(*) FROM backoffice_config")).then(res => {
            return res.Rows.length && parseFloat(res.Rows[0][0]) > 0;
        });
        if (!hasdbconfig) {
            const converted = this.convert(Config.BackOfficeConfig);
            await Loading.waitForPromises(Object.keys(converted).map(key => {
                return requestService.query({
                    Query: (`INSERT INTO backoffice_config (\`SettingId\`, \`Value\`, \`DefaultValue\`) VALUES (:Key, :Json, :DefaultValue)`),
                    Params: {
                        Key: key,
                        Json: JSON.stringify(converted[key]),
                        DefaultValue: JSON.stringify({})
                    }
                });
            }));
        }
        const output = {};
        const settingsres = await Loading.waitForPromises(requestService.query(`SELECT settingid, value, defaultvalue FROM backoffice_config`));
        settingsres.Rows.map(row => {
            const [sid, val, defaultval] = row;
            output[sid] = Object.assign({}, val);
        });
        return output;
    }
}
globalThis.AConfig = AConfig;
