import { AColorHSV } from "./AColorHSV.js";
export const RGB_MAX = 255;
export const HUE_MAX = 360;
export const SV_MAX = 100;
export class AColor {
    /**
     *
     * @param {string|Number}  r   red color from 0 to 255 or a hexidecimal string.
     * @param {Number}         [g] green color from 0 to 255.
     * @param {Number}         [b] blue color from 0 to 255.
     */
    constructor(r, g, b) {
        if (r === undefined && g === undefined && !b === undefined) {
            throw new Error(`Constructor expects arguments.`);
        }
        if (r !== undefined && g === undefined && b === undefined) {
            if (typeof r === 'string') {
                if (r.startsWith('#')) {
                    r = r.replace('#', '');
                }
                this.data = AColor.hexToInt(r);
            }
            else if (Array.isArray(r)) {
                this.data = { r: r[0], g: r[1], b: r[2] };
            }
            else {
                this.data = r;
            }
        }
        else {
            this.data = { r: 0, g: 0, b: 0 };
            this.r = r;
            this.g = g;
            this.b = b;
        }
    }
    set r(val) {
        if (val > 255)
            val = 255;
        if (val < 0)
            val = Math.abs(val);
        this.data.r = Math.round(val);
    }
    set g(val) {
        if (val > 255)
            val = 255;
        if (val < 0)
            val = Math.abs(val);
        this.data.g = Math.round(val);
    }
    set b(val) {
        if (val > 255)
            val = 255;
        if (val < 0)
            val = Math.abs(val);
        this.data.b = Math.round(val);
    }
    get r() {
        return this.data.r;
    }
    get g() {
        return this.data.g;
    }
    get b() {
        return this.data.b;
    }
    /**
     * Converts the current color into a hexidecimal format.
     * @returns {string}
     *  Color in hexidecimal format.
     */
    get hex() {
        let { r, g, b } = this;
        let returnValue = ('0' + r.toString(16)).slice(-2) +
            ('0' + g.toString(16)).slice(-2) +
            ('0' + b.toString(16)).slice(-2);
        return returnValue;
    }
    /**
     * Converts the current color into a hexidecimal format with # as prefix.
     * @returns {string}
     *  Color in hexidecimal format prefixed with #.
     */
    get hexi() {
        let { r, g, b } = this;
        let returnValue = '#' +
            ('0' + r.toString(16)).slice(-2) +
            ('0' + g.toString(16)).slice(-2) +
            ('0' + b.toString(16)).slice(-2);
        return returnValue;
    }
    get hsv() {
        let { r, g, b } = this;
        // It converts [0,255] format, to [0,1]
        r = r % (RGB_MAX + 1) / RGB_MAX;
        g = g % (RGB_MAX + 1) / RGB_MAX;
        b = b % (RGB_MAX + 1) / RGB_MAX;
        let max = Math.max(r, g, b);
        let min = Math.min(r, g, b);
        let h = 0, s, v = max;
        let d = max - min;
        s = max === 0 ? 0 : d / max;
        if (max === min) {
            h = 0; // achromatic
        }
        else {
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }
        return new AColorHSV(Math.floor(h * HUE_MAX), Math.floor(s * SV_MAX), Math.floor(v * SV_MAX));
    }
    rgba(opacity = 1) {
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${opacity})`;
    }
    /**
     * Converts a hex color into red, green and blue values.
     * @param {String} hex
     *  Represents 3 ints within a hexidecimal string format.
     * @returns {Object} {r,g,b}
     *  Color is in RGB format.
     */
    static hexToInt(hex) {
        let r = 0, g = 0, b = 0;
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
        return { r, g, b };
    }
    static addition(a, b) {
        return new AColor(a.r + b.r, a.g + b.g, a.b + b.b);
    }
    static substract(a, b) {
        return new AColor(a.r - b.r, a.g - b.g, a.b - b.b);
    }
    static multiply(color, f) {
        return new AColor(color.r * f, color.g * f, color.b * f);
    }
    static abs(color) {
        return new AColor(Math.abs(color.r), Math.abs(color.g), Math.abs(color.b));
    }
    /**
     * Returns 6 vibrant colors
     * @returns {string[]} array of hex colors
     */
    static get vibrantColors6() {
        return [
            '#FF0000',
            '#FFC000',
            '#FFFC00',
            '#FF0000',
            '#00FFFF',
            '#1974D2',
            '#FF0000',
            '#66FF00'
        ];
    }
}
