import { AEngine, sleep } from "../core/AEngine.js";
import { EVENTS } from "../services/AEventService.js";
import { AUnitTest } from "../core/AUnitTest.js";
export class APageVisitorUnit extends AUnitTest {
    constructor({ period }) {
        super({ period });
        this.skipPages = [
            '/authenticator.html',
            '/legacy-oprations.html',
            '/login.html',
            '/reload.html'
        ];
        super.addSetup(() => this.setup());
        super.addTest(() => this.testAllPages());
    }
    async setup(...args) {
        // this.loopService = AEngine.get(AAutoRefreshService);
        this.hrefs = Object.keys(menuService.menuFlat)
            .filter(href => !href.startsWith('/test/'))
            .filter(href => !this.skipPages.includes(href));
        this.metadata = {};
        this.pageNr = 0;
    }
    async testAllPages() {
        for (let href of this.hrefs) {
            try {
                if (this.error !== null) {
                    throw this.error;
                }
                const metadata = await this.testPage(href);
                this.metadata[href] = metadata;
                this.pageNr++;
            }
            catch (err) {
                this.metadata[href] = { err: err instanceof Error ? err.message : err };
                throw err;
            }
        }
    }
    async testPage(href) {
        href = href.startsWith('/#!') ? href : '/#!' + href;
        AEngine.log(`%cAPageVisitorUnit%c (${this.pageNr}/${this.hrefs.length})%c href=${href}`);
        // eventService.logging = true`
        const waitOnce = new Promise(res => Events.h_once(EVENTS.PAGE_INITIALIZED, res));
        window.location.href = href;
        await waitOnce;
        const hasFilters = $('#AjaxContent #Filters').length > 0;
        const pageMetaData = { hasFilters };
        if (hasFilters) {
            const $refreshBtn = $('#AjaxContent #Filters #Refresh');
            pageMetaData.initType = PageScript.init?.constructor?.name || null;
            pageMetaData.refreshType = PageScript.refresh?.constructor?.name || null;
            if (PageScript.refresh) {
                let d = Date.now();
                pageMetaData.refreshResult = await Promise.resolve().then(() => PageScript.refresh());
                const loadTime = Date.now() - d;
                pageMetaData.loadTime = loadTime + 'ms';
                AEngine.log(`Refresh took %c${loadTime}ms`);
                await sleep(500);
            }
        }
        await sleep(500);
        return pageMetaData;
    }
}
