import { AColor } from "../classes/AColor.js";
import { AConfig } from "../classes/AConfig.js";
import { AError } from "../classes/AError.js";
import { ALoopTimer } from "../classes/ALoopTimer.js";
import { AEngine, sleep } from "../core/AEngine.js";
import { GetFilterArea, getPolygonCenter, DefaultBounds } from "../utils/maps.js";
import { convertObjectToArray, isObject, toggleFullScreen } from "../utils/tools.js";
import { EVENTS } from "./AEventService.js";
import { AMapOverlayService, MAP_POSITION } from "./AMapOverlayService.js";
import { AUserActionService } from "./AUserActionService.js";
export const MAP_OPTIONS = {
    All: -1,
    None: 0,
    Default: 7,
    ParkingSpaces: 1,
    Zones: 2,
    Areas: 4,
    Segments: 8
};
export const UNLOAD_OPTIONS = {
    Default: 0,
    AllListeners: 1,
    HardDelete: 2,
    None: 3
};
export class AMapHelperService {
    static getColorConfig(polygonType, defaultColorConfig, polygonName) {
        let colorConfig = { strokeColor: '#000000', strokeOpacity: 1., fillColor: '#FFFFFF', fillOpacity: .5 };
        if (defaultColorConfig) {
            for (var key in colorConfig) {
                if (defaultColorConfig[key])
                    colorConfig[key] = defaultColorConfig[key];
            }
        }
        let configEntryName = polygonType + 'Color';
        if (Config.BackOfficeConfig && Config.BackOfficeConfig[configEntryName]) {
            let colorConfigTemp = {};
            let configEntry = Config.BackOfficeConfig[configEntryName];
            if (polygonName && configEntry.Names && configEntry.Names[polygonName]) {
                colorConfigTemp = configEntry.Names[polygonName];
            }
            else if (configEntry.Default) {
                colorConfigTemp = configEntry.Default;
            }
            for (var key in colorConfig) {
                if (colorConfigTemp[key])
                    colorConfig[key] = colorConfigTemp[key];
            }
        }
        return colorConfig;
    }
    constructor() {
        this.cache = {};
        this.requested = {};
        this.segments = {};
        this.parkingSpaces = {};
        // @ts-ignore
        this.areas = {};
        // @ts-ignore
        this.zones = {};
        this.lastArea = null;
    }
    autoInit() {
        this.mapOverlayService = AEngine.get(AMapOverlayService);
        this.userActionService = AEngine.get(AUserActionService);
    }
    get AreasOnMap() {
        return this.cache['showAreasOnMap'] || [];
    }
    get ZonesOnMap() {
        return this.cache['showZonesOnMap'] || [];
    }
    get ParkingSpacesOnMap() {
        return this.cache['showParkingSpacesOnMap'] || [];
    }
    get SegmentsOnMap() {
        const segments = this.cache['showSegmentsOnMap'];
        return segments ? segments.filter((item) => item != undefined) : [];
    }
    /**
     * Sets cache for an array of polygons
     * @param {string} key index for hashtable
     * @param {any} polygons array of polygons
     */
    setCache(key, polygons) {
        this.cache[key] = polygons;
    }
    /**
     * Move polygons from one map to another
     * @param {any} map
     * @param {any} polygons
     */
    redirectPolygons(map, polygons) {
        polygons.map(p => {
            p.setMap(map);
            p.setOptions({ visible: true });
        });
    }
    fetchStreetData(coordinates) {
        return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'location': coordinates }, function (results, status) {
                if (status !== 'OK') {
                    return reject(status);
                }
                return resolve(results);
            });
        });
    }
    fetchStreetPosition(service, coordinates) {
        return new Promise((resolve, reject) => {
            try {
                service.getPanoramaByLocation(coordinates, 50, (res, status) => (status === 'OK') ? resolve(res.location.latLng) : resolve(false));
            }
            catch (err) {
                reject(err);
            }
        });
    }
    geoPointToCoords(data, srid = 4326) {
        //data = {"type": "Point", "coordinates": [10.750851301946682, 59.908290312191056]}
        // PageScript.map.setCenter(new google.maps.LatLng(59.9267065136985, 10.8019512068214))
        if (srid == 4326) {
            const [lng, lat] = data.coordinates;
            return { lat, lng };
        }
        else {
            const [lat, lng] = data.coordinates;
            return { lat, lng };
        }
    }
    geoJsonToPolygonCoords(data, srid = 4326) {
        const { coordinates } = data;
        let center = { lat: 0, lng: 0 };
        let areaPaths = [];
        for (let r = 0; r < coordinates.length; r++) {
            let ring = [];
            for (let p = 0; p < coordinates[r].length; p++) {
                let latlng;
                if (srid == 4326) {
                    latlng = { lat: coordinates[r][p][1], lng: coordinates[r][p][0] };
                }
                else {
                    latlng = { lat: coordinates[r][p][0], lng: coordinates[r][p][1] };
                }
                if (r == 0) {
                    center.lat += latlng.lat;
                    center.lng += latlng.lng;
                }
                ring.push(latlng);
            }
            if (r == 0) {
                center.lat /= coordinates[r].length;
                center.lng /= coordinates[r].length;
            }
            areaPaths.push(ring);
        }
        if (areaPaths.length == 1) {
            areaPaths = areaPaths[0];
        }
        return {
            coordinates: areaPaths,
            center: center
        };
    }
    get_legend_green(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 255, 0).rgba(0.7) : new AColor(0, 255, 0).hexi,
            stroke: new AColor(5, 163, 0).hexi
        };
    }
    // get_legend_yellow(useOpacity) {
    // TODO: Implementation
    // }
    get_legend_orange(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 133, 0).rgba(0.7) : new AColor(255, 133, 0).hexi,
            stroke: new AColor(256, 128, 0).hexi
        };
    }
    // get_legend_brown(useOpacity) {
    // TODO: Implementation
    // }
    get_legend_red(useOpacity) {
        return {
            fill: useOpacity ? new AColor(247, 0, 0).rgba(0.7) : new AColor(247, 0, 0).hexi,
            stroke: new AColor(185, 0, 0).hexi
        };
    }
    get_legend_grey(useOpacity) {
        // TODO: Implementation
        return {
            fill: useOpacity ? new AColor(128, 128, 128).rgba(0.7) : new AColor(128, 128, 128).hexi,
            stroke: new AColor(128, 128, 128).hexi
        };
    }
    get_legend_blue(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 173, 255).rgba(0.7) : new AColor(0, 173, 255).hexi,
            stroke: new AColor(0, 114, 232).hexi
        };
    }
    get_legend_yellow(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 255, 0).rgba(0.7) : new AColor(255, 255, 0).hexi,
            stroke: new AColor(255, 255, 0).hexi
        };
    }
    get_legend_brown(useOpacity) {
        return {
            fill: useOpacity ? new AColor(200, 100, 0).rgba(0.7) : new AColor(200, 100, 0).hexi,
            stroke: new AColor(200, 100, 0).hexi
        };
    }
    get_legend_brown_outline() {
        return {
            fill: new AColor(200, 100, 0).rgba(0.0),
            stroke: new AColor(200, 100, 0).hexi
        };
    }
    get_legend_spread(useOpacity) {
        return [
            {
                fill: useOpacity ? new AColor(27, 247, 20).rgba(0.7) : new AColor(27, 247, 20).hexi,
                stroke: new AColor(27, 247, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(20, 247, 222).rgba(0.7) : new AColor(20, 247, 222).hexi,
                stroke: new AColor(20, 247, 222).hexi
            },
            {
                fill: useOpacity ? new AColor(31, 20, 247).rgba(0.7) : new AColor(31, 20, 247).hexi,
                stroke: new AColor(31, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(135, 20, 247).rgba(0.7) : new AColor(135, 20, 247).hexi,
                stroke: new AColor(135, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(240, 20, 247).rgba(0.7) : new AColor(240, 20, 247).hexi,
                stroke: new AColor(240, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 20, 20).rgba(0.7) : new AColor(247, 20, 20).hexi,
                stroke: new AColor(247, 20, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 153, 20).rgba(0.7) : new AColor(247, 153, 20).hexi,
                stroke: new AColor(247, 153, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 243, 20).rgba(0.7) : new AColor(247, 243, 20).hexi,
                stroke: new AColor(247, 243, 20).hexi
            }
        ];
    }
    get legend_legacy() {
        return {
            calcColor: ({ IsIllegallyParked, HasParkingRight }) => {
                const { FillColor, StrokeColor } = this.getColors({ IsIllegallyParked, HasParkingRight });
                return {
                    fill: FillColor,
                    stroke: StrokeColor
                };
            },
            legendItems: {
                'Default': this.get_legend_green(true),
                'No Parking Right': this.get_legend_red(true),
                'Illegally Parked': this.get_legend_brown_outline(),
                'Unknown Parking Right': this.get_legend_grey(true)
            }
        };
    }
    get legend_digital() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'Digital': this.get_legend_green(false),
                'NotDigital': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                'Digital': this.get_legend_green(true),
                'NotDigital': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ Digital }) => {
                switch (Digital) {
                    case 'InProgress':
                        return calcTemplate.InProgress;
                    case 'Digital':
                        return calcTemplate.Digital;
                    case 'NotDigital':
                        return calcTemplate.NotDigital;
                    case 'NotProcessed':
                        return calcTemplate.NotProcessed;
                }
            },
            legendItems: legendItems
        };
    }
    get legend_illegallyparked() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'NotIllegallyParked': this.get_legend_green(false),
                'IllegallyParked': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                'NotIllegallyParked': this.get_legend_green(true),
                'IllegallyParked': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ IllegallyParked }) => {
                switch (IllegallyParked) {
                    case 'NotIllegallyParked':
                        return calcTemplate.NotIllegallyParked;
                    case 'InProgress':
                        return calcTemplate.InProgress;
                    case 'NotProcessed':
                        return calcTemplate.NotProcessed;
                }
                if (IllegallyParked.startsWith('IllegallyParked')) {
                    return calcTemplate.IllegallyParked;
                }
            },
            legendItems
        };
    }
    get legend_parkingright() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'ParkingRight': this.get_legend_green(false),
                'NoParkingRight': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'Other': this.get_legend_grey(false)
            },
            legendItems: {
                'ParkingRight': this.get_legend_green(true),
                'NoParkingRight': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'Other': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ ParkingRight }) => {
                switch (ParkingRight) {
                    case 'InProgress': return calcTemplate.InProgress;
                    case 'NoParkingRight': return calcTemplate.NoParkingRight;
                }
                if (ParkingRight.startsWith('NoParkingRightNeeded')) {
                    return calcTemplate.Other;
                }
                if (ParkingRight.startsWith('ParkingRight')) {
                    return calcTemplate.ParkingRight;
                }
                if (ParkingRight.startsWith('Indecisive')) {
                    return calcTemplate.NoParkingRight;
                }
                if (ParkingRight.startsWith('NotProcessed')) {
                    return calcTemplate.Other;
                }
            },
            legendItems
        };
    }
    get legend_verification() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'NoVerificationNeeded': this.get_legend_green(false),
                'FinedIllegallyParked': this.get_legend_red(false),
                'FinedNoParkingRight': this.get_legend_blue(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                // TODO: Remove NoVerificationNeeded (Bespreek met Jaap ivm met limit 2000 scans, moet ik de query aanpassen ofz?)
                'NoVerificationNeeded': this.get_legend_green(true),
                'FinedIllegallyParked': this.get_legend_red(true),
                'FinedNoParkingRight': this.get_legend_blue(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ Verification }) => {
                if (['NoVerificationNeeded', 'NotFined'].includes(Verification)) {
                    return calcTemplate.NoVerificationNeeded;
                }
                if (['Fined_IllegallyParked', 'Fined_TimeLimitedParkingExperired'].includes(Verification)) {
                    return calcTemplate.FinedIllegallyParked;
                }
                if (['Fined_NoParkingRight', 'Fined_Unknown'].includes(Verification)) {
                    return calcTemplate.FinedNoParkingRight;
                }
                if (Verification.startsWith('InProgress')) {
                    return calcTemplate.InProgress;
                }
                if (Verification.startsWith('NotProcessed')) {
                    return calcTemplate.NotProcessed;
                }
            },
            legendItems
        };
    }
    get legend_detection_state() {
        const colorSpread = this.get_legend_spread(false);
        const colorSpreadOpacity = this.get_legend_spread(true);
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'GeoQueue': colorSpread.pop(),
                'PrdbQueue': colorSpread.pop(),
                'PdaVerificationQueue': colorSpread.pop(),
                'DeskforceVerificationQueue': colorSpread.pop(),
                'AssignedToPda': colorSpread.pop(),
                'AssignedToDeskforce': colorSpread.pop(),
                'Unknown': colorSpread.pop(),
                'Done': colorSpread.pop()
            },
            legendItems: {
                'GeoQueue': colorSpreadOpacity.pop(),
                'PrdbQueue': colorSpreadOpacity.pop(),
                'PdaVerificationQueue': colorSpreadOpacity.pop(),
                'DeskforceVerificationQueue': colorSpreadOpacity.pop(),
                'AssignedToPda': colorSpreadOpacity.pop(),
                'AssignedToDeskforce': colorSpreadOpacity.pop(),
                'Unknown': colorSpreadOpacity.pop(),
                'Done': colorSpreadOpacity.pop()
            }
        };
        return {
            calcColor: ({ DetectionState }) => {
                switch (DetectionState) {
                    case 'InProgress_GeoQueue': return calcTemplate.GeoQueue;
                    case 'InProgress_PrdbQueue': return calcTemplate.PrdbQueue;
                    case 'InProgress_PdaVerificationQueue': return calcTemplate.PdaVerificationQueue;
                    case 'InProgress_DeskforceVerificationQueue': return calcTemplate.DeskforceVerificationQueue;
                    case 'InProgress_AssignedToPda': return calcTemplate.AssignedToPda;
                    case 'InProgress_AssignedToDeskforce': return calcTemplate.AssignedToDeskforce;
                    case 'InProgress_Unknown': return calcTemplate.Unknown;
                    case 'Done': return calcTemplate.Done;
                }
            },
            legendItems
        };
    }
    set calcLegendColor(val) {
        PageScript.__map_legend_calcColor = val;
    }
    get calcLegendColor() {
        return PageScript.__map_legend_calcColor || (_ => '#000');
    }
    async generateDetectionsLegendHtml(options) {
        const { legendItems } = options || {};
        const { tabOptions } = this.mapOverlayService.tabDefinitions;
        const [legendLabelText, translations, tabOptionsT,] = await Promise.all([
            Translate.get('Legend'),
            Translate.get(Object.keys(legendItems)),
            Translate.get(tabOptions)
        ]);
        const LEGEND_SELECTION = this.legendSelection || tabOptions[0];
        const legendItemsData = Object.keys(legendItems).map(key => ({ key, ...legendItems[key] }));
        return ( /*html*/`
      <div class="legend legend-opaque legend-detections" style="${(`zoom: ${AConfig.get(`drawing & colors.legendScale`, 1.2)};`)}">
        <div class="legend-label label-height-lg">${legendLabelText}</div>
        <select class="changeLegend">
        ${tabOptions.map((tabOption) => ( /*html*/`
            <option ${LEGEND_SELECTION == tabOption ? 'selected="selected"' : ''} value="${tabOption}">${tabOptionsT[tabOption]}</option>
          `)).join('')}
        </select>
        ${legendItemsData.map(({ key, fill, stroke }) => {
            return ( /*html*/`
              <div>
                <div class="detectionPreview" style="background-color: ${fill}; outline-color: ${stroke}"></div>
                <span>${translations[key]}</span>
              </div>
            `);
        }).join('')}
      </div>
    `);
    }
    setDetectionsLegendDefault() {
        const { tabConfigurations } = this.mapOverlayService.tabDefinitions;
        const view = tabConfigurations[this.legendSelection] || this.legend_parkingright;
        return this.setDetectionsLegend(view);
    }
    async setDetectionsLegend(options) {
        const { tabConfigurations } = this.mapOverlayService.tabDefinitions;
        const opt = options ?? (tabConfigurations[this.legendSelection] || this.legend_parkingright);
        const { calcColor } = opt || {};
        const $detectionsLegend = $(await this.generateDetectionsLegendHtml(opt));
        const $select = $detectionsLegend.find('.changeLegend');
        $select.on('change', _ => {
            this.legendSelection = $select.val();
            // @ts-ignore
            this.setDetectionsLegend(tabConfigurations[$select.val()]);
        });
        this.calcLegendColor = calcColor;
        this.mapOverlayService.add($detectionsLegend, MAP_POSITION.BOTTOM_LEFT, { uid: 'LEGEND_CONTROLS', order: 0 });
        this.recolorAllMarkers({ calcColor });
    }
    recolorAllMarkers(options) {
        const calcColor = options.calcColor || this.calcLegendColor;
        const markers = this.fetchMarkers();
        for (let marker of markers) {
            const clr = calcColor(marker._final, { opacity: true });
            const { fill, stroke } = (typeof clr === 'string') ? { fill: clr, stroke: clr } : clr;
            marker.setOptions({
                strokeColor: stroke,
                fillColor: fill
            });
        }
    }
    async createCountLabel() {
        const mapCountLabel = document.createElement('label');
        mapCountLabel.classList.add('map-control-count');
        const template = await Translate.get('Detections Displayed: 808');
        mapCountLabel.setAttribute('template', template);
        mapCountLabel.innerText = template.replace('808', '0');
        PageScript.map?.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(mapCountLabel);
    }
    updateCountLabel(count) {
        const $count = $('.map-control-count');
        if ($count.length) {
            const template = $count.attr('template') || '';
            const text = template.replace('808', count);
            $count.text(text);
        }
    }
    async createDownloadButton(options) {
        const { order } = options;
        const $button = await menuService.addMapButton({
            tag: 'a',
            title: 'Export kml',
            icon: 'fa-solid fa-file-arrow-down',
            order,
            position: MAP_POSITION.TOP_RIGHT,
        });
        $button.attr('id', 'DownloadButton');
        $button.attr('disabled', 'disabled');
        return $button;
    }
    /**
     * Creates a toggle button on the map to hide a category
     * @param bitmask only apply on specific type of polygons on the map
     * @param options options
     */
    async createMapToggleSettings(bitmask, { order, fitPolygons = true, showOneScale, click }) {
        let categories = [];
        let methods = [];
        if (bitmask & MAP_OPTIONS.Areas) {
            categories.push({ isCheckbox: true, boolKey: 'vAreas', displayText: 'Areas', collectionKey: 'AreasOnMap', enum: MAP_OPTIONS.Areas });
            methods.push(this.showAreasOnMap);
        }
        if (bitmask & MAP_OPTIONS.Zones) {
            categories.push({ isCheckbox: true, boolKey: 'vZones', displayText: 'Zones', collectionKey: 'ZonesOnMap', enum: MAP_OPTIONS.Zones });
            methods.push(this.showZonesOnMap);
        }
        if (bitmask & MAP_OPTIONS.ParkingSpaces) {
            categories.push({ isCheckbox: true, boolKey: 'vParkingSpaces', displayText: 'ParkingSpaces', collectionKey: 'ParkingSpacesOnMap', enum: MAP_OPTIONS.ParkingSpaces });
            methods.push(this.showParkingSpacesOnMap);
        }
        if (bitmask & MAP_OPTIONS.Segments) {
            categories.push({ isCheckbox: true, boolKey: 'vSegments', displayText: 'Segments', collectionKey: 'SegmentsOnMap', enum: MAP_OPTIONS.Segments });
            methods.push(this.showSegmentsOnMap);
        }
        if (showOneScale === true) {
            const map = script?.map;
            const bounds = map.getBounds() || new google.maps.LatLngBounds();
            await Loading.waitForPromises(methods.map(loadPolygons => loadPolygons.call(this, map, bounds, null, click)));
            if (fitPolygons === true) {
                PageScript.map.fitBounds(bounds);
            }
        }
        await Loading.waitForPromises(Translate.get(categories.map(({ displayText }) => displayText))).then(t => {
            categories.map(c => c.displayText = t[c.displayText]);
        });
        let selectDefault = null;
        const $checkboxArr = [];
        Object.assign(PageScript, { vAreas: false, vZones: false, vParkingSpaces: false, vSegments: false });
        const inputs = await Promise.all(categories.map(async (category) => {
            const { displayText, collectionKey, boolKey } = category;
            if (!PageScript.hasOwnProperty(boolKey)) {
                throw new Error(`PageScript.${boolKey} doesn't exist!`);
            }
            const $a = this.genMapPopoverItem({ displayText });
            const $checkbox = $a.find('[type="checkbox"]');
            $checkboxArr.push($checkbox);
            const toggleAction = async () => {
                if (showOneScale !== true) {
                    await this.toggle(this.getMapOption(collectionKey), !PageScript[boolKey]);
                }
                else {
                    this.toggleTo(category.enum);
                }
                $checkboxArr.map($c => $c.trigger('refreshstate'));
            };
            if (showOneScale === true) {
                selectDefault = toggleAction;
            }
            $checkbox.on('refreshstate', (e) => $checkbox.prop('checked', PageScript[boolKey]));
            $checkbox.on('change', _ => toggleAction().catch(AError.handle));
            $a.on('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                $checkbox.trigger('change');
            });
            return $a;
        }));
        if (showOneScale && selectDefault !== null) {
            selectDefault().catch(AError.handle);
        }
        if (bitmask !== 0) {
            menuService.addMapDropdown(inputs, {
                uid: 'scale-toggle',
                icon: 'fa-solid fa-draw-polygon',
                order,
                position: MAP_POSITION.TOP_LEFT
            });
        }
    }
    genMapPopoverItem({ displayText }) {
        return $(/*html*/ `
      <a>
        <div class="noselect ns-children">
          <label class="form-checkbox">
            <input type="checkbox" class="hidden noselect"> <i class="form-icon"></i>
            ${displayText}
          </label>
        </div>
      </a>
    `);
    }
    getMapOption(fieldname) {
        const options = {
            'AreasOnMap': {
                MAP_OPTION: MAP_OPTIONS.Areas,
                toggleKey: 'vAreas',
            },
            'ZonesOnMap': {
                MAP_OPTION: MAP_OPTIONS.Zones,
                toggleKey: 'vZones',
            },
            'ParkingSpacesOnMap': {
                MAP_OPTION: MAP_OPTIONS.ParkingSpaces,
                toggleKey: 'vParkingSpaces',
            },
            'SegmentsOnMap': {
                MAP_OPTION: MAP_OPTIONS.Segments,
                toggleKey: 'vSegments',
            },
        };
        if (options.hasOwnProperty(fieldname)) {
            const { MAP_OPTION } = options[fieldname];
            return MAP_OPTION;
        }
        else {
            throw new Error(`No action called "${fieldname}" found on mapHelperService.togglePolygon`);
        }
    }
    editStyle({ find, defaultValue, behaviour }) {
        const styles = PageScript.map.get('styles') || [];
        let index = -1;
        styles.map((style, i) => {
            if (find(style)) {
                index = i;
            }
        });
        if (index === -1) {
            index = styles.push(defaultValue()) - 1;
        }
        styles[index] = behaviour(styles[index]);
        PageScript.map.set('styles', styles);
    }
    toggleMapLabels() {
        if (!PageScript.map) {
            return;
        }
        if (!PageScript.__mapOptions) {
            PageScript.__mapOptions = { visibility: true };
        }
        const featureType = 'all', elementType = 'labels';
        this.editStyle({
            find: (style) => (style.featureType === featureType && style.elementType === elementType),
            defaultValue: () => ({
                featureType,
                elementType,
                stylers: [{
                        visibility: PageScript.__mapOptions.visibility ? "on" : "off"
                    }]
            }),
            behaviour: (style) => {
                PageScript.__mapOptions.visibility = !PageScript.__mapOptions.visibility;
                style.stylers[0].visibility = PageScript.__mapOptions.visibility ? "on" : "off";
                return style;
            }
        });
    }
    setMapType(mapType) {
        if (!PageScript.map) {
            return;
        }
        const map = PageScript.map;
        map.setMapTypeId(mapType);
    }
    toggleMapLabelsOld() {
        if (!PageScript.__mapOptions) {
            PageScript.__mapOptions = {
                visibility: true
            };
        }
        PageScript.__mapOptions.visibility = !PageScript.__mapOptions.visibility;
        PageScript.map?.set('styles', [
            {
                featureType: "all",
                elementType: "labels",
                stylers: [{
                        visibility: PageScript.__mapOptions.visibility ? "on" : "off"
                    }]
            }
        ]);
    }
    genDropdownLink({ displayText, isCheckbox = false }) {
        return (isCheckbox) ? $(`
      <a>
        <div class="noselect ns-children">
          <label class="form-checkbox">
            <input type="checkbox" class="hidden noselect">
            <i class="form-icon"></i> ${displayText}
          </label>
        </div>
      </a>
    `) : $(`<a><div class="noselect ns-children">${displayText}</div></a>`);
    }
    async createMapDropdown({ order }) {
        let categories = [
            { displayText: 'Toggle Labels', click: () => this.toggleMapLabels() },
            { displayText: 'Focus On Markers', click: () => PageScript.map?.focusOnMarkers() },
            { displayText: 'Focus On Scales', click: () => PageScript.map?.focusOnScales() },
            { displayText: 'Reset View', click: () => PageScript.map?.resetBounds() },
        ];
        await Loading.waitForPromises(Translate.get(categories.map(({ displayText }) => displayText))).then(t => {
            categories.map(c => c.displayText = t[c.displayText]);
        });
        const inputs = categories.map(category => {
            const $link = this.genDropdownLink(category);
            const $c = $link.find('[type=checkbox]');
            let toggle = false;
            let toggleAction = async () => {
                category.click.call(this);
                $c.prop('checked', toggle);
                toggle = !toggle;
            };
            $link.on('click', () => { toggleAction().catch(AError.handle); });
            $c.on('change', () => { toggleAction().catch(AError.handle); });
            return $link;
        });
        menuService.addMapDropdown(inputs, {
            icon: 'fa-solid fa-bars',
            order,
            position: MAP_POSITION.TOP_LEFT
        });
    }
    polygonCompare(scale) {
        if (FilterSettings.Area === undefined)
            return true;
        return !FilterSettings.Area || FilterSettings.Area === '%' || scale.data.area === FilterSettings.Area;
    }
    /**
     * Updates visibility of polygons, so if the area isn't selected, the polygon won't show up
     */
    updateStaticPolygons() {
        const ps = PageScript;
        if (this.cache.showAreasOnMap && ps.vAreas)
            this.cache.showAreasOnMap.map(scale => scale.setOptions({ visible: this.polygonCompare(scale) }));
        if (this.cache.showZonesOnMap && ps.vZones)
            this.cache.showZonesOnMap.map(scale => scale.setOptions({ visible: this.polygonCompare(scale) }));
        if (this.cache.showParkingSpacesOnMap && ps.vParkingSpaces)
            this.cache.showParkingSpacesOnMap.map(scale => scale.setOptions({ visible: this.polygonCompare(scale) }));
        if (this.cache.showSegmentsOnMap && ps.vSegments)
            this.cache.showSegmentsOnMap.map(scale => scale.setOptions({ visible: this.polygonCompare(scale) }));
        return Promise.resolve();
    }
    /**
     * Creates parking spaces on the map
     * @param {any} map Google Maps reference
     * @param {any} bounds Bounds to extend
     * @param {string} filterArea
     */
    async showParkingSpacesOnMap(map, bounds, filterArea, clickEvent) {
        const KEY = 'showParkingSpacesOnMap';
        if (this.cache[KEY]) {
            if (clickEvent) {
                for (const polygon of this.cache[KEY]) {
                    google.maps.event.addListener(polygon, "click", clickEvent || purgatoryService.clickPolygon);
                }
            }
            this.redirectPolygons(map, this.cache[KEY]);
            return this.cache[KEY];
        }
        const getColorByName = (name) => {
            let colorConfig = AMapHelperService.getColorConfig('ParkingSpace', { strokeColor: '#880000', strokeOpacity: 1., fillColor: '#FF0000', fillOpacity: .5 }, name);
            // TODO: move color config to BackOfficeConfig or Preferences
            switch (name) {
                case "Tax parking":
                    colorConfig.fillColor = "#FFFF00";
                    colorConfig.strokeColor = "#888800";
                    break;
                case "Free parking":
                    colorConfig.fillColor = "#99DD99";
                    colorConfig.strokeColor = "#000000";
                    break;
                case "Private Tax parking":
                    colorConfig.fillColor = "#99DD99";
                    colorConfig.strokeColor = "#000000";
                    break;
                case "Motorcycle Parking":
                    colorConfig.fillColor = "#00FF00";
                    colorConfig.strokeColor = "#008800";
                    break;
                case "Electric":
                    colorConfig.fillColor = "#00FF00";
                    colorConfig.strokeColor = "#008800";
                    break;
            }
            return colorConfig;
        };
        let result = [];
        for (let area in this.parkingSpaces) {
            if (!filterArea || filterArea == area) {
                for (let parkingSpaceId in this.parkingSpaces[area]) {
                    if (this.parkingSpaces[area][parkingSpaceId].Bounds.type == "LineString") {
                        let parkingSpaceBounds = this.parkingSpaces[area][parkingSpaceId].Bounds.coordinates;
                        let path = [];
                        let center = { lat: 0, lng: 0 };
                        for (let p = 0; p < parkingSpaceBounds.length; p++) {
                            let latlng = {
                                lat: parkingSpaceBounds[p][1],
                                lng: parkingSpaceBounds[p][0]
                            };
                            if (bounds)
                                bounds.extend(latlng);
                            center.lat += latlng.lat;
                            center.lng += latlng.lng;
                            path.push(latlng);
                        }
                        center.lat /= parkingSpaceBounds.length;
                        center.lng /= parkingSpaceBounds.length;
                        this.parkingSpaces[area][parkingSpaceId].Center = center;
                        const colorConfig = getColorByName(this.parkingSpaces[area][parkingSpaceId].Name);
                        // Construct the polygon, including both paths.
                        let parkingSpace = new google.maps.Polyline({
                            path: path,
                            strokeColor: colorConfig.strokeColor,
                            strokeOpacity: colorConfig.strokeOpacity,
                            strokeWeight: 1,
                            // fillColor: colorConfig.fillColor,
                            // fillOpacity: colorConfig.fillOpacity,
                            zIndex: 3.,
                            // position: center
                        });
                        Object.assign(parkingSpace, {
                            data: {
                                id: parkingSpaceId,
                                area: area,
                                scale: MAP_OPTIONS.ParkingSpaces
                            }
                        });
                        result.push(parkingSpace);
                        parkingSpace.setMap(map);
                        google.maps.event.addListener(parkingSpace, "click", clickEvent || purgatoryService.clickPolygon);
                    }
                    else if (this.parkingSpaces[area][parkingSpaceId].Bounds.type == "Polygon") {
                        let parkingSpaceBounds = this.parkingSpaces[area][parkingSpaceId].Bounds.coordinates;
                        let parkingSpacePaths = [];
                        let center = { lat: 0, lng: 0 };
                        for (let r = 0; r < parkingSpaceBounds.length; r++) {
                            center = { lat: 0, lng: 0 };
                            let ring = [];
                            for (let p = 0; p < parkingSpaceBounds[r].length; p++) {
                                let latlng = {
                                    lat: parkingSpaceBounds[r][p][1],
                                    lng: parkingSpaceBounds[r][p][0]
                                };
                                if (bounds)
                                    bounds.extend(latlng);
                                center.lat += latlng.lat;
                                center.lng += latlng.lng;
                                ring.push(latlng);
                            }
                            center.lat /= parkingSpaceBounds[r].length;
                            center.lng /= parkingSpaceBounds[r].length;
                            parkingSpacePaths.push(ring);
                        }
                        if (parkingSpacePaths.length == 1) {
                            parkingSpacePaths = parkingSpacePaths[0];
                        }
                        this.parkingSpaces[area][parkingSpaceId].Center = center;
                        const colorConfig = getColorByName(this.parkingSpaces[area][parkingSpaceId].Name);
                        // Construct the polygon, including both paths.
                        let parkingSpace = new google.maps.Polygon({
                            paths: parkingSpacePaths,
                            strokeColor: colorConfig.strokeColor,
                            strokeOpacity: colorConfig.strokeOpacity,
                            strokeWeight: .5,
                            fillColor: colorConfig.fillColor,
                            fillOpacity: colorConfig.fillOpacity,
                            zIndex: 3.,
                            // position: center
                        });
                        Object.assign(parkingSpace, {
                            data: {
                                id: parkingSpaceId,
                                area: area,
                                scale: MAP_OPTIONS.ParkingSpaces
                            }
                        });
                        // Apply ref for fast lookup
                        this.parkingSpaces[area][parkingSpaceId].ref = parkingSpace;
                        result.push(parkingSpace);
                        parkingSpace.setMap(map);
                        google.maps.event.addListener(parkingSpace, "click", clickEvent || purgatoryService.clickPolygon);
                    }
                }
            }
        }
        this.setCache(KEY, result);
        return result;
    }
    /**
     * Creates areas on the map
     * @param {any} map Google Maps reference
     * @param {any} bounds Bounds to extend
     * @param {string} filterArea
     */
    async showAreasOnMap(map, bounds, filterArea, clickEvent) {
        const KEY = 'showAreasOnMap';
        if (this.cache[KEY]) {
            if (clickEvent) {
                for (const polygon of this.cache[KEY]) {
                    google.maps.event.addListener(polygon, "click", clickEvent || purgatoryService.clickPolygon);
                }
            }
            this.redirectPolygons(map, this.cache[KEY]);
            return this.cache[KEY];
        }
        let result = [];
        for (let areaid in this.areas) {
            let area = this.areas[areaid].Name;
            if (!filterArea || filterArea == area) {
                let center = { lat: 0, lng: 0 };
                let areaBounds = this.areas[areaid].Bounds.coordinates;
                let srid = this.areas[areaid].SRID;
                let areaPaths = [];
                for (let r = 0; r < areaBounds.length; r++) {
                    let ring = [];
                    for (let p = 0; p < areaBounds[r].length; p++) {
                        let latlng;
                        if (srid == 4326) {
                            latlng = { lat: areaBounds[r][p][1], lng: areaBounds[r][p][0] };
                        }
                        else {
                            latlng = { lat: areaBounds[r][p][0], lng: areaBounds[r][p][1] };
                        }
                        if (r == 0) {
                            center.lat += latlng.lat;
                            center.lng += latlng.lng;
                        }
                        ring.push(latlng);
                        if (bounds)
                            bounds.extend(latlng);
                    }
                    if (r == 0) {
                        center.lat /= areaBounds[r].length;
                        center.lng /= areaBounds[r].length;
                    }
                    areaPaths.push(ring);
                }
                if (areaPaths.length == 1) {
                    areaPaths = areaPaths[0];
                }
                // Construct the polygon, including both paths.
                let colorConfig = AMapHelperService.getColorConfig("Area", { fillColor: '#ffffaa', fillOpacity: 0.5, strokeColor: '#888888', strokeOpacity: 0.3 });
                this.areas[areaid].Center = center;
                let polygon = new google.maps.Polygon({
                    paths: areaPaths,
                    strokeColor: colorConfig.strokeColor,
                    strokeOpacity: colorConfig.strokeOpacity,
                    strokeWeight: 1,
                    fillColor: colorConfig.fillColor,
                    fillOpacity: colorConfig.fillOpacity,
                    zIndex: 1.,
                    // position: center
                });
                polygon.setMap(map);
                Object.assign(polygon, {
                    data: {
                        id: areaid,
                        area: area,
                        scale: MAP_OPTIONS.Areas
                    }
                });
                result.push(polygon);
                google.maps.event.addListener(polygon, "click", clickEvent || purgatoryService.clickPolygon);
            }
        }
        this.setCache(KEY, result);
        return result;
    }
    /**
     * Creates retracable zones on the map
     * @param {any} map Google Maps reference
     * @param {any} bounds Bounds to extend
     * @param {string} filterArea
     */
    async showZonesOnMap(map, bounds, filterArea, clickEvent) {
        const KEY = 'showZonesOnMap';
        if (this.cache[KEY]) {
            if (clickEvent) {
                for (const polygon of this.cache[KEY]) {
                    google.maps.event.addListener(polygon, "click", clickEvent || purgatoryService.clickPolygon);
                }
            }
            this.redirectPolygons(map, this.cache[KEY]);
            return this.cache[KEY];
        }
        let result = [];
        for (let area in this.zones) {
            if (!filterArea || filterArea == area) {
                for (let zoneId in this.zones[area]) {
                    if (this.zones[area][zoneId].Bounds.type == "Polygon") {
                        let zoneBounds = this.zones[area][zoneId].Bounds.coordinates;
                        let zonePaths = [];
                        let center = {
                            lat: 0,
                            lng: 0
                        };
                        for (let r = 0; r < zoneBounds.length; r++) {
                            center = {
                                lat: 0,
                                lng: 0
                            };
                            let ring = [];
                            for (let p = 0; p < zoneBounds[r].length; p++) {
                                let LatLon = {
                                    lat: zoneBounds[r][p][1],
                                    lng: zoneBounds[r][p][0]
                                };
                                center.lat += LatLon.lat;
                                center.lng += LatLon.lng;
                                ring.push(LatLon);
                                if (bounds)
                                    bounds.extend(LatLon);
                            }
                            center.lat /= zoneBounds[r].length;
                            center.lng /= zoneBounds[r].length;
                            zonePaths.push(ring);
                        }
                        if (zonePaths.length == 1) {
                            zonePaths = zonePaths[0];
                        }
                        // Construct the polygon, including both paths.
                        let colorConfig = AMapHelperService.getColorConfig("Zone", { fillColor: '#888888', fillOpacity: 0.4, strokeColor: '#000000', strokeOpacity: 0.6 });
                        let zone = new google.maps.Polygon({
                            paths: zonePaths,
                            strokeColor: colorConfig.strokeColor,
                            strokeOpacity: colorConfig.strokeOpacity,
                            strokeWeight: 1,
                            fillColor: colorConfig.fillColor,
                            fillOpacity: colorConfig.fillOpacity,
                            zIndex: 2.,
                            // position: center
                        });
                        this.zones[area][zoneId].Center = center;
                        zone.setMap(map);
                        Object.assign(zone, {
                            data: {
                                id: zoneId,
                                area: area,
                                scale: MAP_OPTIONS.Zones
                            }
                        });
                        result.push(zone);
                        google.maps.event.addListener(zone, "click", clickEvent || purgatoryService.clickPolygon);
                    }
                }
            }
        }
        this.setCache(KEY, result);
        return result;
    }
    /**
     * Creates segments on the map
     * @param {any} map Google Maps reference
     * @param {any} bounds Bounds to extend
     */
    async showSegmentsOnMap(map, bounds, filterArea, clickEvent) {
        const KEY = 'showSegmentsOnMap';
        if (this.cache[KEY]) {
            this.redirectPolygons(map, this.cache[KEY]);
            if (clickEvent) {
                for (const polygon of this.cache[KEY]) {
                    google.maps.event.addListener(polygon, "click", clickEvent || purgatoryService.clickPolygon);
                }
            }
            return this.cache[KEY];
        }
        let result = [];
        let emptyCount = 0;
        for (let area in this.segments) {
            console.log(area, filterArea);
            if (!filterArea || filterArea == area) {
                const segments = this.segments[area];
                for (let segmentIndex in segments) {
                    let segment = segments[segmentIndex];
                    let segmentBounds = segment.Bounds.coordinates;
                    let segmentPaths = [];
                    let center = { lat: 0, lng: 0 };
                    for (let r = 0; r < segmentBounds.length; r++) {
                        center = { lat: 0, lng: 0 };
                        let ring = [];
                        for (let p = 0; p < segmentBounds[r].length; p++) {
                            let LatLon = {
                                lat: segmentBounds[r][p][1],
                                lng: segmentBounds[r][p][0]
                            };
                            center.lat += LatLon.lat;
                            center.lng += LatLon.lng;
                            ring.push(LatLon);
                            if (bounds)
                                bounds.extend(LatLon);
                        }
                        center.lat /= segmentBounds[r].length;
                        center.lng /= segmentBounds[r].length;
                        segmentPaths.push(ring);
                    }
                    if (segmentPaths.length == 1) {
                        segmentPaths = segmentPaths[0];
                    }
                    segment.Center = center;
                    // Construct the polygon, including both paths.
                    let polygon = new google.maps.Polygon({
                        paths: segmentPaths,
                        strokeColor: '#000000',
                        strokeOpacity: 0.3,
                        strokeWeight: 1,
                        fillColor: '#888888',
                        fillOpacity: 0.2,
                        zIndex: 2.,
                        // position: center
                    });
                    polygon.setMap(map);
                    Object.assign(polygon, {
                        data: {
                            segmentIndex: segmentIndex,
                            id: segment.id,
                            area: segment.Area,
                            scale: MAP_OPTIONS.Segments
                        }
                    });
                    result[segmentIndex] = polygon;
                    google.maps.event.addListener(polygon, "click", clickEvent || purgatoryService.clickPolygon);
                }
            }
        }
        console.log(emptyCount);
        this.setCache(KEY, result);
        return result;
    }
    /**
     * Toggles all polygon scales off except for the one given as argument
     * @param {any} bitmask polygon scale to be shown
     */
    toggleTo(bitmask) {
        this.toggleParkingSpaces(this, (bitmask & MAP_OPTIONS.ParkingSpaces) !== 0);
        this.toggleZones(this, (bitmask & MAP_OPTIONS.Zones) !== 0);
        this.toggleAreas(this, (bitmask & MAP_OPTIONS.Areas) !== 0);
        this.toggleSegments(this, (bitmask & MAP_OPTIONS.Segments) !== 0);
    }
    async toggle(mapOption, visible) {
        const scales = [
            { call: this.toggleParkingSpaces, mapOpt: MAP_OPTIONS.ParkingSpaces },
            { call: this.toggleZones, mapOpt: MAP_OPTIONS.Zones },
            { call: this.toggleAreas, mapOpt: MAP_OPTIONS.Areas },
            { call: this.toggleSegments, mapOpt: MAP_OPTIONS.Segments }
        ];
        for (let { call, mapOpt } of scales) {
            if ((mapOption & mapOpt) !== 0) {
                await this.showOnMap(mapOpt);
                call.apply(this, [this, visible]);
            }
        }
    }
    /**
     * Toggles visibility of parking spaces
     * @param {boolean} visible whether to show or hide the polygons
     * @param {any} self the context in which to get the polygons
     */
    toggleParkingSpaces(self, visible) {
        if (self.ParkingSpacesOnMap === undefined) {
            throw new Error(`self.ParkingSpacesOnMap doesn't exist!`);
        }
        PageScript.vParkingSpaces = visible !== undefined ? visible : !PageScript.vParkingSpaces;
        if (self.ParkingSpacesOnMap instanceof Array) {
            for (const polygon of self.ParkingSpacesOnMap) {
                polygon.setOptions({ visible });
            }
        }
        else {
            for (const key in self.ParkingSpacesOnMap) {
                self.ParkingSpacesOnMap[key].setOptions({ visible });
            }
        }
    }
    /**
     * Toggles visibility of zones
     * @param {boolean} visible whether to show or hide the polygons
     * @param {any} self the context in which to get the polygons
     */
    toggleZones(self, visible) {
        if (self.ZonesOnMap === undefined) {
            throw new Error(`self.ZonesOnMap doesn't exist!`);
        }
        PageScript.vZones = visible !== undefined ? visible : !PageScript.vZones;
        if (self.ZonesOnMap instanceof Array) {
            for (const polygon of self.ZonesOnMap) {
                polygon.setOptions({ visible });
            }
        }
        else {
            for (const key in self.ZonesOnMap) {
                self.ZonesOnMap[key].setOptions({ visible });
            }
        }
    }
    /**
     * Toggles visibility of areas
     * @param {boolean} visible whether to show or hide the polygons
     * @param {any} self the context in which to get the polygons
     */
    toggleAreas(self, visible) {
        if (self.AreasOnMap === undefined) {
            throw new Error(`self.AreasOnMap doesn't exist!`);
        }
        PageScript.vAreas = visible !== undefined ? visible : !PageScript.AreasOnMap;
        if (self.AreasOnMap instanceof Array) {
            for (const polygon of self.AreasOnMap) {
                polygon.setOptions({ visible });
            }
        }
        else {
            for (const key in self.AreasOnMap) {
                self.AreasOnMap[key].setOptions({ visible });
            }
        }
    }
    /**
     * Toggles visibility of segments
     * @param {boolean} visible whether to show or hide the polygons
     * @param {any} self the context in which to get the polygons
     */
    toggleSegments(self, visible) {
        if (self.SegmentsOnMap === undefined) {
            throw new Error(`self.SegmentsOnMap doesn't exist!`);
        }
        PageScript.vSegments = visible !== undefined ? visible : !PageScript.vSegments;
        if (self.SegmentsOnMap instanceof Array) {
            for (const polygon of self.SegmentsOnMap) {
                polygon.setOptions({ visible });
            }
        }
        else {
            for (const key in self.SegmentsOnMap) {
                self.SegmentsOnMap[key].setOptions({ visible });
            }
        }
    }
    /**
     * Whether the propertyName is already loaded
     * @param {string} propertyName
     */
    isLoaded(propertyName) {
        return this.requested[propertyName] === true && this[propertyName] && Object.keys(this[propertyName]).length > 0;
    }
    /**
     * Whether the propertyName is loading
     * @param {string} propertyName
     */
    isLoading(propertyName) {
        return this.requested[propertyName] instanceof Promise;
    }
    /**
     * Load areas directly into memory
     */
    loadAreas() {
        const name = 'areas';
        if (this.isLoaded(name)) {
            return Promise.resolve();
        }
        if (this.isLoading(name)) {
            return this.requested[name];
        }
        AEngine.log(`Fetching Polygon Areas`);
        let promise = requestService.query({
            Name: name,
            Query: "select AreaId, a.Name as Area, ST_AsGeoJSON(Bounds) as Bounds, ST_SRID(Bounds) as Srid, CAST(a.Attributes as JSON) as Attributes from geo_areas a where Active=true and Visible=true and Bounds is not null;",
        }).then((Data) => {
            // @ts-ignore
            this[name] = {};
            for (let i = 0; i < Data.Rows.length; i++) {
                this[name][Data.Rows[i][0]] = {
                    "AreaId": Data.Rows[i][0],
                    "Name": Data.Rows[i][1],
                    "Bounds": Data.Rows[i][2],
                    "SRID": Data.Rows[i][3],
                    "Attributes": Data.Rows[i][4]
                };
            }
            this.requested[name] = true;
        }).catch(AError.handle);
        this.requested[name] = promise;
        return promise;
    }
    /**
     * Load zones directly into memory
     */
    loadZones() {
        const name = 'zones';
        if (this.isLoaded(name)) {
            return Promise.resolve();
        }
        if (this.isLoading(name)) {
            return this.requested[name];
        }
        AEngine.log(`Fetching Polygon Zones`);
        let promise = requestService.query({
            Name: name,
            Query: "select IFNULL(a.Name, 'Unknown') as Area, z.ZoneId, z.Name, ST_AsGeoJSON(z.Bounds) as Bounds, CAST(z.Attributes as JSON) as Attributes from geo_zones z left join geo_areas a using(AreaId) where z.Active=true and z.Visible=true and z.Bounds is not null",
        }).then((Data) => {
            // @ts-ignore
            this[name] = {};
            for (let i = 0; i < Data.Rows.length; i++) {
                if (this[name][Data.Rows[i][0]] == null) {
                    this[name][Data.Rows[i][0]] = {};
                }
                this[name][Data.Rows[i][0]][Data.Rows[i][1]] = {
                    "Id": Data.Rows[i][1],
                    "Name": Data.Rows[i][2],
                    "Bounds": Data.Rows[i][3],
                    "Attributes": Data.Rows[i][4]
                };
            }
            this.requested[name] = true;
        }).catch(AError.handle);
        this.requested[name] = promise;
        return promise;
    }
    /**
     * Load parking spaces directly into memory
     */
    loadParkingSpaces() {
        const name = 'parkingSpaces';
        if (this.isLoaded(name)) {
            return Promise.resolve();
        }
        if (this.isLoading(name)) {
            return this.requested[name];
        }
        AEngine.log(`Fetching Polygon ParkingSpaces`);
        let promise = requestService.query({
            Name: name,
            Query: "select IFNULL(a.Name, 'Unknown') as Area, p.ParkingSpaceId, p.Name, ST_AsGeoJSON(p.Bounds) as Bounds, CAST(p.Attributes as JSON) as Attributes from geo_parkingspaces p left join geo_areas a using(AreaId) where p.Active=true and p.Visible=true and p.Bounds is not null"
        }).then((Data) => {
            // @ts-ignore
            this[name] = {};
            for (let i = 0; i < Data.Rows.length; i++) {
                if (this[name][Data.Rows[i][0]] == null) {
                    this[name][Data.Rows[i][0]] = {};
                }
                this[name][Data.Rows[i][0]][Data.Rows[i][1]] = {
                    "Name": Data.Rows[i][2],
                    "Bounds": Data.Rows[i][3],
                    "Attributes": Data.Rows[i][4],
                    "ref": null
                };
            }
            this.requested[name] = true;
        }).catch(AError.handle);
        this.requested[name] = promise;
        return promise;
    }
    /**
     * Load segments directly into memory
     */
    loadSegments() {
        const name = 'segments';
        if (this.isLoaded(name)) {
            return Promise.resolve();
        }
        if (this.isLoading(name)) {
            return this.requested[name];
        }
        AEngine.log(`Fetching Polygon Segments`);
        let promise = requestService.query({
            Name: name,
            Query: "SELECT IFNULL(a.Name, 'Unknown') as Area, z.SegmentId, z.Name, ST_AsGeoJSON(z.Bounds) as Bounds, CAST(z.Attributes as JSON) as Attributes from geo_segments z left join geo_areas a using(AreaId) where z.Active=true and z.Visible=true and z.Bounds is not null"
        }).then((Data) => {
            // @ts-ignore
            this[name] = {};
            for (let i = 0; i < Data.Rows.length; i++) {
                let obj = Data.Rows[i][3];
                Object.assign(obj, {
                    area: Data.Rows[i][0]
                });
                if (this[name][Data.Rows[i][0]] == null) {
                    this[name][Data.Rows[i][0]] = {};
                }
                this[name][Data.Rows[i][0]][Data.Rows[i][1]] = {
                    "id": Data.Rows[i][1],
                    "Area": Data.Rows[i][0],
                    "Bounds": Data.Rows[i][3],
                    "Attributes": Data.Rows[i][4]
                };
            }
            this.requested[name] = true;
        }).catch(AError.handle);
        this.requested[name] = promise;
        return promise;
    }
    /**
     * Load specific settings
     * @param {Number} options enum stating which scales to load
     */
    load(options) {
        if (options === undefined)
            return Promise.reject(new Error(`No option selected for MapHelper.load`));
        let promises = [];
        if (options & MAP_OPTIONS.ParkingSpaces) {
            promises.push(this.loadParkingSpaces());
        }
        if (options & MAP_OPTIONS.Zones) {
            promises.push(this.loadZones());
        }
        if (options & MAP_OPTIONS.Areas) {
            promises.push(this.loadAreas());
        }
        if (options & MAP_OPTIONS.Segments) {
            promises.push(this.loadSegments());
        }
        return Loading.waitForPromises(promises);
    }
    /**
     * Load both parking spaces and segments
     */
    loadAll() {
        return this.load(MAP_OPTIONS.All);
    }
    showOnMap(bitmask, click) {
        const methods = [];
        if (bitmask & MAP_OPTIONS.ParkingSpaces) {
            methods.push(this.showParkingSpacesOnMap);
        }
        if (bitmask & MAP_OPTIONS.Zones) {
            methods.push(this.showZonesOnMap);
        }
        if (bitmask & MAP_OPTIONS.Areas) {
            methods.push(this.showAreasOnMap);
        }
        if (bitmask & MAP_OPTIONS.Segments) {
            methods.push(this.showSegmentsOnMap);
        }
        const { map } = PageScript;
        const bounds = PageScript.bounds || DefaultBounds();
        const filterArea = GetFilterArea();
        return this.load(bitmask).then(() => Promise.all(methods.map(loadPolygons => loadPolygons.call(this, map, bounds, filterArea, click)))).catch(AError.handle);
    }
    // prepareMapItems(bitmask: number, { showOneScale, createToggleItems, updateStaticPolygons, allowExport, showLegend, fitPolygons }: AUIMapOptions)
    prepareMapItems(bitmask, options = {}) {
        const { showOneScale = false, createToggleItems = true, updateStaticPolygons = false, allowExport = true, showLegend = false, fitPolygons = false, click } = options || {};
        return Loading.waitForPromises(this._prepareMapItems(bitmask, {
            showOneScale,
            createToggleItems,
            updateStaticPolygons,
            allowExport,
            showLegend,
            fitPolygons,
            click
        }));
    }
    async _prepareMapItems(bitmask, options) {
        const { createToggleItems, updateStaticPolygons, showLegend, allowExport, showOneScale, click, fitPolygons } = options;
        return await Loading.waitForPromises([
            // Map Hamburger menu
            this.createMapDropdown({ order: 1 }),
            // Map Scale toggle menu
            (createToggleItems === true) ? this.createMapToggleSettings(bitmask, { order: 2, showOneScale, click, fitPolygons }) : Promise.resolve(),
            // Map Fullscreen button
            menuService.addMapButton({
                order: 3,
                icon: 'fa-solid fa-expand',
                position: MAP_POSITION.TOP_LEFT
            }).then($btn => {
                $btn.on('click', _ => toggleFullScreen('#map'));
                const $fa = $btn.find('i');
                Events.on(EVENTS.TOGGLE_FULLSCREEN, (v) => {
                    $fa.toggleClass('fa-compress', v);
                    $fa.toggleClass('fa-expand', !v);
                });
            }),
            menuService.addMapButtonRadio({
                order: 99,
                titles: ['Map', 'Sattelite'],
                position: MAP_POSITION.TOP_RIGHT
            }).then(([$map, $sattelite]) => {
                $map.on('click', _ => this.setMapType(google.maps.MapTypeId.ROADMAP));
                $sattelite.on('click', _ => this.setMapType(google.maps.MapTypeId.HYBRID));
            }),
            // Update polygon visibility
            (updateStaticPolygons === true) ? this.updateStaticPolygons() : Promise.resolve(),
            // Show legend if enabled
            (showLegend === true) ? this.setDetectionsLegendDefault() : Promise.resolve(),
            // Show export if enabled
            (allowExport) ? this.createDownloadButton({ order: 10 }) : Promise.resolve(),
            // Create detection count at the bottom of the map
            this.createCountLabel(),
            // Change map bounds to fit the contents
            Promise.resolve().then(_ => PageScript.map && PageScript.map.fit()),
        ]);
    }
    /**
     * Changes the color of the marker
     * @param {any} marker
     * @param {Object} options
     */
    changeColorMarker(marker, options) {
        marker.setOptions(options);
    }
    /**
     * @deprecated
     * Change color of polygons on the map
     * @param {Number} map_options scales to change
     * @param {string} fillColor color of polygon fill
     * @param {string} strokeColor color of polygon stroke
     * @param {boolean} cache whether to cache the colors
     */
    changeColor(map_options, fillColor, strokeColor, cache = false) {
        if (cache === true) {
            if (map_options & MAP_OPTIONS.Areas) {
                this.AreasOnMap.map((marker) => {
                    this.cacheMarkerProperties(marker);
                    marker.setOptions({ fillColor, strokeColor });
                });
            }
            if (map_options & MAP_OPTIONS.Zones) {
                this.ZonesOnMap.map((marker) => {
                    this.cacheMarkerProperties(marker);
                    marker.setOptions({ fillColor, strokeColor });
                });
            }
            if (map_options & MAP_OPTIONS.ParkingSpaces) {
                this.ParkingSpacesOnMap.map((marker) => {
                    this.cacheMarkerProperties(marker);
                    marker.setOptions({ fillColor, strokeColor });
                });
            }
            if (map_options & MAP_OPTIONS.Segments) {
                this.SegmentsOnMap.map((marker) => {
                    this.cacheMarkerProperties(marker);
                    marker.setOptions({ fillColor, strokeColor });
                });
            }
        }
        else {
            if (map_options & MAP_OPTIONS.Areas) {
                this.AreasOnMap.map((marker) => marker.setOptions({ fillColor, strokeColor }));
            }
            if (map_options & MAP_OPTIONS.Zones) {
                this.ZonesOnMap.map((marker) => marker.setOptions({ fillColor, strokeColor }));
            }
            if (map_options & MAP_OPTIONS.ParkingSpaces) {
                this.ParkingSpacesOnMap.map((marker) => marker.setOptions({ fillColor, strokeColor }));
            }
            if (map_options & MAP_OPTIONS.Segments) {
                this.SegmentsOnMap.map((marker) => marker.setOptions({ fillColor, strokeColor }));
            }
        }
    }
    /**
     * Reverts polygon colors & opacity to the initial colors
     * @param {Number} map_options
     */
    revertColors(map_options = MAP_OPTIONS.All) {
        if (map_options & MAP_OPTIONS.Areas) {
            if (this.AreasOnMap.length) {
                this.AreasOnMap.map((marker) => {
                    const { strokeColor, strokeOpacity, fillColor, fillOpacity } = marker.data;
                    marker.setOptions({
                        strokeColor, strokeOpacity, fillColor, fillOpacity
                    });
                });
            }
        }
        if (map_options & MAP_OPTIONS.Zones) {
            if (this.ZonesOnMap.length) {
                this.ZonesOnMap.map((marker) => {
                    const { strokeColor, strokeOpacity, fillColor, fillOpacity } = marker.data;
                    marker.setOptions({
                        strokeColor, strokeOpacity, fillColor, fillOpacity
                    });
                });
            }
        }
        if (map_options & MAP_OPTIONS.ParkingSpaces) {
            if (this.ParkingSpacesOnMap.length) {
                this.ParkingSpacesOnMap.map((marker) => {
                    const { strokeColor, strokeOpacity, fillColor, fillOpacity } = marker.data;
                    marker.setOptions({
                        strokeColor, strokeOpacity, fillColor, fillOpacity
                    });
                });
            }
        }
    }
    /**
     * Caches marker color properties
     * @param {any} marker
     */
    cacheMarkerProperties(marker) {
        const { strokeColor, strokeOpacity, fillColor, fillOpacity } = marker;
        Object.assign(marker.data, {
            strokeColor,
            strokeOpacity,
            fillColor,
            fillOpacity
        });
    }
    /**
     * Finds the markers that are saved to the PageScript object
     */
    fetchMarkers() {
        return PageScript.Markers || PageScript.markers || [];
    }
    /**
     * Deletes polygons (zones, areas, parking spaces or markers) from the map
     * @param {any} arrayOrObject
     */
    destroy(arrayOrObject) {
        if (arrayOrObject === undefined)
            return;
        const eventsToClear = ['click', 'rightclick'];
        if (arrayOrObject instanceof Array) {
            let array = arrayOrObject;
            if (array.length === 0) {
                return array;
            }
            for (let index in array) {
                for (const key of eventsToClear) {
                    google.maps.event.clearListeners(array[index], key);
                }
                array[index].setMap(null);
                delete array[index];
            }
            if (array.length) {
                array.length = 0;
                // @ts-ignore
                array = null;
            }
        }
        else {
            this.destroy(convertObjectToArray(arrayOrObject));
        }
    }
    /**
     * Unloads polygons (zones, areas, parking spaces or markers) from the map
     * @param {any} arrayOrObject polygons on the map
     * @param {Number} options type of unload
     */
    unload(arrayOrObject, options = UNLOAD_OPTIONS.AllListeners) {
        const eventsToClear = [
            'bounds_changed',
            'center_changed',
            'click',
            'dblclick',
            'drag',
            'dragend',
            'dragstart',
            'heading_changed',
            'idle',
            'maptypeid_changed',
            'mousemove',
            'mouseout',
            'mouseover',
            'projection_changed',
            'resize',
            'rightclick',
            'tilesloaded',
            'tilt_changed',
            'zoom_changed',
        ];
        if (arrayOrObject instanceof Array) {
            const array = arrayOrObject;
            if (array.length === 0) {
                return array;
            }
            switch (options) {
                case UNLOAD_OPTIONS.Default:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        for (const key of eventsToClear) {
                            google.maps.event.clearListeners(array[index], key);
                        }
                        array[index].setMap(null);
                        // }
                    }
                    break;
                case UNLOAD_OPTIONS.AllListeners:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        for (const key of eventsToClear) {
                            google.maps.event.clearListeners(array[index], key);
                        }
                        array[index].setMap(null);
                        // }
                    }
                    break;
                case UNLOAD_OPTIONS.None:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        array[index].setMap(null);
                        // }
                    }
                    break;
                default:
                    throw new Error(`MapHelperService.unload(... , ${options}) contains unknown options.`);
            }
        }
        else {
            this.unload(convertObjectToArray(arrayOrObject));
        }
    }
    lerp(from, to, t) {
        const sphericalGeometry = google.maps.geometry.spherical;
        const heading = sphericalGeometry.computeHeading(from, to);
        const distanceToTarget = sphericalGeometry.computeDistanceBetween(from, to);
        const pos = sphericalGeometry.computeOffset(from, distanceToTarget * t, heading);
        const mag = sphericalGeometry.computeDistanceBetween(from, pos);
        return { pos, mag };
    }
    moveTo(from, to, meters) {
        const sphericalGeometry = google.maps.geometry.spherical;
        const heading = sphericalGeometry.computeHeading(from, to);
        const distanceToTarget = sphericalGeometry.computeDistanceBetween(from, to);
        const pos = sphericalGeometry.computeOffset(from, meters, heading);
        const mag = sphericalGeometry.computeDistanceBetween(from, pos);
        return { pos, mag, distanceToTarget, remainingDistance: distanceToTarget - mag };
    }
    clamp01(v) {
        if (v > 1.0) {
            return 1.0;
        }
        else if (v < 0.0) {
            return 0.0;
        }
        return v;
    }
    /**
     * Display other connected devices on the map
     */
    displaySessionsOnMap(options) {
        if (!PageScript)
            throw new Error(`PageScript is not defined!`);
        if (!PageScript.map)
            throw new Error(`PageScript.map is not defined!`);
        if (!isObject(options.sessions)) {
            throw new Error(`ADisplaySessionOnMapOptions.sessions is not an object!`);
        }
        const opt = Object.assign({ lerpSpeed: 0.2, map: PageScript.map }, options);
        return this._displaySessionsOnMap(opt);
    }
    _displaySessionsOnMap(opt) {
        if (opt.interpolate) {
            PageScript.__lerpHandler = new ALoopTimer(() => {
                const sessionMap = PageScript.SessionMarkers;
                const sessionArr = Object.values(sessionMap) || [];
                for (const marker of sessionArr) {
                    if (marker.meta && marker.meta.interpolate && marker.meta.target) {
                        const prevDest = marker.meta.prevTarget;
                        const dest = marker.meta.target;
                        const { avg, nextGpsExpectedAt } = this.calcGpsInterval(marker, opt);
                        const t = nextGpsExpectedAt / avg;
                        const data = this.lerp(prevDest, dest, this.clamp01(1 - t));
                        marker.setPosition(data.pos);
                    }
                }
            }, { loopLifeCycle: "PAGE", timeout: 1000.0 / opt.lerpSpeed }).start();
        }
        Events.on('SessionsChanged', _ => this.updateSessionsOnMap(opt));
        this.updateSessionsOnMap(opt);
    }
    updateSessionsOnMap(opt) {
        for (let session of Sessions) {
            const { NodeName, Gps } = session;
            const hasGps = Gps && Gps.Latitude && Gps.Longitude;
            if (hasGps) {
                const position = { lat: Gps.Latitude, lng: Gps.Longitude };
                if (!opt.sessions.hasOwnProperty(NodeName)) {
                    this.cacheAddMarker(session, position, opt);
                    // this.cacheAddMarker(cache, NodeName, NodeType, session, position, map, opt)
                }
                else {
                    this.cacheUpdateMarker(session, position, opt);
                    // this.cacheUpdateMarker(cache, NodeName, session, position)
                }
            }
            else {
                this.cacheRemoveMarker(session, opt);
            }
        }
        Events.tryInvoke(EVENTS.SESSION_UPDATE_MAP, PageScript.SessionMarkers);
    }
    cacheAddMarker(session, position, opt) {
        try {
            const marker = new google.maps.Marker({
                position: position,
                map: opt.map,
                icon: {
                    url: this.getMapIcon(session.NodeType, ''),
                    scaledSize: new google.maps.Size(48, 48)
                }
            });
            marker.data = session;
            marker.meta = {
                interpolate: session.NodeType !== 'BackOffice' ? opt.interpolate : false,
                gpsTimes: [new Date(session.Gps.GpsTime)],
                target: position,
                prevTarget: position
            };
            opt.sessions[session.NodeName] = marker;
            google.maps.event.addListener(marker, "click", purgatoryService.clickTeamMember);
        }
        catch (err) {
            AError.handle(err);
        }
    }
    /**
     * @deprecated
     */
    cacheAddMarker2(cache, NodeName, NodeType, session, position, map, opt) {
        try {
            const marker = new google.maps.Marker({
                position: position,
                map: map,
                icon: {
                    url: this.getMapIcon(NodeType, ''),
                    scaledSize: new google.maps.Size(48, 48)
                }
            });
            marker.data = session;
            // marker.meta = Object.assign({}, opt || {})
            cache[NodeName] = marker;
            google.maps.event.addListener(marker, "click", purgatoryService.clickTeamMember);
        }
        catch (err) {
            AError.handle(err);
        }
    }
    cacheUpdateMarker(session, position, opt) {
        const marker = opt.sessions[session.NodeName];
        if (marker.meta && marker.meta.interpolate === true) {
            const newGpsTime = new Date(session.Gps?.GpsTime);
            const prevGpsTime = marker.meta.gpsTimes[marker.meta.gpsTimes.length - 1];
            if (prevGpsTime.getTime() !== newGpsTime.getTime()) {
                marker.meta.prevTarget = marker.meta.target;
                marker.meta.target = position;
                marker.meta.gpsTimes.push(newGpsTime);
                if (marker.meta.gpsTimes.length > 5) {
                    // Remove oldest element
                    marker.meta.gpsTimes.shift();
                }
                marker.setPosition(marker.meta.prevTarget);
            }
        }
        else {
            marker.setPosition(position);
        }
        marker.data = session;
    }
    calcGpsInterval(marker, opt) {
        var arr = [];
        // const marker: AMarker = opt.sessions[session.NodeName]
        const gpsTimes = marker.meta?.gpsTimes || [];
        if (gpsTimes.length < 2) {
            return {
                avg: 1000,
                nextGpsExpectedAt: 0
            };
        }
        for (var i = 1; i < gpsTimes.length; i++) {
            arr.push(gpsTimes[i].getTime() - gpsTimes[i - 1].getTime());
        }
        const avg = arr.reduce((a, b) => a + b) / arr.length;
        const nextGpsExpectedAt = Math.max(0, avg - (Date.now() - gpsTimes[gpsTimes.length - 1].getTime()));
        return { avg, nextGpsExpectedAt };
    }
    /**
     * @deprecated
     */
    cacheUpdateMarker2(cache, NodeName, session, position) {
        const marker = cache[NodeName];
        if (marker.meta?.interpolate === true) {
            marker.meta.target = position;
        }
        else {
            marker.setPosition(position);
        }
        marker.data = session;
    }
    cacheRemoveMarker(session, opt) {
        if (!opt.sessions.hasOwnProperty(session.NodeName)) {
            return;
        }
        const marker = opt.sessions[session.NodeName];
        marker.setMap(null);
        delete opt.sessions[session.NodeName];
    }
    /**
     * @deprecated
     */
    cacheRemoveMarker2(cache, NodeName) {
        if (cache && cache.hasOwnProperty(NodeName)) {
            cache[NodeName].setMap(null);
            delete cache[NodeName];
        }
    }
    getColors(detectionData) {
        let FillColor = null;
        let StrokeColor = null;
        const set = (f, o) => {
            if (f !== null && FillColor == null)
                FillColor = f;
            if (o !== null && StrokeColor == null)
                StrokeColor = o;
        };
        let { IsIllegallyParked, HasParkingRight } = detectionData;
        if (HasParkingRight === null) {
            const { fill, stroke } = this.get_legend_grey(false); // AConfig.get('drawing & colors.detections.unknown')
            set(fill, stroke);
        }
        if (HasParkingRight === 0) {
            const { fill, stroke } = this.get_legend_red(false); // AConfig.get('drawing & colors.detections.noParkingRight')
            set(fill, stroke);
        }
        if (IsIllegallyParked) {
            const { stroke } = this.get_legend_brown_outline(); // AConfig.get('drawing & colors.detections.illegallyParked')
            set(null, stroke);
        }
        if (FillColor === null) {
            const { fill, stroke } = this.get_legend_green(false); // AConfig.get('drawing & colors.detections.default')
            set(fill, stroke);
        }
        return { FillColor: FillColor, StrokeColor: StrokeColor };
    }
    /**
     * @deprecated
     */
    getColorsVerification(Verification) {
        // [POST VERIFICATION]
        // GROEN:
        // NoVerificationNeeded
        // NotFined
        // ORANJE:
        // Fined_IllegallyParked
        // Fined_TimeLimitedParkingExperired
        // ROOD:
        // Fined_NoParkingRight
        // Fined_Unknown
        // BLAUW:
        // InProgress
        // GRAY:
        // NotProcessed_%
        if (['NoVerificationNeeded', 'NotFined'].includes(Verification)) {
            return 'green';
        }
        if (['Fined_IllegallyParked', 'Fined_TimeLimitedParkingExperired'].includes(Verification)) {
            return 'orange';
        }
        if (['Fined_NoParkingRight', 'Fined_Unknown'].includes(Verification)) {
            return 'red';
        }
        if (Verification.startsWith('InProgress')) {
            return 'blue';
        }
        if (Verification.startsWith('NotProcessed')) {
            return 'grey';
        }
        console.error('NOT IMPLEMENTED COLOR FOR VERIFICATION: ', Verification);
        return 'black';
    }
    // /**
    //  * Displays a connected node on the map
    //  * @param {Object} cache the stored sessioninfo's
    //  * @param {*} session session to display
    //  * @param {*} map map to display the session on
    //  */
    // displaySessionInfo(cache, session, map) {
    //    if (!session.Gps || (!session.Gps.Latitude && !session.Gps.Longitude)) {
    //       // Remove session from PageScript.SessionInfos if it exists
    //       if (cache[session.NodeName] != undefined) {
    //          cache[session.NodeName].setMap(null)
    //          delete cache[session.NodeName]
    //       }
    //       return
    //    }
    //    const Pointer = { lat: session.Gps.Latitude, lng: session.Gps.Longitude }
    //    if (cache[session.NodeName] != undefined) {
    //       cache[session.NodeName].setPosition(Pointer)
    //       return
    //    }
    //    let Marker = new google.maps.Marker({
    //       position: Pointer,
    //       map: map,
    //       icon: {
    //          url: this.getMapIcon(session.NodeType),
    //          scaledSize: new google.maps.Size(48, 48)
    //       }
    //    })
    //    Object.assign(Marker, {
    //       data: session
    //    })
    //    google.maps.event.addListener(Marker, "click", purgatoryService.clickTeamMember)
    //    cache[session.NodeName] = Marker
    // }
    /**
     * Gets the bounds of the map
     */
    getMapBounds() {
        const output = {};
        const { map } = PageScript;
        if (!map) {
            return null;
        }
        const bounds = map.getBounds();
        const json = bounds.toJSON !== undefined ? bounds.toJSON() : bounds.JSON();
        Object.keys(json).map((key) => {
            if (key.length) {
                const newKey = key[0].toUpperCase() + key.substr(1);
                Object.assign(output, { [newKey]: json[key] });
            }
        });
        return output;
    }
    /**
     * Gets points of a Polygon or Marker
     * @param {any} marker Polygon or Marker
     */
    getPoints(marker) {
        if (marker == null) {
            throw new Error(`AMapHelperService.getPoints unexpected marker is not defined!`);
        }
        const path = marker.getPath();
        const length = path.getLength();
        const lnglat = [];
        for (let i = 0; i < length; i++) {
            const { lat, lng } = path.getAt(i).toJSON();
            lnglat.push([lng, lat]);
        }
        return lnglat;
    }
    calcBoundsByLargestScale() {
        const scales = [
            { visible: PageScript.vSegments, collection: mapHelperService.SegmentsOnMap },
            { visible: PageScript.vAreas, collection: mapHelperService.AreasOnMap },
            { visible: PageScript.vZones, collection: mapHelperService.ZonesOnMap },
            { visible: PageScript.vParkingSpaces, collection: mapHelperService.ParkingSpacesOnMap },
        ];
        var bounds = new google.maps.LatLngBounds();
        for (let { visible, collection } of scales) {
            if (visible) {
                collection.map(polygon => { bounds.extend(getPolygonCenter(polygon)); });
                break;
            }
        }
        return bounds;
    }
    /**
     * Gets the bounds of a polygon
     * @param {any} polygon
     */
    getPolygonBounds(polygon) {
        let paths = polygon.getPaths();
        let bounds = new google.maps.LatLngBounds();
        paths.forEach(function (path) {
            let array = path.getArray();
            for (let i = 0, l = array.length; i < l; i++) {
                bounds.extend(array[i]);
            }
        });
        return bounds;
    }
    async createTempMarker(latLng, timeout = 0) {
        await Loading.waitForPromises(sleep(timeout));
        const markerX = new google.maps.Marker({
            position: latLng,
            map: PageScript.map,
            title: "Example Point",
        });
        console.log('marker', timeout, markerX);
    }
    /**
     * @deprecated
     */
    showMarkerOnPano(mapDisplayFromAndToPointers = false) {
        const detectionPosition = getPolygonCenter(PageScript.Markers[0]);
        const detectionDevicePosition = getPolygonCenter(PageScript.Markers[0]);
        const sv = new google.maps.StreetViewService();
        const { panorama } = PageScript.DeskControl;
        const MAX_DISTANCE = 50;
        // @ts-ignore
        sv.getPanoramaByLocation(detectionDevicePosition, MAX_DISTANCE, (result, status) => {
            if (status == google.maps.StreetViewStatus.OK) {
                if (mapDisplayFromAndToPointers === true) {
                    this.createTempMarker(result.location.latLng, 500);
                    this.createTempMarker(detectionPosition, 2500);
                }
                let heading = google.maps.geometry.spherical.computeHeading(result.location.latLng, detectionPosition);
                panorama.setPosition(result.location.latLng);
                panorama.setPov({ heading: heading, pitch: 0, zoom: 1 });
                panorama.setVisible(true);
            }
            else {
                AError.handle(status);
            }
        });
    }
    getMapIcon(nodeType, defaultValue = null) {
        switch (nodeType) {
            case "ExternalDevice": return '/img/huisstijl/pda_los_72ppi_rgb.png';
            case "Pda": return '/img/huisstijl/pda_los_72ppi_rgb.png';
            case "ScanAuto": return '/img/huisstijl/scanacar_los_72ppi_rgb.png';
            case "ScanScooter": return '/img/huisstijl/scooter_los_72ppi_rgb.png';
            case "ScanSegway": return '/img/huisstijl/segway_los_72ppi_rgb.png';
            case "ScanBike": return '/img/huisstijl/fiets_los_72ppi_rgb.png';
            case "BackOffice": return '/img/huisstijl/centrale_los_72ppi_rgb.png';
            case "CentralVerification": return '/img/huisstijl/centrale_los_72ppi_rgb.png';
            case "ScanCam": return '/img/huisstijl/paal_72ppi_rgb.png';
            default: return defaultValue;
        }
    }
    /**
     * Returns flat array of all zones
     */
    getZones() {
        const output = [];
        Object.keys(this.zones).map(area => {
            Object.keys(this.zones[area]).map(zoneId => {
                const zone = this.zones[area][zoneId];
                output.push(Object.assign({ Area: area }, zone));
            });
        });
        return output;
    }
    /**
     * Returns all the areas sorted by areaname
     */
    getAreaPairs() {
        return Object.keys(this.areas).map((key) => {
            const { AreaId, Name } = this.areas[key];
            return {
                Id: AreaId,
                Name: Name
            };
        }).sort((a, b) => a.Name.localeCompare(b.Name));
    }
    getAreaByName(name) {
        const pairs = this.getAreaPairs();
        for (const area of pairs) {
            if (area.Name === name) {
                return area['id'];
            }
        }
        return -1;
    }
}
