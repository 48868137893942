import { AEngine } from "../AEngine.js";
import { ALL_MAP_OPTIONS, MAP_OPTIONS } from "./AMapStructs.js";
import { asyncMapArray } from "../../utils/tools.js";
import { EVENTS } from "../../services/AEventService.js";
import { AGeoUtils } from "./AGeoUtils.js";
export class AGeoService {
    constructor() {
        this.cached = undefined;
    }
    autoInit() {
        Events.h_once(EVENTS.API_READY, async () => {
            this.defaultCoordinates = await this.fetchDefaultCoordinates();
        });
        Events.hardwire('GeoResponse', (res) => {
            if (this.cached === undefined) {
                this.cached = res;
            }
            else {
                Object.keys(res.Geo).map(key => {
                    if (this.cached.Geo[key] === undefined) {
                        this.cached.Geo[key] = res.Geo[key];
                    }
                });
            }
            Events.tryInvoke('GeoResponseStored');
        });
    }
    async waitForGeoResults(mapOptions) {
        await Promise.all(mapOptions.map((mapOption) => {
            return this.waitForGeoResultSingle(mapOption);
        }));
    }
    async waitForGeoResultSingle(mapOption) {
        const key = this.toKey(mapOption);
        while (!this.cached || this.cached && this.cached.Geo[key] === undefined) {
            await Loading.waitForEvent('GeoResponseStored', true);
        }
    }
    async fetch(opt) {
        const requestData = {};
        const mapOptions = this.mapOptionBitmaskToArray(opt);
        const promise = this.waitForGeoResults(mapOptions);
        for (const mO of mapOptions) {
            requestData[this.toKey(mO)] = null;
        }
        AEngine.log(`Fetching Geo [${mapOptions.map(v => MAP_OPTIONS[v]).join(', ')}]`);
        requestService.send('GeoRequest', requestData);
        await promise;
        return this.cached;
    }
    async fetchBoundingBox() {
        const promise = Loading.waitForEvent('GeoResponse', true);
        requestService.send('GeoRequest', {});
        const result = await promise;
        const bb = result.BoundingBox;
        let bounds = new google.maps.LatLngBounds();
        if (bounds.isEmpty()) {
            bounds.extend({ lng: bb[0][0], lat: bb[0][1] });
            bounds.extend({ lng: bb[1][0], lat: bb[1][1] });
        }
        return bounds;
    }
    async fetchDefaultCoordinates() {
        const bb = await this.fetchBoundingBox();
        return {
            bounds: bb,
            center: bb.getCenter()
        };
    }
    toKey(mapOption) {
        const str = (typeof mapOption === 'number') ? MAP_OPTIONS[mapOption] : mapOption;
        return (str.endsWith('s')) ? str.substring(0, str.length - 1) : str;
    }
    mapOptionBitmaskToArray(mapOption) {
        return ALL_MAP_OPTIONS.filter(opt => mapOption & opt).map((opt) => {
            return opt;
        });
    }
    async load(mapOption, opts) {
        const response = await this.fetch(mapOption);
        const { Box, GeoMap, Type } = response.Geo[this.toKey(MAP_OPTIONS[mapOption])];
        const geoObjects = Object.values(GeoMap);
        const geoInstances = await asyncMapArray(geoObjects, 100, (geoObject) => {
            const { Active, GeoId, Name, Attributes } = geoObject;
            return AGeoUtils.parseAny(geoObject.Geo, {
                Active, GeoId, Name, Attributes
            }, opts);
        }).then((geoInstances) => geoInstances.flat());
        if (opts.skipPaint !== true) {
            geoPaintService.paintAll(geoInstances);
        }
        if (opts.addClickListener === true) {
            // TODO: Implement filtering for other types
            coreMapService.addClickListeners(geoInstances);
        }
        return geoInstances;
    }
    setCachedAttributes(geoType, geoMatchPair) {
        if (!this.cached || !this.cached.Geo.hasOwnProperty(geoType) || geoMatchPair === undefined) {
            return;
        }
        const geoMap = this.cached.Geo[geoType]?.GeoMap;
        if (geoMap === undefined) {
            return;
        }
        geoMatchPair.map(pair => {
            if (geoMap.hasOwnProperty(pair.GeoId)) {
                // Assign the values
                const { Attributes, Confidence, Created, GeoId, GeoVersion, Modified, Name, Timestamp } = pair;
                const toAssign = { Attributes, Confidence, Created, GeoId, GeoVersion, Modified, Name, Timestamp };
                Object.assign(geoMap[pair.GeoId], toAssign);
            }
        });
    }
    async loadSegmentToSplitParkingSpaceMap() {
        const response = await this.fetch(MAP_OPTIONS.SplitParkingSpace);
        const geoObjects = Object.values(response.Geo.SplitParkingSpace.GeoMap);
        const segmentIdToSplitParkingSpaceId = {};
        geoObjects.filter(AGeoUtils.filterInactive).map((geoObject) => {
            const { Active, GeoId, Name, Attributes } = geoObject;
            const { SegmentId, ParkingSpaceId } = Attributes;
            if (SegmentId != null) {
                if (!segmentIdToSplitParkingSpaceId.hasOwnProperty(SegmentId)) {
                    segmentIdToSplitParkingSpaceId[SegmentId] = [];
                }
                const splitParkingSpaceId = GeoId;
                segmentIdToSplitParkingSpaceId[SegmentId].push(splitParkingSpaceId);
            }
        });
        return segmentIdToSplitParkingSpaceId;
    }
    async loadSplitParkingSpaces({ parseToMap }) {
        const response = await this.fetch(MAP_OPTIONS.SplitParkingSpace);
        const { GeoMap } = response.Geo.SplitParkingSpace;
        const geoObjects = Object.values(GeoMap);
        const polyLines = await asyncMapArray(geoObjects, 100, (geoObject) => {
            const { Active, Index, GeoId, Name, Attributes } = geoObject;
            return AGeoUtils.parsePolygon(geoObject.Geo, {
                Active, Index, GeoId, Name, Attributes
            }, { parseToMap });
        });
        return polyLines;
    }
    async loadSegmentToWaySegmentMap() {
        const response = await this.fetch(MAP_OPTIONS.Segment);
        const geoObjects = Object.values(response.Geo.Segment.GeoMap);
        const segToWaySegMap = {};
        geoObjects.filter(AGeoUtils.filterInactive).map((geoObject) => {
            const { GeoId, Name, Attributes } = geoObject;
            const { WaySegmentId, WaySegmentSide } = Attributes;
            const SegmentId = GeoId;
            if (WaySegmentId != null) {
                // if (!segToWaySegMap.hasOwnProperty(SegmentId)) {
                segToWaySegMap[SegmentId] = { WaySegmentId, WaySegmentSide };
                // }
                // segToWaySegMap[WaySegmentId] (SegmentId)
            }
        });
        // waysegment to segment ... segment to parking spaces
        // waysegment - segment: 0.*
        // segment - waysegment: 1.1
        // every segment has exactly 1 waysegment
        return segToWaySegMap;
    }
    async loadWaySegmentToSegmentMap() {
        const response = await this.fetch(MAP_OPTIONS.Segment);
        const geoObjects = Object.values(response.Geo.Segment.GeoMap);
        const waySegmentToSegmentId = {};
        geoObjects.filter(AGeoUtils.filterInactive).map((geoObject) => {
            const { GeoId, Name, Attributes } = geoObject;
            const { WaySegmentId, WaySegmentSide } = Attributes;
            if (WaySegmentId != null) {
                if (!waySegmentToSegmentId.hasOwnProperty(WaySegmentId)) {
                    waySegmentToSegmentId[WaySegmentId] = {};
                }
                waySegmentToSegmentId[WaySegmentId](GeoId);
            }
        });
        // waysegment to segment ... segment to parking spaces
        // waysegment - segment: 0.*
        // segment - waysegment: 1.1
        // every segment has exactly 1 waysegment
        return waySegmentToSegmentId;
    }
}
