const VehicleCountryCodes = {
    "AFG": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AND": "Andorra",
    "RA": "Argentina",
    "AM": "Armenia",
    "AUS": "Australia",
    "A": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BRN": "Bahrain",
    "BD": "Bangladesh",
    "BDS": "Barbados",
    "BY": "Belarus",
    "B": "Belgium",
    "BH": "Belize",
    "DY": "Benin",
    "BOL": "Bolivia",
    "BIH": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BR": "Brazil",
    "BRU": "Brunei",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "RU": "Burundi",
    "K": "Cambodia",
    "CAM": "Cameroon",
    "CDN": "Canada",
    "RCA": "Central African Republic",
    "TCH": "Chad",
    "RCH": "Chile",
    "CO": "Colombia",
    "CGO": "Democratic Republic of the Congo",
    "RCB": "Republic of the Congo",
    "CR": "Costa Rica",
    "CI": "Ivory Coast",
    "HR": "Croatia",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "WD": "Dominica",
    "DOM": "Dominican Republic",
    "EC": "Ecuador",
    "ET": "Egypt",
    "ES": "El Salvador",
    "ER": "Eritrea",
    "EST": "Estonia",
    "SD": "Eswatini",
    "ETH": "Ethiopia",
    "FO": "Faroe Islands",
    "FJI": "Fiji",
    "FIN": "Finland",
    "F": "France",
    "G": "Gabon",
    "WAG": "Gambia",
    "GE": "Georgia",
    "D": "Germany",
    "GH": "Ghana",
    "GBZ": "Gibraltar",
    "GR": "Greece",
    "WG": "Grenada",
    "GCA": "Guatemala",
    "GBG": "Guernsey",
    "RG": "Guinea",
    "GUY": "Guyana",
    "RH": "Haiti",
    "V": "Vatican City",
    "HN": "Honduras",
    "H": "Hungary",
    "IS": "Iceland",
    "IND": "India",
    "RI": "Indonesia",
    "IR": "Iran",
    "IRQ": "Iraq",
    "IRL": "Ireland",
    "GBM": "Isle of Man",
    "IL": "Israel",
    "I": "Italy",
    "JA": "Jamaica",
    "J": "Japan",
    "GBJ": "Jersey",
    "HKJ": "Jordan",
    "KZ": "Kazakhstan",
    "EAK": "Kenya",
    "ROK": "South Korea",
    "KWT": "Kuwait",
    "KG": "Kyrgyzstan",
    "LAO": "Laos",
    "LV": "Latvia",
    "RL": "Lebanon",
    "LS": "Lesotho",
    "LB": "Liberia",
    "LAR": "Libya",
    "FL": "Liechtenstein",
    "LT": "Lithuania",
    "L": "Luxembourg",
    "NMK": "North Macedonia",
    "RM": "Madagascar",
    "MW": "Malawi",
    "MAL": "Malaysia",
    "MV": "Maldives",
    "RMM": "Mali",
    "M": "Malta",
    "RIM": "Mauritania",
    "MS": "Mauritius",
    "MEX": "Mexico",
    "MD": "Moldova",
    "MC": "Monaco",
    "MGL": "Mongolia",
    "MNE": "Montenegro",
    "MA": "Morocco",
    "MOC": "Mozambique",
    "BUR": "Myanmar",
    "NAM": "Namibia",
    "NAU": "Nauru",
    "NEP": "Nepal",
    "NL": "Netherlands",
    "NZ": "New Zealand",
    "NIC": "Nicaragua",
    "RN": "Niger",
    "WAN": "Nigeria",
    "N": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PA": "Panama",
    "PNG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "RP": "Philippines",
    "PL": "Poland",
    "P": "Portugal",
    "Q": "Qatar",
    "RO": "Romania",
    "RUS": "Russia",
    "RWA": "Rwanda",
    "WL": "Saint Lucia",
    "WV": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "RSM": "San Marino",
    "KSA": "Saudi Arabia",
    "SN": "Senegal",
    "SRB": "Serbia",
    "SY": "Seychelles",
    "WAL": "Sierra Leone",
    "SGP": "Singapore",
    "SK": "Slovakia",
    "SLO": "Slovenia",
    "SO": "Somalia",
    "ZA": "South Africa",
    "E": "Spain",
    "CL": "Sri Lanka",
    "SUD": "Sudan",
    "SME": "Suriname",
    "S": "Sweden",
    "CH": "Switzerland",
    "SYR": "Syria",
    "RC": "Taiwan",
    "TJ": "Tajikistan",
    "EAT": "Tanzania",
    "T": "Thailand",
    "TG": "Togo",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "EAU": "Uganda",
    "UA": "Ukraine",
    "UAE": "United Arab Emirates",
    "UK": "United Kingdom",
    "USA": "United States",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "YV": "Venezuela",
    "VN": "Vietnam",
    "BVI": "British Virgin Islands",
    "YAR": "Yemen",
    "Z": "Zambia",
    "ZW": "Zimbabwe"
    // "": "Åland Islands",
    // "": "American Samoa",
    // "": "Angola",
    // "": "Anguilla",
    // "": "Antarctica",
    // "": "Antigua and Barbuda",
    // "": "Aruba",
    // "": "Bermuda",
    // "": "Bhutan",
    // "": "Bonaire\n  Sint Eustatius\n  Saba",
    // "": "Bouvet Island",
    // "": "British Indian Ocean Territory",
    // "": "Cabo Verde",
    // "": "Cayman Islands",
    // "": "China",
    // "": "Christmas Island",
    // "": "Cocos",
    // "": "Comoros",
    // "": "Cook Islands",
    // "": "Curaçao",
    // "": "Djibouti",
    // "": "Equatorial Guinea",
    // "": "Falkland Islands",
    // "": "French Guiana",
    // "": "French Polynesia",
    // "": "French Southern Territories",
    // "": "Greenland",
    // "": "Guadeloupe",
    // "": "Guam",
    // "": "Heard Island and McDonald Islands",
    // "": "Hong Kong",
    // "": "Kiribati",
    // "": "Korea",
    // "": "Macao",
    // "": "Marshall Islands",
    // "": "Martinique",
    // "": "Mayotte",
    // "": "Micronesia",
    // "": "Montserrat",
    // "": "New Caledonia",
    // "": "Niue",
    // "": "Norfolk Island",
    // "": "Northern Mariana Islands",
    // "": "Palau",
    // "": "Palestine, State of",
    // "": "Pitcairn",
    // "": "Puerto Rico",
    // "": "Réunion",
    // "": "Saint Barthélemy",
    // "": "Saint Helena\n  Ascension Island\n  Tristan da Cunha",
    // "": "Saint Kitts and Nevis",
    // "": "Saint Martin",
    // "": "Saint Pierre and Miquelon",
    // "": "Sao Tome and Principe",
    // "": "Sint Maarten",
    // "": "Solomon Islands",
    // "": "South Georgia and the South Sandwich Islands",
    // "": "South Sudan",
    // "": "Svalbard\n  Jan Mayen",
    // "": "Timor",
    // "": "Tokelau",
    // "": "Turks and Caicos Islands",
    // "": "Tuvalu",
    // "": "United States Minor Outlying Islands",
    // "": "Vanuatu",
    // "": "Virgin Islands",
    // "": "Wallis and Futuna",
    // "": "Western Sahara",
};
export const LICENSE_PLATE_COUNTRY_CODES = VehicleCountryCodes;
