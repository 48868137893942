import { ADetectionStatistics, CHART_TYPE } from "./ADetectionStatistics.js";
import { AError } from "./AError.js";
import { AMapHelperService } from "../services/AMapHelperService.js";
import { ARound } from "../utils/tools.js";
import { EVENTS } from "../services/AEventService.js";
import { AEngine } from "../core/AEngine.js";
const mapHelperService = AEngine.get(AMapHelperService);
export class AStatisticsChart {
    constructor(chartOptions) {
        try {
            const { id, title, chartType, statistics, wrap, $wrapParent, animation, roundValues, autoReflow, autoReflowTabs, hideUneccessaryData } = chartOptions;
            this.isShowCalled = false;
            this.id = id;
            this.chartType = chartType;
            this.statisticsSet = this.traverseStatistics({ chartType, statistics });
            this.title = title || this.statisticsSet.root.KeyShort;
            this.didOverrideTitle = (title != undefined);
            this.wrap = wrap || false;
            this.$wrapParent = $wrapParent;
            this.route = [];
            this.chartOptions = {
                animation: animation === false ? false : true
            };
            this.roundValues = roundValues || false; // TODO: Default set to true
            this.autoReflow = autoReflow || true;
            this.autoReflowTabs = autoReflowTabs || true;
            this.hideZeroValues = true;
            this.highlightClickableLabels = true;
            this.hideUneccessaryData = hideUneccessaryData || true;
            /** @type {{ change: any[], load: any[]}} */
            this.events = { 'change': [], 'load': [] };
            this.$wrapper = (this.wrap) ? this.createWrapper() : $('');
            this.onLoad(_ => { });
            this.onChange(_ => this.updateTitle());
            this.translateTitle().catch(AError.handle);
        }
        catch (err) {
            AError.handle(err);
        }
    }
    get statistics() {
        return this.statisticsSet.display;
    }
    set rootStatistics(val) {
        this.statisticsSet.root = val;
    }
    get rootStatistics() {
        return this.statisticsSet.root;
    }
    get chartUnit() {
        return this.statisticsSet.unit || Translate.getCacheFast('detections');
    }
    async translateTitle() {
        this.titlePromise = (this.didOverrideTitle === false) ?
            Loading.waitForPromises(Translate.get(this.title)) : Promise.resolve(this.title);
        this.title = await this.titlePromise;
        this.updateTitle();
    }
    createListeners() {
        let eventName = EVENTS.CONTENT_RESIZE;
        let eventId = null;
        if (this.autoReflow) {
            eventId = Events.on(eventName, () => {
                if (!this.highchart) {
                    if (this.isShowCalled) {
                        Events.off(eventName, eventId);
                    }
                    return;
                }
                this.highchart.reflow();
            });
        }
        if (this.autoReflowTabs && (this.wrap || this.$wrapParent)) {
            const $tabview = $(this.highchart.renderTo).closest('[tabgroup][tabview]');
            const chartEvent = `ACI_TABS_CHANGED->${$tabview.attr('tabgroup')}->${$tabview.attr('tabview')}`;
            Events.on(chartEvent, () => {
                if (this.highchart) {
                    this.highchart.reflow();
                }
            });
        }
    }
    /**
     * Create & show chart with statistics
     */
    async show() {
        try {
            await this.titlePromise;
            await this.createChart(this);
            this.callEvent(this.EVENT_LOAD);
            this.isShowCalled = true;
            this.createListeners();
        }
        catch (err) {
            AError.handle(err);
        }
        return this;
    }
    /**
     * Redraws chart if possible
     */
    reflow() {
        if (!this.autoReflow) {
            return;
        }
        this.forceReflow();
    }
    /**
     * Redraws chart regardless of the settings
     */
    forceReflow() {
        if (!this.highchart) {
            if (this.isShowCalled) {
                throw new Error(`Couldn't reflow highchart!`);
            }
            return;
        }
        this.highchart.reflow();
    }
    /**
     * Tries to set height of chart
     * @param {*} height
     */
    setPrefferedHeight(height) {
        if (!this.chartOptions) {
            this.chartOptions = { height: height, animation: false };
        }
        else {
            this.chartOptions.height = height;
        }
        return this;
    }
    overrideReflow(getPreferredSize) {
        this.getPreferredSize = getPreferredSize;
        return this;
    }
    updateSize() {
        const MIN_WIDTH = 50, MIN_HEIGHT = 50;
        const { width, height } = this.getPreferredSize();
        try {
            this.highchart.setSize(Math.max(MIN_WIDTH, width), Math.max(height, MIN_HEIGHT));
        }
        catch (err) {
            console.error(err);
        }
        return this;
    }
    /**
     * Sets size of chart (Please call AStatisticsChart.show() before using this method)
     * @param {{width: any, height: any}} size
     */
    setSize(size) {
        const { width, height } = Object.assign({
            width: this.highchart.chartWidth,
            height: this.highchart.chartHeight
        }, size || {});
        if (!this.highchart) {
            throw new Error(`AStatisticsChart has not been created yet! Please call AStatisticsChart.show() before using this method`);
        }
        try {
            this.highchart.setSize(width, height);
        }
        catch (err) {
            console.error(err);
        }
    }
    /**
     * Creates wrapper html element for title & chart navigation
     */
    createWrapper() {
        const { $wrapParent, title, id } = this;
        if (!$wrapParent || $wrapParent.length === 0) {
            throw new Error(`Couldn't find $wrapParent in AStatisticsChart`);
        }
        const $wrapper = $(`
            <div class="statistics-container statistics-border hidden">
                <div class="statistics-title hidden">${title}</div>
                <div id="${id}" class="pie-chart"></div>
                <div class="route"></div>
            </div>
        `);
        $wrapParent.append($wrapper);
        return $wrapper;
    }
    /**
     * Check whether statistics has the correct type
     * @param {*} statistics
     */
    isDataInvalid(statistics) {
        return !(statistics instanceof ADetectionStatistics);
    }
    /**
     * Adds event listener for when the chart is moved to a new instance
     * @param {Function} callback
     */
    onLoad(callback) {
        this.events.load.push(callback);
        return this;
    }
    /**
     * Adds event listener for when the chart changes states
     * @param {Function} callback
     */
    onChange(callback) {
        this.events.change.push(callback);
        return this;
    }
    /**
     * Call/Invoke change event
     */
    callEvent(eventIdentifier) {
        let callbacks = [];
        switch (eventIdentifier) {
            case this.EVENT_LOAD:
                callbacks = this.events.load;
                break;
            case this.EVENT_FORWARD:
                callbacks = this.events.change;
                break;
            case this.EVENT_BACKWARD:
                callbacks = this.events.change;
                break;
        }
        callbacks.map(cb => cb({
            type: eventIdentifier,
            chart: this
        }));
        return this;
    }
    /**
     * Change route of data being represented
     * @param {{ key: string, name: string }} args
     * @returns {AStatisticsChart}
     */
    clickRoute({ key, name }) {
        this.route.push({ key, name });
        this.reload();
        this.callEvent(this.EVENT_FORWARD);
        return this;
    }
    /**
     * Navigates backwards to parent chart
     * @returns {AStatisticsChart}
     */
    clickBack(index) {
        const deleteCount = this.route.length - index;
        this.route.splice(index, deleteCount);
        this.reload();
        this.callEvent(this.EVENT_BACKWARD);
        return this;
    }
    /**
     * Checks whether a route exists
     * @param {{ key, name }} routeEntry
     * @returns {boolean} whether the route is possible
     */
    canTraverseRoute(routeEntry) {
        const routeToNavigate = this.route.concat([routeEntry]);
        let statistics = this.rootStatistics;
        for (const { key } of routeToNavigate) {
            if (!statistics.Options.hasOwnProperty(key)) {
                return false;
            }
            statistics = statistics.Options[key];
        }
        return (statistics.Count && Object.keys(statistics.Options).length > 0);
    }
    /**
     * Fetches currently displayed statistics
     */
    getCurrentStatistics() {
        let statistics = this.rootStatistics;
        this.route.map(({ key }) => {
            statistics = statistics.Options[key];
        });
        return statistics;
    }
    /**
     * Reloads data & displays new chart
     * @returns {AStatisticsChart}
     */
    reload() {
        let statistics = this.getCurrentStatistics();
        this.createChart({ statistics });
        return this;
    }
    /**
     * Update the title of the chart with navigation hrefs
     * @returns {AStatisticsChart}
     */
    updateTitle() {
        const $title = this.$container.find(`.route`);
        $title.html('');
        const fullRoute = this.getFullRoute();
        fullRoute.map((name, i) => {
            if (i !== 0)
                $title.append(`/`);
            const isLast = (fullRoute.length - 1) === i;
            const $a = $(`<a class="${isLast ? 'disabled' : ''}">${name}</a>`);
            if (!isLast) {
                $a.click(e => {
                    e.preventDefault();
                    this.clickBack(i);
                });
            }
            $title.append($a);
        });
        return this;
    }
    /**
     * Highlight labels in chart whom have the subcharts
     */
    highlightClickableLinks() {
        const { $container } = this;
        const $clickableLinks = $container.find(`.${this.CLICKABLE_LINK_CLASS} > text`);
        $clickableLinks.css({
            color: 'var(--main-color)',
            fill: 'var(--main-color)'
        });
    }
    /**
     * Disable all zero values in the legend in order to show a clean chart
     */
    hideZeroCountLabels() {
        const { highchart } = this;
        const series = Object.values(highchart.series);
        for (const serie of series) {
            const legendItems = Object.values(serie.data);
            for (const legendItem of legendItems) {
                if (legendItem.y === 0 && legendItem.percentage === 0) {
                    legendItem.update({ visible: false });
                }
            }
        }
    }
    shouldHideOption({ statistics, key }) {
        if (!this.hideUneccessaryData)
            return false;
        if (statistics.Key === 'Verification' && key === 'NoVerificationNeeded') {
            return true;
        }
        if (statistics.Key === 'ParkingRight' && key === 'NoParkingRightNeeded') {
            return true;
        }
        return false;
    }
    calcPieColor(key) {
        if (this.route.length > 0) {
            return undefined;
        }
        // TODO: Centralize the tab configuration
        const tabConfigurations = {
            'Digital': mapHelperService.legend_digital,
            'IllegallyParked': mapHelperService.legend_illegallyparked,
            'ParkingRight': mapHelperService.legend_parkingright,
            'Verification': mapHelperService.legend_verification,
            'DetectionState': mapHelperService.legend_detection_state
        };
        const { calcColor } = tabConfigurations[this.chartType] || {};
        // MapHelper.legend_parkingright.calcColor({ Digital: 'InProgress' })
        if (!calcColor) {
            return undefined;
        }
        const colorSetting = calcColor({ [this.chartType]: key });
        return colorSetting.fill;
    }
    async statisticsToData({ statistics }) {
        const data = await Promise.all(Object.keys(statistics.Options).map(async (key) => {
            const child = statistics.Options[key];
            // This was the code to hide chart data to never be seen!
            // if (this.shouldHideOption({ statistics, child, key })) {
            //     return null
            // }
            const hideOption = this.shouldHideOption({ statistics, key });
            return {
                key,
                name: await Translate.get(child.KeyShort),
                y: this.roundValues ? ARound(child.Count) : child.Count,
                dataLabels: {
                    className: Object.keys(child.Options).length > 0 ? this.CLICKABLE_LINK_CLASS : this.NON_CLICKABLE_LINK_CLASS
                },
                // TODO: Calculate Piechart Color
                // color: this.calcPieColor(key),
                visible: (child.Count > 0) && !hideOption
                // sliced: true,
                // selected: true
            };
        }));
        return data.filter(v => v !== null);
    }
    /**
     * Transforms chart data to highcharts chart
     * @param {{ statistics: any, title?: string }} args
     */
    async createChart({ statistics, title }) {
        const data = await this.statisticsToData({ statistics });
        if (data.length > 0) {
            // @ts-ignore
            data[0].sliced = true;
            // @ts-ignore
            data[0].selected = true;
        }
        if (!title) {
            title = this.getFullRouteText();
        }
        this.highchart = this.instantiateHC(data, title);
        if (this.highlightClickableLabels) {
            this.highlightClickableLinks();
        }
        if (this.hideZeroValues) {
            this.hideZeroCountLabels();
        }
        if (this.wrap) {
            this.removeHiddenClass();
        }
        return this;
    }
    removeHiddenClass() {
        // @ts-ignore
        this.$wrapper.removeClass('hidden');
    }
    copyVisibility(serie, data) {
        serie.data.map((v, i) => {
            data[i].visible = v.visible;
        });
        return data;
    }
    async updateChart(statisticsTotal) {
        this.rootStatistics = this.traverseStatistics({ chartType: this.chartType, statistics: statisticsTotal }).root;
        const statistics = this.getCurrentStatistics();
        const rawData = await this.statisticsToData({ statistics });
        this.highchart.series[0].update({
            data: this.copyVisibility(this.highchart.series[0], rawData)
        });
        if (this.highlightClickableLabels) {
            this.highlightClickableLinks();
        }
    }
    /**
     * Instantiate highcharts object & return it
     * @param {*} data
     */
    instantiateHC(data, title) {
        const { chartOptions } = this;
        return Highcharts.chart(this.id, {
            chart: {
                type: 'pie',
                height: chartOptions.height,
                animation: chartOptions.animation
            },
            title: {
                text: title
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b> {series.name}'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b class="{point.labelClass}">{point.name}</b>: {point.percentage:.1f} %',
                        distance: 10
                    },
                    showInLegend: true
                }
            },
            series: [{
                    cursor: 'pointer',
                    events: {
                        click: ({ point }) => {
                            const { key, name } = point;
                            if (this.canTraverseRoute({ key, name })) {
                                this.clickRoute({ key, name });
                            }
                        }
                    },
                    name: this.chartUnit,
                    colorByPoint: true,
                    data
                }],
            exporting: {
                enabled: false
            }
        });
    }
    /**
     * Traverses through rootStatistics
     * @returns Object containing traverse methods for every CHART_TYPE
     */
    getTraverseMethods() {
        return {
            [CHART_TYPE.OCCUPANCY]: (rootStatistics) => {
                return {
                    root: rootStatistics.Occupancy,
                    display: rootStatistics.Occupancy,
                    unit: Translate.getCacheFast('parking spaces')
                };
            },
            [CHART_TYPE.VISITOR_RATE]: (rootStatistics) => {
                return {
                    route: [{
                            key: 'ParkingRight',
                            name: rootStatistics.ParkingRight.Options.ParkingRight.KeyShort
                        }],
                    root: rootStatistics.ParkingRight,
                    display: rootStatistics.ParkingRight.Options.ParkingRight
                };
            },
            [CHART_TYPE.COMPLIANCY]: (rootStatistics) => {
                return {
                    root: rootStatistics.ParkingRight,
                    display: rootStatistics.ParkingRight
                };
            },
            [CHART_TYPE.COMPLIANCY_VISITOR]: (rootStatistics) => {
                return {
                    root: rootStatistics.ParkingRight,
                    display: rootStatistics.ParkingRight
                };
            },
            [CHART_TYPE.DIGITAL]: (rootStatistics) => {
                return {
                    root: rootStatistics.Digital,
                    display: rootStatistics.Digital
                };
            },
            [CHART_TYPE.ILLEGALY_PARKED]: (rootStatistics) => {
                return {
                    root: rootStatistics.IllegallyParked,
                    display: rootStatistics.IllegallyParked
                };
            },
            [CHART_TYPE.PARKING_RIGHT]: (rootStatistics) => {
                return {
                    root: rootStatistics.ParkingRight,
                    display: rootStatistics.ParkingRight
                };
            },
            [CHART_TYPE.TIME_LIMITED_PARKING]: (rootStatistics) => {
                return {
                    root: rootStatistics.TimeLimitedParking,
                    display: rootStatistics.TimeLimitedParking
                };
            },
            [CHART_TYPE.VERIFICATION]: (rootStatistics) => {
                return {
                    root: rootStatistics.Verification,
                    display: rootStatistics.Verification
                };
            },
            [CHART_TYPE.DETECTION_STATE]: (rootStatistics) => {
                return {
                    route: [{
                            key: 'DetectionState',
                            name: rootStatistics.DetectionState.Options.InProgress.Name
                        }],
                    root: rootStatistics.DetectionState,
                    display: rootStatistics.DetectionState.Options.InProgress
                };
            }
        };
    }
    /**
     * Traverses to the correct data for the chart to display
     * @param {{ chartType: string, statistics: ADetectionStatistics }} args
     * @returns {{ root: any, display: any, route?: [{key: string, name: string}], unit?:string }}
     */
    traverseStatistics({ chartType, statistics }) {
        if (chartType === undefined) {
            return {
                root: statistics,
                display: statistics,
            };
        }
        if (!(statistics instanceof ADetectionStatistics)) {
            throw new Error(`AStatisticsChart expected { statistics: ADetectionStatistics } because chartType is defined!`);
        }
        const traverseMethods = this.getTraverseMethods();
        if (!traverseMethods.hasOwnProperty(chartType)) {
            console.warn(`CHART_TYPE "${chartType}" isn't recognized!`);
        }
        const traverse = traverseMethods[chartType];
        return traverse(statistics);
    }
    /**
     * Returns title of chart with full depth in subcharts if available
     */
    getFullRouteText() {
        return this.getFullRoute().join(' / ');
    }
    /**
     * Returns array of chart with full depth in subcharts if available
     */
    getFullRoute() {
        const fullRoute = [{ name: this.title }].concat(this.route);
        return fullRoute.map(({ name }) => name);
    }
    /**
     * Event type for when a new chart is created
     */
    get EVENT_LOAD() {
        return 'EVENT_LOAD';
    }
    /**
     * Event type for forward navigation in chart
     */
    get EVENT_FORWARD() {
        return 'EVENT_FORWARD';
    }
    /**
     * Event type for backwards navigation in chart
     */
    get EVENT_BACKWARD() {
        return 'EVENT_BACK';
    }
    /**
     * CSS Class for clickable links
     */
    get CLICKABLE_LINK_CLASS() {
        return 'clickableLink';
    }
    /**
     * CSS Class for non clickable links
     */
    get NON_CLICKABLE_LINK_CLASS() {
        return 'nonClickableLink';
    }
    get $container() {
        const $parent = $(`#${this.id}`).parent();
        if ($parent.length !== 1) {
            throw new Error(`Couldn't locate chart container for $('#${this.id}')`);
        }
        return $parent;
    }
}
