import { AError } from "../classes/AError.js";
import { AEngine } from "../core/AEngine.js";
import { TextEncoderLite } from "../core/text-encoder-lite.js";
import { AAlertService, ALERTS, ALERT_BUTTONS, ALERT_TITLES } from "../services/AAlertService.js";
import { ADetectionService } from "../services/ADetectionService.js";
import { AEventService, EVENTS } from "../services/AEventService.js";
import { ALoadingService } from "../services/ALoadingService.js";
import { AMenuHelperService } from "../services/AMenuHelperService.js";
import { APreferenceService } from "../services/APreferenceService.js";
import { ATranslateService } from "../services/ATranslateService.js";
import { assertHasValue } from "./assert.js";
const { Events, Translate } = getLegacyClasses();
export function SetCookie(name, value, days, shareSubdomains) {
    let expires = '';
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "expires=" + date.toUTCString();
    }
    let domain = '';
    if (shareSubdomains === true) {
        const parentDomain = location.host.split('.').splice(1).join('.');
        domain = (parentDomain.endsWith('.scanacar.com')) ? `domain=${parentDomain}` : '';
    }
    const cookie = [
        `${name}=${value}`,
        `SameSite=Strict`,
        `path=/`,
        expires,
        domain
    ].filter(v => v != '').join(';');
    // const cookie = name + "=" + value + "; SameSite=Strict" + expires + "; path=/" + domain
    console.log('cookie', cookie);
    document.cookie = cookie;
}
export function GetCookie(name, fallback = (v) => v) {
    let nameEQ = name + "=";
    let parts = document.cookie.split(';');
    for (let i = 0; i < parts.length; i++) {
        let q = parts[i];
        while (q.charAt(0) == ' ')
            q = q.substring(1, q.length);
        if (q.indexOf(nameEQ) == 0)
            return q.substring(nameEQ.length, q.length);
    }
    return fallback(null);
}
export function checkIfFullScreen() {
    return (window.innerWidth == screen.width && window.innerHeight == screen.height);
}
export function toggleFullScreen(ele) {
    let element = _getEle(ele);
    const isfull = checkIfFullScreen();
    if (isfull) {
        exitFullScreen();
    }
    else {
        requestFullScreen(element);
    }
    return isfull;
}
function requestFullScreen(ele) {
    if (ele.requestFullscreen) {
        ele.requestFullscreen();
    }
    else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen();
    }
    else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
    }
    else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen();
    }
}
function exitFullScreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
        // @ts-ignore
    }
    else if (document.msExitFullscreen) {
        // @ts-ignore
        document.msExitFullscreen();
        // @ts-ignore
    }
    else if (document.mozCancelFullScreen) {
        // @ts-ignore
        document.mozCancelFullScreen();
        // @ts-ignore
    }
    else if (document.webkitExitFullscreen) {
        // @ts-ignore
        document.webkitExitFullscreen();
    }
    else {
        alert("No ExitFullScreen function found!");
    }
}
export function ADeviceIdToName(id) {
    const keys = Object.keys(globalThis.ScanDeviceIds);
    for (let key of keys) {
        if (globalThis.ScanDeviceIds[key] === id) {
            return key;
        }
    }
}
export function getMeta(url) {
    return new Promise((resolve, reject) => {
        try {
            let img = new Image();
            img.addEventListener("load", function () {
                return resolve({
                    width: this.naturalWidth,
                    height: this.naturalHeight
                });
            });
            img.src = url;
        }
        catch (err) {
            return reject(err);
        }
    });
}
export function scaleMetaSize(size, max) {
    let { width, height } = size;
    if (width > height) {
        let w = width / max;
        return {
            width: width / w,
            height: height / w
        };
    }
    else {
        let h = height / max;
        return {
            width: width / h,
            height: height / h
        };
    }
}
export function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}
export function lerp(a, b, t) {
    t = Math.max(Math.min(t, 1), 0);
    return a + (b - a) * t;
}
export function normalizeValue(x, min, max) {
    return (x <= min) ? 0.0 : (x >= max) ? 1.0 : (x - min) / (max - min);
}
export function AHasValue(val) {
    return (!['%', '', ' '].includes(val) && val) ? true : false;
}
/**
 * Converts complex data to formatted human readable text (for ex. tables)
 * @param {*} text input object
 * @param {*} options format params
 */
export function AConvert(text, options) {
    if (text == null)
        return text;
    let functions = Object.assign({
        AFormatDate
    }, options);
    switch (text.constructor.name) {
        case 'Number':
            return text;
        case 'String':
            if (AIsDate(text)) {
                return functions.AFormatDate(new Date(text));
            }
            return text;
        case 'Array':
            return text;
        case 'Object':
            return text;
        case 'Boolean':
            return text.toString();
    }
    console.error(new Error(`Type '${typeof text}' Is Not Implemented Yet!`));
    return text;
}
export function AIsDate(text) {
    if (typeof text !== 'string')
        return false;
    return (text.charAt(4) == '-' && text.charAt(7) == '-' && text.charAt(10) == 'T');
}
export function AFormatNumber(n, invalidValue = '0') {
    if (n === null || n === undefined || Number.isNaN(n))
        return invalidValue;
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '<div class="number-seperator"></div>');
}
export function AFormatDate(d, s = " ", ds = "-", ts = ":", tzs = " ") {
    if (!d)
        return "";
    if (d.getTime() === 0)
        return "";
    let Year = d.getFullYear();
    let Month = d.getMonth() + 1;
    let Day = d.getDate();
    let Hour = d.getHours();
    let Minute = d.getMinutes();
    let Second = d.getSeconds();
    if (Month < 10)
        Month = "0" + Month;
    if (Day < 10)
        Day = "0" + Day;
    if (Hour < 10)
        Hour = "0" + Hour;
    if (Minute < 10)
        Minute = "0" + Minute;
    if (Second < 10)
        Second = "0" + Second;
    return Year + ds + Month + ds + Day + s + Hour + ts + Minute + ts + Second;
}
export function AFormatDateTimezone(d, s = " ", ds = "-", ts = ":", tzs = " ") {
    let Year = d.getFullYear();
    let Month = d.getMonth() + 1;
    let Day = d.getDate();
    let Hour = d.getHours();
    let Minute = d.getMinutes();
    let Second = d.getSeconds();
    if (Month < 10)
        Month = "0" + Month;
    if (Day < 10)
        Day = "0" + Day;
    if (Hour < 10)
        Hour = "0" + Hour;
    if (Minute < 10)
        Minute = "0" + Minute;
    if (Second < 10)
        Second = "0" + Second;
    let FormatedDateTime = Year + ds + Month + ds + Day + s + Hour + ts + Minute + ts + Second;
    if (ds != '_') {
        let TimeZoneOffsetSign = '+';
        let TimeZoneOffset = -d.getTimezoneOffset() / 60;
        if (TimeZoneOffset < 0) {
            TimeZoneOffsetSign = '-';
            TimeZoneOffset = -TimeZoneOffset;
        }
        if (TimeZoneOffset < 10) {
            TimeZoneOffset = '0' + TimeZoneOffset;
        }
        FormatedDateTime += tzs + TimeZoneOffsetSign + TimeZoneOffset;
    }
    return FormatedDateTime;
}
export function AFormatDateFull(d, s = " ", ds = "-", ts = ":", mss = ".", tzs = " ") {
    let Year = d.getFullYear();
    let Month = d.getMonth() + 1;
    let Day = d.getDate();
    let Hour = d.getHours();
    let Minute = d.getMinutes();
    let Second = d.getSeconds();
    let MSecond = d.getMilliseconds();
    if (Month < 10)
        Month = "0" + Month;
    if (Day < 10)
        Day = "0" + Day;
    if (Hour < 10)
        Hour = "0" + Hour;
    if (Minute < 10)
        Minute = "0" + Minute;
    if (Second < 10)
        Second = "0" + Second;
    if (MSecond < 10)
        MSecond = "00" + MSecond;
    else if (MSecond < 100)
        MSecond = "0" + MSecond;
    let FormatedDateTime = Year + ds + Month + ds + Day + s + Hour + ts + Minute + ts + Second + mss + MSecond;
    if (ds != '_') {
        let TimeZoneOffsetSign = '+';
        let TimeZoneOffset = -d.getTimezoneOffset() / 60;
        if (TimeZoneOffset < 0) {
            TimeZoneOffsetSign = '-';
            TimeZoneOffset = -TimeZoneOffset;
        }
        if (TimeZoneOffset < 10) {
            TimeZoneOffset = '0' + TimeZoneOffset;
        }
        FormatedDateTime += tzs + TimeZoneOffsetSign + TimeZoneOffset;
    }
    return FormatedDateTime;
}
export function AFormatIsoDate(d) {
    return AFormatDate(d, 'T', '-', ':', '');
}
export function AFormatIsoDateFull(d) {
    return AFormatDateFull(d, 'T', '-', ':', '.', '');
}
export function AConvertMillisecondsToHM(ms) {
    const seconds = ms / 1000;
    const ss = Math.floor(seconds);
    const hh = Math.floor(seconds / 3600);
    const mm = Math.floor(seconds / 60);
    return (`
        ${(hh).toString().padStart(2, '0')}:${(mm % 60).toString().padStart(2, '0')}:${(ss % 60).toString().padStart(2, '0')}
    `).trim();
}
export function AStripUrl(cssurl) {
    if (!cssurl.includes('url(')) {
        console.warn(`Couldn't strip url: ${cssurl}`);
        return cssurl;
    }
    if (cssurl.startsWith('url("')) {
        return cssurl.substring(5, cssurl.length - 2);
    }
    else {
        return cssurl.substring(4, cssurl.length - 1);
    }
}
export function AInputDate(d) {
    let yyyy = d.getFullYear();
    let mm = (d.getMonth() + 1);
    let dd = d.getDate();
    if (mm < 10)
        mm = '0' + mm;
    if (dd < 10)
        dd = '0' + dd;
    return yyyy + "-" + mm + "-" + dd;
}
export function AInputTime(d) {
    let hh = d.getHours();
    let mm = d.getMinutes();
    if (hh < 10)
        hh = '0' + hh;
    if (mm < 10)
        mm = '0' + mm;
    return hh + ":" + mm;
}
export function AInputSeconds(d) {
    const seconds = d.getSeconds();
    return seconds < 10 ? '0' + seconds : seconds;
}
export function AInputCleanDateTime(d) {
    return `${AInputDate(d)} ${AInputTime(d)}`;
}
export function AInputDateTime(d) {
    return `${AInputDate(d)} ${AInputTime(d)}:${AInputSeconds(d)}`;
}
export function APrepareMysqlDate(d) {
    return AInputDateTime(d).replace(/-/g, '').replace(/ /g, '').replace(/:/g, '');
}
export function ACombineDateTime(date, time) {
    return new Date(date + ' ' + time + ':00');
}
export function ARound(val, decimals = 0) {
    const pow = Math.pow(10, decimals); // ?
    return Math.round(val * pow) / pow;
}
export function CreateUtfDownloadUrl(str, type) {
    var blob = new Blob([str], { type: type });
    return window.URL.createObjectURL(blob);
}
export function APascal(input) {
    return input[0].toUpperCase() + input.substr(1);
}
export function ACamel(input) {
    return input[0].toLowerCase() + input.substr(1);
}
export function AStickyReportTable($scrollParent, $table) {
    $scrollParent.html('');
    $scrollParent.append($table);
    $scrollParent.scrollTop(0);
    $table.floatThead();
}
export function AArrayDiff(a1, a2) {
    let a = [];
    let diff = [];
    for (let i = 0; i < a1.length; i++) {
        // @ts-ignore
        a[a1[i]] = true;
    }
    for (let i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        }
        else {
            // @ts-ignore
            a[a2[i]] = true;
        }
    }
    for (let k in a) {
        // @ts-ignore
        diff.push(k);
    }
    return diff;
}
export function AResizableTable($table) {
    const $ths = $table.find('thead tr > th');
    const readColumnSizes = ($ths) => { const c = []; $ths.each((i, th) => c[i] = $(th).width()); return c; };
    const { syncGrips, tables } = $table.colResizable({
        columnSizes: readColumnSizes($ths),
        // resizeMode: 'flex',
        minWidth: 5
    });
    for (const $table of tables) {
        syncGrips($table);
    }
}
export function createArray(length, defaultValue = undefined) {
    let output = [];
    if (typeof defaultValue === 'function') {
        for (let i = 0; i < length; i++) {
            output.push(defaultValue());
        }
    }
    else {
        for (let i = 0; i < length; i++) {
            output.push(defaultValue);
        }
    }
    return output;
}
/**
 * Implementation of Bryntum Grid
 * For Event handling see: https://bryntum.com/docs/scheduler/#Grid/data/ColumnStore#event-change
 * @param {*} gridOptions
 */
export function AShowTable(gridOptions) {
    const $displayOnce = $('.display-once');
    const $bryntum = $(`#${gridOptions.appendTo}`);
    $displayOnce.remove();
    $bryntum.removeClass('hidden');
    $bryntum.html('');
    const $export = $('.footer.aci #export');
    if ($export.length) {
        $export.removeAttr('disabled');
        if ($export.attr('exportListener') !== 'added') {
            $export.attr('exportListener', 'added');
            $export.on('click', (e) => {
                userActionService.logAction('USER', 'EXPORT TABLE', FilterManager.save({ cacheFilters: false }));
                const exporter = new bryntum.grid.TableExporter({ target: PageScript.grid }); //({ target: PageScript.grid })
                const { columns, rows } = exporter.export();
                // TODO: Optimize export
                const csv = [];
                csv.push(columns.map(({ value }) => (value instanceof Date) ? AFormatIsoDate(value) : value).join(','));
                for (const row of rows) {
                    csv.push(row.map((value) => {
                        if (value instanceof Date) {
                            return AFormatIsoDate(value);
                        }
                        if (value != null && value.hasOwnProperty('className') && value.className == 'b-percent-bar-outer') {
                            try {
                                return value.children[0].style.width;
                            }
                            catch (err) {
                                console.error(err);
                                return '%?';
                            }
                        }
                        let strOut = String(value).replace(/<\/?[^>]+(>|$)/g, '');
                        if (strOut.includes(',')) {
                            strOut = `"${strOut.replace(/"/g, `""`)}"`;
                        }
                        if (strOut == 'null') {
                            strOut = '';
                        }
                        return strOut;
                    }).join(','));
                }
                const title = document.title.replace(/[^a-zA-Z ]/g, '').replace(/\s+/g, ' ');
                const dateStamp = AInputDate(new Date()).replace(/\s/, '-');
                const filename = `${title} ${dateStamp}.csv`;
                // const encoded = encodeURI(`data:application/csv;base64,${base64EncodingUTF8(csv.join('\r\n'))}`)
                const encoded = encodeURI(`data:text/csv;charset=utf-8,${csv.join('\r\n')}`);
                const link = document.createElement("a");
                link.setAttribute("href", encoded);
                link.setAttribute("download", filename);
                link.innerHTML = "Click Here to download";
                document.body.appendChild(link); // Required for FF
                link.click();
                link.remove();
            });
        }
    }
    const menuHelperService = AEngine.getOrCreateInstance(AMenuHelperService);
    const bryntumGridOptions = mergeDeep({}, {
        aci: {
            updateCount: true,
            showLimit: false,
            resizeToFit: true,
            flex: undefined,
            overrideFooterText: undefined
        },
        features: {
            filter: true,
            quickFind: true,
            cellEdit: {
                disabled: true
            },
            // API 4.0
            cellMenu: {
                items: {
                    removeRow: false,
                    copyText: {
                        text: Translate.getCacheFast('copy text'),
                        icon: 'b-fa b-fa-copy',
                        cls: 'b-seperator color',
                        name: 'custom',
                        onItem: (obj) => copyToClipboard(obj.targetElement.innerText)
                    },
                    showInPopup: {
                        text: Translate.getCacheFast('show in popup window'),
                        icon: 'b-fa b-fa-copy',
                        cls: 'b-seperator color',
                        name: 'custom2',
                        onItem: (obj) => {
                            const tryParseJson = (str) => { try {
                                return JSON.stringify(JSON.parse(str), null, 4);
                            }
                            catch (err) {
                                return str;
                            } };
                            Alerts.show({
                                title: ALERT_TITLES.Info,
                                buttons: ALERT_BUTTONS.ok,
                                type: ALERTS.Large,
                                content: `<code style="color: var(--main-color) !important;">${tryParseJson(obj.targetElement.innerText)}</code>`
                            });
                        }
                    }
                },
                processItems({ items, column, record }) {
                    // Add a custom item to certain records
                    if (record.DetectionId && record.DetectionDeviceId) {
                        items.lookup = {
                            text: Translate.getCacheFast('show on map'),
                            icon: 'fa fa-search',
                            cls: 'b-seperator color',
                            name: 'customLookup',
                            onItem: (obj) => {
                                const { DetectionId, DetectionDeviceId, DetectionTime } = obj.record;
                                if (gridOptions.aci?.isModal) {
                                    Alerts.closeAllActiveModals();
                                }
                                // menuHelperService.pressMenuItem('_mapsearch', { DetectionId, DetectionDeviceId, DetectionTime })
                                detectionService.showOnMap({ DetectionId, DetectionDeviceId, DetectionTime }).catch(AError.handle);
                            }
                        };
                        items.history = {
                            text: Translate.getCacheFast('show history'),
                            icon: 'b-fa b-fa-history',
                            cls: 'b-seperator color',
                            name: 'custom',
                            onItem: (obj) => {
                                const detectionService = AEngine.getOrCreateInstance(ADetectionService), Alerts = AEngine.getOrCreateInstance(AAlertService);
                                const { DetectionId, DetectionDeviceId } = obj.record;
                                if (!DetectionId || !DetectionDeviceId) {
                                    return Alerts.noResults();
                                }
                                detectionService.findHistory({
                                    DetectionDeviceId,
                                    DetectionId
                                });
                            }
                        };
                    }
                }
            }
        },
    }, gridOptions);
    if (bryntumGridOptions.aci.flex !== undefined) {
        bryntumGridOptions.columns = bryntumGridOptions.columns.map(c => {
            c.flex = bryntumGridOptions.aci.flex;
            return c;
        });
    }
    else if (bryntumGridOptions.aci.columnWidth !== undefined) {
        bryntumGridOptions.columns = bryntumGridOptions.columns.map(c => {
            c.width = bryntumGridOptions.aci.columnWidth;
            return c;
        });
    }
    const $count = $('#count.text > span');
    if ($count.length && bryntumGridOptions.aci.updateCount === true) {
        const length = bryntumGridOptions.store
            ? bryntumGridOptions.store._data.length
            : bryntumGridOptions.data.length;
        const showLimit = bryntumGridOptions.aci.showLimit;
        const limit = globalThis.FilterSettings.Limit;
        let inputText = '';
        if (bryntumGridOptions.aci.overrideFooterText) {
            console.warn('// TODO: Implement translation & allow distinction between span & entire text block, also make footer.count column width wider');
            inputText = bryntumGridOptions.aci.overrideFooterText({
                length,
                showLimit,
                limit
            });
            $count.closest('#count').text(inputText);
        }
        else {
            inputText = (bryntumGridOptions.aci.showLimit === true) ? `${length} / ${limit}` : length;
            $count.text(inputText);
        }
    }
    const grid = new bryntum.grid.Grid(bryntumGridOptions);
    const KEY_COLUMNS = (`${_.getUser().User}->GC->${routeService.url.full}`);
    const initialColumns = grid.columns.records.map(r => r.field);
    // Load column order from past.
    const preferenceService = AEngine.getOrCreateInstance(APreferenceService);
    const columnPrefs = preferenceService.get(KEY_COLUMNS);
    if (columnPrefs) {
        const records = grid.columns.records.filter(r => initialColumns.includes(r.field));
        const removedArray = grid.columns.remove(records).sort((a, b) => {
            const indexA = columnPrefs.indexOf(a.field);
            const indexB = columnPrefs.indexOf(b.field);
            return (indexA < indexB) ? -1 : (indexA > indexB) ? 1 : 0;
        });
        for (const t of removedArray.reverse()) {
            grid.columns.insert(0, t);
        }
    }
    // Save column order until page unload.
    const defaultColumns = grid.columns.records.map(r => r.field);
    // Save order of table columns
    grid.on({
        horizontalscroll(event) {
            if (PageScript && PageScript.grid) {
                setTimeout(_ => {
                    if (PageScript && PageScript.grid) {
                        const currentColumns = PageScript.grid.columns.records.map(r => r.field);
                        const json1 = JSON.stringify(defaultColumns);
                        const json2 = JSON.stringify(currentColumns);
                        if (json1.localeCompare(json2) !== 0) {
                            preferenceService.set(KEY_COLUMNS, currentColumns);
                        }
                    }
                }, 500);
            }
        },
        renderrows(event) {
            const $count = $('#count.text > span');
            if ($count.length && bryntumGridOptions.aci.updateCount === true) {
                const length = grid.store.records.length;
                const showLimit = bryntumGridOptions.aci.showLimit;
                const limit = globalThis.FilterSettings.Limit;
                let inputText = '';
                if (bryntumGridOptions.aci.overrideFooterText) {
                    inputText = bryntumGridOptions.aci.overrideFooterText({
                        length,
                        showLimit,
                        limit
                    });
                }
                else {
                    inputText = (bryntumGridOptions.aci.showLimit === true) ? `${length} / ${limit}` : length;
                }
                $count.text(inputText);
            }
        }
    });
    if (bryntumGridOptions.aci.resizeToFit === true) {
        if ($(grid.appendTo).closest('[tabview]').length > 0) {
            _autoResizeGridToFitTabs(grid);
        }
        AResizeToFit(grid);
    }
    return grid;
}
export function AResizeToFit(grid) {
    let resizeExceptions = [];
    grid.columns.visibleColumns.map(t => {
        try {
            if (!t.originalData.hasOwnProperty('width')) {
                t.resizeToFitContent();
            }
        }
        catch (err) {
            resizeExceptions.push(err);
        }
    });
    if (resizeExceptions.length > 0) {
        if (resizeExceptions.length === 1) {
            console.warn(`Couldn't resize column! ${resizeExceptions[0].toString()}`);
        }
        else {
            const logs = resizeExceptions.map(err => err.toString()).join('\r\n');
            console.warn(`Couldn't resize column ${resizeExceptions.length} times! \r\n${logs}`);
        }
    }
}
export function AGetGridRecord(id) {
    if (!PageScript) {
        throw new Error(`Pagescript is not defined!`);
    }
    if (!PageScript.grid) {
        throw new Error(`Pagescript.Grid is not defined!`);
    }
    const element = document.querySelector(`#${id}`);
    return PageScript.grid.getRecordFromElement(element);
}
export function appendResponseRows(response, names) {
    for (let name of names) {
        appendResponseRow(response, name);
    }
    return response;
}
export function appendResponseRow(response, nameRow) {
    response.Columns.push(nameRow);
    response.ColumnsTranslated.push(nameRow);
    response.Rows = response.Rows.map(row => {
        row.push(nameRow);
        return row;
    });
}
export function prependResponseRow(response, nameRow) {
    response.Columns.unshift(nameRow);
    response.ColumnsTranslated.unshift(nameRow);
    response.Rows = response.Rows.map(row => {
        row.unshift(nameRow);
        return row;
    });
}
export function AConvertToGridColumnsTuple(result, columnOptions) {
    const response = {
        Columns: [],
        ColumnsTranslated: [],
        Rows: []
    };
    if (!result || result.length === 0) {
        return response;
    }
    const [data, statistics] = result[0];
    const additionalSelects = Object.keys(data);
}
export async function AGridColumns(columnOptions) {
    const cols = Object.keys(columnOptions);
    const colsTranslated = await Translate.get(cols);
    let output = cols.map(col => {
        return Object.assign({
            field: col,
            text: colsTranslated[col]
        }, columnOptions[col]);
    });
    return output;
}
export function AGridData(data, map, options) {
    const { makeCopy } = options || {};
    if (makeCopy === true) {
        data = JSON.parse(JSON.stringify(data));
    }
    return data.map(d => {
        Object.keys(map).map(key => {
            d[key] = map[key](d[key]);
        });
        return d;
    });
}
export function AConvertToGridColumns(response, columnOptions) {
    const { Columns, ColumnsTranslated } = response;
    let output = [];
    const options = Object.assign({}, columnOptions);
    if (Object.keys(options).length > 0) {
        let editorFlag = false;
        for (let i = 0; i < Columns.length; i++) {
            const currentColumn = Columns[i];
            const columnToInsert = {
                field: Columns[i],
                text: ColumnsTranslated[i]
            };
            if (options.hasOwnProperty(currentColumn)) {
                if (options.hasOwnProperty('editor')) {
                    editorFlag = true;
                }
                Object.assign(columnToInsert, options[currentColumn]);
            }
            output.push(columnToInsert);
        }
        if (editorFlag) {
            output = output.map((column) => {
                if (!column.hasOwnProperty('editor')) {
                    column.editor = false;
                }
                return column;
            });
        }
    }
    else {
        for (let i = 0; i < Columns.length; i++) {
            output.push({
                field: Columns[i],
                text: ColumnsTranslated[i]
            });
        }
    }
    return output;
}
export function AConvertToGridData(response, options) {
    const { Columns, Rows } = response;
    const output = [];
    const columns = Object.assign({}, options);
    const formatIndexes = {};
    // Format display text
    Object.keys(columns).map((columnName) => {
        for (let i = 0; i < Columns.length; i++) {
            if (Columns[i] === columnName) {
                formatIndexes[i] = columns[columnName];
            }
        }
    });
    for (let i = 0; i < Rows.length; i++) {
        const obj = {};
        for (let x = 0; x < Columns.length; x++) {
            obj[Columns[x]] = Rows[i][x];
            if (formatIndexes.hasOwnProperty(x)) {
                obj[Columns[x]] = formatIndexes[x](Rows[i][x]);
            }
        }
        output.push(obj);
    }
    return output;
}
export async function AConvertToGridDataAsync(response, options) {
    const { Columns, Rows } = response;
    const output = [];
    const columns = Object.assign({}, options);
    const formatIndexes = {};
    // Format display text
    Object.keys(columns).map((columnName) => {
        for (let i = 0; i < Columns.length; i++) {
            if (Columns[i] === columnName) {
                formatIndexes[i] = columns[columnName];
            }
        }
    });
    for (let i = 0; i < Rows.length; i++) {
        const obj = {};
        for (let x = 0; x < Columns.length; x++) {
            obj[Columns[x]] = Rows[i][x];
            if (formatIndexes.hasOwnProperty(x)) {
                obj[Columns[x]] = await Promise.resolve().then(_ => formatIndexes[x](Rows[i][x]));
            }
        }
        output.push(obj);
    }
    return output;
}
export function AGetGridIds(id, keys) {
    const $row = $(`[data-id="${id}"]`);
    const output = {};
    keys.map((key) => {
        output[key] = $row.find(`[data-column="${key}"]`).text().trim();
    });
    return output;
}
export function AGetGridDetectionIds(id) {
    const $row = $(`#table-bryntum [data-id="${id}"]`);
    return {
        DetectionId: $row.find('[data-column="DetectionId"]').text().trim(),
        DetectionDeviceId: $row.find('[data-column="DetectionDeviceId"]').text().trim()
    };
}
export function base64EncodingUTF8(str) {
    // @ts-ignore
    let encoded = new TextEncoderLite('utf-8').encode(str);
    let b64Encoded = "77u/" + _arrayBufferToBase64(encoded);
    return b64Encoded;
}
export function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}
export function AUrlEncodedImageFromBase64(Base64) {
    if (Base64 == null || Base64.length == 0) {
        return "";
    }
    if (Base64.substr(0, 5) == "data:") {
        return Base64;
    }
    switch (Base64.substr(0, 2)) {
        case "/9": return "data:image/jpg;base64," + Base64;
        case "Qk": return "data:image/bmp;base64," + Base64;
        case "iV": return "data:image/png;base64," + Base64;
    }
    return "data:image/unknown;base64," + Base64;
}
export function ADurationToSeconds(text) {
    const parts = text.split(' ');
    if (parts.length < 2) {
        throw new Error(`Need atleast 2 parts for a duration!`);
    }
    const key = parts[1].toLowerCase().endsWith('s') ? parts[1].toLowerCase() : parts[1].toLowerCase() + 's';
    switch (key) {
        case 'microseconds':
            return parseFloat(parts[0]) / 1000000;
        case 'milliseconds':
            return parseFloat(parts[0]) / 1000;
        case 'seconds':
            return parseFloat(parts[0]);
        case 'minutes':
            return parseFloat(parts[0]) * 60;
        case 'hours':
            return parseFloat(parts[0]) * 3600;
        case 'days':
            return parseFloat(parts[0]) * 3600 * 24;
        case 'weeks':
            return parseFloat(parts[0]) * 3600 * 24 * 7;
        default:
            return parseFloat(parts[0]);
    }
}
export function AGetCssRule(Selector, Key) {
    try {
        let foundCss = null;
        // @ts-ignore
        for (let css of document.styleSheets) {
            if (css.href && css.href.endsWith(`/css/style.css`)) {
                foundCss = css;
                break;
            }
        }
        let rules;
        try {
            rules = foundCss.cssRules || foundCss.rules;
        }
        catch (err) {
            console.error(err);
        }
        let attributes = {};
        for (let r = 0; r < rules.length; r++) {
            let text = rules[r].cssText;
            if (text) {
                let rule = text.split("{");
                if (rule && rule.length == 2) {
                    let selectors = rule[0].split(",");
                    for (let i = 0; i < selectors.length; i++) {
                        if (selectors[i].trim() == Selector) {
                            let data = rule[1].trim();
                            if (data.length && data[data.length - 1] == "}") {
                                data = data.substr(0, data.length - 1);
                            }
                            let items = data.split(";");
                            for (let j = 0; j < items.length; j++) {
                                let attribute = items[j].split(":");
                                if (attribute.length >= 2) {
                                    attributes[attribute.shift().trim()] = attribute.join(":").trim();
                                }
                            }
                        }
                    }
                }
            }
        }
        if (Key) {
            return attributes[Key];
        }
        return attributes;
    }
    catch (e) {
        console.error(e);
        return null;
    }
}
export function MakePercentageColorGent(Value) {
    if (Value <= 0.)
        return "rgb(255,0,0)";
    if (Value >= 1.)
        return "rgb(0,200,0)";
    Value *= 2;
    if (Value <= 1.) {
        let R = 1.;
        let G = Value;
        return "rgb(0," + Math.floor(R * 200.) + "," + Math.floor(G * 200.) + ")";
    }
    else {
        let R = 2. - Value;
        let G = 1.;
        return "rgb(0," + Math.floor(R * 200.) + "," + Math.floor(G * 200.) + ")";
    }
}
export function MakePercentageColor(Value) {
    Value = 1. - Value;
    if (Value <= 0.)
        return "rgb(0,255,0)";
    if (Value >= 1.)
        return "rgb(255,0,0)";
    Value *= 2;
    if (Value <= 1.) {
        let G = 1.;
        let R = Value;
        let Sum = Math.sqrt(G + R);
        G /= Sum;
        R /= Sum;
        return "rgb(" + Math.floor(R * 255.) + "," + Math.floor(G * 255.) + ",0)";
    }
    else {
        let G = 2. - Value;
        let R = 1.;
        let Sum = Math.sqrt(G + R);
        G /= Sum;
        R /= Sum;
        return "rgb(" + Math.floor(R * 255.) + "," + Math.floor(G * 255.) + ",0)";
    }
}
export function hoursToHHMMSS(input) {
    return secondsToHHMMSS(input * 3600);
}
export function secondsToHHMMSS(input) {
    var sec_num = parseInt(input, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
}
export function addTabListeners($tabs) {
    $tabs.removeWhiteSpaces();
    const $children = $tabs.find('.aci-tab');
    $children.each((i, tab) => {
        const $singleTab = $(tab);
        $singleTab.click(e => {
            $children.removeClass('active');
            $singleTab.addClass('active');
            const tabgroup = $tabs.attr('tabgroup');
            const tabview = $singleTab.attr('tab');
            $(`[tabgroup="${tabgroup}"][tabview]`).hide();
            const $tabToView = $(`[tabgroup="${tabgroup}"][tabview="${tabview}"]`);
            $tabToView.show();
            $tabs.trigger('ACI_TABS_CHANGED', { tabview });
            Events.tryInvoke(`ACI_TABS_CHANGED->${tabgroup}`, { tabgroup, tabview, $tabview: $tabToView });
            Events.tryInvoke(`ACI_TABS_CHANGED->${tabgroup}->${tabview}`, { tabgroup, tabview, $tabview: $tabToView });
        });
    });
    const $found = $tabs.find('.aci-tab.active');
    $found.trigger('click');
}
export function addTabListenersFind($container) {
    const $aciTabs = $container.find('.aci-tabs');
    if ($aciTabs.length > 0) {
        $(`[tabview]`).hide();
        $aciTabs.each((i, tabs) => {
            addTabListeners($(tabs));
        });
    }
}
export function autoReflowChart(chart) {
    assertHasValue(chart, `No chart found for autoReflow!`);
    assertHasValue(chart.renderTo, `Chart.renderTo is not defined for autoReflow!`);
    try {
        _autoReflowChart(chart);
        _autoReflowChartTabs(chart);
    }
    catch (err) {
        console.error(err);
    }
    return chart;
}
export function _autoReflowChart(chart) {
    let eventName = EVENTS.CONTENT_RESIZE;
    let eventId = null;
    eventId = Events.on(eventName, () => {
        if (!chart) {
            console.warn(`Chart not found, disabling autoReflow`);
            Events.off(eventName, eventId);
            return;
        }
        chart.reflow();
    });
}
export function _autoReflowChartTabs(chart) {
    const $tabview = $(chart.renderTo).closest('[tabgroup][tabview]');
    const eventName = `ACI_TABS_CHANGED->${$tabview.attr('tabgroup')}->${$tabview.attr('tabview')}`;
    let eventId = null;
    eventId = Events.on(eventName, ({ tabgroup, tabview }) => {
        if (!chart) {
            console.warn(`Chart not found, disabling autoReflow [tabgroup=${tabgroup}][tabview=${tabview}]`);
            Events.off(eventName, eventId);
            return;
        }
        chart.reflow();
    });
}
// TODO: See if autoresize still functions & doesnt become annoying everytime you switch tab
export function _autoResizeGridToFitTabs(grid) {
    const $tabview = $(grid.appendTo).closest('[tabgroup][tabview]');
    const eventName = `ACI_TABS_CHANGED->${$tabview.attr('tabgroup')}->${$tabview.attr('tabview')}`;
    let eventId = null;
    eventId = Events.once(eventName, ({ tabgroup, tabview }) => {
        if (!grid) {
            console.warn(`Grid not found, disabling autoResizeToFit [tabgroup=${tabgroup}][tabview=${tabview}]`);
            Events.off(eventName, eventId);
            return;
        }
        AResizeToFit(grid);
    });
}
export function getLegacyClasses() {
    return {
        Alerts: AEngine.getOrCreateInstance(AAlertService),
        Loading: AEngine.getOrCreateInstance(ALoadingService),
        Events: AEngine.getOrCreateInstance(AEventService),
        // FilterManager: AEngine.getOrCreateInstance(AFilterService),
        Translate: AEngine.getOrCreateInstance(ATranslateService)
    };
}
function loadTreeDropDownFilters({ $ddw, $dd, $title }) {
    const id = $ddw.attr('id');
    const settings = FilterSettings[id];
    if (settings) {
        $dd.find('input[type="checkbox"]').toArray().map(c => $(c)).map($c => $c.prop('checked', false));
        $dd.find('[unificationindex]').toArray().map(c => $(c)).map($c => {
            const unificationindex = parseInt($c.attr('unificationindex'));
            if (settings.includes(unificationindex)) {
                $c.prop('checked', true);
                $c.trigger('change');
            }
        });
        updateTreeDropDownTitle({ $ddw, $dd, $title });
    }
}
function updateTreeDropDownTitle({ $ddw, $dd, $title }) {
    const findSelectedGroupsRec = ($ul) => {
        return $ul.find('> li > input[type="checkbox"]:checked, > li > input[type="checkbox"].is-indeterminate')
            .toArray()
            .map(c => $(c))
            .map($c => ($c.is('.is-indeterminate')) ? findSelectedGroupsRec($c.parent().find('ul').first()) : $c.next().text().trim())
            .join(', ');
    };
    $ddw.removeClass('is-error');
    let title = '';
    if ($dd.find('input[type="checkbox"]:not(:checked)').length === 0) {
        title = Translate.getCacheFast('all');
    }
    else if ($dd.find('input[type="checkbox"]:checked').length === 0) {
        title = Translate.getCacheFast('none');
        $ddw.addClass('is-error');
    }
    else {
        title = findSelectedGroupsRec($ddw.find('ul').first()) || '...';
    }
    $title.text(title);
}
/**
 * @returns {Promise<{ $ddw: any, $dd: any }>}
 */
export async function generateTreeDropdown(querySelector, unification, options) {
    const opt = Object.assign({ loadCache: true }, options || {});
    let i = Date.now();
    // @ts-ignore
    const getUid = () => querySelector.replace('#', '').toString(16) + '-' + (i++).toString(16);
    const rec = ({ Options }) => {
        let html = [];
        Object.keys(Options).map(k => {
            const subOption = Options[k];
            const uid = getUid();
            if (Object.keys(subOption.Options).length) {
                html.push(`
               <li class="tree-item collapsed">
                  <div class="accordion"><i class="fa-solid fa-plus"></i></div>
                  <input id="${uid}" type="checkbox" checked="checked">
                  <label for="${uid}">${subOption._title}</label>
                  <ul>${rec(subOption)}</ul>
               </li>
            `);
            }
            else {
                if (subOption.FirstIndex !== subOption.LastIndex) {
                    console.log(subOption);
                    throw new Error(`Expected Unification FirstIndex & LastIndex to be identical because it this subOption doesn't have children!`);
                }
                html.push(`
               <li>
                  <input id="${uid}" unificationindex="${subOption.FirstIndex}" type="checkbox" checked="checked"> <label for="${uid}">${subOption._title}</label>
               </li>
            `);
            }
        });
        return html.join('').replace(/\s+/g, ' ');
    };
    const translateUnification = async (v) => {
        const promises = [Translate.get(v.KeyShort)];
        for (var k in v.Options) {
            if (v.Options.hasOwnProperty(k)) {
                promises.push(translateUnification(v.Options[k]));
            }
        }
        const [_title] = await Promise.all(promises);
        v._title = _title;
        return v;
    };
    const onShortcutClick = ({ $ddw, $dd, $title, checked }) => {
        const $checkboxes = $ddw.find('.dropdown > li > input[type="checkbox"]').toArray().map(c => $(c));
        $checkboxes.map($c => {
            $c.prop('checked', checked);
            $c.trigger('change');
        });
        updateTreeDropDownTitle({ $ddw, $dd, $title });
    };
    const addSelectShortcuts = async ({ $ddw, $dd, $title }) => {
        const shortcutT = await Translate.get(['Select All', 'Select None']);
        const $selectAll = $(`<li class="shortcut"><span class="lbl lbl-shortcut">${shortcutT['Select All']}</span></li>`);
        const $selectNone = $(`<li class="shortcut"><span class="lbl lbl-shortcut">${shortcutT['Select None']}</span></li>`);
        $selectAll.on('click', e => {
            e.preventDefault();
            onShortcutClick({ $ddw, $dd, $title, checked: true });
        });
        $selectNone.on('click', e => {
            e.preventDefault();
            onShortcutClick({ $ddw, $dd, $title, checked: false });
        });
        $dd.prepend($selectNone);
        $dd.prepend($selectAll);
    };
    const $filter = $(querySelector);
    const $ddw = ($filter.is('.wrapper-dropdown.tree-config')) ? $filter : $filter.find('.wrapper-dropdown.tree-config');
    const $dd = $('<ul class="dropdown dropdown-tree"></ul>');
    const $title = $ddw.find(' > span');
    await Loading.waitForPromises(translateUnification(unification));
    $dd.append(rec(unification));
    await Loading.waitForPromises(addSelectShortcuts({ $ddw, $dd, $title }));
    $filter.find('.dropdown').remove();
    $filter.append($dd);
    return initTreeDropdown({ $ddw, $dd, $title }, opt);
}
/**
 *
 * @param {{ $ddw: any, $dd: any, $title: any }} args
 * @param {{ loadCache: boolean }} options
 * @returns {{} $ddw: jQuery, $dd: jQuery }}
 */
export function initTreeDropdown({ $ddw, $dd, $title }, options) {
    $ddw.on('click', e => {
        if ($(e.target).closest('.dropdown').length) {
            return;
        }
        const isLoading = $ddw.closest('#Filters').hasClass('disabled');
        if (!isLoading) {
            $ddw.toggleClass('active');
        }
        recalcTreeDropdownSize({ $ddw, $dd });
        e.stopPropagation();
    });
    $dd.find('input[type=checkbox]').each((_, check) => {
        const $check = $(check);
        $check.on('change', (e) => {
            const val = $check.prop('checked');
            $check.parent().find('input[type=checkbox]').each((_, cCheck) => {
                $(cCheck).prop('checked', val);
                $(cCheck).prop('indeterminate', false);
                $(cCheck).toggleClass('is-indeterminate', false);
            });
            $check.parents('ul:not(.dropdown-tree)').each((_, pUl) => {
                const $pUl = $(pUl);
                const $pCheck = $pUl.parent().find('> input[type=checkbox]');
                const allLength = $pUl.find('input[type=checkbox]').length;
                const checkedLength = $pUl.find('input[type=checkbox]:checked').length;
                $pCheck.prop('checked', checkedLength == allLength);
                $pCheck.prop('indeterminate', (allLength !== (checkedLength || allLength)));
                $pCheck.toggleClass('is-indeterminate', (allLength !== (checkedLength || allLength)));
            });
            updateTreeDropDownTitle({ $ddw, $dd, $title });
        });
    });
    const accordions = $dd.find('.tree-item > .accordion');
    accordions.each((_, accordion) => {
        const $a = $(accordion);
        $a.on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            $a.parent().toggleClass('collapsed');
            const $i = $a.closest('.accordion').find('i');
            $i.toggleClass('fa-minus');
            $i.toggleClass('fa-plus');
            recalcTreeDropdownSize({ $ddw, $dd });
        });
    });
    $(document).on('mouseup.dropdown', (e) => {
        const $clicked = $(e.target);
        const $found = $clicked.closest('.wrapper-dropdown.tree-config');
        if ($ddw.hasClass('active')) {
            if ($found.length === 0) {
                $ddw.removeClass('active');
            }
            else if (!$found.is($ddw)) {
                $ddw.removeClass('active');
            }
        }
    });
    if (options.loadCache) {
        loadTreeDropDownFilters({ $ddw, $dd, $title });
    }
    return { $ddw, $dd };
}
function recalcTreeDropdownSize({ $ddw, $dd }) {
    if ($ddw.hasClass('active')) {
        const $body = $('body');
        let { left, top } = $ddw.offset();
        top += $ddw.outerHeight();
        const bodyHeight = $body.outerHeight();
        const bodyWidth = $body.width();
        const ddHeight = $dd.outerHeight();
        // @ts-ignore
        if (top + ddHeight > bodyHeight) {
            // @ts-ignore
            top = bodyHeight - ddHeight;
        }
        // @ts-ignore
        const right = bodyWidth - left - $ddw.outerWidth();
        $dd.css('position', 'fixed');
        $dd.css('right', right + 'px');
        $dd.css('left', 'auto');
        $dd.css('top', top + 'px');
    }
}
/**
 * Example:
 * const treeBreakdown = createTreeBreakdown('level-display', 'levels', {
 *   level0: {
 *     level1: {
 *       level2a: '',
 *       level2b: '',
 *     }
 *   }
 * }
 */
export function createTreeBreakdownObj(rootLabel, obj, options) {
    const treeBreakdown = convertToTreeBreakdown(obj, rootLabel);
    options.idPrefix = options.idPrefix.replace(/\W+/g, '-');
    const html = createTreeBreakdownPartial(treeBreakdown, options);
    return ( /*html*/`<ul class="tree_breakdown" prefix="${options.idPrefix}">${html}</ul>`);
}
export function createTreeBreakdown(treeBreakdown, options) {
    options.idPrefix = options.idPrefix.replace(/\W+/g, '-');
    const html = createTreeBreakdownPartial(treeBreakdown, options);
    return ( /*html*/`<ul class="tree_breakdown" prefix="${options.idPrefix}">${html}</ul>`);
}
function convertToTreeBreakdown(obj, label) {
    const keys = Object.keys(obj).filter(k => obj.hasOwnProperty(k));
    let suffix = null;
    const breakdowns = keys.map(key => {
        if (key === 'suffix') {
            suffix = obj[key];
            return null;
        }
        return convertToTreeBreakdown(obj[key], key);
    }).filter(v => v !== null);
    return { data: label + (suffix || '') || '?', children: breakdowns };
}
function createTreeBreakdownPartial({ data: label, children, collapse }, options, id = 0) {
    const { idPrefix, lock } = options;
    const initialId = idPrefix + id;
    const childPartials = (children || []).map(child => {
        return createTreeBreakdownPartial(child, options, ++id);
    });
    return (childPartials.length === 0) ?
        ( /*html*/`<li><span class="tree_breakdown_label">${label}</span></li>`)
        : ( /*html*/`<li><input type="checkbox" ${lock === true ? 'disabled="disabled"' : ''} ${(collapse === true && lock !== true) ? '' : `checked="checked"`} id="c1-${initialId}" /><label class="tree_breakdown_label" for="c1-${initialId}">${label}</label><ul>${childPartials.join('')}</ul></li>`);
}
export function getBoundsZoomLevel(map, bounds) {
    let WORLD_DIM = { height: 256, width: 256 };
    let ZOOM_MAX = 21;
    function latRad(lat) {
        let sin = Math.sin(lat * Math.PI / 180);
        let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }
    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }
    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    let latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
    let lngDiff = ne.lng() - sw.lng();
    let lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;
    let latZoom = zoom(map.getDiv().offsetHeight + 80, WORLD_DIM.height, latFraction);
    let lngZoom = zoom(map.getDiv().offsetWidth + 80, WORLD_DIM.width, lngFraction);
    return Math.min(latZoom, lngZoom, ZOOM_MAX);
}
export function getMarkerBounds(markers) {
    let bounds = new google.maps.LatLngBounds();
    if (markers.length) {
        if (markers[0].hasOwnProperty('position')) {
            for (let marker of markers) {
                bounds.extend(marker.position);
            }
        }
        else {
            for (let marker of markers) {
                const points = mapHelperService.getPoints(marker);
                for (let [lng, lat] of points) {
                    bounds.extend({ lng, lat });
                }
            }
        }
    }
    return bounds;
}
/**
 * @deprecated
 * @param {*} response
 * @returns
 */
export async function TransformResponseToObject(response) {
    const { Rows, Columns } = response;
    if (!Rows.length)
        return {};
    const out = {};
    for (let columnIndex in Columns) {
        out[Columns[columnIndex]] = Rows[0][columnIndex];
    }
    return out;
}
/**
* @deprecated
* @param {*} response
* @returns
*/
export async function TransformResponseToObjects(response) {
    const { Rows, Columns } = response;
    const output = [];
    for (let i = 0; i < Rows.length; i++) {
        const out = {};
        for (let columnIndex in Columns) {
            out[Columns[columnIndex]] = Rows[i][columnIndex];
        }
        output.push(out);
    }
    return output;
}
export async function TransformObjectsToResponse(objs) {
    const objsKeys = Object.keys(objs);
    if (objsKeys.length === 0) {
        return {
            Columns: [],
            ColumnsTranslated: [],
            Rows: []
        };
    }
    const firstKey = objsKeys[0];
    const translated = await Loading.waitForPromises(Translate.get(Object.keys(objs[firstKey])));
    const response = {
        Columns: Object.keys(objs[firstKey]),
        ColumnsTranslated: Object.values(translated),
        Rows: []
    };
    Object.keys(objs).map(key => {
        const obj = objs[key];
        // @ts-ignore
        response.Rows.push(Object.values(obj));
    });
    return response;
}
export async function TransformObjectToResponse(obj) {
    const keys = Object.keys(obj);
    const translated = await Loading.waitForPromises(Translate.get(keys));
    const response = {
        Columns: keys,
        ColumnsTranslated: Object.values(translated),
        Rows: [Object.values(obj)]
    };
    return response;
}
export function calculateWindowPosition({ $target, $popover }) {
    const $body = $('body');
    let { left, top } = $target.offset();
    top += $target.outerHeight();
    const [bodyHeight, bodyWidth] = [$body.height() || 0, $body.width() || 0];
    const [_h, _w] = [$popover.height(), $popover.width()];
    if (top + _h > bodyHeight) {
        top = bodyHeight - _h;
    }
    left -= (_w - $target.width());
    if (left + _w > bodyWidth) {
        left = bodyWidth - _w;
    }
    return {
        left: left + 'px',
        top: top + 'px'
    };
}
export function isUserACI() {
    return _.getUser().UserGroups.includes(ACI_ADMIN);
}
export function copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
export function mergeDeep(target, ...sources) {
    if (!sources.length)
        return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return mergeDeep(target, ...sources);
}
function processPartition(arrayPartition, callback, timeout = 1) {
    return new Promise((resolve, reject) => {
        try {
            const result = arrayPartition.map(((value, index) => callback(value, index)));
            setTimeout(() => resolve(result), timeout);
        }
        catch (err) {
            reject(err);
        }
    });
}
export function asyncMapArray(array, partitionCount, callback) {
    if (array.length < partitionCount) {
        partitionCount = array.length;
    }
    return asyncMap(array, callback, partitionCount);
}
function asyncMap(realArray, callback, partitionCount) {
    const SIZE = realArray.length;
    const partitionSize = Math.ceil(SIZE / partitionCount);
    const arrayPartitions = [];
    let array = Object.assign([], realArray);
    do {
        const partition = array.splice(0, partitionSize);
        arrayPartitions.push(partition);
    } while (array.length > 0);
    return Loading.waitForPromises(Promise.resolve().then(async (_) => {
        let results = [];
        for (let i = 0; i < arrayPartitions.length; i++) {
            const nextPartition = arrayPartitions[i];
            results.push(await processPartition(nextPartition, callback).then((value) => {
                // this.log(`AsyncMap -> [${ (Math.round(i) / arrayPartitions.length * 100) }/100]`)
                return value;
            }));
            await waitForChromeFrame();
        }
        const arrays = await Promise.all(results);
        return [].concat.apply([], arrays);
    }));
}
/**
 * Converts object to array by removing the keys & returning the values in the same order of the keys
 * @param {any} object
 * @returns {any[]}
 */
export function convertObjectToArray(object) {
    const keys = Object.keys(object);
    const output = new Array(keys.length);
    for (let i = 0; i < keys.length; i++) {
        output[i] = object[keys[i]];
    }
    return output;
}
export function getDummyElement() {
    return document.createElement('div');
}
export function stringify(val, depth, replacer, space) {
    depth = isNaN(+depth) ? 1 : depth;
    function _build(key, val, depth, o, a) {
        return !val || typeof val != 'object' ? val : (a = Array.isArray(val), JSON.stringify(val, function (k, v) { if (a || depth > 0) {
            if (replacer)
                v = replacer(k, v);
            if (!k)
                return (a = Array.isArray(v), val = v);
            !o && (o = a ? [] : {});
            o[k] = _build(k, v, a ? depth : depth - 1);
        } }), o || (a ? [] : {}));
    }
    return JSON.stringify(_build('', val, depth), null, space);
}
export function getApiBaseUrl() {
    return convertToApiBaseUrl(location.href);
}
export function getApiDescriptionUrl() {
    return getApiBaseUrl() + '/description/openapi.json';
}
export function convertToApiBaseUrl(urlString) {
    const url = new URL(urlString);
    const [_, ...rest] = url.host.split('.');
    url.host = (['api']).concat(rest).join('.');
    return url.origin;
}
export function createSelectDeviceListHtml() {
    const scandevices = Sessions.filter((session) => {
        return session.NodeType === "ScanAuto" && ([
            "Connected", "ReadyToCount", "ReadyToEnforce",
            "ReadyToFollowUp", "LoggedIn", "Info", "SessionStarted",
            "SessionContinued", "LoggedIn"
        ]).includes(session.Status);
    });
    const deviceNames = scandevices.map(session => session.DeviceName);
    const options = [`<option selected value=""></option>`].concat(deviceNames.map((device) => (`<option value="${device}">${device}</option>`)));
    const html = (`<select name="devices-dropdown" id="devices-dropdown">${options.join('')}</select>`);
    // for (const device in scandevices) {
    //   deviceshtml += `<option value="${device}">${device}</option>`
    // }
    // deviceshtml += `</select>`
    return html;
}
export function waitForChromeFrame() {
    const promise = new Promise(requestAnimationFrame);
    return promise.then(function frameCallback(timestamp) {
        return timestamp;
    });
}
export function secondsPassed(date1, date2) {
    let second = 1000;
    let date1_ms = date1.getTime();
    let date2_ms = date2.getTime();
    let difference_ms = date2_ms - date1_ms;
    return Math.round(difference_ms / second);
}
export function weeksPassed(date1, date2) {
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.abs(Math.round(diff));
}
export function transformButtonCls(color) {
    switch (color) {
        case null:
        case undefined:
        case "default":
        case "blue":
            return 'btn-primary';
        case "grey":
            return 'btn-grey';
        case "green":
        case "success":
            return 'btn-success';
        case "orange":
            return 'btn-orange';
        case "yellow":
            return 'btn-yellow';
        case "red":
        case "error":
            return 'btn-red';
        case 'purple':
            return 'btn-purple';
        default:
            console.warn(`AVerificationButtonCls "${color}" is not recognized!`);
            return '';
    }
}
export function transformSpanCls(color) {
    const map = {
        'default': 'label-primary',
        'blue': 'label-blue',
        'grey': 'label-grey',
        'green': 'label-green',
        'yellow': 'label-yellow',
        'orange': 'label-orange',
        'red': 'label-red',
        'success': 'label-green',
        'error': 'label-red',
        'purple': 'label-purple',
    };
    if (color !== undefined && map.hasOwnProperty(color)) {
        return map[color];
    }
    return map['default'];
}
export function transformTextCls(color) {
    const map = {
        'default': 'text-default',
        'blue': 'text-default',
        'grey': 'text-grey',
        'green': 'text-green',
        'yellow': 'text-yellow',
        'orange': 'text-orange',
        'red': 'text-red',
        'success': 'text-green',
        'error': 'text-red',
        'purple': 'text-purple',
    };
    if (color !== undefined && map.hasOwnProperty(color)) {
        return map[color];
    }
    return map['default'];
}
export function formatPrice(str, prefix = '€', suffix = '') {
    const price = Number(str);
    if (!isNaN(price)) {
        let fixedNum = ARound(price, 2).toFixed(2);
        const formattedPrice = ((fixedNum.endsWith('00')) ? fixedNum.substring(0, fixedNum.length - 2) + '-' : fixedNum);
        return [prefix, formattedPrice, suffix].filter(v => v !== undefined && v !== '').join(' ');
    }
    AError.handleSilent(`Invalid Price ${str}`);
    return str ? str + '' : '';
}
export function _getEle(str) {
    let ele = null;
    if (str instanceof jQuery) {
        // @ts-ignore
        ele = str.get(0);
    }
    else if (str instanceof Element) {
        ele = str;
    }
    else if (typeof str === 'string') {
        let found = document.querySelector(str) || (!str.startsWith('#') ? document.querySelector('#' + str) : null);
        ele = found;
    }
    else {
        throw new Error(`_getEle(#${str}) is an unexpected value`);
    }
    return ele;
}
export function _getEle$(str) {
    let ele = _getEle(str);
    return $(ele);
}
