import { AEngine } from "../core/AEngine.js";
import { escapeHtml } from "../utils/json.js";
import { ALERT_BUTTONS, ALERTS, ALERT_STATUS } from "./AAlertService.js";
import { EVENTS } from "./AEventService.js";
import { SAVE_IDS } from "./APreferenceService.js";
export class AChannelService {
    constructor() {
        this.availableChannels = [];
    }
    get activeChannelKeys() { return this.activeChannels ? this.activeChannels?.map(c => c.Key) : ['']; }
    async autoInit() {
        Events.hardwire(EVENTS.VERIFICATION_RESULT_OPTIONS, (resultOptions) => {
            const found = this.availableChannels.filter(ch => this.desiredChannelKeys.includes(ch.Key));
            if (found.length === 0) {
                throw new Error(`Didn't find channel key="${this.desiredChannelKeys}"`);
            }
            this.activeChannels = found.map((c, i) => ({ Options: resultOptions[c.Key], ...c }));
        });
    }
    findChannel(channelKey) {
        if (channelKey == null)
            return undefined;
        return this.availableChannels.find(c => c.Key === channelKey);
    }
    getActiveChannels() {
        return this.getAllOptions().filter(c => c.Selected);
    }
    getAllOptions() {
        return this.availableChannels.map((ch) => {
            return {
                Key: ch.Key,
                Name: ch.Name,
                Selected: (this.activeChannels && this.activeChannels.find(c => c.Key == ch.Key)) ? true : false
            };
        });
    }
    getVerificationResultOptions(selected) {
        return Config.VerifyResultOptions || this.activeChannels?.find(c => c.Key === selected?.VerificationChannel || selected?.VerificationChannel == null).Options;
    }
    async selectChannels(channels) {
        this.desiredChannelKeys = channels;
        const res = await requestService.send('SetVerificationChannel', {
            VerificationChannels: channels
        }, { waitForEvent: EVENTS.STATE_CHANGED, version: 2 });
        preferenceService.save(SAVE_IDS.VERIFICATION_CHANNELS, channels);
        return res.state.Status;
    }
    async showVerificationChannels(channels, opt) {
        this.availableChannels = Object.keys(channels).map(key => {
            return {
                Key: key,
                Name: channels[key]
            };
        });
        const channelOptions = this.getAllOptions();
        let queues = await queueService.refreshChannelStats({ triggerEvent: true });
        console.log('queues', queues);
        if (channelOptions.length === 1 && opt.autoProceed) {
            const state = await this.selectChannels([channelOptions[0].Key]);
            return state;
        }
        const modal = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Select Verification Channel')),
            buttons: ALERT_BUTTONS.okCancel,
            type: ALERTS.Medium,
            skipRegex: true,
            content: await requestService.translateDom(/*html*/ `
        <div class="columns col-gapless mb-1">
          <div class="column col-4">
            <b style="line-height: 36px">Channel</b>
            ${channelOptions.map(({ Key, Name }, i) => {
                return ( /*html*/`
                <div style="line-height: 36px" >
                  <label class="form-checkbox">
                    <input id="channel-${i}" channel="${Key}" type="checkbox" class="hidden noselect">
                    <i class="form-icon"></i> ${escapeHtml(Name).padEnd(6, ' ').replace(/ /g, '&nbsp;')}
                  </label>
                </div>
              `);
            }).join('')}
          </div>
          <div class="column col-4">
            <b style="line-height: 36px">Assigned</b>
            ${channelOptions.map(({ Key, Name }) => {
                const chStats = queues.find(q => q.Channel === Key);
                return ( /*html*/`<div style="line-height: 36px" value="${Key}">${(chStats?.AssignedCentralVerifications ?? 0)}</div>`);
            }).join('')}
          </div>
          <div class="column col-4">
            <b style="line-height: 36px">Central Verification Queue</b>
            ${channelOptions.map(({ Key, Name }) => {
                const chStats = queues.find(q => q.Channel === Key);
                return ( /*html*/`<div style="line-height: 36px" value="${Key}">${(chStats?.CentralVerificationQueue ?? 0)}</div>`);
            }).join('')}
          </div>
        </div>
      `)
        });
        const $checkboxes = modal.$ele.find('[type="checkbox"]').toArray().map(ele => $(ele));
        $checkboxes.map(($checkbox, i) => {
            $checkbox.on('change', (e) => {
                AEngine.log(`Channels: ${$checkboxes.filter($c => $c.is(':checked')).map($c => $c.attr('channel')).join(', ')}`);
                if (modal.$ele.find('[type="checkbox"]:checked').length === 0) {
                    modal.$ele.find('#option1').attr('disabled', 'disabled');
                }
                else {
                    modal.$ele.find('#option1').removeAttr('disabled');
                }
            });
        });
        const $selectInputs = modal.$ele.find('#channel,#channel2,#channel3');
        $selectInputs.on('pointerdown mousedown input', (e) => {
            $selectInputs.val($(e.target).val());
        });
        const selectedChannels = preferenceService.load(SAVE_IDS.VERIFICATION_CHANNELS, null);
        if (selectedChannels !== null) {
            selectedChannels.map(channel => modal.$ele.find(`[type="checkbox"][channel="${channel}"]`).prop('checked', true));
        }
        $checkboxes[0].trigger('change');
        const eventId = Events.hardwire(EVENTS.CHANNEL_QUEUES_UPDATED, (channelQueues) => {
            queues = channelQueues;
            channelOptions.map(({ Key }, index) => {
                const chStats = queues.find(q => q.Channel === Key);
                $selectInputs.eq(1).children().eq(index).text(chStats?.AssignedCentralVerifications ?? '?');
                $selectInputs.eq(2).children().eq(index).text(chStats?.CentralVerificationQueue ?? '?');
            });
        });
        return new Promise((resolve) => {
            modal.on(ALERT_STATUS.ON_MODAL_CLOSED, async ({ action }) => {
                Events.off(EVENTS.CHANNEL_QUEUES_UPDATED, eventId);
                if (action === ALERT_STATUS.ON_ACTION_PROCEED) {
                    const channels = $checkboxes.filter($c => $c.is(':checked')).map($c => $c.attr('channel'));
                    const state = await this.selectChannels(channels);
                    return resolve(state);
                }
                else {
                    return resolve(false);
                }
            });
        });
    }
}
