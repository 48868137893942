import { AError } from "./AError.js";
import { ARequestService } from "../services/ARequestService.js";
import { AUrlEncodedImageFromBase64 } from "../utils/tools.js";
import { AEngine } from "../core/AEngine.js";
export class APhotoFetcher {
    constructor() {
        if (CCCClient === undefined) {
            throw new Error('CCCClient not found!');
        }
        this.client = CCCClient;
        this.request = AEngine.get(ARequestService);
    }
    /**
     * Fetch Overview Photo
     * @param {*} DetectionId
     * @param {*} DetectionDeviceId
     */
    fetchOverviewPhoto(DetectionId, DetectionDeviceId) {
        return Loading.waitForPromises(this.request.query({
            Query: "SELECT * FROM overviewimages WHERE DetectionId=:DetectionId AND DetectionDeviceId=:DetectionDeviceId",
            Name: 'FetchOverviewPhoto',
            Params: {
                DetectionId,
                DetectionDeviceId
            },
            Language: Language
        }).then((response) => {
            const imageIndex = response.Columns.indexOf('Image');
            let images = [];
            for (let i = 0; i < response.Rows.length; i++) {
                const image = response.Rows[i][imageIndex];
                if (image && image.length) {
                    const src = AUrlEncodedImageFromBase64(image);
                    images.push({ src });
                }
            }
            return images;
        })).catch(AError.handle);
    }
}
