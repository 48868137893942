import { EVENTS } from "../services/AEventService.js";
import { AError } from "./AError.js";
export class ALoopTimerAsync {
    get nextId() {
        return (ALoopTimerAsync.lastId++);
    }
    constructor(callbackfn, options) {
        this.id = -1;
        this.callbackfn = callbackfn;
        this.options = options;
    }
    start() {
        if (this.id === -1) {
            this.id = this.nextId;
            this.promise = this.iteration().catch(AError.handle);
            if (this.options.loopLifeCycle === "PAGE") {
                // TODO: Only do this once
                Events.h_once(EVENTS.DESTRUCT, () => this.stop());
            }
        }
        return this;
    }
    async iteration() {
        if (this.id === -1)
            return;
        await Promise.resolve().then(_ => this.callbackfn()).then(_ => this.iteration());
    }
    stop() {
        this.id = -1;
        return this;
    }
    restart() {
        this.stop();
        return this.start();
    }
    restartWith(options) {
        this.stop();
        Object.assign(this.options, options || {});
        return this.start();
    }
    changeTimeout(timeout) {
        const options = Object.assign({}, this.options, { timeout });
        return this.restartWith(options);
    }
}
ALoopTimerAsync.lastId = 1;
