import { AError, ERROR_GROUPS } from "../../classes/AError.js";
import { EVENTS } from "../../services/AEventService.js";
import { AColor } from "../colors/AColor.js";
import { AGeoService } from "./AGeoService.js";
import { GetFilterArea, _getEle } from "../../utils/maps.js";
import { asyncMapArray, convertObjectToArray, toggleFullScreen } from "../../utils/tools.js";
import { AEngine } from "../AEngine.js";
import { ALL_MAP_OPTIONS, MAP_OPTIONS, MAP_POSITION, UNLOAD_OPTIONS } from "./AMapStructs.js";
import { AIdAllocatorService } from "../allocator/AIdAllocatorService.js";
import { AGeoUtils } from "./AGeoUtils.js";
import { APurgatoryService } from "../../services/APurgatoryService.js";
import { polygons_tableformatter } from "../../format/table_formatter.js";
import { AMapOverlayService } from "./AMapOverlayService.js";
export function DefaultBounds() {
    return new google.maps.LatLng(0, 0);
}
export function createMap(id, options) {
    const opts = $.extend(true, {}, options);
    const mapEle = _getEle(id);
    if (!mapEle) {
        throw new Error(`Couldn't find HTMLElement: #${id}`);
    }
    const map = new google.maps.Map(mapEle, $.extend(true, {
        scaleControl: false,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP
        },
        mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
        },
    }, opts));
    return map;
}
export class ACoreMapService {
    constructor() {
        this.cache = {};
        this.requested = {};
        this.segments = {};
        this.parkingSpaces = {};
        // @ts-ignore
        this.areas = {};
        // @ts-ignore
        this.zones = {};
        this.lastArea = null;
    }
    autoInit() { }
    // get AreasOnMap(): (google.maps.Polygon | any)[] {
    //   return this.cache['showAreasOnMap'] || []
    // }
    // get ZonesOnMap(): (google.maps.Polygon | any)[] {
    //   return this.cache['showZonesOnMap'] || []
    // }
    // get ParkingSpacesOnMap(): (google.maps.Polygon | any)[] {
    //   return this.cache['showParkingSpacesOnMap'] || []
    // }
    // get SegmentsOnMap(): (google.maps.Polygon | any)[] {
    //   const segments = this.cache['showSegmentsOnMap']
    //   return segments ? segments.filter((item) => item != undefined) : []
    // }
    /**
     * Sets cache for an array of polygons
     * @param {string} key index for hashtable
     * @param {any} polygons array of polygons
     */
    setCache(key, polygons) {
        this.cache[key] = polygons;
    }
    /**
     * Move polygons from one map to another
     * @param {any} map
     * @param {any} polygons
     */
    redirectPolygons(map, polygons) {
        polygons.map(p => {
            p.setMap(map);
            p.setOptions({ visible: true });
        });
    }
    fetchStreetData(coordinates) {
        return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'location': coordinates }, function (results, status) {
                if (status !== 'OK') {
                    return reject(status);
                }
                return resolve(results);
            });
        });
    }
    fetchStreetPosition(service, coordinates) {
        return new Promise((resolve, reject) => {
            try {
                service.getPanoramaByLocation(coordinates, 50, (res, status) => (status === 'OK') ? resolve(res.location.latLng) : resolve(false));
            }
            catch (err) {
                reject(err);
            }
        });
    }
    geoPointToCoords(data, srid = 4326) {
        //data = {"type": "Point", "coordinates": [10.750851301946682, 59.908290312191056]}
        // PageScript.map.setCenter(new google.maps.LatLng(59.9267065136985, 10.8019512068214))
        if (srid == 4326) {
            const [lng, lat] = data.coordinates;
            return { lat, lng };
        }
        else {
            const [lat, lng] = data.coordinates;
            return { lat, lng };
        }
    }
    geoJsonToPolygonCoords(data, srid = 4326) {
        const { coordinates } = data;
        let center = { lat: 0, lng: 0 };
        let areaPaths = [];
        for (let r = 0; r < coordinates.length; r++) {
            let ring = [];
            for (let p = 0; p < coordinates[r].length; p++) {
                let latlng;
                if (srid == 4326) {
                    latlng = { lat: coordinates[r][p][1], lng: coordinates[r][p][0] };
                }
                else {
                    latlng = { lat: coordinates[r][p][0], lng: coordinates[r][p][1] };
                }
                if (r == 0) {
                    center.lat += latlng.lat;
                    center.lng += latlng.lng;
                }
                ring.push(latlng);
            }
            if (r == 0) {
                center.lat /= coordinates[r].length;
                center.lng /= coordinates[r].length;
            }
            areaPaths.push(ring);
        }
        if (areaPaths.length == 1) {
            areaPaths = areaPaths[0];
        }
        return {
            coordinates: areaPaths,
            center: center
        };
    }
    get_legend_from_name(name, useOpacity) {
        switch (name) {
            case "green": return this.get_legend_green(useOpacity);
            case "yellow": return this.get_legend_yellow(useOpacity);
            case "orange": return this.get_legend_orange(useOpacity);
            case "red": return this.get_legend_red(useOpacity);
            case "blue": return this.get_legend_blue(useOpacity);
            case "grey": return this.get_legend_grey(useOpacity);
        }
        return null;
    }
    get_legend_green(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 255, 0).rgba(0.7) : new AColor(0, 255, 0).hexi,
            stroke: new AColor(5, 163, 0).hexi
        };
    }
    get_legend_orange(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 133, 0).rgba(0.7) : new AColor(255, 133, 0).hexi,
            stroke: new AColor(256, 128, 0).hexi
        };
    }
    get_legend_red(useOpacity) {
        return {
            fill: useOpacity ? new AColor(247, 0, 0).rgba(0.7) : new AColor(247, 0, 0).hexi,
            stroke: new AColor(185, 0, 0).hexi
        };
    }
    get_legend_grey(useOpacity) {
        // TODO: Implementation
        return {
            fill: useOpacity ? new AColor(128, 128, 128).rgba(0.7) : new AColor(128, 128, 128).hexi,
            stroke: new AColor(128, 128, 128).hexi
        };
    }
    get_legend_blue(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 173, 255).rgba(0.7) : new AColor(0, 173, 255).hexi,
            stroke: new AColor(0, 114, 232).hexi
        };
    }
    get_legend_yellow(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 255, 0).rgba(0.7) : new AColor(255, 255, 0).hexi,
            stroke: new AColor(255, 255, 0).hexi
        };
    }
    get_legend_brown(useOpacity) {
        return {
            fill: useOpacity ? new AColor(200, 100, 0).rgba(0.7) : new AColor(200, 100, 0).hexi,
            stroke: new AColor(200, 100, 0).hexi
        };
    }
    get_legend_brown_outline() {
        return {
            fill: new AColor(200, 100, 0).rgba(0.0),
            stroke: new AColor(200, 100, 0).hexi
        };
    }
    get_legend_spread(useOpacity) {
        return [
            {
                fill: useOpacity ? new AColor(27, 247, 20).rgba(0.7) : new AColor(27, 247, 20).hexi,
                stroke: new AColor(27, 247, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(20, 247, 222).rgba(0.7) : new AColor(20, 247, 222).hexi,
                stroke: new AColor(20, 247, 222).hexi
            },
            {
                fill: useOpacity ? new AColor(31, 20, 247).rgba(0.7) : new AColor(31, 20, 247).hexi,
                stroke: new AColor(31, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(135, 20, 247).rgba(0.7) : new AColor(135, 20, 247).hexi,
                stroke: new AColor(135, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(240, 20, 247).rgba(0.7) : new AColor(240, 20, 247).hexi,
                stroke: new AColor(240, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 20, 20).rgba(0.7) : new AColor(247, 20, 20).hexi,
                stroke: new AColor(247, 20, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 153, 20).rgba(0.7) : new AColor(247, 153, 20).hexi,
                stroke: new AColor(247, 153, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 243, 20).rgba(0.7) : new AColor(247, 243, 20).hexi,
                stroke: new AColor(247, 243, 20).hexi
            }
        ];
    }
    get legend_legacy() {
        const { legendItems } = {
            legendItems: {
                'Default': this.get_legend_green(true),
                'No Parking Right': this.get_legend_red(true),
                'Illegally Parked': this.get_legend_brown_outline(),
                'Unknown Parking Right': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ IsIllegallyParked, HasParkingRight }) => {
                const { FillColor, StrokeColor } = this.getColors({ IsIllegallyParked, HasParkingRight });
                return {
                    fill: FillColor,
                    stroke: StrokeColor
                };
            },
            legendItems
        };
    }
    get legend_digital() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'Digital': this.get_legend_green(false),
                'NotDigital': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                'Digital': this.get_legend_green(true),
                'NotDigital': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ Digital }) => {
                if (Digital === null)
                    return null;
                switch (Digital) {
                    case 'InProgress':
                        return calcTemplate.InProgress;
                    case 'Digital':
                        return calcTemplate.Digital;
                    case 'NotDigital':
                        return calcTemplate.NotDigital;
                    case 'NotProcessed':
                        return calcTemplate.NotProcessed;
                }
            },
            legendItems
        };
    }
    get legend_illegallyparked() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'NotIllegallyParked': this.get_legend_green(false),
                'IllegallyParked': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                'NotIllegallyParked': this.get_legend_green(true),
                'IllegallyParked': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ IllegallyParked }) => {
                if (IllegallyParked === null)
                    return null;
                switch (IllegallyParked) {
                    case 'NotIllegallyParked':
                        return calcTemplate.NotIllegallyParked;
                    case 'InProgress':
                        return calcTemplate.InProgress;
                    case 'NotProcessed':
                        return calcTemplate.NotProcessed;
                }
                if (IllegallyParked?.startsWith('IllegallyParked')) {
                    return calcTemplate.IllegallyParked;
                }
            },
            legendItems
        };
    }
    get legend_parkingright() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'ParkingRight': this.get_legend_green(false),
                'NoParkingRight': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'Other': this.get_legend_grey(false)
            },
            legendItems: {
                'ParkingRight': this.get_legend_green(true),
                'NoParkingRight': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'Other': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ ParkingRight }) => {
                if (ParkingRight === null)
                    return null;
                switch (ParkingRight) {
                    case 'InProgress': return calcTemplate.InProgress;
                    case 'NoParkingRight': return calcTemplate.NoParkingRight;
                }
                if (ParkingRight?.startsWith('NoParkingRightNeeded')) {
                    return calcTemplate.Other;
                }
                if (ParkingRight?.startsWith('ParkingRight')) {
                    return calcTemplate.ParkingRight;
                }
                if (ParkingRight?.startsWith('Indecisive')) {
                    return calcTemplate.NoParkingRight;
                }
                if (ParkingRight?.startsWith('NotProcessed')) {
                    return calcTemplate.Other;
                }
            },
            legendItems
        };
    }
    get legend_verification() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'NoVerificationNeeded': this.get_legend_green(false),
                'FinedIllegallyParked': this.get_legend_red(false),
                'FinedNoParkingRight': this.get_legend_blue(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                // TODO: Remove NoVerificationNeeded (Bespreek met Jaap ivm met limit 2000 scans, moet ik de query aanpassen ofz?)
                'NoVerificationNeeded': this.get_legend_green(true),
                'FinedIllegallyParked': this.get_legend_red(true),
                'FinedNoParkingRight': this.get_legend_blue(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ Verification }) => {
                if (Verification === null)
                    return null;
                if (['NoVerificationNeeded', 'NotFined'].includes(Verification)) {
                    return calcTemplate.NoVerificationNeeded;
                }
                if (['Fined_IllegallyParked', 'Fined_TimeLimitedParkingExperired'].includes(Verification)) {
                    return calcTemplate.FinedIllegallyParked;
                }
                if (['Fined_NoParkingRight', 'Fined_Unknown'].includes(Verification)) {
                    return calcTemplate.FinedNoParkingRight;
                }
                if (Verification?.startsWith('InProgress')) {
                    return calcTemplate.InProgress;
                }
                if (Verification?.startsWith('NotProcessed')) {
                    return calcTemplate.NotProcessed;
                }
            },
            legendItems
        };
    }
    get legend_detection_state() {
        const colorSpread = this.get_legend_spread(false);
        const colorSpreadOpacity = this.get_legend_spread(true);
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'GeoQueue': colorSpread.pop(),
                'PrdbQueue': colorSpread.pop(),
                'PdaVerificationQueue': colorSpread.pop(),
                'DeskforceVerificationQueue': colorSpread.pop(),
                'AssignedToPda': colorSpread.pop(),
                'AssignedToDeskforce': colorSpread.pop(),
                'Unknown': colorSpread.pop(),
                'Done': colorSpread.pop()
            },
            legendItems: {
                'GeoQueue': colorSpreadOpacity.pop(),
                'PrdbQueue': colorSpreadOpacity.pop(),
                'PdaVerificationQueue': colorSpreadOpacity.pop(),
                'DeskforceVerificationQueue': colorSpreadOpacity.pop(),
                'AssignedToPda': colorSpreadOpacity.pop(),
                'AssignedToDeskforce': colorSpreadOpacity.pop(),
                'Unknown': colorSpreadOpacity.pop(),
                'Done': colorSpreadOpacity.pop()
            }
        };
        return {
            calcColor: ({ DetectionState }) => {
                if (DetectionState === null)
                    return null;
                switch (DetectionState) {
                    case 'InProgress_GeoQueue': return calcTemplate.GeoQueue;
                    case 'InProgress_PrdbQueue': return calcTemplate.PrdbQueue;
                    case 'InProgress_PdaVerificationQueue': return calcTemplate.PdaVerificationQueue;
                    case 'InProgress_DeskforceVerificationQueue': return calcTemplate.DeskforceVerificationQueue;
                    case 'InProgress_AssignedToPda': return calcTemplate.AssignedToPda;
                    case 'InProgress_AssignedToDeskforce': return calcTemplate.AssignedToDeskforce;
                    case 'InProgress_Unknown': return calcTemplate.Unknown;
                    case 'Done': return calcTemplate.Done;
                }
            },
            legendItems
        };
    }
    set calcLegendColor(val) {
        // PageScript.__map_legend_calcColor = val
    }
    // get calcLegendColor(): ADetectionCalcColorFunc {
    // const fallbackClr = { fill: '#000', stroke: '#000' }
    // const calcColor: ADetectionCalcColorFunc = PageScript.__map_legend_calcColor
    // if (calcColor == undefined) {
    //   AError.handleSilent(`Page calcLegendColor has not been initialized!`)
    //   return (_ => (fallbackClr))
    // }
    // const resultFunc: ADetectionCalcColorFunc = (args: AUnificationTypeArgs) => {
    //   const clr = calcColor(args)
    //   if (clr === null) {
    //     AError.handle({
    //       err: new Error(`AMapHelperService.calcLegendColor unification is null data=${JSON.stringify(args)}`),
    //       useAdminAlerts: false,
    //       useCentralServerLogging: false,
    //       useModal: false,
    //     })
    //   } else if (clr === undefined) {
    //     AError.handleSilent(`AMapHelperService.calcLegendColor unexpected unification (POSSIBLY MISSING UNIFICATIONS) data=${JSON.stringify(args)}`,
    //       ERROR_GROUPS.CalcLegendColorError)
    //   }
    //   return clr || fallbackClr
    // }
    // return resultFunc
    // }
    async generateDetectionsLegendHtml({ legendItems }) {
        // const { tabOptions } = this.mapOverlayService.tabDefinitions
        // const [
        //   legendLabelText,
        //   translations,
        //   tabOptionsT,
        // ] = await Promise.all([
        //   Translate.get('Legend'),
        //   Translate.get(Object.keys(/** @type {object} */(legendItems))),
        //   Translate.get(tabOptions)
        // ])
        // const LEGEND_SELECTION = this.legendSelection || tabOptions[0]
        // const HTML_SELECT = tabOptions.map(
        //   tabOption => (`
        //         <option${LEGEND_SELECTION == tabOption ? ' selected="selected"' : ''} value="${tabOption}">${tabOptionsT[tabOption]
        //     }</option>
        //      `).trim()
        // ).join('\r\n')
        // const HTML_LEGEND_ITEMS = Object.keys(legendItems).map(key => {
        //   const { stroke, fill } = legendItems[key]
        //   return (`
        //     <div>
        //       <div class="detectionPreview" style="background-color: ${fill}; outline-color: ${stroke}"></div>
        //       <span>${translations[key]}</span>
        //     </div>
        //   `).trim()
        // }).join('\r\n')
        // const style = (`zoom: ${AConfig.get(`drawing & colors.legendScale`, 1.2)};`)
        // return (`
        //   <div class="legend legend-opaque legend-detections" style="${style}">
        //     <div class="legend-label label-height-lg">${legendLabelText}</div>
        //     <select class="changeLegend">${HTML_SELECT}</select>
        //     ${HTML_LEGEND_ITEMS}
        //   </div>
        // `)
    }
    async setDetectionsLegendDefault() {
        // const { tabConfigurations } = this.mapOverlayService.tabDefinitions
        // const view = tabConfigurations[this.legendSelection] || this.legend_parkingright
        // await Loading.waitForPromises(this.setDetectionsLegend(view)).catch(AError.handle)
    }
    async setDetectionsLegend(options) {
        // const { calcColor } = options
        // const { tabConfigurations } = this.mapOverlayService.tabDefinitions
        // const $detectionsLegend = $(await this.generateDetectionsLegendHtml(options))
        // const $select = $detectionsLegend.find('.changeLegend')
        // $select.on('change', _ => {
        //   this.legendSelection = <string>$select.val()
        //   Loading.waitForPromises(
        //     this.setDetectionsLegend(tabConfigurations[$select.val() as string])
        //   ).catch(AError.handle)
        // })
        // this.calcLegendColor = calcColor
        // this.mapOverlayService.add($detectionsLegend, MAP_POSITION.BOTTOM_LEFT, { uid: 'LEGEND_CONTROLS', order: 0 })
        // await this.recolorAllMarkers({ calcColor })
    }
    async recolorAllMarkers(options) {
        const fallbackClr = { fill: '#000', stroke: '#000' };
        const calcColor = options.calcColor || this.calcLegendColor;
        const markers = this.fetchMarkers();
        await asyncMapArray(markers, 10, (marker) => {
            const optClr = calcColor(marker._final, { opacity: true });
            if (optClr === null) {
                AError.handle({
                    err: new Error(`AMapHelperService.recolorAllMarkers unification is null data=${JSON.stringify(marker._final)}`),
                    useAdminAlerts: false,
                    useCentralServerLogging: false,
                    useModal: false,
                });
            }
            else if (optClr === undefined) {
                AError.handleSilent(`AMapHelperService.recolorAllMarkers unexpected unification (POSSIBLY MISSING UNIFICATIONS) data=${JSON.stringify(marker._final)}`, ERROR_GROUPS.CalcMarkerColorError);
            }
            const clr = optClr || fallbackClr;
            const { fill, stroke } = (typeof clr === 'string') ? { fill: clr, stroke: clr } : clr;
            marker.setOptions({
                strokeColor: stroke,
                fillColor: fill
            });
        });
    }
    async createCountLabel() {
        const mapCountLabel = document.createElement('label');
        mapCountLabel.classList.add('map-control-count');
        const template = await Translate.get('Detections Displayed: 808');
        mapCountLabel.setAttribute('template', template);
        mapCountLabel.innerText = template.replace('808', '-');
        PageScript.map?.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(mapCountLabel);
    }
    updateCountLabel(count) {
        const $count = $('.map-control-count');
        if ($count.length) {
            const template = $count.attr('template') || '';
            const text = template.replace('808', count);
            $count.text(text);
        }
    }
    async createDownloadButton(options) {
        const { order } = options;
        const $button = await menuService.addMapButton({
            tag: 'a',
            title: 'Export kml',
            icon: 'fa-solid fa-file-arrow-down',
            order,
            position: MAP_POSITION.TOP_RIGHT,
        });
        $button.attr('id', 'DownloadButton');
        $button.attr('disabled', 'disabled');
        return $button;
    }
    /**
     * Creates a toggle button on the map to hide a category
     * @param bitmask only apply on specific type of polygons on the map
     * @param options options
     */
    async createMapToggleSettings(bitmask, { order, fitPolygons = true, showOneScale, click }) {
        let categories = [];
        // type AFuncShowOnMap = (map: any, bounds: any, filterArea: any, clickEvent: any) => Promise<any>
        // let methods: AFuncShowOnMap[] = []
        const mapOptionToFunc = (_enum) => {
            return ({ parseToMap }) => {
                return AEngine.get(AGeoService).load(_enum, { parseToMap, addClickListener: true });
            };
        };
        ALL_MAP_OPTIONS.map((mapOption) => {
            if (bitmask & mapOption) {
                const optName = MAP_OPTIONS[mapOption];
                categories.push({
                    id: AEngine.get(AIdAllocatorService).getNextId({ prefix: 'tgl-' }),
                    isCheckbox: true,
                    enum: mapOption,
                    displayText: optName,
                    boolKey: `v${optName}`,
                    collectionKey: `${optName}OnMap`,
                    updateState: ({ visible }) => {
                        console.log('updateState', { visible });
                    },
                    loadPolygons: mapOptionToFunc(mapOption)
                });
            }
        });
        // Translate displayText
        await Loading.waitForPromises(Translate.get(categories.map(({ displayText }) => displayText))).then(t => {
            categories.map(c => c.displayText = t[c.displayText]);
        });
        const $checkboxArr = [];
        const inputs = await Promise.all(categories.map(async (category, i) => {
            const { displayText, boolKey } = category;
            PageScript[boolKey] = false;
            const $a = this.genMapPopoverItem({ displayText, id: `category-${category}-${i}` });
            const $checkbox = $a.find('[type="checkbox"]');
            $checkboxArr.push($checkbox);
            const toggleAction = async () => {
                await Loading.waitForPromises(this.toggleAny(category, !PageScript[boolKey])).catch(AError.handle);
                $checkboxArr.map($c => $c.trigger('refreshstate'));
            };
            $checkbox.on('refreshstate', (e) => $checkbox.prop('checked', PageScript[boolKey]));
            $checkbox.on('change', _ => toggleAction().catch(AError.handle));
            $a.on('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                $checkbox.trigger('change');
            });
            return $a;
        }));
        if (bitmask !== 0) {
            menuService.addMapDropdown(inputs, {
                uid: 'scale-toggle',
                icon: 'fa-solid fa-draw-polygon',
                order,
                position: MAP_POSITION.TOP_LEFT
            });
        }
    }
    genMapPopoverItem({ displayText, id }) {
        return $(/*html*/ `
      <a ${id ? `id="${id}"` : ''}>
        <div class="noselect ns-children">
          <label class="form-checkbox">
            <input type="checkbox" class="hidden noselect"> <i class="form-icon"></i>
            ${displayText}
          </label>
        </div>
      </a>
    `);
    }
    editStyle({ find, defaultValue, behaviour }) {
        const styles = PageScript.map.get('styles') || [];
        let index = -1;
        styles.map((style, i) => {
            if (find(style)) {
                index = i;
            }
        });
        if (index === -1) {
            index = styles.push(defaultValue()) - 1;
        }
        styles[index] = behaviour(styles[index]);
        PageScript.map.set('styles', styles);
    }
    toggleMapLabels() {
        if (!PageScript.map) {
            return;
        }
        if (!PageScript.__mapOptions) {
            PageScript.__mapOptions = { visibility: true };
        }
        const featureType = 'all', elementType = 'labels';
        this.editStyle({
            find: (style) => (style.featureType === featureType && style.elementType === elementType),
            defaultValue: () => ({
                featureType,
                elementType,
                stylers: [{
                        visibility: PageScript.__mapOptions.visibility ? "on" : "off"
                    }]
            }),
            behaviour: (style) => {
                PageScript.__mapOptions.visibility = !PageScript.__mapOptions.visibility;
                style.stylers[0].visibility = PageScript.__mapOptions.visibility ? "on" : "off";
                return style;
            }
        });
    }
    setMapType(mapType) {
        if (!PageScript.map) {
            return;
        }
        const map = PageScript.map;
        map.setMapTypeId(mapType);
    }
    toggleMapLabelsOld() {
        if (!PageScript.__mapOptions) {
            PageScript.__mapOptions = {
                visibility: true
            };
        }
        PageScript.__mapOptions.visibility = !PageScript.__mapOptions.visibility;
        PageScript.map?.set('styles', [
            {
                featureType: "all",
                elementType: "labels",
                stylers: [{
                        visibility: PageScript.__mapOptions.visibility ? "on" : "off"
                    }]
            }
        ]);
    }
    genDropdownLink({ displayText, isCheckbox = false }) {
        return (isCheckbox) ? $(`
      <a>
        <div class="noselect ns-children">
          <label class="form-checkbox">
            <input type="checkbox" class="hidden noselect">
            <i class="form-icon"></i> ${displayText}
          </label>
        </div>
      </a>
    `) : $(`<a><div class="noselect ns-children">${displayText}</div></a>`);
    }
    async createMapDropdown({ order }) {
        let categories = [
            { displayText: 'Toggle Labels', click: () => this.toggleMapLabels() },
            { displayText: 'Focus On Markers', click: () => PageScript.map?.focusOnMarkers() },
            { displayText: 'Focus On Scales', click: () => PageScript.map?.focusOnScales() },
            { displayText: 'Reset View', click: () => PageScript.map?.resetBounds() },
        ];
        await Loading.waitForPromises(Translate.get(categories.map(({ displayText }) => displayText))).then(t => {
            categories.map(c => c.displayText = t[c.displayText]);
        });
        const inputs = categories.map(category => {
            const $link = this.genDropdownLink(category);
            const $c = $link.find('[type=checkbox]');
            let toggle = false;
            let toggleAction = async () => {
                category.click.call(this);
                $c.prop('checked', toggle);
                toggle = !toggle;
            };
            $link.on('click', () => { toggleAction().catch(AError.handle); });
            $c.on('change', () => { toggleAction().catch(AError.handle); });
            return $link;
        });
        menuService.addMapDropdown(inputs, {
            icon: 'fa-solid fa-bars',
            order,
            position: MAP_POSITION.TOP_LEFT
        });
    }
    polygonCompare(scale) {
        if (FilterSettings.Area === undefined)
            return true;
        return !FilterSettings.Area || FilterSettings.Area === '%' || scale.data.area === FilterSettings.Area;
    }
    /**
     * Updates visibility of polygons, so if the area isn't selected, the polygon won't show up
     */
    updateStaticPolygons() {
        const ps = PageScript;
        if (this.cache.showAreasOnMap && ps.vAreas)
            this.cache.showAreasOnMap.map(scale => scale.setOptions({ visible: this.polygonCompare(scale) }));
        if (this.cache.showZonesOnMap && ps.vZones)
            this.cache.showZonesOnMap.map(scale => scale.setOptions({ visible: this.polygonCompare(scale) }));
        if (this.cache.showParkingSpacesOnMap && ps.vParkingSpaces)
            this.cache.showParkingSpacesOnMap.map(scale => scale.setOptions({ visible: this.polygonCompare(scale) }));
        if (this.cache.showSegmentsOnMap && ps.vSegments)
            this.cache.showSegmentsOnMap.map(scale => scale.setOptions({ visible: this.polygonCompare(scale) }));
        return Promise.resolve();
    }
    /**
     * Creates parking spaces on the map
     * @param {any} map Google Maps reference
     * @param {any} bounds Bounds to extend
     * @param {string} filterArea
     */
    async showParkingSpacesOnMap(map, bounds, filterArea, clickEvent) {
        const KEY = 'showParkingSpacesOnMap';
        if (this.cache[KEY]) {
            if (clickEvent) {
                for (const polygon of this.cache[KEY]) {
                    google.maps.event.addListener(polygon, "click", clickEvent || globalThis.purgatoryService?.clickPolygon);
                }
            }
            this.redirectPolygons(map, this.cache[KEY]);
            return this.cache[KEY];
        }
        const getColor = (parkingSpace) => {
            const colorConfig = {
                strokeColor: '#ffffff',
                fillColor: '#ffffff',
                strokeOpacity: 1.0,
                fillOpacity: 0.5
            };
            switch (parkingSpace.Name) {
                case "Tax parking":
                    colorConfig.fillColor = "#FFFF00";
                    colorConfig.strokeColor = "#888800";
                    break;
                case "Free parking":
                    colorConfig.fillColor = "#99DD99";
                    colorConfig.strokeColor = "#000000";
                    break;
                case "Private Tax parking":
                    colorConfig.fillColor = "#99DD99";
                    colorConfig.strokeColor = "#000000";
                    break;
                case "Motorcycle Parking":
                    colorConfig.fillColor = "#00FF00";
                    colorConfig.strokeColor = "#008800";
                    break;
                case "Electric":
                    colorConfig.fillColor = "#00FF00";
                    colorConfig.strokeColor = "#008800";
                    break;
            }
            // TODO: Find out what to use for coloring
            if (parkingSpace.Attributes["KLEUR"]) {
                const hexColor = parkingSpace.Attributes["KLEUR"];
                colorConfig.fillColor = hexColor;
                colorConfig.strokeColor = new AColor(hexColor).hsv.lerpTo(new AColor('#000000').hsv, 0.5).hexi;
            }
            if (parkingSpace.Attributes["BO.Color"]) {
                const bo_color = this.get_legend_from_name(parkingSpace.Attributes["BO.Color"], true);
                if (bo_color) {
                    colorConfig.fillColor = bo_color.fill;
                    colorConfig.strokeColor = bo_color.stroke;
                }
            }
            return colorConfig;
        };
        let result = [];
        for (let area in this.parkingSpaces) {
            if (!filterArea || filterArea == area) {
                for (let parkingSpaceId in this.parkingSpaces[area]) {
                    if (this.parkingSpaces[area][parkingSpaceId].Bounds.type == "LineString") {
                        let parkingSpaceBounds = this.parkingSpaces[area][parkingSpaceId].Bounds.coordinates;
                        let path = [];
                        let center = { lat: 0, lng: 0 };
                        for (let p = 0; p < parkingSpaceBounds.length; p++) {
                            let latlng = {
                                lat: parkingSpaceBounds[p][1],
                                lng: parkingSpaceBounds[p][0]
                            };
                            if (bounds)
                                bounds.extend(latlng);
                            center.lat += latlng.lat;
                            center.lng += latlng.lng;
                            path.push(latlng);
                        }
                        center.lat /= parkingSpaceBounds.length;
                        center.lng /= parkingSpaceBounds.length;
                        this.parkingSpaces[area][parkingSpaceId].Center = center;
                        // 2022-10-11 Ivan, hoe kan dit mooier?
                        let colorConfig = getColor(this.parkingSpaces[area][parkingSpaceId]);
                        // Construct the polygon, including both paths.
                        let parkingSpace = new google.maps.Polyline({
                            path: path,
                            strokeColor: colorConfig.strokeColor,
                            strokeOpacity: colorConfig.strokeOpacity,
                            strokeWeight: 1,
                            // fillColor: colorConfig.fillColor,
                            // fillOpacity: colorConfig.fillOpacity,
                            zIndex: 3.,
                            // position: center
                        });
                        Object.assign(parkingSpace, {
                            data: {
                                id: parkingSpaceId,
                                area: area,
                                scale: MAP_OPTIONS.ParkingSpace
                            }
                        });
                        result.push(parkingSpace);
                        parkingSpace.setMap(map);
                        google.maps.event.addListener(parkingSpace, "click", clickEvent || globalThis.purgatoryService?.clickPolygon);
                    }
                    else if (this.parkingSpaces[area][parkingSpaceId].Bounds.type == "Polygon") {
                        let parkingSpaceBounds = this.parkingSpaces[area][parkingSpaceId].Bounds.coordinates;
                        let parkingSpacePaths = [];
                        let center = { lat: 0, lng: 0 };
                        for (let r = 0; r < parkingSpaceBounds.length; r++) {
                            center = { lat: 0, lng: 0 };
                            let ring = [];
                            for (let p = 0; p < parkingSpaceBounds[r].length; p++) {
                                let latlng = {
                                    lat: parkingSpaceBounds[r][p][1],
                                    lng: parkingSpaceBounds[r][p][0]
                                };
                                if (bounds)
                                    bounds.extend(latlng);
                                center.lat += latlng.lat;
                                center.lng += latlng.lng;
                                ring.push(latlng);
                            }
                            center.lat /= parkingSpaceBounds[r].length;
                            center.lng /= parkingSpaceBounds[r].length;
                            parkingSpacePaths.push(ring);
                        }
                        if (parkingSpacePaths.length == 1) {
                            parkingSpacePaths = parkingSpacePaths[0];
                        }
                        this.parkingSpaces[area][parkingSpaceId].Center = center;
                        const colorConfig = getColor(this.parkingSpaces[area][parkingSpaceId]);
                        // 2022-10-11 Ivan, hoe kan dit mooier?
                        // Bekijk getColor() functie hierboven ^^^^^^^^^^^^^
                        // if(this.parkingSpaces[area][parkingSpaceId].Attributes["BO.Color"] )
                        // {
                        //   const bo_color = this.get_legend_from_name(this.parkingSpaces[area][parkingSpaceId].Attributes["BO.Color"], true)
                        //   if(bo_color)
                        //   {
                        //     colorConfig.fillColor = bo_color.fill;
                        //     colorConfig.strokeColor = bo_color.stroke;
                        //   }
                        // }
                        // Construct the polygon, including both paths.
                        let parkingSpace = new google.maps.Polygon({
                            paths: parkingSpacePaths,
                            strokeColor: colorConfig.strokeColor,
                            strokeOpacity: colorConfig.strokeOpacity,
                            strokeWeight: .5,
                            fillColor: colorConfig.fillColor,
                            fillOpacity: colorConfig.fillOpacity,
                            zIndex: 3.,
                            // position: center
                        });
                        Object.assign(parkingSpace, {
                            data: {
                                id: parkingSpaceId,
                                area: area,
                                scale: MAP_OPTIONS.ParkingSpace
                            }
                        });
                        // Apply ref for fast lookup
                        this.parkingSpaces[area][parkingSpaceId].ref = parkingSpace;
                        result.push(parkingSpace);
                        parkingSpace.setMap(map);
                        google.maps.event.addListener(parkingSpace, "click", clickEvent || globalThis.purgatoryService?.clickPolygon);
                    }
                }
            }
        }
        this.setCache(KEY, result);
        return result;
    }
    /**
     * Creates areas on the map
     * @param {any} map Google Maps reference
     * @param {any} bounds Bounds to extend
     * @param {string} filterArea
     */
    async showAreasOnMap(map, bounds, filterArea, clickEvent) {
        const KEY = 'showAreasOnMap';
        if (this.cache[KEY]) {
            if (clickEvent) {
                for (const polygon of this.cache[KEY]) {
                    google.maps.event.addListener(polygon, "click", clickEvent || globalThis.purgatoryService?.clickPolygon);
                }
            }
            this.redirectPolygons(map, this.cache[KEY]);
            return this.cache[KEY];
        }
        let result = [];
        for (let areaid in this.areas) {
            let area = this.areas[areaid].Name;
            if (!filterArea || filterArea == area) {
                let center = { lat: 0, lng: 0 };
                let areaBounds = this.areas[areaid].Bounds.coordinates;
                let srid = this.areas[areaid].SRID;
                let areaPaths = [];
                for (let r = 0; r < areaBounds.length; r++) {
                    let ring = [];
                    for (let p = 0; p < areaBounds[r].length; p++) {
                        let latlng;
                        if (srid == 4326) {
                            latlng = { lat: areaBounds[r][p][1], lng: areaBounds[r][p][0] };
                        }
                        else {
                            latlng = { lat: areaBounds[r][p][0], lng: areaBounds[r][p][1] };
                        }
                        if (r == 0) {
                            center.lat += latlng.lat;
                            center.lng += latlng.lng;
                        }
                        ring.push(latlng);
                        if (bounds)
                            bounds.extend(latlng);
                    }
                    if (r == 0) {
                        center.lat /= areaBounds[r].length;
                        center.lng /= areaBounds[r].length;
                    }
                    areaPaths.push(ring);
                }
                if (areaPaths.length == 1) {
                    areaPaths = areaPaths[0];
                }
                // Construct the polygon, including both paths.
                const colorConfig = {
                    strokeColor: '#ffffff',
                    fillColor: '#ffffff',
                    strokeOpacity: 1.0,
                    fillOpacity: 0.5
                };
                this.areas[areaid].Center = center;
                let polygon = new google.maps.Polygon({
                    paths: areaPaths,
                    strokeColor: colorConfig.strokeColor,
                    strokeOpacity: colorConfig.strokeOpacity,
                    strokeWeight: 1,
                    fillColor: colorConfig.fillColor,
                    fillOpacity: colorConfig.fillOpacity,
                    zIndex: 1.,
                    // position: center
                });
                polygon.setMap(map);
                Object.assign(polygon, {
                    data: {
                        id: areaid,
                        area: area,
                        scale: MAP_OPTIONS.Area
                    }
                });
                result.push(polygon);
                google.maps.event.addListener(polygon, "click", clickEvent || globalThis.purgatoryService?.clickPolygon);
            }
        }
        this.setCache(KEY, result);
        return result;
    }
    /**
     * Creates retracable zones on the map
     * @param {any} map Google Maps reference
     * @param {any} bounds Bounds to extend
     * @param {string} filterArea
     */
    async showZonesOnMap(map, bounds, filterArea, clickEvent) {
        const KEY = 'showZonesOnMap';
        if (this.cache[KEY]) {
            if (clickEvent) {
                for (const polygon of this.cache[KEY]) {
                    google.maps.event.addListener(polygon, "click", clickEvent || globalThis.purgatoryService?.clickPolygon);
                }
            }
            this.redirectPolygons(map, this.cache[KEY]);
            return this.cache[KEY];
        }
        let result = [];
        for (let area in this.zones) {
            if (!filterArea || filterArea == area) {
                for (let zoneId in this.zones[area]) {
                    if (this.zones[area][zoneId].Bounds.type == "Polygon") {
                        let zoneBounds = this.zones[area][zoneId].Bounds.coordinates;
                        let zonePaths = [];
                        let center = {
                            lat: 0,
                            lng: 0
                        };
                        for (let r = 0; r < zoneBounds.length; r++) {
                            center = {
                                lat: 0,
                                lng: 0
                            };
                            let ring = [];
                            for (let p = 0; p < zoneBounds[r].length; p++) {
                                let LatLon = {
                                    lat: zoneBounds[r][p][1],
                                    lng: zoneBounds[r][p][0]
                                };
                                center.lat += LatLon.lat;
                                center.lng += LatLon.lng;
                                ring.push(LatLon);
                                if (bounds)
                                    bounds.extend(LatLon);
                            }
                            center.lat /= zoneBounds[r].length;
                            center.lng /= zoneBounds[r].length;
                            zonePaths.push(ring);
                        }
                        if (zonePaths.length == 1) {
                            zonePaths = zonePaths[0];
                        }
                        // Construct the polygon, including both paths.
                        const colorConfig = {
                            strokeColor: '#ffffff',
                            fillColor: '#ffffff',
                            strokeOpacity: 1.0,
                            fillOpacity: 0.5
                        };
                        let zone = new google.maps.Polygon({
                            paths: zonePaths,
                            strokeColor: colorConfig.strokeColor,
                            strokeOpacity: colorConfig.strokeOpacity,
                            strokeWeight: 1,
                            fillColor: colorConfig.fillColor,
                            fillOpacity: colorConfig.fillOpacity,
                            zIndex: 2.,
                            // position: center
                        });
                        this.zones[area][zoneId].Center = center;
                        zone.setMap(map);
                        Object.assign(zone, {
                            data: {
                                id: zoneId,
                                area: area,
                                scale: MAP_OPTIONS.Zone
                            }
                        });
                        result.push(zone);
                        google.maps.event.addListener(zone, "click", clickEvent || globalThis.purgatoryService?.clickPolygon);
                    }
                }
            }
        }
        this.setCache(KEY, result);
        return result;
    }
    /**
     * Creates segments on the map
     * @param {any} map Google Maps reference
     * @param {any} bounds Bounds to extend
     */
    async showSegmentsOnMap(map, bounds, filterArea, clickEvent) {
        const KEY = 'showSegmentsOnMap';
        if (this.cache[KEY]) {
            this.redirectPolygons(map, this.cache[KEY]);
            if (clickEvent) {
                for (const polygon of this.cache[KEY]) {
                    google.maps.event.addListener(polygon, "click", clickEvent || globalThis.purgatoryService?.clickPolygon);
                }
            }
            return this.cache[KEY];
        }
        let result = [];
        let emptyCount = 0;
        for (let area in this.segments) {
            console.log(area, filterArea);
            if (!filterArea || filterArea == area) {
                const segments = this.segments[area];
                for (let segmentIndex in segments) {
                    let segment = segments[segmentIndex];
                    let segmentBounds = segment.Bounds.coordinates;
                    let segmentPaths = [];
                    let center = { lat: 0, lng: 0 };
                    for (let r = 0; r < segmentBounds.length; r++) {
                        center = { lat: 0, lng: 0 };
                        let ring = [];
                        for (let p = 0; p < segmentBounds[r].length; p++) {
                            let LatLon = {
                                lat: segmentBounds[r][p][1],
                                lng: segmentBounds[r][p][0]
                            };
                            center.lat += LatLon.lat;
                            center.lng += LatLon.lng;
                            ring.push(LatLon);
                            if (bounds)
                                bounds.extend(LatLon);
                        }
                        center.lat /= segmentBounds[r].length;
                        center.lng /= segmentBounds[r].length;
                        segmentPaths.push(ring);
                    }
                    if (segmentPaths.length == 1) {
                        segmentPaths = segmentPaths[0];
                    }
                    segment.Center = center;
                    // Construct the polygon, including both paths.
                    let polygon = new google.maps.Polygon({
                        paths: segmentPaths,
                        strokeColor: '#000000',
                        strokeOpacity: 0.3,
                        strokeWeight: 1,
                        fillColor: '#888888',
                        fillOpacity: 0.2,
                        zIndex: 2.,
                        // position: center
                    });
                    polygon.setMap(map);
                    Object.assign(polygon, {
                        data: {
                            segmentIndex: segmentIndex,
                            id: segment.id,
                            area: segment.Area,
                            scale: MAP_OPTIONS.Segment
                        }
                    });
                    result[segmentIndex] = polygon;
                    google.maps.event.addListener(polygon, "click", clickEvent || globalThis.purgatoryService?.clickPolygon);
                }
            }
        }
        console.log(emptyCount);
        this.setCache(KEY, result);
        return result;
    }
    /**
     * Toggles all polygon scales off except for the one given as argument
     * @param {any} bitmask polygon scale to be shown
     */
    toggleTo(bitmask) {
        this.toggleParkingSpaces(this, (bitmask & MAP_OPTIONS.ParkingSpace) !== 0);
        this.toggleZones(this, (bitmask & MAP_OPTIONS.Zone) !== 0);
        this.toggleAreas(this, (bitmask & MAP_OPTIONS.Area) !== 0);
        this.toggleSegments(this, (bitmask & MAP_OPTIONS.Segment) !== 0);
    }
    // private toggleAny(mapOption: MAP_OPTIONS, visible?: boolean) {
    async toggleAny(opt, visible) {
        const { collectionKey, boolKey, loadPolygons } = opt;
        if (!this.hasOwnProperty(collectionKey)) {
            this[collectionKey] = await loadPolygons({ parseToMap: true });
        }
        const collection = this[collectionKey];
        PageScript[boolKey] = visible !== undefined ? visible : PageScript[boolKey];
        if (collection instanceof Array) {
            await asyncMapArray(collection, 100, (polygon) => {
                polygon.setOptions({ visible });
            });
        }
        else {
            await asyncMapArray(Object.keys(collection), 100, (key) => {
                collection[key].setOptions({ visible });
            });
        }
    }
    /**
     * Toggles visibility of parking spaces
     * @param {boolean} visible whether to show or hide the polygons
     * @param {any} self the context in which to get the polygons
     */
    toggleParkingSpaces(self, visible) {
        if (self.ParkingSpacesOnMap === undefined) {
            throw new Error(`self.ParkingSpacesOnMap doesn't exist!`);
        }
        PageScript.vParkingSpaces = visible !== undefined ? visible : !PageScript.vParkingSpaces;
        if (self.ParkingSpacesOnMap instanceof Array) {
            for (const polygon of self.ParkingSpacesOnMap) {
                polygon.setOptions({ visible });
            }
        }
        else {
            for (const key in self.ParkingSpacesOnMap) {
                self.ParkingSpacesOnMap[key].setOptions({ visible });
            }
        }
    }
    /**
     * Toggles visibility of zones
     * @param {boolean} visible whether to show or hide the polygons
     * @param {any} self the context in which to get the polygons
     */
    toggleZones(self, visible) {
        if (self.ZonesOnMap === undefined) {
            throw new Error(`self.ZonesOnMap doesn't exist!`);
        }
        PageScript.vZones = visible !== undefined ? visible : !PageScript.vZones;
        if (self.ZonesOnMap instanceof Array) {
            for (const polygon of self.ZonesOnMap) {
                polygon.setOptions({ visible });
            }
        }
        else {
            for (const key in self.ZonesOnMap) {
                self.ZonesOnMap[key].setOptions({ visible });
            }
        }
    }
    /**
     * Toggles visibility of areas
     * @param {boolean} visible whether to show or hide the polygons
     * @param {any} self the context in which to get the polygons
     */
    toggleAreas(self, visible) {
        if (self.AreasOnMap === undefined) {
            throw new Error(`self.AreasOnMap doesn't exist!`);
        }
        PageScript.vAreas = visible !== undefined ? visible : !PageScript.AreasOnMap;
        if (self.AreasOnMap instanceof Array) {
            for (const polygon of self.AreasOnMap) {
                polygon.setOptions({ visible });
            }
        }
        else {
            for (const key in self.AreasOnMap) {
                self.AreasOnMap[key].setOptions({ visible });
            }
        }
    }
    /**
     * Toggles visibility of segments
     * @param {boolean} visible whether to show or hide the polygons
     * @param {any} self the context in which to get the polygons
     */
    toggleSegments(self, visible) {
        if (self.SegmentsOnMap === undefined) {
            throw new Error(`self.SegmentsOnMap doesn't exist!`);
        }
        PageScript.vSegments = visible !== undefined ? visible : !PageScript.vSegments;
        if (self.SegmentsOnMap instanceof Array) {
            for (const polygon of self.SegmentsOnMap) {
                polygon.setOptions({ visible });
            }
        }
        else {
            for (const key in self.SegmentsOnMap) {
                self.SegmentsOnMap[key].setOptions({ visible });
            }
        }
    }
    /**
     * Whether the propertyName is already loaded
     * @param {string} propertyName
     */
    isLoaded(propertyName) {
        return this.requested[propertyName] === true && this[propertyName] && Object.keys(this[propertyName]).length > 0;
    }
    /**
     * Whether the propertyName is loading
     * @param {string} propertyName
     */
    isLoading(propertyName) {
        return this.requested[propertyName] instanceof Promise;
    }
    /**
     * Load areas directly into memory
     */
    loadAreas() {
        const name = 'areas';
        if (this.isLoaded(name)) {
            return Promise.resolve();
        }
        if (this.isLoading(name)) {
            return this.requested[name];
        }
        AEngine.log(`Fetching Polygon Areas`);
        let promise = requestService.query({
            Name: name,
            Query: "select AreaId, a.Name as Area, ST_AsGeoJSON(Bounds) as Bounds, ST_SRID(Bounds) as Srid, CAST(a.Attributes as JSON) as Attributes from geo_areas a where Active=true and Visible=true and Bounds is not null;",
        }).then((Data) => {
            // @ts-ignore
            this[name] = {};
            for (let i = 0; i < Data.Rows.length; i++) {
                this[name][Data.Rows[i][0]] = {
                    "AreaId": Data.Rows[i][0],
                    "Name": Data.Rows[i][1],
                    "Bounds": Data.Rows[i][2],
                    "SRID": Data.Rows[i][3],
                    "Attributes": Data.Rows[i][4]
                };
            }
            this.requested[name] = true;
        }).catch(AError.handle);
        this.requested[name] = promise;
        return promise;
    }
    /**
     * Load zones directly into memory
     */
    loadZones() {
        const name = 'zones';
        if (this.isLoaded(name)) {
            return Promise.resolve();
        }
        if (this.isLoading(name)) {
            return this.requested[name];
        }
        AEngine.log(`Fetching Polygon Zones`);
        let promise = requestService.query({
            Name: name,
            Query: "select IFNULL(a.Name, 'Unknown') as Area, z.ZoneId, z.Name, ST_AsGeoJSON(z.Bounds) as Bounds, CAST(z.Attributes as JSON) as Attributes from geo_zones z left join geo_areas a using(AreaId) where z.Active=true and z.Visible=true and z.Bounds is not null",
        }).then((Data) => {
            // @ts-ignore
            this[name] = {};
            for (let i = 0; i < Data.Rows.length; i++) {
                if (this[name][Data.Rows[i][0]] == null) {
                    this[name][Data.Rows[i][0]] = {};
                }
                this[name][Data.Rows[i][0]][Data.Rows[i][1]] = {
                    "Id": Data.Rows[i][1],
                    "Name": Data.Rows[i][2],
                    "Bounds": Data.Rows[i][3],
                    "Attributes": Data.Rows[i][4]
                };
            }
            this.requested[name] = true;
        }).catch(AError.handle);
        this.requested[name] = promise;
        return promise;
    }
    /**
     * Load parking spaces directly into memory
     */
    loadParkingSpaces() {
        const name = 'parkingSpaces';
        if (this.isLoaded(name)) {
            return Promise.resolve();
        }
        if (this.isLoading(name)) {
            return this.requested[name];
        }
        AEngine.log(`Fetching Polygon ParkingSpaces`);
        let promise = requestService.query({
            Name: name,
            Query: "select IFNULL(a.Name, 'Unknown') as Area, p.ParkingSpaceId, p.Name, ST_AsGeoJSON(p.Bounds) as Bounds, CAST(p.Attributes as JSON) as Attributes from geo_parkingspaces p left join geo_areas a using(AreaId) where p.Active=true and p.Visible=true and p.Bounds is not null"
        }).then((Data) => {
            // @ts-ignore
            this[name] = {};
            for (let i = 0; i < Data.Rows.length; i++) {
                if (this[name][Data.Rows[i][0]] == null) {
                    this[name][Data.Rows[i][0]] = {};
                }
                this[name][Data.Rows[i][0]][Data.Rows[i][1]] = {
                    "Name": Data.Rows[i][2],
                    "Bounds": Data.Rows[i][3],
                    "Attributes": Data.Rows[i][4],
                    "ref": null
                };
            }
            this.requested[name] = true;
        }).catch(AError.handle);
        this.requested[name] = promise;
        return promise;
    }
    /**
     * Load segments directly into memory
     */
    loadSegments() {
        const name = 'segments';
        if (this.isLoaded(name)) {
            return Promise.resolve();
        }
        if (this.isLoading(name)) {
            return this.requested[name];
        }
        AEngine.log(`Fetching Polygon Segments`);
        let promise = requestService.query({
            Name: name,
            Query: "SELECT IFNULL(a.Name, 'Unknown') as Area, z.SegmentId, z.Name, ST_AsGeoJSON(z.Bounds) as Bounds, CAST(z.Attributes as JSON) as Attributes from geo_segments z left join geo_areas a using(AreaId) where z.Active=true and z.Visible=true and z.Bounds is not null"
        }).then((Data) => {
            // @ts-ignore
            this[name] = {};
            for (let i = 0; i < Data.Rows.length; i++) {
                let obj = Data.Rows[i][3];
                Object.assign(obj, {
                    area: Data.Rows[i][0]
                });
                if (this[name][Data.Rows[i][0]] == null) {
                    this[name][Data.Rows[i][0]] = {};
                }
                this[name][Data.Rows[i][0]][Data.Rows[i][1]] = {
                    "id": Data.Rows[i][1],
                    "Area": Data.Rows[i][0],
                    "Bounds": Data.Rows[i][3],
                    "Attributes": Data.Rows[i][4]
                };
            }
            this.requested[name] = true;
        }).catch(AError.handle);
        this.requested[name] = promise;
        return promise;
    }
    /**
     * Load specific settings
     * @param {Number} options enum stating which scales to load
     */
    load(options) {
        if (options === undefined)
            return Promise.reject(new Error(`No option selected for MapHelper.load`));
        let promises = [];
        if (options & MAP_OPTIONS.ParkingSpace) {
            promises.push(this.loadParkingSpaces());
        }
        if (options & MAP_OPTIONS.Zone) {
            promises.push(this.loadZones());
        }
        if (options & MAP_OPTIONS.Area) {
            promises.push(this.loadAreas());
        }
        if (options & MAP_OPTIONS.Segment) {
            promises.push(this.loadSegments());
        }
        return Loading.waitForPromises(promises);
    }
    /**
     * Load both parking spaces and segments
     */
    loadAll() {
        return this.load(MAP_OPTIONS.All);
    }
    showOnMap(bitmask, click) {
        const methods = [];
        if (bitmask & MAP_OPTIONS.ParkingSpace) {
            methods.push(this.showParkingSpacesOnMap);
        }
        if (bitmask & MAP_OPTIONS.Zone) {
            methods.push(this.showZonesOnMap);
        }
        if (bitmask & MAP_OPTIONS.Area) {
            methods.push(this.showAreasOnMap);
        }
        if (bitmask & MAP_OPTIONS.Segment) {
            methods.push(this.showSegmentsOnMap);
        }
        const { map } = PageScript;
        const bounds = PageScript.bounds || DefaultBounds();
        const filterArea = GetFilterArea();
        return this.load(bitmask).then(() => Promise.all(methods.map(loadPolygons => loadPolygons.call(this, map, bounds, filterArea, click)))).catch(AError.handle);
    }
    // prepareMapItems(bitmask: number, { showOneScale, createToggleItems, updateStaticPolygons, allowExport, showLegend, fitPolygons }: AUIMapOptions)
    prepareMapItems(bitmask, options = {}) {
        const { showOneScale = false, createToggleItems = true, updateStaticPolygons = false, allowExport = false, showLegend = false, fitPolygons = false, click } = options || {};
        return Loading.waitForPromises(this._prepareMapItems(bitmask, {
            showOneScale,
            createToggleItems,
            updateStaticPolygons,
            allowExport,
            showLegend,
            fitPolygons,
            click
        }));
    }
    async _prepareMapItems(bitmask, options) {
        const { createToggleItems, updateStaticPolygons, showLegend, allowExport, showOneScale, click, fitPolygons } = options;
        return await Loading.waitForPromises([
            // Map Hamburger menu
            this.createMapDropdown({ order: 1 }),
            // Map Scale toggle menu
            (createToggleItems === true) ? this.createMapToggleSettings(bitmask, {
                order: 2,
                showOneScale,
                click,
                fitPolygons
            }) : Promise.resolve(),
            // Map Fullscreen button
            menuService.addMapButton({
                order: 3,
                icon: 'fa-solid fa-expand',
                position: MAP_POSITION.TOP_LEFT
            }).then($btn => {
                $btn.on('click', _ => toggleFullScreen('#map'));
                const $fa = $btn.find('i');
                Events.on(EVENTS.TOGGLE_FULLSCREEN, (v) => {
                    $fa.toggleClass('fa-compress', v);
                    $fa.toggleClass('fa-expand', !v);
                });
            }),
            menuService.addMapButtonRadio({
                order: 99,
                titles: ['Map', 'Sattelite'],
                position: MAP_POSITION.TOP_RIGHT
            }).then(([$map, $sattelite]) => {
                $map.on('click', _ => this.setMapType(google.maps.MapTypeId.ROADMAP));
                $sattelite.on('click', _ => this.setMapType(google.maps.MapTypeId.HYBRID));
            }),
            // Update polygon visibility
            (updateStaticPolygons === true) ? this.updateStaticPolygons() : Promise.resolve(),
            // Show legend if enabled
            (showLegend === true) ? this.setDetectionsLegendDefault() : Promise.resolve(),
            // Show export if enabled
            (allowExport) ? this.createDownloadButton({ order: 10 }) : Promise.resolve(),
            // Create detection count at the bottom of the map
            this.createCountLabel(),
            // Change map bounds to fit the contents
            Promise.resolve().then(_ => PageScript.map && PageScript.map.fit && PageScript.map.fit()),
            // Create safe-zone for streetview btn to appear
            (PageScript.map.streetViewControlOptions?.position === 7) ? Promise.resolve()
                .then(_ => AEngine.get(AMapOverlayService).setOffset(MAP_POSITION.TOP_RIGHT, '0 45px 0 0')) : Promise.resolve()
        ]);
    }
    /**
     * Changes the color of the marker
     * @param {any} marker
     * @param {Object} options
     */
    changeColorMarker(marker, options) {
        marker.setOptions(options);
    }
    /**
     * Reverts polygon colors & opacity to the initial colors
     * @param {Number} map_options
     */
    revertColors(map_options = MAP_OPTIONS.All) {
        if (map_options & MAP_OPTIONS.Area) {
            if (this.AreasOnMap.length) {
                this.AreasOnMap.map((marker) => {
                    const { strokeColor, strokeOpacity, fillColor, fillOpacity } = marker.data;
                    marker.setOptions({
                        strokeColor, strokeOpacity, fillColor, fillOpacity
                    });
                });
            }
        }
        if (map_options & MAP_OPTIONS.Zone) {
            if (this.ZonesOnMap.length) {
                this.ZonesOnMap.map((marker) => {
                    const { strokeColor, strokeOpacity, fillColor, fillOpacity } = marker.data;
                    marker.setOptions({
                        strokeColor, strokeOpacity, fillColor, fillOpacity
                    });
                });
            }
        }
        if (map_options & MAP_OPTIONS.ParkingSpace) {
            if (this.ParkingSpacesOnMap.length) {
                this.ParkingSpacesOnMap.map((marker) => {
                    const { strokeColor, strokeOpacity, fillColor, fillOpacity } = marker.data;
                    marker.setOptions({
                        strokeColor, strokeOpacity, fillColor, fillOpacity
                    });
                });
            }
        }
    }
    /**
     * Finds the markers that are saved to the PageScript object
     */
    fetchMarkers() {
        return PageScript.Markers || PageScript.markers || [];
    }
    /**
     * Deletes polygons (zones, areas, parking spaces or markers) from the map
     * @param {any} arrayOrObject
     */
    destroy(arrayOrObject) {
        if (arrayOrObject === undefined)
            return;
        const eventsToClear = ['click', 'rightclick'];
        if (arrayOrObject instanceof Array) {
            let array = arrayOrObject;
            if (array.length === 0) {
                return array;
            }
            for (let index in array) {
                for (const key of eventsToClear) {
                    google.maps.event.clearListeners(array[index], key);
                }
                array[index].setMap(null);
                delete array[index];
            }
            if (array.length) {
                array.length = 0;
                // @ts-ignore
                array = null;
            }
        }
        else {
            this.destroy(convertObjectToArray(arrayOrObject));
        }
    }
    /**
     * Unloads polygons (zones, areas, parking spaces or markers) from the map
     * @param {any} arrayOrObject polygons on the map
     * @param {Number} options type of unload
     */
    unload(arrayOrObject, options = UNLOAD_OPTIONS.AllListeners) {
        const eventsToClear = [
            'bounds_changed',
            'center_changed',
            'click',
            'dblclick',
            'drag',
            'dragend',
            'dragstart',
            'heading_changed',
            'idle',
            'maptypeid_changed',
            'mousemove',
            'mouseout',
            'mouseover',
            'projection_changed',
            'resize',
            'rightclick',
            'tilesloaded',
            'tilt_changed',
            'zoom_changed',
        ];
        if (arrayOrObject instanceof Array) {
            const array = arrayOrObject;
            if (array.length === 0) {
                return array;
            }
            switch (options) {
                case UNLOAD_OPTIONS.Default:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        for (const key of eventsToClear) {
                            google.maps.event.clearListeners(array[index], key);
                        }
                        array[index].setMap(null);
                        // }
                    }
                    break;
                case UNLOAD_OPTIONS.AllListeners:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        for (const key of eventsToClear) {
                            google.maps.event.clearListeners(array[index], key);
                        }
                        array[index].setMap(null);
                        // }
                    }
                    break;
                case UNLOAD_OPTIONS.None:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        array[index].setMap(null);
                        // }
                    }
                    break;
                default:
                    throw new Error(`MapHelperService.unload(... , ${options}) contains unknown options.`);
            }
        }
        else {
            this.unload(convertObjectToArray(arrayOrObject));
        }
    }
    getColors(detectionData) {
        let FillColor = '';
        let StrokeColor = '';
        const set = (f, o) => {
            if (f !== null && FillColor === '')
                FillColor = f;
            if (o !== null && StrokeColor === '')
                StrokeColor = o;
        };
        let { IsIllegallyParked, HasParkingRight } = detectionData;
        if (HasParkingRight === null) {
            const { fill, stroke } = this.get_legend_grey(false); // AConfig.get('drawing & colors.detections.unknown')
            set(fill, stroke);
        }
        if (HasParkingRight === 0) {
            const { fill, stroke } = this.get_legend_red(false); // AConfig.get('drawing & colors.detections.noParkingRight')
            set(fill, stroke);
        }
        if (IsIllegallyParked) {
            const { stroke } = this.get_legend_brown_outline(); // AConfig.get('drawing & colors.detections.illegallyParked')
            set(null, stroke);
        }
        if (FillColor === null) {
            const { fill, stroke } = this.get_legend_green(false); // AConfig.get('drawing & colors.detections.default')
            set(fill, stroke);
        }
        return { FillColor, StrokeColor };
    }
    /**
     * Gets the bounds of the map
     */
    getMapBounds() {
        const output = {};
        const { map } = PageScript;
        if (!map) {
            return null;
        }
        const bounds = map.getBounds();
        const json = bounds.toJSON !== undefined ? bounds.toJSON() : bounds.JSON();
        Object.keys(json).map((key) => {
            if (key.length) {
                const newKey = key[0].toUpperCase() + key.substr(1);
                Object.assign(output, { [newKey]: json[key] });
            }
        });
        return output;
    }
    /**
     * Gets points of a Polygon or Marker
     * @param {any} marker Polygon or Marker
     */
    getPoints(marker) {
        if (marker == null) {
            throw new Error(`AMapHelperService.getPoints unexpected marker is not defined!`);
        }
        const path = marker.getPath();
        const length = path.getLength();
        const lnglat = [];
        for (let i = 0; i < length; i++) {
            const { lat, lng } = path.getAt(i).toJSON();
            lnglat.push([lng, lat]);
        }
        return lnglat;
    }
    onGeoClick() {
        const geoInstance = this;
        const pos = AGeoUtils.calcCenter(geoInstance);
        AEngine.get(APurgatoryService).buildAndShowInfoWindowLegacy({
            data: geoInstance.data,
            marker: geoInstance,
            tableFormatter: polygons_tableformatter(),
            greyOutFields: true,
            sorting: [],
        });
        // const { htmlWrapper } = embedJsonInHtml('json-text', JSON.stringify(geoInstance.data!), 2)
        // const infowindow = new google.maps.InfoWindow()
        // infowindow.setContent(htmlWrapper)
        // infowindow.setPosition(pos)
        // infowindow.open(PageScript.map)
    }
    addClickListeners(geoInstances) {
        geoInstances.map((geoInstance) => {
            google.maps.event.addListener(geoInstance, "click", this.onGeoClick);
        });
    }
    addClickListener(geoInstance) {
        google.maps.event.addListener(geoInstance, "click", this.onGeoClick);
    }
}
