import { AColor, HUE_MAX, RGB_MAX, SV_MAX } from "./AColor.js";
export class AColorHSV {
    constructor(h = 1, s = 100, v = 100) {
        this.h = h;
        this.s = s;
        this.v = v;
    }
    get hsl() {
        let { h, s, v } = this;
        h /= HUE_MAX;
        s /= SV_MAX;
        v /= SV_MAX;
        let _h = h, _s = s * v, _l = (2 - s) * v;
        _s /= (_l <= 1) ? _l : 2 - _l;
        _l /= 2;
        return `hsl(${Math.round(_h * HUE_MAX)}, ${Math.round(_s * SV_MAX)}%, ${Math.round(_l * SV_MAX)}%)`;
    }
    get rgb() {
        let { h, s, v } = this;
        h = h === HUE_MAX ? 1 : (h % HUE_MAX / HUE_MAX * 6);
        s = s === SV_MAX ? 1 : (s % SV_MAX / SV_MAX);
        v = v === SV_MAX ? 1 : (v % SV_MAX / SV_MAX);
        const i = Math.floor(h);
        const f = h - i;
        const p = v * (1 - s);
        const q = v * (1 - f * s);
        const t = v * (1 - (1 - f) * s);
        const mod = i % 6;
        const r = [v, q, p, p, t, v][mod];
        const g = [t, v, v, q, p, p][mod];
        const b = [p, p, t, v, v, q][mod];
        return new AColor(Math.round(r * RGB_MAX), Math.round(g * RGB_MAX), Math.round(b * RGB_MAX));
    }
    get normalized() {
        return new AColorHSV(this.h / HUE_MAX, this.s / SV_MAX, this.v / SV_MAX);
    }
    /**
     * Converts the current color into a hexidecimal format.
     * @returns {string}
     *  Color in hexidecimal format.
     */
    get hex() {
        return this.rgb.hex;
    }
    /**
     * Converts the current color into a hexidecimal format with # as prefix.
     * @returns {string}
     *  Color in hexidecimal format prefixed with #.
     */
    get hexi() {
        return this.rgb.hexi;
    }
    rgba(opacity = 1) {
        return this.rgb.rgba(opacity);
    }
    lerpTo(other, t) {
        return interpolateService.colorHSV(this, other, t);
    }
}
