export class AStatisticResponse {
    constructor(data, originalResponse) {
        this.data = data;
        this.originalResponse = originalResponse;
    }
    get isEmpty() {
        return this.data.length === 0;
    }
    toTuple() {
        return this.data;
    }
    defaultApply(stats) {
        return ({
            Total: stats.getTotal(),
            Followups: stats.getSuccessfulFollowUps(),
            Suspects: stats.getSuspects(),
            Sanctions: stats.getSanctions(),
        });
    }
    /**
     * Converts response into array of objects
     * @param apply defaults to {Total, Followups, Suspects, Sanctions, ...}
     */
    toJSON(options) {
        const { apply, replaceNullWithUnknown } = Object.assign({
            apply: this.defaultApply,
            replaceNullWithUnknown: false
        }, options);
        if (replaceNullWithUnknown === true) {
            return this.data.map(([obj, stats]) => {
                const copy = Object.assign({}, obj);
                Object.keys(copy).map(key => {
                    if (copy[key] === null) {
                        copy[key] = Translate.getCacheFast('unknown');
                    }
                });
                return Object.assign(apply(stats), copy);
            });
        }
        return this.data.map(([obj, stats]) => Object.assign(apply(stats), obj));
    }
}
