import { AError } from "../../classes/AError.js";
import { AInputDate } from "../../utils/tools.js";
function combine(...args) {
    // console.log(JSON.stringify(args))
    for (let item of args) {
        Object.keys(item || {}).map(k => {
            if (item[k] === undefined) {
                delete item[k];
            }
        });
    }
    // console.log(JSON.stringify(args))
    return Object.assign.apply(undefined, [{}, ...args]);
}
export class AForm {
    static async genForm(fields, opt) {
        const html = {};
        let ids = fields.map(f => f.id);
        await Promise.all(fields.map(async (field) => {
            if (!field.label) {
                field.label = await Translate.get(field.id);
            }
            if (!AForm.formGeneratorMap.hasOwnProperty(field.type)) {
                console.error(`formGeneratorMap Doesn't contain key=${field.type}`);
                html[field.id] = '';
            }
            else {
                const formGroupHtml = await AForm.formGeneratorMap[field.type](field);
                const wrappedHtml = (opt?.wrapInColumns) ? ( /*html*/`<div contains="${field.id}" class="column ${field.width ?? 'col-12'}">${formGroupHtml}</div>`) : formGroupHtml;
                html[field.id] = wrappedHtml;
            }
        }));
        return await AForm.genFormModular({
            ids,
            html,
            translate: opt?.translate ?? false,
            wrapInColumns: opt?.wrapInColumns ?? false
        });
    }
    static async genFormModular(opt) {
        const innerHtml = opt.ids.map(key => opt.html[key]).join('');
        const html = ( /*html*/`
      <form onsubmit="return false;">
        ${(opt.wrapInColumns === true) ? `<div class="columns aci-align-inputs-bottom">${innerHtml}</div>` : innerHtml}
      </form>
    `);
        return (opt.translate === false) ? html : await requestService.translateDom(html);
    }
    static async injectFormData($form, opt) {
        const { formData, formInputs, triggerChange } = combine({
            formInputs: [],
            formData: {},
            triggerChange: true
        }, opt);
        const promises = $form.find('[name]').toArray().map(inp => $(inp)).map(async ($inp) => {
            const key = $inp.attr('name');
            const type = $inp.attr('aci-type');
            const hasData = formData.hasOwnProperty(key);
            const value = formData[key];
            switch (type) {
                case 'button':
                case 'text':
                    if (hasData) {
                        $inp.val(value);
                    }
                    break;
                case 'textarea':
                case 'number':
                    if (hasData) {
                        $inp.val(value);
                    }
                    break;
                case 'checkbox':
                    if (hasData) {
                        $inp.prop('checked', value);
                    }
                    break;
                case 'time':
                    if (hasData) {
                        $inp.val(value);
                    }
                    break;
                case 'date':
                    if (hasData) {
                        $inp.val(AInputDate(value ?? new Date()));
                    }
                    break;
                case 'select':
                    if (hasData) {
                        $inp.val(value);
                        if ($inp.val() === null) {
                            $inp.val($inp.find('option:first-child').val());
                        }
                    }
                    break;
                case 'multiselect':
                    const inputConf = formInputs.find(conf => conf.type === 'multiselect' && conf.id === key);
                    await globalThis.filterService.fillDropDownTemplate($inp, inputConf?.options || []);
                    break;
                case 'duration':
                    if (hasData) {
                        // console.log('duration', value)
                        const [hh, mm] = (value ?? '00:00').split(':');
                        const $eles = $inp.find('input');
                        if ($eles.length > 1) {
                            $eles.eq(0).val(hh);
                            $eles.eq(1).val(mm);
                        }
                    }
                    break;
                default:
                    AError.handleSilent(`AForm.injectFormData Unexpected form input type! key=${key} aci-type=${type}`);
                    break;
            }
            if (triggerChange) {
                $inp.trigger('change');
            }
        });
        return await Loading.waitForPromises(promises);
    }
    static async initFormValidation($form, formInputs) {
        const genFormInputs = formInputs.map((formInput) => {
            formInput.$input = $form.find(`#${formInput.id}`);
            formInput.toggle = (visible) => {
                let $inputWrapper = formInput.$input.closest(`[contains="${formInput.id}"]`);
                $inputWrapper = $inputWrapper.length ? $inputWrapper : formInput.$input.closest(`.form-group`);
                $inputWrapper.toggleClass('hidden', !visible);
            };
            return formInput;
        });
        for (let input of genFormInputs) {
            const $inp = input.$input;
            // const $inp = $form.find(`#${input.id}`) as JQuery
            const overrideHasError = input.overrideHasError ?? (($inp, hasError) => hasError);
            const onChange = input.onChange ?? ((input, formInputs) => { });
            if (input.disabled === true) {
                $inp.prop('disabled', true);
            }
            const minlength = input.minlength;
            if (minlength !== undefined) {
                $inp.on('change keyup', (e) => {
                    const str = $inp.val();
                    const hasErr = (str.length || 0) < minlength;
                    const isError = overrideHasError($inp, hasErr);
                    onChange(input, genFormInputs);
                    $inp.toggleClass('is-error', isError);
                }).trigger('change');
            }
            if (input.type === 'text') {
                const { regexAllow } = input;
                if (regexAllow) {
                    $inp.on('keypress', (e) => {
                        // console.log(e.key, regexAllow.test(e.key))
                        onChange(input, genFormInputs);
                        return regexAllow.test(e.key);
                    });
                }
            }
            if (input.type === 'select') {
                $inp.on('change', (e) => {
                    const v = ($inp.val() || '');
                    const hasErr = (input.disallowNone === true) ? !(v && v.length && v !== 'null') : false;
                    const isError = overrideHasError($inp, hasErr);
                    onChange(input, genFormInputs);
                    $inp.toggleClass('is-error', isError);
                    // (v && v.length && v !== 'null')$mustFollowUp.prop('checked')
                });
            }
            if (input.type === 'multiselect') {
                $inp.on('change', (e) => {
                    const dd = $inp.data('DropDown');
                    const disabled = dd.isDisabled();
                    const validated = dd.validate();
                    const hasErr = !disabled && !validated;
                    // if (input.disallowNone) {  }
                    const isError = overrideHasError($inp, hasErr);
                    onChange(input, genFormInputs);
                    $inp.toggleClass('is-error', isError);
                });
            }
            if (input.type === 'duration') {
                const $inpArr = $inp.find('input').toArray().map(inp => $(inp));
                $inpArr.map(($inp, i) => {
                    $inp.on('change keyup', (e) => {
                        $inp.val($inp.val().padStart(2, '0'));
                        onChange(input, genFormInputs);
                    });
                });
            }
            if (input.type === 'button') {
                $inp.on('click', (e) => {
                    return input.onClick($form);
                });
            }
        }
        for (let input of genFormInputs) {
            if (input.onChange) {
                input.onChange(input, genFormInputs);
            }
        }
    }
    static convertInputToKeyValue($input, opt) {
        const key = $input.attr('name');
        const type = $input.attr('type') || $input.attr('aci-type') || $input.prop('tagName')?.toLowerCase();
        switch (type) {
            case 'number':
                const num = Number($input.val());
                if (isNaN(num))
                    throw new Error(`Expected number but got value: ${$input.val()}`);
                return { key, value: num };
            case 'text':
            case 'password':
                return { key, value: $input.val() };
            case 'checkbox':
                return { key, value: $input.prop('checked') };
            case 'select':
                let v = $input.val();
                return { key, value: v === 'null' ? null : v };
            case 'multiselect':
                // Check if dropdown
                const dd = $input.data('DropDown');
                if (dd !== undefined) {
                    if (dd.selectedTextsQuery === '%' && opt.ignoreWildcards === true) {
                        return { key, value: null };
                    }
                    return { key, value: dd.selectedKeysWithoutPrefix };
                }
                break;
            case 'duration':
                const $eles = $input.find('input');
                let value = '00:00';
                if ($eles.length > 1) {
                    const hh = $eles.eq(0).val();
                    const mm = $eles.eq(1).val();
                    value = `${(hh ?? '00')}:${(mm ?? '00')}`;
                }
                return { key, value };
            case 'time':
                console.log('time', $input.val());
                break;
            case 'button':
                return undefined;
            case 'textarea':
            case 'date':
            default:
                AError.handleSilent(`Didn't expect input type: ${type}`);
                break;
        }
        return { key, value: $input.val() };
    }
    static extractFormData($form, opt) {
        const keyValuePairs = {};
        let $inputArr = $form.find('[name]').toArray().map(ele => $(ele));
        if (opt.ignoreInvisible) {
            $inputArr = $inputArr.filter($inp => $inp.is(':visible'));
        }
        $inputArr.map(($input) => {
            let keyValue = AForm.convertInputToKeyValue($input, opt);
            if (keyValue !== undefined) {
                let { key, value } = keyValue;
                if (opt.ignoreWildcards !== true) {
                    keyValuePairs[key] = value;
                }
                else if (value !== '%') {
                    keyValuePairs[key] = value;
                }
            }
        });
        return keyValuePairs;
    }
    static genDateSelectInput({ id, label, cls, dateRangeOpt, }) {
        const keys = Object.keys(dateRangeOpt);
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <select id="${id}" name="${id}" class="form-input ${AForm.genInputCls({ cls })}">
          ${keys.map(key => {
            const tKey = key.replace(/ */, ' ');
            return ( /*html*/`
              <option value="${key}">${tKey}</option>
            `);
        }).join('')}
        </select>
      </div>
    `);
    }
    static genNumberInput({ id, label, cls, step, type }) {
        const attrs = [
            `aci-type="${type}"`,
            step !== undefined ? `step="${step}"` : ''
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <input class="form-input ${AForm.genInputCls({ cls })}" type="number" id="${id}" name="${id}" placeholder="0.00" ${attrs}>
      </div>
    `);
    }
    static genCheckboxInput({ id, label, cls, value, type }) {
        const attrs = [
            `aci-type="${type}"`,
            value === true ? `checked="checked"` : ''
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        <label class="form-switch">
          <input id="${id}" name="${id}" type="checkbox" ${attrs}>
          <i class="form-icon ${AForm.genInputCls({ cls })}"></i> ${label ?? id}
        </label>
      </div>
    `);
    }
    static genDateInput({ id, label, cls, value, type }) {
        const attrs = [
            `aci-type="${type}"`,
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <input class="form-input ${AForm.genInputCls({ cls })}" type="date" id="${id}" name="${id}" value="${AInputDate(value ?? new Date())}" ${attrs}>
      </div>
    `);
    }
    static genTimeInput({ id, label, cls, value, type }) {
        const attrs = [
            `aci-type="${type}"`,
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <input class="form-input ${AForm.genInputCls({ cls })}" type="time" id="${id}" name="${id}" value="${value}" ${attrs}>
      </div>
    `);
    }
    static genDurationInput({ id, label, cls, value, type }) {
        const attrs = [
            `aci-type="${type}"`
        ].join(' ');
        const [hh, mm] = (value ?? '00:00:00').split(':');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <div id="${id}" name="${id}" class="input-group group-glue-inputs" ${attrs}>
          <input class="form-input form-inline ${AForm.genInputCls({ cls })}" type="number" id="${id}-hh" value="${hh}" min="0" maxlength="2">
          <input class="form-input form-inline ${AForm.genInputCls({ cls })}" type="number" id="${id}-mm" value="${mm}" min="0" max="59" maxlength="2">
        </div>
      </div>
    `);
    }
    static genTextInput({ id, label, cls, minlength, maxlength, value, type }) {
        const attrs = [
            `aci-type="${type}"`,
            minlength !== undefined ? `minlength="${minlength}"` : '',
            maxlength !== undefined ? `maxlength="${maxlength ?? 64}"` : '',
            value !== undefined ? `value="${value}"` : '',
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <input class="form-input ${AForm.genInputCls({ cls })}" type="text" id="${id}" name="${id}" placeholder="..." ${attrs}>
      </div>
    `);
    }
    static genTextAreaInput({ id, label, cls, minlength, maxlength, value, type }) {
        // { id: string, label: string, minlength?: number, maxlength?: number, value?: string }) {
        const attrs = [
            `aci-type="${type}"`,
            minlength !== undefined ? `minlength="${minlength}"` : '',
            maxlength !== undefined ? `maxlength="${maxlength ?? 64}"` : '',
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <textarea class="form-input ${AForm.genInputCls({ cls })}" type="text" id="${id}" name="${id}" placeholder="..." ${attrs}>${value || ''}</textarea>
      </div>
    `);
    }
    static genButton({ id, cls, label, iconCls, type }) {
        const attrs = [
            `aci-type="${type}"`
        ].join(' ');
        const clsStr = AForm.genInputCls({ cls });
        const clsParts = [
            'btn',
            clsStr.indexOf('btn-') === -1 ? 'btn-primary' : null,
            clsStr
        ].filter(v => v != null).join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label: '', cls })}
        <button class="${clsParts}" id="${id}" name="${id}" placeholder="..." ${attrs}>
          ${iconCls ? `<i class="${iconCls}"></i>` : label ?? id}
        </button>
      </div>
    `);
    }
    // number text textarea time checkbox select multiselect
    static genSelectInput({ id, label, cls, options, type, value }) {
        let opt = [];
        if (options[0] && options[0] instanceof Object) {
            opt = options;
        }
        else {
            opt = options.map(key => {
                return {
                    id: key,
                    text: key
                };
            });
        }
        const attrs = [
            `aci-type="${type}"`
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <select id="${id}" name="${id}" class="form-input ${AForm.genInputCls({ cls })}" ${attrs}>
          ${opt.map(({ id, text }) => {
            if (id === value) {
                return (`<option value="${id}" selected="selected">${text}</option>`);
            }
            else {
                return (`<option value="${id}">${text}</option>`);
            }
        }).join('')}
        </select>
      </div>
    `);
    }
    static genMultiSelectInput({ id, label, cls, disabled, disallowNone, options }) {
        const attrs = [
            `aci-type="multiselect"`,
        ].join(' ');
        const clsList = [
            // disabled ? 'disabled' : null,
            disallowNone ? 'dd-disallow-none' : null,
            AForm.genInputCls({ cls }) ?? null,
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <div id="${id}" name="${id}" class="copycat noselect ${clsList}" ${attrs}>
          <span>None</span>
          <ul class="dropdown"></ul>
        </div>
      </div>
    `);
    }
    static genSeperator(opt) {
        const attrs = [`aci-type="${opt.type}"`,].join(' ');
        return /*html*/ `<div ${attrs} class="divider text-center" data-content=""></div>`;
    }
    static genChartTypeInput({ id, label, cls }) {
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <select class="form-select ${AForm.genInputCls({ cls })}" id="${id}" name="${id}">
          <option value="column">Column Chart</option>
          <option value="line">Line Chart</option>
          <option value="spline">Spline Chart</option>
          <option value="area">Area Chart</option>
          <option value="areaspline">Area Spline Chart</option>
          <option value="scatter">Scatter Chart</option>
          <option value="pie">Pie Chart</option>
        </select>
      </div>
    `);
    }
    static genLabelHtml({ id, label, cls }) {
        const className = (cls !== undefined ? (typeof cls === 'string' ? cls : cls.label) : '') ?? '';
        return `<label class="form-label ${className}" for="${id}">${label ?? id}</label>`;
    }
    static genInputCls({ cls }) {
        const className = (cls !== undefined ? (typeof cls === 'string' ? cls : cls.input) : '') ?? '';
        return className;
    }
}
AForm.formGeneratorMap = {
    number: (field) => AForm.genNumberInput(combine({}, field)),
    text: (field) => AForm.genTextInput(combine({}, field)),
    textarea: (field) => AForm.genTextAreaInput(combine({}, field)),
    button: (field) => AForm.genButton(combine({}, field)),
    date: (field) => AForm.genDateInput(combine({}, field)),
    time: (field) => AForm.genTimeInput(combine({ value: '01:00:00' }, field)),
    duration: (field) => AForm.genDurationInput(combine({ value: '24:00:00' }, field)),
    checkbox: (field) => AForm.genCheckboxInput(combine({}, field)),
    select: (field) => AForm.genSelectInput(combine({}, field)),
    multiselect: (field) => AForm.genMultiSelectInput(combine({ disallowNone: false }, field)),
    seperator: (field) => AForm.genSeperator(field),
};
Object.assign(globalThis, { AForm });
