import { AEngine } from '../core/AEngine.js';
import { AMapHelperService } from './AMapHelperService.js';
export var MAP_POSITION;
(function (MAP_POSITION) {
    MAP_POSITION[MAP_POSITION["TOP_LEFT"] = 0] = "TOP_LEFT";
    MAP_POSITION[MAP_POSITION["TOP_RIGHT"] = 1] = "TOP_RIGHT";
    MAP_POSITION[MAP_POSITION["BOTTOM_LEFT"] = 2] = "BOTTOM_LEFT";
    MAP_POSITION[MAP_POSITION["BOTTOM_RIGHT"] = 3] = "BOTTOM_RIGHT";
})(MAP_POSITION || (MAP_POSITION = {}));
export class AMapOverlayService {
    constructor() {
        this.map = null;
        this.overlays = {};
        this.uids = {};
    }
    autoInit() {
        this.mapHelperService = AEngine.get(AMapHelperService);
    }
    get tabDefinitions() {
        const tabOptions = [
            'ParkingRight',
            'IllegallyParked',
            'Verification',
            'DetectionState',
            'Digital',
            'Default'
        ];
        const tabConfigurations = {
            'Digital': this.mapHelperService.legend_digital,
            'IllegallyParked': this.mapHelperService.legend_illegallyparked,
            'ParkingRight': this.mapHelperService.legend_parkingright,
            'Verification': this.mapHelperService.legend_verification,
            'DetectionState': this.mapHelperService.legend_detection_state,
            'Default': this.mapHelperService.legend_legacy
        };
        return { tabOptions, tabConfigurations };
    }
    getOrCreateOverlay(className) {
        let $overlay = $(`.map-overlay-controls.${className}`);
        if ($overlay.length === 0) {
            $overlay = $(`<div class="map-overlay-controls ${className}"></div>`);
            $('#map').css({ 'position': 'relative' });
            $('#map').prepend($overlay);
        }
        return $overlay;
    }
    getContainer(position) {
        switch (position) {
            case MAP_POSITION.TOP_LEFT: return this.getOrCreateOverlay('top-left');
            case MAP_POSITION.TOP_RIGHT: return this.getOrCreateOverlay('top-right');
            case MAP_POSITION.BOTTOM_LEFT: return this.getOrCreateOverlay('bottom-left');
            case MAP_POSITION.BOTTOM_RIGHT: return this.getOrCreateOverlay('bottom-right');
        }
    }
    clear() {
        this.map = null;
        this.overlays = {};
        this.uids = {};
    }
    /**
     * @param $ele the element to show on top of the map
     * @param position position of the element on top of the map
     * @param options if options.uid is defined, it will delete the previous element with the same uid, if order is defined it'll sort the ui elements
     */
    add($ele, position, { uid, order }) {
        this.insert($ele, { $parent: this.getContainer(position), order, uid });
    }
    /**
     * @param $container
     * @param options if uid is defined, it will delete the previous element with the same uid
     */
    insert($container, { uid, $parent, order }) {
        // Delete previous element if uid is set
        this.delete({ uid });
        this.uids[uid] = $container;
        $container.attr('order', order);
        const $mapOverlays = $parent.find('[order]').toArray().map((e) => $(e));
        for (let $ele of $mapOverlays) {
            if (order < Number($ele.attr('order'))) {
                $container.insertAfter($ele);
                this.sortElements($parent);
                return;
            }
        }
        this.sortElements($parent);
        $parent.append($container);
    }
    delete({ uid }) {
        if (this.uids.hasOwnProperty(uid)) {
            this.uids[uid].remove();
        }
    }
    sortElements($parent) {
        $parent.find('[order]')
            .sort((a, b) => Number($(a).attr('order')) - Number($(b).attr('order')))
            .appendTo($parent);
    }
}
