import { AConfig } from "./classes/AConfig.js";
import { AEngine } from "./core/AEngine.js";
import { APolicyService } from "./services/APolicyService.js";
import { addTabListeners, AInputDate, AInputTime } from "./utils/tools.js";
import { initToolTips } from "./utils/tooltip.js";
export function initPolicies() {
    const policyService = AEngine.get(APolicyService);
    // Change button color when filters change
    policyService.add(_ => {
        const $btn = $('#RefreshButton');
        const FiltersEdited = $btn => {
            if (!$btn.hasClass('changed')) {
                $btn.addClass('changed');
            }
        };
        $('#Filters :input').on('change', _ => {
            FiltersEdited($btn);
        });
        FiltersEdited($btn);
        $btn.on('click', _ => {
            if ($btn.hasClass('changed')) {
                // Change button color to blue
                $btn.removeClass('changed');
                // User actions log
                userActionService.logAction('USER', 'VIEW', FilterManager.save({ cacheFilters: false }));
            }
        });
        $btn.on('aci-event-enabled', (event) => {
            $btn.removeClass('loading');
        });
        $btn.on('aci-event-disabled', (event, options) => {
            if (options.silent === true) {
                return;
            }
            $btn.addClass('loading');
        });
        return true;
    });
    // Add min value to limit filter
    policyService.add(_ => {
        const $limit = $('#Limit');
        $limit.attr('min', 1);
        $limit.attr('max', AConfig.get('filters.maxResultsCeiling', 100000));
        $limit.on('change', e => {
            const $ele = $(e.target);
            const val = parseInt($ele.val());
            if (val <= 0) {
                $ele.val(1);
            }
        });
        return true;
    });
    // Initialize tabviews
    policyService.add(_ => {
        const $aciTabs = $('.aci-tabs');
        if ($aciTabs.length > 0) {
            $(`[tabview]`).hide();
            $aciTabs.each((i, tabs) => {
                addTabListeners($(tabs));
            });
        }
        const $alignedTabs = $('.aci-tabs.tabs-align');
        if ($alignedTabs.length > 0) {
            $alignedTabs.each((i, tabs) => {
                const $tabs = $(tabs);
                const $items = $tabs.find('.aci-tab');
                let maxHeight = -1;
                $items.each((i, tab) => {
                    const $tab = $(tab);
                    const height = $tab.outerHeight();
                    if (height > maxHeight) {
                        maxHeight = height;
                    }
                });
                $items.css('height', maxHeight);
            });
        }
        return true;
    });
    // Remove .display-once after pressing Refresh Button
    policyService.add(async (_) => {
        const $displayOnce = $('.display-once .empty-white .empty-subtitle');
        if ($displayOnce.length > 0) {
            const partial = await Translate.get(`Press '' to fetch results`);
            $('.empty-subtitle').text(partial.replace(`''`, `"${$('#RefreshButton').text()}"`));
        }
        return true;
    });
    // Add Quick select filter options
    policyService.add(async (_) => {
        const $filters = $('#Filters');
        const a = $filters.find('#FromDate');
        const b = $filters.find('#ToDate');
        if ($filters.length === 0 || a.length === 0 || b.length === 0) {
            return true;
        }
        const labelText = await Translate.get('Shortcuts');
        const btns = ['Today', 'Yesterday', 'This week', 'This Month'];
        const btnsT = await Translate.get(btns);
        const $shortcuts = $(`
            <div class="column col-12">
                <div class="form-group">
                    <div class="children-inline-block toggle-shortcut">
                        <label class="form-label">${labelText}</label>
                        <i class="fa-solid fa-chevron-up fa-xs"></i>
                    </div>
                    <div class="shortcut-buttons">
                    ${btns.map((btn, i) => (`
                            <button class="quick-btn quick-${i} btn btn-grey btn-sm full-width" style="display: block; margin-bottom: 3px;">
                                ${btnsT[btn]}
                            </button>
                        `)).join('')}
                    </div>
                </div>
            </div>
        `);
        const $toggleShortcut = $shortcuts.find('.toggle-shortcut');
        const $buttons = $shortcuts.find('.shortcut-buttons');
        FilterManager.createToggle($toggleShortcut, $buttons, preferenceService.load('filters-shortcut-collapsed', false));
        $filters.prepend($shortcuts);
        $filters.find('#FromDate, #FromTime, #ToDate, #ToTime').on('change', e => {
            FilterManager.unselectShortcut();
        });
        FilterManager.highlightSelectedShortcut();
        $filters.find('.quick-0').on('click', e => {
            const { todayDate, tomorrowDate } = FilterManager;
            $filters.find('#FromDate').val(AInputDate(todayDate));
            $filters.find('#FromTime').val(AInputTime(todayDate));
            $filters.find('#ToDate').val(AInputDate(tomorrowDate));
            $filters.find('#ToTime').val(AInputTime(tomorrowDate));
            FilterManager.selectShortcut('.quick-0');
        });
        $filters.find('.quick-1').on('click', e => {
            const { yesterdayDate, todayDate } = FilterManager;
            $filters.find('#FromDate').val(AInputDate(yesterdayDate));
            $filters.find('#FromTime').val(AInputTime(yesterdayDate));
            $filters.find('#ToDate').val(AInputDate(todayDate));
            $filters.find('#ToTime').val(AInputTime(todayDate));
            FilterManager.selectShortcut('.quick-1');
        });
        $filters.find('.quick-2').on('click', e => {
            const { startOfWeek, endOfWeek } = FilterManager;
            $filters.find('#FromDate').val(AInputDate(startOfWeek));
            $filters.find('#FromTime').val(AInputTime(startOfWeek));
            $filters.find('#ToDate').val(AInputDate(endOfWeek));
            $filters.find('#ToTime').val(AInputTime(endOfWeek));
            FilterManager.selectShortcut('.quick-2');
        });
        $filters.find('.quick-3').on('click', e => {
            const { startOfMonth, endOfMonth } = FilterManager;
            $filters.find('#FromDate').val(AInputDate(startOfMonth));
            $filters.find('#FromTime').val(AInputTime(startOfMonth));
            $filters.find('#ToDate').val(AInputDate(endOfMonth));
            $filters.find('#ToTime').val(AInputTime(endOfMonth));
            FilterManager.selectShortcut('.quick-3');
        });
        return true;
    });
    // Initialize tooltip overlays
    policyService.add(_ => {
        initToolTips($('[atooltip]'));
        return true;
    });
    policyService.add(_ => {
        const $ajaxContent = $('#AjaxContent');
        $ajaxContent.toggleClass('use-flex', $ajaxContent.find('#Filters').length > 0);
        return true;
    });
}
