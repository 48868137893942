import { ACrypto } from '../classes/ACrypto.js';
import { AEngine } from '../core/AEngine.js';
import { gpsstat_tableformatter } from '../format/table_formatter.js';
import { AIsLatLngValid } from '../utils/maps.js';
import { AFormatDate, ARound, lerp } from '../utils/tools.js';
import { AInterpolateService } from './AInterpolateService.js';
export class ARouteDrawingService {
    constructor() {
        this.degToRad = Math.PI / 180.0;
    }
    autoInit() {
        this.interpolateService = AEngine.get(AInterpolateService);
    }
    checkPageScriptRequirements() {
        const requiredMembers = [
            'map',
            'bounds',
            '$legend',
            'titles',
            'start',
            'end',
            'legendBounds',
            'calcColorCategory',
            'minGpsDistance',
            'maxTimeDifference'
        ];
        for (let member of requiredMembers) {
            if (!PageScript.hasOwnProperty(member)) {
                if (PageScript.__lookupGetter__(member) === undefined) {
                    throw new Error(`PageScript.${member} is required for ARouteDrawingService!`);
                }
            }
        }
    }
    async showMapRoute(QueryResponse, { map, bounds, category }, $legendParent = null) {
        this.checkPageScriptRequirements();
        let output = [];
        PageScript.$legend.remove();
        if (category === 'speed') {
            PageScript.$legend = $(await this.createLegend(PageScript.titles[category], PageScript.legendBounds[category][0], PageScript.legendBounds[category][1], 'km/h', PageScript, $legendParent));
        }
        else {
            PageScript.$legend = $(await this.createLegendLog(PageScript.titles[category], PageScript.legendBounds[category][0], PageScript.legendBounds[category][1], 'm', PageScript, $legendParent));
        }
        const GpsTimeIndex = QueryResponse.Columns.indexOf("GpsTime");
        const DeviceIndex = QueryResponse.Columns.indexOf("DeviceName");
        const LatitudeIndex = QueryResponse.Columns.indexOf("Latitude");
        const LongitudeIndex = QueryResponse.Columns.indexOf("Longitude");
        const PointsIndex = QueryResponse.Columns.indexOf("Points");
        const PrecisionIndex = QueryResponse.Columns.indexOf("Precision");
        const SpeedIndex = QueryResponse.Columns.indexOf("Speed");
        /** @type {{ gpsTime, deviceName, path?, speed, precision }[]} */
        let lines = [];
        for (let i = 0; i < QueryResponse.Rows.length; i++) {
            const row = QueryResponse.Rows[i];
            const gpsTime = new Date(Date.parse(row[GpsTimeIndex]));
            const deviceName = row[DeviceIndex];
            const latitude = row[LatitudeIndex];
            const longitude = row[LongitudeIndex];
            const precision = row[PrecisionIndex];
            const speed = row[SpeedIndex];
            const points = this.removeAmbigiousPointsLngLat(row[PointsIndex]);
            let path = [];
            let latlng = null;
            switch (points.length) {
                case 0:
                    if (AIsLatLngValid([latitude, longitude])) {
                        latlng = new google.maps.LatLng(latitude, longitude);
                        bounds.extend(latlng);
                        path.push(latlng);
                    }
                    break;
                case 1:
                    if (AIsLatLngValid([points[0][1], points[0][0]])) {
                        latlng = new google.maps.LatLng(points[0][1], points[0][0]);
                        bounds.extend(latlng);
                        path.push(latlng);
                    }
                    break;
                default:
                    points.map((point) => {
                        if (AIsLatLngValid([point[1], point[0]])) {
                            latlng = new google.maps.LatLng(point[1], point[0]);
                            bounds.extend(latlng);
                            path.push(latlng);
                        }
                    });
                    break;
            }
            lines.push({ gpsTime, deviceName, path, speed, precision });
        }
        let lastColor = null;
        let lastPoint = null;
        let lastDevice = null;
        let lastGpsTime = null;
        let lastStroke = null;
        for (let line of lines) {
            const { gpsTime, deviceName, path, speed, precision } = line;
            if (path.length === 0)
                continue;
            const dataKey = category;
            const dataValue = category === 'speed' ? speed : precision;
            if (deviceName == lastDevice && Math.abs(this.gpsTimeDiff(gpsTime, lastGpsTime)) <= PageScript.maxTimeDifference) {
                try {
                    let fill = new google.maps.Polyline({
                        path: [lastPoint, path[path.length - 1]],
                        geodesic: true,
                        strokeColor: lastColor,
                        strokeOpacity: .8,
                        strokeWeight: 6,
                    });
                    fill.data = {
                        _Name: (`${dataKey}: ${dataValue}`),
                        [category]: dataValue
                    };
                    // Used in click method, needs to be inside loop
                    let cachedLastStroke = lastStroke;
                    google.maps.event.addListener(fill, 'click', function (event) {
                        if (cachedLastStroke != null) {
                            new google.maps.event.trigger(cachedLastStroke, 'click', { latLng: event.latLng });
                        }
                    });
                    fill.setMap(map);
                    output.push(fill);
                }
                catch (err) {
                    console.error(err);
                }
            }
            let color = PageScript.calcColorCategory[category]({ category, speed, precision });
            let stroke = new google.maps.Polyline({
                path: path,
                geodesic: true,
                strokeColor: color,
                strokeOpacity: 1,
                strokeWeight: 6
            });
            stroke.data = {
                _Name: (`${dataKey}: ${dataValue}`),
                [category]: dataValue
            };
            google.maps.event.addListener(stroke, 'click', async function (event) {
                // let table = new ATableBuilder(2)
                const data = Object.assign({}, line);
                delete data.path;
                Object.keys(data).map(key => {
                    let val = data[key];
                    if (val instanceof Date) {
                        data[key] = AFormatDate(val);
                    }
                    else if (!isNaN(Number(val))) {
                        data[key] = ARound(Number(val), 2);
                    }
                });
                await Loading.waitForPromises(purgatoryService.buildAndShowInfoWindowLegacy({
                    marker: this,
                    data: data,
                    sorting: [],
                    tableFormatter: gpsstat_tableformatter()
                }));
                // for (let key in data) {
                //   const translated = await Translate.get(
                //     key[0].toUpperCase() + key.substr(-key.length + 1)
                //   )
                //   let value = data[key]
                //   if (value instanceof Date) value = AFormatDate(value)
                //   if (!isNaN(Number(value))) {
                //     table.insert([translated, ARound(Number(value), 2)])
                //   } else {
                //     table.insert([translated, value])
                //   }
                // }
                // purgatoryService.showInfoWindowLegacy({
                //   content: table.build(),
                //   pos: event.latLng,
                //   marker: this
                // })
            });
            stroke.setMap(map);
            output.push(stroke);
            lastColor = color;
            lastPoint = path[0];
            lastDevice = deviceName;
            lastGpsTime = gpsTime;
            lastStroke = stroke;
        }
        return output;
    }
    distanceMetersLon_X(LL1, LL2) {
        const EquatorialDistancePerDeg = 6378137.0 * this.degToRad; /// EquatorialRadius = 6378137.
        return (LL2.lng - LL1.lng) * (EquatorialDistancePerDeg * Math.cos((LL1.lat + LL2.lat) * 0.5 * this.degToRad));
    }
    distanceMetersLat_Y(LL1, LL2) {
        const NorthSouthDistancePerDeg = 6335439.0 * this.degToRad; /// NorthSouthRadius = 6335439.
        return (LL2.lat - LL1.lat) * NorthSouthDistancePerDeg;
    }
    distanceLL(LL1, LL2) {
        const DeltaLatY_SN_Meter = this.distanceMetersLat_Y(LL1, LL2);
        const DeltaLongX_WE_Meter = this.distanceMetersLon_X(LL1, LL2);
        return Math.sqrt(DeltaLatY_SN_Meter * DeltaLatY_SN_Meter + DeltaLongX_WE_Meter * DeltaLongX_WE_Meter);
    }
    gpsTimeDiff(a, b) {
        if (a == null || b == null)
            return 0;
        return (b.getTime() - a.getTime()) / 1000; // difference in seconds
    }
    calcColor({ start, end, t, inverted }) {
        t = Math.min(Math.max(t, 0), 1);
        // @ts-ignore
        return this.interpolateService.colorHSV(start, end, inverted ? 1 - t : t);
    }
    createLegendPart(rgb, innerHTML, unit) {
        return $(`<div style="background: ${rgb.rgba(0.8)};">${innerHTML} ${unit} </div>`);
    }
    /**
     * @returns {Promise<HTMLElement>}
     */
    async createLegend(title, minBound, maxBound, unit, { start, end, inverted }, $legendParent) {
        let $container = $('<div class="legend legend-one columns"><div class="legend-collection"></div></div>');
        $container.prepend(`<label class="legend-label">${title}</label>`);
        let $visual = $container.find('div');
        const amountOfColors = 11;
        for (let i = 0; i < amountOfColors; i++) {
            const t = i / (amountOfColors - 1);
            const rgb = this.calcColor({ start, end, t, inverted }).rgb;
            const decimalMagnitude = inverted ? 10 : 1;
            const innerHTML = Math.round(lerp(minBound, maxBound, i / (amountOfColors - 1)) * decimalMagnitude) / decimalMagnitude;
            $visual.append(this.createLegendPart(rgb, innerHTML, unit));
        }
        $container.attr('id', ACrypto.randomHexString([0, 0, 0, 0, 0]));
        const container = $container.get(0);
        if ($legendParent) {
            $legendParent.after(container);
        }
        else {
            PageScript.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(container);
        }
        // @ts-ignore
        return container;
    }
    async createLegendLog(title, minBound, maxBound, unit, { start, end, inverted }, $legendParent) {
        let $container = $('<div class="legend legend-one columns"><div class="legend-collection"></div></div>');
        $container.prepend(`<label class="legend-label">${title}</label>`);
        let $visual = $container.find('div');
        for (let i = 0; i <= 10; i++) {
            const step = maxBound * Math.pow(2, 0.1 * i) - maxBound;
            const rgb = this.calcColor({ start, end, t: Math.log2(1 + (step / maxBound)), inverted }).rgb;
            $visual.append(this.createLegendPart(rgb, Math.round(step * 100) / 100, unit));
        }
        $container.attr('id', ACrypto.randomHexString([0, 0, 0, 0, 0]));
        const container = $container.get(0);
        if ($legendParent) {
            $legendParent.after(container);
        }
        else {
            PageScript.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(container);
        }
        // @ts-ignore
        return container;
    }
    removeNullValues(points) {
        return points.filter((point) => point[0] != null && point[1] != null && point[0] !== 0 && point[1] !== 0);
    }
    removeAmbigiousPointsLatLng(points) {
        if (points.length <= 1)
            return points;
        let last = { lat: points[0][0], lng: points[0][1] };
        return this.removeNullValues(points).filter((coord) => {
            if (last.lat === coord[0] && last.lng === coord[1])
                return false; // If is exactly the same, filter out
            let point = { lat: coord[0], lng: coord[1] };
            let dist = this.distanceLL(last, point);
            if (dist > PageScript.minGpsDistance) {
                last = point;
                return true;
            }
            return false;
        });
    }
    removeAmbigiousPointsLngLat(points) {
        if (points.length <= 1)
            return points;
        let last = { lat: points[0][1], lng: points[0][0] };
        return this.removeNullValues(points).filter((coord) => {
            if (last.lat === coord[1] && last.lng === coord[0])
                return false; // If is exactly the same, filter out
            let point = { lat: coord[1], lng: coord[0] };
            let dist = this.distanceLL(last, point);
            if (dist > PageScript.minGpsDistance) {
                last = point;
                return true;
            }
            return false;
        });
    }
}
